/* Confirmation Modal Template */

// External
import clsx from "clsx";
import { Inter } from "next/font/google";
import { useDispatch } from "react-redux";

// components
import Button from "@/components/button";

// constants
import { STYLES } from "@/globals/constants";

// store
import { setModalIsOpen } from "../modalSlice";

// styles
import styles from "../styles.module.scss";

const font = Inter({
  weight: ["400", "500", "700"],
  subsets: ["latin"]
});

export interface ConfirmationModalProps {
  message: string;
  title: string;
  onConfirm: () => void;
}

export default function Confirmation({
  message,
  onConfirm
}: ConfirmationModalProps) {
  const dispatch = useDispatch();

  // Event handlers

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const handleConfirm = () => {
    // dispatch confirmation event, e.g. deleting users
    onConfirm();
  };

  return (
    <div className={clsx(styles.Confirm, font.className)}>
      <div className={styles.message}>{message}</div>
      <div className={styles.buttons}>
        <Button style={STYLES.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
        <Button style={STYLES.PRIMARY} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}
