// External
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

// Components
import Button from "@/components/button";
import ControlledCombobox from "@/components/input/controlledCombobox";

// Store
import { TaskType, useTaskCreateMutation } from "@/store/services/task";
import { MODAL_TYPES } from "../dispatcher";
import { setModalContent, setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS } from "@/globals/constants";

// Styles
import styles from "../styles.module.scss";
import { SelectDefaultStyles, SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";

interface TaskCreationProps {
  assigneeId: number;
}

export default function TaskCreation({ assigneeId }: TaskCreationProps) {
  const dispatch = useDispatch();

  const { control, handleSubmit, formState } = useForm();
  const [createTask, createTaskResponse] = useTaskCreateMutation();

  useEffect(() => {
    if (createTaskResponse.isSuccess) {
      dispatch(
        addAlertToToastTrough({
          message: "Task assigned",
          type: STATUS_KEYS.SUCCESS
        })
      );
      dispatch(setModalIsOpen(false));
    } else if (createTaskResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Error assigning task",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  });

  const onSubmit = (data: any) => {
    let taskType: string = data.task_type.value;
    if (taskType == "DEMOGRAPHICS") {
      createTask({
        taskCreatePayload: {
          assignee: assigneeId,
          task_type: "DEMOGRAPHICS" as TaskType
        }
      });
      return;
    }

    const taskTypeLabel: string = taskType
      .substring(0, 1)
      .concat(taskType.substring(1).toLowerCase());

    dispatch(
      setModalContent({
        type: MODAL_TYPES.TASK_CREATION_INFO,
        props: {
          taskType: taskType,
          title: `${taskTypeLabel} task creation`,
          assigneeId: assigneeId
        }
      })
    );
  };

  return (
    <div className={styles.TaskCreation} data-cy="task-creation-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
          <Controller
            name="task_type"
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => (
              <Select
                theme={SelectDefaultTheme}
                styles={SelectDefaultStyles}
                onChange={onChange}
                value={value}
                options={[
                  {
                    value: "FORM",
                    label: "Fill out a form"
                  },
                  {
                    value: "UPLOAD",
                    label: "Upload a file"
                  },
                  {
                    value: "GENERIC",
                    label: "General task"
                  },
                  {
                    value: "DEMOGRAPHICS",
                    label: "Demographics & Insurance"
                  }
                ]}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button type="submit">Next</Button>
        </div>
      </form>
    </div>
  );
}
