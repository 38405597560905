import { SETTINGS_VIEWS, METRICS } from "@/globals/constants";
import CreatePatient from "@/components/forms/createUser";
import { PatientInfo } from "@/store/services/patient";
import { parseParioDate } from "@/utils/api/time";
import dayjs from "dayjs";
import {
  PhoneNumberUtil,
  PhoneNumberFormat,
  PhoneNumber,
  AsYouTypeFormatter
} from "google-libphonenumber";
import { ChangeEvent } from "react";

const FORMS = {
  [SETTINGS_VIEWS.USERS]: <CreatePatient />
};

function calculateAge(birthdate: Date): number {
  const today = new Date();
  const age = today.getFullYear() - birthdate.getFullYear();

  if (
    today.getMonth() < birthdate.getMonth() ||
    (today.getMonth() === birthdate.getMonth() &&
      today.getDate() < birthdate.getDate())
  ) {
    return age - 1;
  }

  return age;
}

function calculateAgeInMonths(birthdate: Date): string {
  // given an infant's birthdate
  // we need to return a string in the following format:
  // "X" months if the infant is more than 4 months old
  // "X weeks, Y days" if the infant is less than 4 months old
  // "X days" if the infant is less than a week old
  // we achieve this using the dayjs library
  const today = dayjs();
  const birthday = dayjs(birthdate);
  const age = today.diff(birthday, "month");
  if (age > 4) {
    return `${age} months`;
  }
  const weeks = today.diff(birthday, "week");
  const days = today.diff(birthday, "day");
  if (weeks > 0) {
    // if weeks is 1, we return "1 week" instead of "1 weeks"
    // if days is 1, we return "1 day" instead of "1 days"
    // if days is 0, we return "X weeks" instead of "X weeks, 0 days"
    return `${weeks} ${weeks === 1 ? "week" : "weeks"}${
      days % 7 === 1 ? ", 1 day" : days % 7 === 0 ? "" : `, ${days % 7} days`
    }`;
  }
  // if days is 1, we return "1 day" instead of "1 days"
  if (days === 1) {
    return `${days} day`;
  }
  return `${days} days`;
}

function getUserPersona(cookie: any) {
  let persona = "";
  if (cookie) {
    if (cookie.is_provider || cookie.is_ma) {
      persona = METRICS.PROVIDER;
    } else if (cookie.is_patient) {
      persona = METRICS.PATIENT;
    } else if (cookie.is_admin) {
      persona = METRICS.ADMIN;
    } else if (cookie.is_biller) {
      persona = METRICS.BILLER;
    }
  }
  return persona;
}

const getGsPs = (patient: PatientInfo) => {
  let gravida = patient.pregnancy ? 1 : 0;
  let termBirths = 0;
  let pretermBirths = 0;
  let abortionsMiscarriages = 0;
  let livingChildren = 0;

  const pretermCutoff = 37 * 7;
  for (const preg of (patient.medical_history.pregnancies || []).filter(
    preg => preg.state !== "DELETED"
  )) {
    if (preg.living) {
      livingChildren += 1;
    }

    gravida += 1;
    function addTermOrPretermBirth() {
      if (preg.gestational_age_at_delivery) {
        termBirths += preg.gestational_age_at_delivery >= pretermCutoff ? 1 : 0;
        pretermBirths +=
          preg.gestational_age_at_delivery < pretermCutoff ? 1 : 0;
      } else {
        // default to term if no gestational age is provided
        termBirths += 1;
      }
    }
    switch (preg.outcome) {
      case "NSVD (vaginal delivery)":
      case "NSVD (vaginal delivery) - Water":
      case "VAVD (vacuum vaginal delivery)":
      case "NSVB (vaginal birth)":
      case "FAVD (forceps vaginal delivery)":
      case "CS (cesarean)":
      case "VBAC (vaginal birth after previous cesarean)":
      case "VBAC (vaginal birth after previous cesarean) - Water":
        addTermOrPretermBirth();
        break;
      default:
        if (preg.outcome) abortionsMiscarriages += 1;
        break;
    }
  }

  const result = {
    gravida,
    termBirths,
    pretermBirths,
    abortionsMiscarriages,
    livingChildren
  };

  return result;
};

function getGestationalAge(dueDateInt: number) {
  const { weeks, days } = getGestationalAgeObjectAtDate(
    dueDateInt,
    parseInt(dayjs().format("YYYYMMDD"))
  );

  return `${weeks} weeks, ${days} days`;
}

function getGestationalAgeObjectAtDate(
  dueDateInt: number,
  calculateDateInt: number
) {
  const edd = dayjs(dueDateInt.toString(), "YYYYMMDD").startOf("day");
  const currentDate = dayjs(calculateDateInt.toString(), "YYYYMMDD").startOf(
    "day"
  );
  const totalDaysDifference = currentDate.diff(edd, "days");

  const weeks = Math.floor(Math.abs(totalDaysDifference) / 7);
  const days = Math.abs(totalDaysDifference) % 7;

  if (totalDaysDifference < 0) {
    const daysOffset = days > 0 ? 1 : 0;
    return {
      weeks: 40 - weeks - daysOffset,
      days: days == 0 ? days : 7 - days
    };
  } else {
    return {
      weeks: 40 + weeks,
      days: days
    };
  }
}

function getGestationalAgeObject(dueDateInt?: number) {
  if (!dueDateInt) return { weeks: "-", days: "-" };
  return getGestationalAgeObjectAtDate(
    dueDateInt,
    parseInt(dayjs().format("YYYYMMDD"))
  );
}

function getPostPartumDuration(completedDateInt: number) {
  const completedDate = parseParioDate(completedDateInt) as Date;

  // Get the current date and time difference
  const currentDate = new Date();
  const timeDiff = currentDate.getTime() - completedDate?.getTime();

  // Calculate the days difference
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

  // Convert days difference to postpartum age
  const postpartumDays = daysDiff;

  const weeks = Math.floor(postpartumDays / 7);
  const days = postpartumDays % 7;

  return `${weeks} ${weeks === 1 ? "week" : "weeks"}, ${days} ${
    days === 1 ? "day" : "days"
  }`;
}

interface NormalizePhoneNumberResult {
  normalizedPhoneNumber?: string;
  error?: string;
}

const normalizePhoneNumber = (
  phoneNumber: string,
  countryCode: string = "US"
): NormalizePhoneNumberResult => {
  try {
    // Create an instance of PhoneNumberUtil
    const phoneUtil = PhoneNumberUtil.getInstance();

    // Parse the phone number with the specified default country and country code
    const parsedPhoneNumber: PhoneNumber = phoneUtil.parse(
      phoneNumber,
      countryCode
    );

    // Format the parsed phone number in INTERNATIONAL format
    const normalizedPhoneNumber: string = phoneUtil.format(
      parsedPhoneNumber,
      PhoneNumberFormat.E164
    );

    return { normalizedPhoneNumber };
  } catch (error) {
    console.error(`Error parsing or formatting phone number: ${error}`);
    return {
      error: "Error parsing or formatting phone number :" + phoneNumber
    };
  }
};

const normalizePhoneNumberOnInput = (e: ChangeEvent<HTMLInputElement>) => {
  const formatter = new AsYouTypeFormatter("US");
  const { value } = e.target;
  return value
    .split("")
    .reduce((acc, currentChar) => formatter.inputDigit(currentChar));
};

// Convert blob to base64 string to send to backend
// Note: This returns a promise so you'll have to await the result
const getBase64String = (blob: any) => {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve(
        (reader.result as string)
          .split(",")[1]
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "")
      );
    reader.readAsDataURL(blob);
  });
};

function calculateEDD(lmpDate: string) {
  // Ensure lmpDate is a valid dayjs object
  const lmp = dayjs(lmpDate);

  if (!lmp.isValid()) {
    throw new Error("Invalid date format for Last Menstrual Period (LMP).");
  }

  // Add 280 days to the LMP date
  const edd = lmp.add(280, "day");

  return edd;
}

function calculateLMP(eddInt: number) {
  // Parse the integer EDD into a dayjs object
  const edd = dayjs(String(eddInt), { format: "YYYYMMDDHHmmss" });

  // Subtract 280 days to get the LMP date
  const lmp = edd.subtract(280, "day");

  return lmp;
}

function getAppointmentLocationIcon(appointmentLocation: string) {
  if (appointmentLocation) {
    // if location is "HOME" return "home-03" icon
    if (appointmentLocation === "HOME") {
      return "home-03";
    }
    // if location is "PHONE" return "phone" icon
    if (appointmentLocation === "PHONE") {
      return "phone-01";
    }
  }

  // else return medical-cross icon
  return "medical-cross";
}

export {
  FORMS,
  calculateAge,
  getUserPersona,
  getGsPs,
  getGestationalAge,
  getPostPartumDuration,
  calculateAgeInMonths,
  normalizePhoneNumber,
  normalizePhoneNumberOnInput,
  getBase64String,
  calculateEDD,
  calculateLMP,
  getGestationalAgeObject,
  getGestationalAgeObjectAtDate,
  getAppointmentLocationIcon
};
