/* ProgressUpdate form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
/* Local Imports */

// components
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import ControlledOptionsField from "@/components/forms/generated/options";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

// constants
import { STYLES } from "@/globals/constants";

// store
import { FORM_METRICS, FORM_VALUES } from "./constants";
import { EVENTS } from "@/components/flows/labor/constants";

// styles
import styles from "./styles.module.scss";
import { LaborModel } from "@/store/services/patient";

/* ProgressUpdateform Typescript Interface */
interface ProgressUpdateformProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  defaultValues?: any;
  disabled?: boolean;
  lastBaselineEventId?: string;
  labor: LaborModel;
}

export default function ProgressUpdateform({
  onSubmit,
  defaultValues,
  disabled = false,
  // The event ID of the last baseline shift event
  lastBaselineEventId,
  labor
}: ProgressUpdateformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    reset,
    watch
  } = form;
  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);

  const baselineShift = watch("baseline_shift");

  /* Effects */
  // Scroll the form into view on first render
  useEffect(() => {
    if (formRef?.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  /* Event Handlers */

  const contractionFields = [
    { name: "freq", type: "text" },
    { name: "dur", type: "text" },
    { name: "strength", type: "text" }
  ];

  const progressFields = [
    { name: "dil", type: "number" },
    { name: "eff", type: "text" },
    { name: "station", type: "number" }
  ];

  const vitalInputs = [
    { name: "BP", type: "text" },
    { name: "heart_rate", type: "number" },
    { name: "temp", type: "number" },
    { name: "inputs", type: "text" },
    { name: "outputs", type: "text" }
  ];

  const lastBaselineValue = useMemo(() => {
    let eventId = lastBaselineEventId || defaultValues.baseline;
    const lastBaselineEvent = [
      ...(labor?.stages?.labor?.progress_updates || [])
    ].find(event => event.event_id === eventId);
    if (!lastBaselineEvent) {
      return "";
    }
    const eventForms = lastBaselineEvent.forms as Record<string, any>;
    return eventForms.new_baseline;
  }, [lastBaselineEventId, defaultValues]);

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues,
        start_time: defaultValues.start_time,
        listening_device: defaultValues.listening_device
      });
    } else {
      setValue("start_time", dayjs().utc().format("YYYYMMDDHHmmss"));
    }
  }, [defaultValues]);

  useEffect(() => {
    setValue("baseline", lastBaselineEventId);
  }, [lastBaselineEventId]);

  return (
    <form
      data-cy="progress-update-form"
      className={clsx(styles.LaborForm, styles.ProgressUpdateform)}
      onSubmit={handleSubmit((data: any) => {
        if (data.new_baseline === "") {
          // Just in case the use clicked "yes" on the baseline shift, causing the input to
          // be empty and they don't enter a value, we force the baseline_shift to be no
          onSubmit(
            {
              ...data,
              baseline_shift: "no"
            },
            reset
          );
        } else {
          onSubmit(data, reset);
        }
      })}
      ref={formRef}
    >
      <fieldset
        className={clsx(styles.time, styles.spanTwo, styles.progressTime)}
      >
        <legend>Start time</legend>
        <Controller
          name="start_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={defaultValues && defaultValues.start_time}
                value={value}
                hiddenLabel
              />
            );
          }}
        />
      </fieldset>
      {/** Fetal Section */}
      <fieldset
        className={clsx(
          styles.progressSection,
          styles.darkSection,
          styles.spanSix
        )}
      >
        <legend className={clsx("t4 dark", styles.sectionTitle)}>
          Fetal Heart Tones
        </legend>
        <div>
          <Input
            type="text"
            label="FHT"
            name="FHT"
            id="FHT"
            register={register}
            disabled={disabled}
            fitWidth
          />
          <p>Baseline: {lastBaselineValue || "Not set"}</p>
          {/**
           * Hidden input to record this progress updates baseline seperate from a baseline shift
           */}
          <div style={{ display: "none" }}>
            <Input
              type="text"
              label="Baseline"
              id="baseline"
              name="baseline"
              register={register}
            />
          </div>
        </div>
        <ControlledOptionsField
          label="Baseline Shift?"
          name="baseline_shift"
          id="baseline_shift"
          control={control}
          isHorizontal={false}
          options={[
            { label: "Yes", id: "yes" },
            { label: "No", id: "no" }
          ]}
          disabled={disabled}
          fitWidth
          centered
        />

        <ControlledOptionsField
          label="Relative to contractions"
          name="relative_to_contractions"
          control={control}
          isHorizontal={false}
          options={[
            { label: "before", id: "before" },
            { label: "during", id: "during" },
            { label: "after", id: "after" },
            { label: "all", id: "all" }
          ]}
          disabled={disabled}
          fitWidth
          centered
        />
        <ControlledOptionsField
          label="Accelerations"
          name="accelerations"
          control={control}
          isHorizontal={false}
          options={[
            { label: "Yes", id: "yes" },
            { label: "No", id: "no" }
          ]}
          disabled={disabled}
          fitWidth
          centered
        />
        <ControlledOptionsField
          label="Decelerations"
          name="decelerations"
          control={control}
          isHorizontal={false}
          options={[
            { label: "Yes", id: "yes" },
            { label: "No", id: "no" }
          ]}
          disabled={disabled}
          fitWidth
          centered
        />
        <ControlledOptionsField
          options={[
            { label: "Doppler", id: "doppler" },
            { label: "Fetoscope", id: "fetoscope" },
            { label: "EFM", id: "efm" }
          ]}
          label="Listening device"
          name={"listening_device"}
          control={control}
          isHorizontal={false}
          disabled={disabled}
          fitWidth
          centered
        />
      </fieldset>
      {baselineShift === "yes" && (
        <fieldset className={clsx(styles.progressSection, styles.fullWidth)}>
          <legend className={clsx("t4 dark", styles.sectionTitle)}>
            New baseline
          </legend>
          <Input
            type="text"
            label="Baseline"
            id="new_baseline"
            name="new_baseline"
            register={register}
            fitWidth
            disabled={disabled}
          />
          <Input
            type="number"
            label="Listening duration (seconds)"
            id="listening_duration"
            name="listening_duration"
            register={register}
            fitWidth
            disabled={disabled}
          />
          <div className={clsx(styles.spanSix, styles.fieldsetTextArea)}>
            <ControlledTextArea
              label="Notes"
              form={form}
              name="baseline_notes"
              id="baseline_notes"
              placeholder="Enter notes here."
              hiddenLabel
              rows={1}
            />
          </div>
        </fieldset>
      )}

      {/** Contractions section */}
      <fieldset className={clsx(styles.progressSection, styles.spanFour)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>
          Contractions
        </legend>
        {contractionFields.map(({ name, type }) => (
          <div className={styles.spanTwo} key={name}>
            <Input
              key={name}
              type={type}
              label={name.replace("_", " ")}
              name={name}
              id={name}
              fullWidth
              register={register}
              disabled={disabled}
              fitWidth
            />
          </div>
        ))}
      </fieldset>

      {/** Cervical Exam section */}
      <fieldset className={clsx(styles.progressSection, styles.spanFour)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>
          Cervical Exam
        </legend>

        {progressFields.map(({ name, type }) => (
          <div className={styles.spanTwo} key={name}>
            <Input
              key={name}
              type={type}
              label={name.replace("_", " ")}
              name={name}
              id={name}
              fullWidth
              register={register}
              disabled={disabled}
              fitWidth
            />
          </div>
        ))}
      </fieldset>

      {/** Vitals section */}
      <fieldset className={clsx(styles.progressSection, styles.fullWidth)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>Vitals</legend>

        {vitalInputs.map(({ name, type }, index) => (
          <Input
            key={name}
            type={type}
            label={name.replace("_", " ")}
            name={name}
            id={name}
            fullWidth
            register={register}
            disabled={disabled}
          />
        ))}
        <div style={{ minWidth: "200px" }}>
          <ControlledCombobox
            options={FORM_VALUES[EVENTS.BIRTH][FORM_METRICS.MATERNAL_POS]}
            label="Maternal Position"
            name={"maternal_position"}
            control={control}
            isHorizontalLayout={false}
            errors={errors}
            fullWidth
            disabled={disabled}
          />
        </div>
      </fieldset>

      <fieldset className={clsx(styles.fullWidth, styles.progressTime)}>
        <legend>Comments</legend>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          hiddenLabel
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
        />
      </fieldset>
      <div className={styles.fullWidth}>
        <Button
          style={STYLES.FULL_WIDTH}
          type="submit"
          nativeButtonProps={{ disabled }}
        >
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}
