/* immunization */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
/* Local Imports */

// components
import Button from "@/components/button";
import ButtonLink from "@/components/button/link";
import BasicAccordion from "@/components/accordions/basic";
import Icon from "@/components/icons";
import TextArea from "@/components/textArea";

// constants
import { STYLES, STATUS_KEYS, PAGES, METRICS } from "@/globals/constants";
// store
// styles
import styles from "./styles.module.scss";

/* immunization Typescript Interface */
interface immunizationProps {
  id: number | string; // immunization id or diagnosis name
  patientId: number; // id of patient associated with immunization
  isOpen?: boolean; // UI
  title: string;
  instances: {
    timestamp: number;
    immunizationId: number;
    noteContent?: string;
    encounterId?: number;
    reaction?: string;
  }[];
  tagLabel?: string; // ICD10 code or "Note" // UI
  mostRecentTimestamp: number;
  onUpdateNote: (immunizationId: number) => void; // UI
  register: any; // UI
  isExpanded?: boolean; // UI
}

export default function Immunization({
  id,
  patientId,
  isOpen,
  title,
  instances,
  tagLabel = "",
  onUpdateNote,
  isExpanded = false,
}: immunizationProps) {
  /* Redux */

  /* Local State */
  const [editimmunizationInstance, setEditimmunizationInstance] = useState<
    number | null
  >(null);

  /* Effects */
  /* Event Handlers */
  const handleSaveChanges = (immunizationId: number) => {
    onUpdateNote(immunizationId);
    setEditimmunizationInstance(null);
  };
  return (
    <div className={clsx(styles.immunization)}>
      <BasicAccordion
        key={id}
        open={isOpen}
        title={""}
        tag={{
          type: STATUS_KEYS.INFO_GREY,
          label: tagLabel,
        }}
        count={instances.length > 1 ? instances.length : null}
        buttonIsLeft
      >
        {instances &&
          instances.map(
            ({
              immunizationId,
              noteContent = "(no comment added)",
              encounterId,
              reaction = "rash",
            }) => (
              <div className={styles.note} key={immunizationId}>
                <div className={styles.display}>
                  <div className={styles.left}>
                    <p className="t5 med">{reaction}</p>
                    <p className="t5 xLight">{noteContent}</p>
                  </div>
                  <div className={styles.right}>
                    {/* Edit this note */}
                    {isExpanded && (
                      <>
                        <Button
                          style={STYLES.ICON}
                          onClick={() => {
                            immunizationId === editimmunizationInstance
                              ? handleSaveChanges(immunizationId)
                              : setEditimmunizationInstance(immunizationId);
                          }}
                        >
                          <Icon
                            height={14}
                            svg={
                              immunizationId === editimmunizationInstance
                                ? "save"
                                : "edit"
                            }
                          />
                        </Button>
                        {/* Delete this note */}
                        <Button style={STYLES.ICON}>
                          <Icon svg="delete" />
                        </Button>
                      </>
                    )}
                    {/* Go to encounter if encounter note */}
                    {encounterId && (
                      <ButtonLink
                        style={STYLES.NO_OUTLINE}
                        href={`${PAGES.ENCOUNTERS}/${encounterId}`}
                      >
                        {"->"}
                      </ButtonLink>
                    )}
                  </div>
                </div>

                {isExpanded && immunizationId === editimmunizationInstance && (
                  <div className={styles.text}>
                    <TextArea
                      hiddenLabel
                      label="immunization commentary"
                      id={`immunization-${immunizationId}-note`}
                      name={`immunization-${immunizationId}-note`}
                      rows={3}
                      placeholder={noteContent}
                    />
                  </div>
                )}
              </div>
            )
          )}
      </BasicAccordion>
    </div>
  );
}
