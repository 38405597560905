import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum Sections {
  PROBLEMS = "problem_list",
  ALLERGIES = "allergies",
  MEDICATIONS = "current_medications",
  IMAGING = "imaging",
  MENSTRUAL = "menstrual_history",
  OB = "OB_history",
  NOTES = "notes",
}

type initialState = {
  sections: Sections[];
};

const initialState: initialState = {
  sections: [Sections.PROBLEMS],
};

export const snapshotSlice = createSlice({
  name: "snapshot",
  initialState,
  reducers: {
    addSection: (state, { payload }: PayloadAction<Sections>) => {
      state.sections.push(payload);
    },
    removeSection: (state, { payload }: PayloadAction<Sections>) => {
      state.sections = state.sections.filter((section) => section !== payload);
    },
  },
});

export const { addSection, removeSection } = snapshotSlice.actions;

export default snapshotSlice.reducer;
