import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    codingSearchIcd10: build.query<
      CodingSearchIcd10ApiResponse,
      CodingSearchIcd10ApiArg
    >({
      query: queryArg => ({
        url: `/coding/icd10/search`,
        params: { term: queryArg.term }
      })
    }),
    codingIcd10Lookup: build.mutation<
      CodingIcd10LookupApiResponse,
      CodingIcd10LookupApiArg
    >({
      query: queryArg => ({
        url: `/coding/icd10/lookup`,
        method: "POST",
        body: queryArg.body
      })
    }),
    codingSearchCpt: build.query<
      CodingSearchCptApiResponse,
      CodingSearchCptApiArg
    >({
      query: queryArg => ({
        url: `/coding/cpt/search`,
        params: { term: queryArg.term }
      })
    }),
    codingCptLookup: build.mutation<
      CodingCptLookupApiResponse,
      CodingCptLookupApiArg
    >({
      query: queryArg => ({
        url: `/coding/cpt/lookup`,
        method: "POST",
        body: queryArg.body
      })
    }),
    codingSearchCptModifier: build.query<
      CodingSearchCptModifierApiResponse,
      CodingSearchCptModifierApiArg
    >({
      query: queryArg => ({
        url: `/coding/cpt-modifer/search`,
        params: { term: queryArg.term }
      })
    }),
    codingCptModifierLookup: build.mutation<
      CodingCptModifierLookupApiResponse,
      CodingCptModifierLookupApiArg
    >({
      query: queryArg => ({
        url: `/coding/cpt-modifer/lookup`,
        method: "POST",
        body: queryArg.body
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as coding };
export type CodingSearchIcd10ApiResponse =
  /** status 200 Response to a search for ICD-10 codes.
   */ Icd10CodeDetails[];
export type CodingSearchIcd10ApiArg = {
  /** Search term. Uses standard MySQL search syntax.
   */
  term: string;
};
export type CodingIcd10LookupApiResponse =
  /** status 200 Response to a request to lookup ICD-10 code details.
   */ {
    [key: string]: Icd10CodeDetails;
  };
export type CodingIcd10LookupApiArg = {
  /** Payload for looking up the details for ICD-10 codes.
   */
  body: Icd10Id[];
};
export type CodingSearchCptApiResponse =
  /** status 200 Response to a search for CPT codes.
   */ CptCodeDetails[];
export type CodingSearchCptApiArg = {
  /** Search term. Uses standard MySQL search syntax.
   */
  term: string;
};
export type CodingCptLookupApiResponse =
  /** status 200 Response to a request to lookup CPT code details.
   */ {
    [key: string]: CptCodeDetails;
  };
export type CodingCptLookupApiArg = {
  /** Payload for looking up the details for CPT codes.
   */
  body: CptId[];
};
export type CodingSearchCptModifierApiResponse =
  /** status 200 Response to a search for CPT modifiers.
   */ CptModifier[];
export type CodingSearchCptModifierApiArg = {
  /** Search term. Uses standard MySQL search syntax.
   */
  term: string;
};
export type CodingCptModifierLookupApiResponse =
  /** status 200 Response to a request to lookup CPT modifier details.
   */ {
    [key: string]: CptModifier;
  };
export type CodingCptModifierLookupApiArg = {
  /** Payload for looking up the details for CPT modifiers.
   */
  body: CptModifierId[];
};
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type Icd10Id = number;
export type CptCodeDetails = {
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id: number;
  /** The actual CPT code.
   */
  cpt_code: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type CptId = number;
export type CptModifier = {
  /** The unique identifier of the modifier, internal to Pario.
   */
  modifier_id: number;
  /** The alphanumeric code of the modifier as specified by the AMA.
   */
  modifier_code: string;
  /** The name of the modifier.
   */
  modifier_name: string;
  /** The guidelines / instructions for using the modifier.
   */
  modifier_description?: string;
};
export type CptModifierId = number;
export const {
  useCodingSearchIcd10Query,
  useLazyCodingSearchIcd10Query,
  useCodingIcd10LookupMutation,
  useCodingSearchCptQuery,
  useLazyCodingSearchCptQuery,
  useCodingCptLookupMutation,
  useCodingSearchCptModifierQuery,
  useLazyCodingSearchCptModifierQuery,
  useCodingCptModifierLookupMutation
} = injectedRtkApi;
