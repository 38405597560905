/* Button Component */

// External
import clsx from "clsx";
import { MouseEvent } from "react";
// constants
import { STYLES } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";

export interface ButtonProps {
  style?: string;
  children: string | JSX.Element | (JSX.Element | string)[];
  type?: "button" | "submit" | "reset";
  onClick?: (e?: MouseEvent<HTMLButtonElement>, item?: any) => void;
  nativeButtonProps?: { disabled?: boolean; title?: string };
  loading?: boolean; // if button should display loading spinner
}

export default function Button({
  style = STYLES.PRIMARY,
  children,
  type = "button",
  onClick,
  nativeButtonProps = { disabled: false },
  loading = false
}: ButtonProps) {
  return (
    <button
      className={clsx(styles.Button, styles[style], {
        [styles.disabled]: nativeButtonProps?.disabled,
        [styles.loading]: loading // Apply loading style when loading is true
      })}
      type={type}
      onClick={onClick}
      tabIndex={0}
      {...nativeButtonProps}
    >
      {loading ? (
        <div className={styles.spinner}></div> // Display spinner when loading
      ) : (
        children
      )}
    </button>
  );
}
