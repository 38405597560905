// External
import { useDispatch } from "react-redux";

// Globals, constants, helpers
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS } from "@/globals/constants";
import { setModalIsOpen } from "../modalSlice";

// Store
import { EncounterInfo, IdType, LaborEvent, useEncounterLaborRecurringEventUpdateMutation } from "@/store/services/encounter";

// Components
import NewbornProgressform from "@/components/forms/_labor/newbornProgress";

interface EditLaborProgressUpdateProps {
  selectedStage: string;
  encounter?: EncounterInfo;
  laborEventId: string;
  scope: IdType;
  editingEvent: LaborEvent;
}

export default function EditLaborInfantProgressUpdate({
  selectedStage,
  encounter,
  laborEventId,
  scope,
  editingEvent
}: EditLaborProgressUpdateProps) {
  const dispatch = useDispatch();
  const [updateRecurringEvent] =
    useEncounterLaborRecurringEventUpdateMutation();

  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };
  const handleUpdateRecurringEvent = (formData: any) => {
    if (!encounter) {
      launchToast("Something went wrong!", STATUS_KEYS.ERROR);
      return;
    }

    const laborEventUpdate = {
      event_id: laborEventId,
      forms: formData,
      start: formData.start_time
    };

    const payload = {
      encounterId: encounter.encounter_id,
      stage: selectedStage,
      laborEventId,
      scope,
      /** Payload for creating/updating a labor.
       */
      laborEventUpdate
    };

    updateRecurringEvent(payload)
      .unwrap()
      .then(res => {
        launchToast(`Labor successfully updated`, STATUS_KEYS.SUCCESS);
        dispatch(setModalIsOpen(false));
      })
      .catch(err =>
        launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
      );
  };

  const handleRenderProgressForm = (progressUpdate: LaborEvent) => {
    const handleSubmit = (v: any, reset: (values: any) => void) => {
      if (progressUpdate) {
        handleUpdateRecurringEvent(v);
      }
    };

    return (
      <NewbornProgressform
        onSubmit={handleSubmit}
        defaultValues={progressUpdate.forms}
      />
    );
  };

  return (
    <div>
      {editingEvent && encounter && handleRenderProgressForm(editingEvent)}
    </div>
  );
}
