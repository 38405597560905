import { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";

// components
import Icon from "@/components/icons";
import Tag, { TagProps } from "@/components/tag";

// styles
import styles from "./styles.module.scss";

interface BasicAccordionProps {
  title: string;
  children: ReactNode;
  complete?: boolean;
  open?: boolean;
  count?: number | null;
  tag?: TagProps;
  unpadded?: boolean;
  buttonIsLeft?: boolean;
  customLeftContent?: JSX.Element | JSX.Element[];
  style?: string;
  noTitleContent?: boolean;
  smallTitle?: boolean;
  disabled?: boolean;
}

export default function BasicAccordion({
  title,
  children,
  open = false,
  count = null,
  tag,
  unpadded = false,
  buttonIsLeft = false,
  customLeftContent,
  style = "",
  noTitleContent = false,
  smallTitle = false,
  disabled = false
}: BasicAccordionProps) {
  /* Local State */
  const [isOpen, setIsOpen] = useState<boolean>(open);

  /* Effects */
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div
      className={clsx(styles.Accordion, styles[style], {
        [styles.isOpen]: isOpen,
        [styles.unpadded]: unpadded,
        [styles.leftButton]: buttonIsLeft,
        [styles.noTitle]: !title || title?.length === 0
      })}
    >
      <button
        disabled={disabled}
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-controls={`${title}_accordion_content`}
        type="button"
        className={styles.header}
      >
        {!noTitleContent && (
          <div className={styles.left}>
            {buttonIsLeft && !disabled && (
              <ExpandButton
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={title}
              />
            )}
            {tag && <Tag {...tag} />}
            {count ? (
              <div
                className={clsx(styles.title, {
                  [styles.smallTitle]: smallTitle
                })}
              >
                {title} ({count}){" "}
              </div>
            ) : title ? (
              <div
                className={clsx(styles.title, {
                  [styles.smallTitle]: smallTitle
                })}
              >
                {title}
              </div>
            ) : null}
          </div>
        )}
        <div
          className={clsx(styles.right, { [styles.fullWidth]: noTitleContent })}
        >
          {customLeftContent}
          {!buttonIsLeft && !disabled && (
            <ExpandButton isOpen={isOpen} setIsOpen={setIsOpen} title={title} />
          )}
        </div>
      </button>

      <div
        className={clsx(styles.contentWrapper, { [styles.hidden]: !isOpen })}
        id={`${title}_accordion_content`}
      >
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

function ExpandButton({
  setIsOpen,
  isOpen,
  title
}: {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  title: string;
}) {
  return (
    <button
      type="button"
      onClick={() => setIsOpen(!isOpen)}
      aria-expanded={isOpen}
      aria-controls={`${title}_accordion_content`}
    >
      <Icon
        svg={"chevron_up_blue"}
        width={10}
        height={10}
        flipped={isOpen}
      ></Icon>
    </button>
  );
}
