// External
import { useDispatch } from "react-redux";

// Globals, helpers, constants
import { STAGES } from "@/components/flows/labor/constants";
import { STATUS_KEYS } from "@/globals/constants";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "../modalSlice";

// Store
import {
  EncounterInfo,
  IdType,
  LaborEvent,
  LaborModel,
  useEncounterLaborInfoQuery,
  useEncounterLaborRecurringEventUpdateMutation
} from "@/store/services/encounter";

// Components
import BirthProgressUpdateform from "@/components/forms/_labor/birthProgressUpdate";
import MaternalProgressform from "@/components/forms/_labor/maternalProgress";
import ProgressUpdateform from "@/components/forms/_labor/progressUpdate";
import VitalsProgressUpdateform from "@/components/forms/_labor/vitalsProgressUpdate";

interface EditLaborProgressUpdateProps {
  selectedStage: string;
  encounter?: EncounterInfo;
  laborEventId: string;
  scope: IdType;
  editingEvent: LaborEvent;
  tab?: string;
}

export default function EditLaborProgressUpdate({
  selectedStage,
  encounter,
  laborEventId,
  scope,
  editingEvent,
  tab = "",
}: EditLaborProgressUpdateProps) {
  const dispatch = useDispatch();
  const [updateRecurringEvent] =
    useEncounterLaborRecurringEventUpdateMutation();

  const { data: labor } = useEncounterLaborInfoQuery(
    {
      encounterId: encounter?.encounter_id as number
    },
    { skip: !encounter?.encounter_id }
  );

  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };
  const handleUpdateRecurringEvent = (formData: any) => {
    if (!encounter) {
      launchToast("Something went wrong!", STATUS_KEYS.ERROR);
      return;
    }

    const laborEventUpdate = {
      event_id: laborEventId,
      forms: formData,
      start: formData.start_time
    };

    const payload = {
      encounterId: encounter.encounter_id,
      stage: selectedStage,
      laborEventId,
      scope,
      /** Payload for creating/updating a labor.
       */
      laborEventUpdate
    };

    updateRecurringEvent(payload)
      .unwrap()
      .then(res => {
        launchToast(`Labor successfully updated`, STATUS_KEYS.SUCCESS);
        dispatch(setModalIsOpen(false));
      })
      .catch(err =>
        launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
      );
  };

  const handleRenderProgressForm = (progressUpdate?: LaborEvent) => {
    const handleSubmit = (v: any, reset: (values: any) => void) => {
      if (progressUpdate) {
        handleUpdateRecurringEvent(v);
      }
    };
    switch (selectedStage) {
      case STAGES.LABOR:
        return (
          <ProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={progressUpdate?.forms}
            labor={labor as LaborModel}
          />
        );
      case STAGES.HEMORRHAGE:
        return (
          <ProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={progressUpdate?.forms}
            labor={labor as LaborModel}
          />
        );
      case STAGES.RESUSCITATION:
        return (
          <ProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={progressUpdate?.forms}
            labor={labor as LaborModel}
          />
        );
      case STAGES.BIRTH:
        return (
          <BirthProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={progressUpdate?.forms}
          />
        );
      case STAGES.POSTPARTUM:
        return (
          <MaternalProgressform
            onSubmit={handleSubmit}
            defaultValues={progressUpdate?.forms}
          />
        );
      case STAGES.ADMISSION:
      case STAGES.TRANSFER:
        return (
          <VitalsProgressUpdateform
            onSubmit={handleSubmit}
            defaultValues={progressUpdate?.forms}
          />
        );
      case STAGES.TRIAGE:
        if (tab == "triage") {
          return (
            <VitalsProgressUpdateform
              onSubmit={handleSubmit}
              defaultValues={progressUpdate?.forms}
            />
          );
        }
        return (
          <MaternalProgressform
            onSubmit={handleSubmit}
            defaultValues={progressUpdate?.forms}
          />
        );
      default:
        return <div className="med">No progress update for this stage</div>;
    }
  };

  return (
    <div>
      {editingEvent && encounter && handleRenderProgressForm(editingEvent)}
    </div>
  );
}
