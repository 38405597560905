import * as generated from "./generated/phrase";

const phrase = generated.phrase.enhanceEndpoints({
  endpoints: {
    phraseUpsert: {
      invalidatesTags: ["Phrases"],
    },
    phraseDelete: {
      invalidatesTags: ["Phrases"],
    },
    phraseList: {
      providesTags: ["Phrases"],
    },
    phraseText: {
      providesTags: ["Phrases"],
    },
  },
});

export * from "./generated/location";
export default phrase;
export const {
  usePhraseListQuery,
  useLazyPhraseListQuery,
  usePhraseUpsertMutation,
  usePhraseDeleteMutation,
  usePhraseTextQuery,
  useLazyPhraseTextQuery,
} = phrase;
