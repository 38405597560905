import { BACKEND_BASE_URL } from "@/globals/constants/environment";

export const openOrderResults = async (orderId: string) => {
  try {
    const response = fetch(
      `${BACKEND_BASE_URL}/api/v1/lab/order/${orderId}/results`,
      { credentials: "include" }
    );
    const blob = await (await response).blob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  } catch (error) {
    console.error(error);
  }
};

export const openOrderRequisition = async (orderId: string) => {
  try {
    const response = await fetch(
      `${BACKEND_BASE_URL}/api/v1/lab/order/${orderId}/requisition`,
      { credentials: "include" }
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  } catch (error) {
    console.error(error);
  }
};

export const openOrderABN = async (orderId: string) => {
  try {
    const response = await fetch(
      `${BACKEND_BASE_URL}/api/v1/lab/order/${orderId}/abn`,
      { credentials: "include" }
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  } catch (error) {
    console.error(error);
  }
};

export const openPdfFromBase64 = (base64String: string) => {
  try {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length)
      .fill(0)
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  } catch (error) {
    console.error(error);
  }
};
