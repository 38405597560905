/* Confirmation Modal Template */

// External
import { useEffect, useMemo } from "react";
import { Inter } from "next/font/google";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

// components
import Button from "@/components/button";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// store
import { setModalIsOpen } from "../modalSlice";
import {
  CreatePatientRecallApiArg,
  PatientInfo,
  useCreatePatientRecallMutation
} from "@/store/services/patient";
import { usePatientGetListQuery } from "@/store/services/practice";

// styles
import styles from "../styles.module.scss";

export interface PatientRecallProps {
  patient?: PatientInfo;
}

export default function PatientRecall({ patient }: PatientRecallProps) {
  const dispatch = useDispatch();

  const patientListResponse = usePatientGetListQuery({
    pagesz: 1000
  });

  const [createPatientRecall, createPatientRecallResponse] =
    useCreatePatientRecallMutation();

  const componentState: "LOADING" | "READY" | "ERROR" = useMemo(() => {
    if (
      patientListResponse.isLoading ||
      createPatientRecallResponse.isLoading
    ) {
      return "LOADING";
    }

    if (patientListResponse.isSuccess) {
      return "READY";
    }

    return "LOADING";
  }, [patientListResponse, createPatientRecallResponse]);

  const form = useForm({
    defaultValues: {
      patient: patient,
      date: dayjs().format("YYYY-MM-DD"),
      reason: ""
    }
  });

  const {
    control,
    register,
    handleSubmit,
    formState: { errors }
  } = form;

  /**
   * The effects for the response of creating the patient recall
   * Success: Toast and close modal
   * Failure: Toast but keep modal open
   */
  useEffect(() => {
    if (createPatientRecallResponse.isSuccess) {
      dispatch(setModalIsOpen(false));
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Successfully created patient recall"
        })
      );
    }

    if (createPatientRecallResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Something went wrong creating the patient recall"
        })
      );
    }
  }, [createPatientRecallResponse]);

  const onSubmit = (data: any) => {
    const createRecallPayload: CreatePatientRecallApiArg = {
      patientRecallCreate: {
        patient_id: data.patient.user_id,
        due_date: data.date,
        reason: data.reason
      }
    };

    createPatientRecall(createRecallPayload);
  };

  return (
    <div className={styles.PatientRecall} data-cy="patient-recall-modal">
      {componentState == "LOADING" && (
        <>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </>
      )}
      {componentState == "READY" && (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.recallForm}>
          <ControlledCombobox
            label="Patient"
            options={patientListResponse.data?.patients || []}
            name="patient"
            control={control}
            labelAcc={patient => FORMAT.name(patient)}
            required
            errors={errors}
            isHorizontalLayout={false}
            fullWidth
          />
          <Input
            type="date"
            id="date"
            name="date"
            label="Date"
            register={register}
            isHorizontalLayout={false}
            fullWidth
            required
          />
          <ControlledTextArea
            label="Reason"
            id="reason"
            name="reason"
            form={form}
          />
          <div className={styles.buttonContainer}>
            <Button
              style={STYLES.SECONDARY}
              onClick={() => dispatch(setModalIsOpen(false))}
            >
              Cancel
            </Button>
            <Button style={STYLES.PRIMARY} type="submit">
              Submit
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}
