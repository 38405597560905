/* Confirmation Modal Template */

// External
import clsx from "clsx";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { sanitize } from "dompurify";
import { useDispatch } from "react-redux";

// styles
import styles from "../styles.module.scss";

// components
import Button from "@/components/button";
import Icon from "@/components/icons";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import ContentRenderer from "@/components/textArea/contentRenderer";

// constants
import { STYLES, STATUS_KEYS } from "@/globals/constants";

// store
import {
  PatientInfo,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "../modalSlice";

export interface SecretNoteModalProps {
  patient: PatientInfo;
}

export default function SecretNoteModal({ patient }: SecretNoteModalProps) {
  const dispatch = useDispatch();
  /* React Use Form Configuration */

  const form = useForm({
    defaultValues: {
      secret_note: patient?.secret_note
    }
  });

  /* Redux */
  const [upsertPatient] = usePatientUpsertMutation();
  /* Local State */
  const [isEditMode, setIsEditMode] = useState(!patient.secret_note);

  const onSubmit = (data: any) => {
    if (data.secret_note) {
      upsertPatient({
        patientUpsertRequest: {
          user_id: patient.user_id,

          secret_note: data.secret_note
        }
      })
        .then(() => {
          dispatch(
            addAlertToToastTrough({
              message: `Successfully saved private note`,
              type: STATUS_KEYS.SUCCESS
            })
          );
          dispatch(setModalIsOpen(false));
        })
        .catch(() =>
          dispatch(
            addAlertToToastTrough({
              message: `Oops! Failed to update private note`,
              type: STATUS_KEYS.ERROR
            })
          )
        );
    }
  };
  return (
    <div className={clsx(styles.SecretNote)}>
      <div className="note">
        <Icon svg="lightbulb" width={12} />
        Private note not visible to patient or part of official medical record
      </div>
      {isEditMode ? (
        <form onSubmit={form.handleSubmit(onSubmit)} className={styles.content}>
          <ControlledTextArea
            label=""
            placeholder=""
            id="secret_note"
            name="secret_note"
            required={false}
            setValue={form.setValue}
            content={patient?.secret_note || ""}
            form={form}
          />
          <Button style={STYLES.FULL_WIDTH} type="submit">
            Save
          </Button>
        </form>
      ) : (
        <div className={styles.content}>
          <ContentRenderer content={sanitize(patient?.secret_note || "")} />
          <Button
            style={STYLES.SECONDARY_FULL}
            onClick={() => setIsEditMode(true)}
          >
            Edit
          </Button>
        </div>
      )}
    </div>
  );
}
