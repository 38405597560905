/* New Week Scheduling View */

/* External Imports */
import { useMemo, useEffect, MouseEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import clsx from "clsx";
import { motion } from "framer-motion";
import { sanitize } from "dompurify";

/* Local Imports */

// components
import FixedMenu from "@/components/menus/fixedMenu";
import ContentRenderer from "@/components/textArea/contentRenderer";
import Icon from "@/components/icons";
import Tag from "@/components/tag";

// constants
import {
  APPOINTMENT_STATUS_TYPES,
  EVENT_COLOR_MAP,
  METRIC_LABELS,
  METRICS,
  STATUS_KEYS
} from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import { MODAL_TYPES } from "@/components/modal/dispatcher";

// store
import { RootState } from "@/store/store";
import {
  AppointmentInfo,
  useAppointmentsGetListQuery
} from "@/store/services/scheduling";
import {
  ProviderListItem,
  usePracticeGetProvidersQuery,
  usePracticeListAppointmentTypesQuery
} from "@/store/services/practice";
import { AppointmentStatus, UserId } from "@/store/services/patient";
import { EventInfo, useEventListQuery } from "@/store/services/event";
import { useLocationGetListQuery } from "@/store/services/phrase";
import {
  setRightPaneContent,
  setRightPaneOpen
} from "@/components/drawer/drawerSlice";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { setSelectedProviderId, setSelectedSlot } from "../calendarSlice";

// utils
import {
  convertUtcIntToLocalDatetime,
  convertLocalDatetimeToUtcInt,
  getApptsFilteredByProviderAndLocation,
  getEventsFilteredByProviderAndLocation,
  apptSlots as slots,
  userIdToHexCode,
  getAppointmentStyle,
  getEventHeight,
  getEventIcon,
  getTextColorForBackground
} from "../utils";
import { useColorByProvider } from "@/globals/helpers/customHooks";

// styles
import styles from "./styles.module.scss";
import { getAppointmentLocationIcon } from "@/globals/helpers";
import { SetStaffScheduleModalProps } from "@/components/modal/templates/setStaffSchedule";

// TEMP: we will need to record patient check in time and dynamically calculate in F/E
const LABEL_OVERRIDES: Partial<Record<AppointmentStatus, string>> = {
  COMPLETED: "Ready for Checkout"
};

/* NewView Typescript Interface */
interface NewViewProps {
  days: string[];
}

export default function NewView({ days }: NewViewProps) {
  const colorByProvider = useColorByProvider();

  /* Redux */
  const dispatch = useDispatch();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  // local store settings
  const { locationFilter, selectedSlot, providerFilter } = useSelector(
    (state: RootState) => state.calendar
  );

  // Fetch data from cache or server
  const { data: appointmentTypes } = usePracticeListAppointmentTypesQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  const { data: providers } = usePracticeGetProvidersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    { skip: !sessionInfo }
  );

  const filteredProviders = useMemo(() => {
    return providers?.filter(provider =>
      providerFilter.includes(provider.user_id)
    );
  }, [providers, providerFilter]);

  // get practices available to this user
  const { data: locations } = useLocationGetListQuery(
    { practiceId: sessionInfo?.practice_id as number },
    {
      skip: !sessionInfo || !sessionInfo.practice_id
    }
  );

  // events & appointments
  const { data: appointments, isLoading: appointmentsLoading } =
    useAppointmentsGetListQuery({
      id: sessionInfo?.practice_id as number,
      scope: "practice",
      statusNotIn: [METRICS.CANCELED as AppointmentStatus],
      start: convertLocalDatetimeToUtcInt(dayjs(days[0]).startOf("day")),
      end: convertLocalDatetimeToUtcInt(
        dayjs(days[days.length - 1]).endOf("day")
      )
    });

  const { data: events } = useEventListQuery({
    rangeStart: dayjs(days[0]).startOf("day").toISOString(),
    rangeEnd: dayjs(days[days.length - 1])
      .endOf("day")
      .toISOString(),
    allDay: false,
    participantId: providerFilter.length === 1 ? providerFilter[0] : undefined
  });

  const { data: scheduleEvents } = useEventListQuery({
    rangeStart: dayjs(days[0]).startOf("day").toISOString(),
    rangeEnd: dayjs(days[days.length - 1])
      .endOf("day")
      .toISOString(),
    allDay: true
  });

  /* Memoized Values */
  const getDaysSlots = (day: string) => slots(day);

  const filteredAppointments = useMemo(() => {
    return appointments
      ? getApptsFilteredByProviderAndLocation(
          providerFilter,
          locationFilter,
          appointments
        )
      : [];
  }, [providerFilter, locationFilter, appointments]);

  const filteredEvents = useMemo(() => {
    return events
      ? getEventsFilteredByProviderAndLocation(
          providerFilter,
          locationFilter,
          events
        )
      : [];
  }, [events, locationFilter, providerFilter]);

  // Days appointments by provider and timeslot {[providerId]: { [timeslot]: [event, event, ...]},...}
  // map appointments onto timeslots {timeslot: [appointment]}
  // appointments should be mapped to a timeslot if their start time is on or after the timeslot but before the next timeslot and matches the provider_id
  const daysAppointments = useMemo(() => {
    return days.map(day => {
      const slots = getDaysSlots(day);

      const providerAppointments = providerFilter.reduce<
        Record<string, Record<string, AppointmentInfo[]>>
      >((acc, providerId) => ({ ...acc, [providerId]: {} }), {});

      filteredAppointments.forEach(appointment => {
        const providerId = appointment.provider.user_id;

        slots.forEach((timeSlot, index) => {
          const nextSlot = slots[index + 1];

          if (
            appointment.starts &&
            timeSlot === appointment.starts &&
            nextSlot >= appointment.starts
          )
            providerAppointments[providerId][timeSlot]
              ? providerAppointments[providerId][timeSlot].push(appointment)
              : (providerAppointments[providerId][timeSlot] = [appointment]);
        });
      });

      return providerAppointments;
    });
  }, [days, filteredAppointments, providerFilter]);

  // Days events by provider and timeslot {[providerId]: { [timeslot]: [event, event, ...]},...}
  const daysEvents = useMemo(() => {
    return days.map(day => {
      const slots = getDaysSlots(day);

      const providerEvents = providerFilter.reduce<
        Record<string, Record<string, EventInfo[]>>
      >((acc, providerId) => ({ ...acc, [providerId]: {} }), {});

      filteredEvents.forEach(event => {
        event.participants.forEach(participant => {
          const providerId = participant.user_id;

          slots.forEach((timeSlot, index) => {
            const nextSlot = slots[index + 1];

            if (
              event.start_at &&
              timeSlot ===
                convertLocalDatetimeToUtcInt(dayjs(event.start_at)) &&
              nextSlot >=
                (convertLocalDatetimeToUtcInt(
                  dayjs(event.start_at as string)
                ) as number)
            )
              providerEvents[providerId][timeSlot]
                ? providerEvents[providerId][timeSlot].push(event)
                : (providerEvents[providerId][timeSlot] = [event]);
          });
        });
      });

      return providerEvents;
    });
  }, [days, filteredEvents, providerFilter]);

  // Days all day events by provider {[providerId]: [event, event, ...]},...}
  // These are the events we use to render on call schedule under the provider name for the day
  const daysAllDayEvents = useMemo(() => {
    return days.map(day => {
      const daysEvents = scheduleEvents?.filter(event =>
        dayjs(event.start_at).isSame(day, "day")
      );
      const providerEvents = providerFilter.reduce<Record<string, EventInfo>>(
        (acc, providerId) => ({
          ...acc,
          [providerId]: daysEvents?.find(event =>
            event.participants?.some(
              participant => participant.user_id === providerId
            )
          )
        }),
        {}
      );

      return providerEvents;
    });
  }, [days, scheduleEvents, providerFilter]);

  /* Local State */

  // memoize the slots for the day
  const todaySlots = useMemo(() => getDaysSlots(days[0]), [days]);

  /* Effects */
  // SCROLL TO NOON on load
  useEffect(() => {
    if (!todaySlots.length) return; // Ensure the slots are loaded

    const renderer = document.getElementById(
      "calendar-container"
    ) as HTMLElement;

    if (renderer?.scrollHeight <= renderer?.clientHeight) {
      return;
    }

    // get time at 8am
    const localEight = dayjs(days[0])
      .hour(8)
      .minute(0)
      .second(0)
      .millisecond(0);
    const eight = convertLocalDatetimeToUtcInt(localEight);
    const topPos = document.getElementById(`slot-${eight}`)?.offsetTop;
    if (topPos) {
      renderer.scrollTop = topPos as number;
    }
  }, [todaySlots]);

  /* Event Handlers */

  // When calendar timeslot is clicked, launch create / edit appointment modal
  const handleApptClick = (
    appointment: AppointmentInfo,
    e: MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    dispatch(
      setRightPaneContent({
        type: MODAL_TYPES.APPOINTMENT,
        props: {
          appointment,
          title: "Schedule Appointment"
        }
      })
    );
    dispatch(setRightPaneOpen(true));
  };

  const handleSlotClick = (
    slot: number,
    provider: ProviderListItem,
    item: string
  ) => {
    if (item === "other_event") {
      const props: SetStaffScheduleModalProps = {
        defaultValues: {
          select_participants: [provider],
          shift: {
            starts: slot,
            ends: convertLocalDatetimeToUtcInt(
              convertUtcIntToLocalDatetime(slot).add(30, "minute")
            )
          },
          event_date: convertUtcIntToLocalDatetime(slot).format("YYYY-MM-DD"),
          select_location: locations?.find(
            location => location.location_id === locationFilter[0]
          )
        }
      };
      dispatch(setModalIsOpen(true));

      dispatch(
        setModalContent({
          type: MODAL_TYPES.STAFF_SCHEDULE,
          props: { title: "Schedule Event", ...props }
        })
      );
    } else {
      const props = {
        appointment: {
          provider,
          starts: slot,
          ends: convertLocalDatetimeToUtcInt(
            convertUtcIntToLocalDatetime(slot).add(30, "minute")
          )
        },
        // since its an appointment creation, open in edit mode
        isDefaultEditMode: true,
        title: "Schedule Appointment"
      };
      dispatch(setRightPaneOpen(true));

      dispatch(
        setRightPaneContent({
          type: MODAL_TYPES.APPOINTMENT,
          props
        })
      );
    }
  };

  const handleEventClick = (
    event: EventInfo,
    e: MouseEvent<HTMLDivElement>
  ) => {
    e.stopPropagation();
    dispatch(
      setModalContent({
        type: MODAL_TYPES.STAFF_SCHEDULE,
        props: {
          eventId: event?.event_id,
          title: `Schedule event`,
          defaultValues: {
            select_participants: event.participants,
            event_date: dayjs(event?.start_at).format("YYYY-MM-DD"),
            event_title: event.title,
            select_event_type: event.event_type,
            shift: {
              starts: convertLocalDatetimeToUtcInt(dayjs(event.start_at)),
              ends: convertLocalDatetimeToUtcInt(dayjs(event.end_at))
            },
            select_location: locations?.find(
              location => location.location_id === locationFilter[0]
            )
          }
        }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  /* Templating */

  const renderAllDayEvent = (dayIndex: number, providerId: UserId) => {
    const event = daysAllDayEvents[dayIndex][providerId];
    if (event) {
      return (
        <div
          className={clsx(styles.allDayEvent, styles.withText)}
          style={{
            backgroundColor: userIdToHexCode(providerId),
            color: getTextColorForBackground(userIdToHexCode(providerId))
          }}
        >
          <Icon svg={getEventIcon(event as EventInfo)} height={14} width={14} />
          {METRIC_LABELS[event.event_type?.toLowerCase()]}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={clsx(styles.ScheduleView)}>
      <div
        className={styles.calendarGrid}
        style={{ gridTemplateColumns: `70px repeat(${days.length}, 1fr)` }}
        id="calendar-container"
      >
        <div className={styles.colLevel1}>
          <div className={styles.day}></div>
          <div className={styles.providerGrid}>
            <div className={styles.providerName}></div>
            {todaySlots.map((time, index) => (
              <div key={`slot-${index}`} className={styles.timeSlot} id={`slot-${time}`}>
                <div className={styles.timeName}>
                  {convertUtcIntToLocalDatetime(time).format("hh:mm a")}
                </div>
              </div>
            ))}
          </div>
        </div>
        {days.map((day, dayIndex) => {
          const timeslots = getDaysSlots(day);
          return (
            <div key={day} className={styles.colLevel1}>
              <div className={styles.day}>
                <p className="xLight">{dayjs(day as string).format("dddd")}</p>
                <p
                  className={clsx(styles.dateWrapper, {
                    [styles.selected]:
                      day === dayjs().startOf("day").toISOString()
                  })}
                >
                  {dayjs(day as string).date()}
                </p>
              </div>
              <div
                className={styles.providerGrid}
                style={{
                  gridTemplateColumns: `repeat(${filteredProviders?.length}, 1fr)`
                }}
              >
                {filteredProviders?.map(provider => (
                  <div className={styles.providerName} key={`provider-${provider.user_id}`}>
                    <p>{FORMAT.name(provider)}</p>
                    <div className={styles.allDayWrapper}>
                      {renderAllDayEvent(dayIndex, provider.user_id)}
                      <div
                        className={styles.allDayEventTail}
                        style={{
                          backgroundColor: userIdToHexCode(provider.user_id)
                        }}
                      />
                    </div>
                  </div>
                ))}

                {timeslots.map((time, index) =>
                  filteredProviders?.map(provider => {
                    const providerAppointments =
                      daysAppointments?.[dayIndex]?.[provider.user_id];

                    const slotAppointments = providerAppointments?.[time];

                    const providerEvents =
                      daysEvents?.[dayIndex]?.[provider.user_id];
                    const slotEvents = providerEvents?.[time];

                    const totalSlotColumns =
                      (slotAppointments?.length || 0) +
                      (slotEvents?.length || 0);

                    const slotId = `slot-${time}-${provider.user_id}`;

                    return (
                      <div
                        key={slotId}
                        className={styles.appointmentSlot}
                        style={{
                          gridTemplateColumns: `repeat(${totalSlotColumns}, 1fr)`
                        }}
                        onClick={() => {
                          dispatch(
                            setSelectedSlot(
                              selectedSlot === slotId ? null : slotId
                            )
                          );
                          dispatch(setSelectedProviderId(provider.user_id));
                        }}
                        role="button"
                        aria-roledescription="button"
                        id={slotId}
                      >
                        {slotAppointments?.map(appointment => (
                          <div
                            role="button"
                            key={`appt-${appointment.appointment_id}`}
                            className={clsx(styles.appointment, { [styles.alert]: appointment.flagged })}
                            style={getAppointmentStyle(appointment, !!colorByProvider, appointmentTypes)}
                            onClick={e => handleApptClick(appointment, e)}
                          >
                            <p className="t5">
                              {FORMAT.name(appointment.patient)}
                            </p>
                            <p className="t5">
                              {METRIC_LABELS[appointment.appointment_type] ||
                                appointment.appointment_type}
                            </p>
                            <Tag
                              label={
                                LABEL_OVERRIDES[appointment.status] ||
                                METRIC_LABELS[appointment.status]
                              }
                              type={
                                APPOINTMENT_STATUS_TYPES[appointment.status]
                              }
                            />
                            <div className={styles.participantBadges}>
                              <Icon
                                svg={getAppointmentLocationIcon(
                                  appointment.appointment_location as string
                                )}
                                width={10}
                                height={10}
                              />
                              <p className="t5">
                                {convertUtcIntToLocalDatetime(
                                  appointment.starts
                                ).format("h:mma")}{" "}
                                -{" "}
                                {convertUtcIntToLocalDatetime(
                                  appointment.ends
                                ).format("h:mma")}
                              </p>
                            </div>
                            <ContentRenderer
                              content={sanitize(
                                appointment.chief_complaint || ""
                              )}
                              classes="t5"
                            />
                          </div>
                        ))}
                        {slotEvents?.map(event => (
                          <div
                            role="button"
                            key={`event-${event.event_id}`}
                            className={clsx(
                              styles.appointment,
                              styles[
                                EVENT_COLOR_MAP[
                                  event?.event_type?.toLowerCase()
                                ] || STATUS_KEYS.INFO
                              ]
                            )}
                            style={{
                              height: getEventHeight(event)
                            }}
                            onClick={e => handleEventClick(event, e)}
                          >
                            <Icon
                              svg={
                                event?.event_type?.toLowerCase() ===
                                "out_of_office"
                                  ? "calendar-minus"
                                  : "users"
                              }
                              width={10}
                              height={10}
                            />
                            <p>{event.title}</p>
                            <p className="t5 xLight">
                              {dayjs(event.start_at).format("h:mma")} -{" "}
                              {dayjs(event.end_at).format("h:mma")}
                            </p>
                            {event.participants.map((participant, idx) => (
                              <p
                                key={`user-${participant.user_id}`}
                                className="t5 gray400"
                              >
                                {FORMAT.name(participant)}
                                {idx === event.participants.length - 1
                                  ? ""
                                  : ","}
                              </p>
                            ))}
                          </div>
                        ))}
                        {selectedSlot && selectedSlot === slotId && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                          >
                            <FixedMenu
                              menuItems={["appointment", "other_event"]}
                              menuItemsLabels={{
                                appointment: "appointment",
                                other_event: "event"
                              }}
                              onClick={item => {
                                dispatch(setSelectedSlot(null));
                                handleSlotClick(time, provider, item);
                              }}
                              noIcons
                            />
                          </motion.div>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
