import { createSlice } from "@reduxjs/toolkit";
import { METRICS } from "@/globals/constants";

export type SelectedView =
  | typeof METRICS.APPOINTMENTS
  | typeof METRICS.TIMELINE
  | typeof METRICS.MESSAGES
  | typeof METRICS.FORMS
  | typeof METRICS.BILLING
  | "home";

interface InitialState {
  selectedView: SelectedView;
}
const initialState: InitialState = {
  selectedView: "home",
};

export const mobileSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {
    setSelectedView: (state, action) => {
      state.selectedView = action.payload;
    },
  },
});

export const { setSelectedView } = mobileSlice.actions;

export default mobileSlice.reducer;
