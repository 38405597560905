/* Medication form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

/* Local Imports */

// components
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import Button from "@/components/button";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

// constants
import { STYLES } from "@/globals/constants";

// styles
import styles from "../styles.module.scss";
import { FORM_METRICS, FORM_VALUES } from "../constants";
import { EVENTS } from "@/components/flows/labor/constants";

/* Medicationform Typescript Interface */
interface MaternalVitalsFormProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  defaultValues?: any;
  startTime?: number;
  disabled?: boolean;
}

export default function MaternalVitalsForm({
  onSubmit,
  defaultValues,
  startTime,
  disabled = false
}: MaternalVitalsFormProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
    setValue
  } = useForm({ defaultValues });

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    setValue("start_time", startTime);
  }, [startTime]);

  return (
    <form
      className={clsx(styles.DetailTimelineForm, styles.MaternalVitals)}
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data, reset);
      })}
    >
      <Controller
        name="start_time"
        control={control}
        render={({ field: { onChange, name, value } }) => {
          return (
            <DateTimeStamp
              name={name}
              onChange={onChange}
              initialValue={
                (defaultValues && defaultValues.start_time) || startTime
              }
              value={value}
            />
          );
        }}
      />
      <Input
        type="text"
        label="Respirations"
        name="respirations"
        id="respirations"
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Temp"
        name="temp"
        id="temp"
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Heart Rate"
        name="heart_rate"
        id="heart_rate"
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Fundus"
        name="fundus"
        id="fundus"
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Inputs"
        name="inputs"
        id="inputs"
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Outputs"
        name="outputs"
        id="outputs"
        register={register}
        disabled={disabled}
      />
      <Input
        type="number"
        label="Estimated Bleeding"
        name={"est_bleeding"}
        id={"est_bleeding"}
        register={register}
        disabled={disabled}
      />
      <ControlledCombobox
        options={FORM_VALUES[EVENTS.BIRTH][FORM_METRICS.MATERNAL_POS]}
        label="Maternal Position"
        name={"maternal_position"}
        control={control}
        isHorizontalLayout={false}
        errors={errors}
        fullWidth
        disabled={disabled}
      />
      <div className={styles.fullWidth}>
        <Input
          type="text"
          label="Comments"
          name="comments"
          id="comments"
          fullWidth
          register={register}
          disabled={disabled}
        />
      </div>
      <div className={styles.fullWidth}>
        <Button
          style={STYLES.FULL_WIDTH}
          type="submit"
          nativeButtonProps={{ disabled }}
        >
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}
