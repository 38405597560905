//third-party
import { useDispatch } from "react-redux";

// components
import TabMenu from "@/components/tabbed";
import NoKnownToggle from "../allergyList/noKnownToggle";

// store
import { Medication, PatientInfo } from "@/store/services/patient";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { MODAL_TYPES } from "../modal/dispatcher";

import styles from "./styles.module.scss";
import Tag from "../tag";

import clsx from "clsx";

interface MedicationListProps {
  patient: PatientInfo;
}

export default function MedicationList({ patient }: MedicationListProps) {
  const dispatch = useDispatch();

  const handleRowClick = (uuid: string, med: Medication) => () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.ADD_MEDICATION,
        props: {
          userId: patient.user_id,
          medication: med,
          uuid: uuid,
          title: med.is_prescription ? "Prescription Info" : "Edit Medication"
        }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  return (
    <div className={styles.Medications}>
      <NoKnownToggle
        patientId={patient.user_id}
        toggleField="no_known_medications"
      />
      <TabMenu
        tabs={[
          { id: "ACTIVE", label: "Active" },
          { id: "INACTIVE", label: "Inactive" },
          { id: "NONCOMPLIANT", label: "Noncompliant" }
        ]}
        style={STYLES.SECONDARY}
        noPadding
      >
        {(tab: string) => (
          <div className={styles.MedicationList}>
            {Object.entries(patient.medical_history.medications || {})
              .filter(([_, med]) => med.status == tab)
              .map(([uuid, med]) => (
                <div
                  key={uuid}
                  className={styles.MedicationRow}
                  onClick={handleRowClick(uuid, med)}
                >
                  <Tag
                    type={STATUS_KEYS.INFO_GREY}
                    label={med.is_prescription ? "Rx" : "Note"}
                  />

                  <div className={clsx(styles.MedicationName)}>{med.name}</div>
                  <div className="t4 xLight med">{med.dosage}</div>
                </div>
              ))}
          </div>
        )}
      </TabMenu>
    </div>
  );
}
