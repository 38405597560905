/* LabOrderingTemplate Name */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";

/* Local Imports */

// components
import Wizard from "@/components/wizard";
import LabOrdering from "@/components/wizard/templates/labOrdering";
// constants

// store
import { EncounterInfo } from "@/store/services/encounter";
import { LabOrderInfo } from "@/store/services/patient";

// styles
import styles from "../styles.module.scss";

/* LabOrderingTemplate Typescript Interface */
interface LabOrderingTemplateProps {
  encounter: EncounterInfo;
  order: LabOrderInfo;
}

export default function LabOrderingTemplate({
  encounter,
  order,
}: LabOrderingTemplateProps) {
  /* Redux */

  /* Local State */
  const [submittingStep, setSubmittingStep] = useState("");
  const [customActionId, setCustomActionId] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  /* Effects */

  /* Event Handlers */

  const handleNextClick = (step: string) => {
    setSubmittingStep(step);
  };

  const handleCustomButtonClick = (id: string) => {
    setCustomActionId(id);
  };

  return (
    <div className={clsx(styles.LabOrderingTemplate)}>
      <Wizard
        steps={[
          "select_lab",
          "select_tests",
          "add_details",
          "add_icd10_codes",
          "add_order_details",
          "review",
        ]}
        labels={{
          select_lab: "Select Lab",
          select_tests: "Select Tests",
          add_details: "Add Test Details",
          add_icd10_codes: "Add ICD-10 Codes",
          add_order_details: "Add Order Details",
          review: "Review Order",
        }}
        customButtons={{
          review: [
            {
              label: "Save Draft & Close",
              actionId: "save_order_draft",
              disabled: submitDisabled,
            },
          ],
        }}
        onNextClick={handleNextClick}
        onCustomButtonClick={handleCustomButtonClick}
        isSubmitDisabled={submitDisabled}
        onSubmit={() => setShouldSubmit(true)}
      >
        {(selectedStep) => (
          <LabOrdering
            selectedStep={selectedStep}
            encounter={encounter}
            submittingStep={submittingStep}
            customActionId={customActionId}
            onOrderValidation={(valid) => setSubmitDisabled(!valid)}
            order={order}
            shouldSubmit={shouldSubmit}
          />
        )}
      </Wizard>
    </div>
  );
}
