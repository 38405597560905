/* FixedMenu Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Icon from "../icons";

// constants

// store

// styles
import styles from "./styles.module.scss";

/* FixedMenu Typescript Interface */
interface FixedMenuProps {
  menuItems: string[];
  menuItemsLabels?: { [key: string]: string };
  onClick: (item: string) => void;
  fitContent?: boolean; // determines whether menu should take full width of parent, or shrink down to content size
  noIcons?: boolean;
}

export default function FixedMenu({
  menuItems,
  menuItemsLabels,
  onClick,
  fitContent = false,
  noIcons
}: FixedMenuProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.FixedMenu, { [styles.fitContent]: fitContent })}
      data-cy="fixed-menu"
    >
      <ul>
        {menuItems.map(item => (
          <li key={item}>
            <button
              onClick={() => onClick(item)}
              data-cy={`fixed-menu-option-${item}`}
            >
              {!noIcons && <Icon svg={item} />}
              <span className="t4 tMd med">
                {menuItemsLabels?.[item] || item}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
