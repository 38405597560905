/* Timestamp form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
// components
import Button from "@/components/button";
import Input from "@/components/input";
import Clock from "../../../../public/svgs/clock.svg";

// constants
import { STYLES } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store

// styles
import styles from "./styles.module.scss";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* Timestampform Typescript Interface */
interface TimestampformProps {
  onSubmit: (data: any) => void;
  startTime: number;
  endTime?: number;
  defaultValues: any;
}

export default function Timestampform({
  onSubmit,
  startTime,
  endTime,
  defaultValues
}: TimestampformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = form;
  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  /* Event Handlers */

  useEffect(() => {
    reset({
      ...defaultValues,
      start_time: startTime,
      end_time: endTime
    });
  }, [defaultValues, startTime, endTime]);

  return (
    <form
      className={clsx(styles.LaborForm, styles.Timestampform)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div>
        <Controller
          name="start_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={startTime}
                value={value}
              />
            );
          }}
        />
        {endTime && (
          <Controller
            name="end_time"
            control={control}
            render={({ field: { onChange, name, value } }) => {
              return (
                <DateTimeStamp
                  name={name}
                  onChange={onChange}
                  initialValue={endTime}
                  value={value}
                />
              );
            }}
          />
        )}
      </div>
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
          onBlur={() => {
            setTextAreaFocused(false);
          }}
        />
      </div>
      <div className={styles.fullWidth}>
        <Button style={STYLES.FULL_WIDTH} type="submit">
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}
