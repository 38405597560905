import * as generated from "./generated/location";

const location = generated.location.enhanceEndpoints({});

export * from "./generated/location";
export default location;
export const {
  useLocationGetListQuery,
  useLazyLocationGetListQuery,
  useLocationGetInfoQuery,
  useLazyLocationGetInfoQuery,
  useLocationGetAppointmentTypesQuery,
  useLazyLocationGetAppointmentTypesQuery,
  useLocationGetUserListQuery,
  useLazyLocationGetUserListQuery
} = location;
