import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";
import KeyValueGrid from "../keyValueGrid";
import KeyValueItem from "../keyValueItem";
import ReadOnlySection from "../readOnlySection";
import SectionCol from "../sectionCol";
import SectionRow from "../sectionRow";
import { LaborModel, PatientInfo } from "@/store/services/patient";
import { useEffect, useState } from "react";
import { STAGES } from "@/components/flows/labor/constants";

interface SummaryTransferInfoProps {
  labor: LaborModel;
  patient: PatientInfo;
}

export default function SummaryTransferInfo({
  labor,
  patient
}: SummaryTransferInfoProps) {
  const [transferData, setTransferData] = useState<Record<string, any>>({});

  useEffect(() => {
    if (
      labor &&
      labor.stages[STAGES.TRANSFER] &&
      labor.stages[STAGES.TRANSFER].events.decision
    ) {
      setTransferData(
        labor.stages[STAGES.TRANSFER].events.decision.forms as Record<
          string,
          any
        >
      );
    }
  }, [labor]);

  if (!transferData.hospital_name) {
    return <></>;
  }

  return (
    <ReadOnlySection header="Patient Transport">
      {/* Transfer */}
      <SectionRow>
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="Patient:">
              <p>{nameAndDOB(patient)}</p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="Transfer to:">
              <p>{transferData.hospital_name}</p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
      </SectionRow>
    </ReadOnlySection>
  );
}
