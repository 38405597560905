/* CreateProblem */
/* External Imports */
import clsx from "clsx";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormGetValues,
  UseFormSetValue
} from "react-hook-form";
import { motion } from "framer-motion";

/* Local Imports */

// components
import ControlledTextArea from "../textArea/controlledTextArea";
import ComboboxSelect from "@/components/input/combobox";
import Button from "@/components/button";
import Input from "@/components/input";

// constants
import { STYLES } from "@/globals/constants";

// store
import { useLazyCodingSearchIcd10Query } from "@/store/services/coding";

// styles
import styles from "./styles.module.scss";

/* CreateProblem Typescript Interface */
interface CreateProblemProps {
  onSubmit: () => void;
  onCancel: () => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  form: any;
}

export default function CreateProblem({
  onSubmit,
  onCancel,
  register,
  errors,
  control,
  getValues,
  setValue,
  form
}: CreateProblemProps) {
  /* Redux */
  const [searchICD10, { data: icd10codes }] = useLazyCodingSearchIcd10Query();
  /* Local State */

  /* Effects */

  /* Event Handlers */
  return (
    <motion.div
      className={clsx(styles.CreateProblem)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <form onSubmit={onSubmit}>
        <div className={styles.filterWrapper}>
          <Controller
            name="select_icd10"
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => (
              <ComboboxSelect
                label="Select diagnosis"
                placeholder="Search for ICD10 code"
                options={icd10codes || []}
                labelAcc={item => `${item?.name} - ${item?.description}`}
                onChange={v => onChange(v)}
                onSearch={term => {
                  // get ICD10 codes from API
                  if (term.length >= 3) searchICD10({ term });
                }}
                error={
                  errors?.select_icd10 &&
                  (errors?.select_icd10?.message as string)
                }
                fullWidth
              />
            )}
            rules={{ required: false }}
          />
          <span className="tMd t5">or</span>
          <Input
            label="Create problem title"
            id="new_problem_title"
            name="new_problem_title"
            type="text"
            required={!getValues("select_icd10")}
            register={register}
            fullWidth
          />
        </div>
        <div className="grid2">
          <Input
            label="Date of onset"
            id="new_date_of_onset"
            name="new_date_of_onset"
            type="date"
            register={register}
            fullWidth
          />
          <div />
        </div>
        <ControlledTextArea
          hiddenLabel
          label="problem commentary"
          id={`problem_new_note`}
          name={`problem_new_note`}
          placeholder="add a note about this problem"
          setValue={setValue}
          rules={{
            required: true
          }}
          form={form}
        />
        <div className={styles.buttons}>
          <Button style={STYLES.SECONDARY} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save Problem</Button>
        </div>
      </form>
    </motion.div>
  );
}
