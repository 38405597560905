/* ViewFax Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import ViewBody from "@/components/viewBody";

// constants

// store

// styles
import styles from "../styles.module.scss"

/* ViewFax Typescript Interface */
interface ViewFaxProps {
  url: string;
}

export default function ViewFax({ url }: ViewFaxProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={styles.ViewFile}>
        <embed src={url} type="application/pdf" width="100%" height="100%" style={{ minHeight: "70vh" }} />
      {/* Leaving this for posterity because I don't think the component is finished but it
      seems cool.
      <ViewBody>
        <embed src={src} type="application/pdf" width="100%" height="100%" />
      </ViewBody> */}
    </div>
  );
}
