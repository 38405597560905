import React, { ReactNode, ReactPropTypes } from "react";
import Button from "../button";
import Error from "next/error";
import clsx from "clsx";
import { ReportSystemError } from "@/store/services/system";
import {
  BACKEND_PROTOCOL,
  BACKEND_HOST,
  BACKEND_PORT
} from "@/globals/constants/environment";
import { withRouter, NextRouter } from "next/router";

import Icon from "../icons";

import { STYLES } from "@/globals/constants";

import styles from "./styles.module.scss";

interface ErrorBoundaryProps {
  children: ReactNode;
  isComponentScoped?: boolean;
  router: NextRouter; // Define router prop
}

// Define the type of the component's state
interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  // Reset error state when navigating to a new page
  resetErrorState = () => {
    this.setState({ hasError: false });
  };

  componentDidMount() {
    // Add event listener for Next.js Router events
    this.props.router.events.on("routeChangeStart", this.resetErrorState);
  }

  componentWillUnmount() {
    // Remove event listener when component is unmounted
    this.props.router.events.off("routeChangeStart", this.resetErrorState);
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    const reportErrorUrl = `${BACKEND_PROTOCOL}://${BACKEND_HOST}:${BACKEND_PORT}/api/v1/system/report-error`;
    const body: ReportSystemError = {
      message: error.message,
      stack_trace: error.stack
    };

    // dispatch error to backend
    fetch(reportErrorUrl, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          className={clsx(styles.Error, {
            [styles.isComponentScoped]: this.props.isComponentScoped
          })}
        >
          <div className={styles.headerWrapper}>
            <div className={styles.wrap}>
              <h1 className="tMd dark">Uh oh! Something went wrong</h1>
            </div>
          </div>
          <div className={styles.message}>
            <Icon svg="warning" />
            <p>
              Oops! It seems like we're experiencing a cramp in our system. Our
              team is on it, working to get everything back to normal. Your data
              is in good hands—the Pario care team will have it feeling better
              in no time!
            </p>
          </div>
          <div className="grid2">
            <Button
              type="button"
              onClick={() => this.props.router.back()}
              style={STYLES.FULL_WIDTH}
            >
              Back
            </Button>
            <Button
              type="button"
              onClick={() => this.setState({ hasError: false })}
              style={STYLES.SECONDARY_FULL}
            >
              Try again?
            </Button>
          </div>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
