import { InputHTMLAttributes, ReactNode } from "react";
import { Inter } from "next/font/google";
import clsx from "clsx";
import {
  FieldValues,
  FieldErrors,
  UseFormRegister,
  Path,
} from "react-hook-form";

// constants
// TEMP
import { FIELD_TYPES } from "@/globals/constants/formSchema";

// styles
import styles from "./styles.module.scss";

const font = Inter({
  subsets: ["latin"],
});

export interface GeneratedInputProps<T extends FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string; // input label
  name: Path<T>; // input name
  register: UseFormRegister<T>;
  errors: FieldErrors<T>; // errors received from parent form
  hiddenLabel?: boolean;
  fullWidth?: boolean;
}

const GeneratedInput = <T extends FieldValues>({
  label,
  name,
  register,
  errors,
  defaultValue,
  hiddenLabel,
  fullWidth,
  ...nativeInputProps
}: GeneratedInputProps<T>) => {
  const { required, type, onChange } = nativeInputProps || {};
  return (
    <div
      className={clsx(
        styles.Input,
        styles.Generated,
        font.className,
        { [styles.fullWidth]: fullWidth, [styles.horizontal]: !fullWidth }
      )}
    >
      {/* label for input: do not remove, required for accessibility */}
      <label htmlFor={name} className={clsx({ [styles.hidden]: hiddenLabel })}>
        {label}
        {/* if field is required, add asterix */}
        {required && <sup>*</sup>}
      </label>
      <div>
        <input
          /* "register" comes from react-hook-form useForm()
        input registers validation according to its name
        passing field up to parent for validation */
          {...register(name, {
            valueAsNumber: type === FIELD_TYPES.NUMBER,
          })}
          name={name}
          id={name}
          onChange={onChange}
          /* Additional native input element attributes such as type, required, etc. */
          {...nativeInputProps}
          defaultValue={defaultValue}
        />
        {/* "errors" object comes from parent form via react-hook-form useForm()
          if there is an error for this field, display it */}
        {errors && errors[name] && (
          <div className={styles.validationError}>
            {errors[name]?.message as ReactNode}
          </div>
        )}
      </div>
    </div>
  );
};

export default GeneratedInput;
