import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    phraseList: build.query<PhraseListApiResponse, PhraseListApiArg>({
      query: queryArg => ({
        url: `/phrases/${queryArg.id}/${queryArg.scope}/list`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    phraseUpsert: build.mutation<PhraseUpsertApiResponse, PhraseUpsertApiArg>({
      query: queryArg => ({
        url: `/phrases/${queryArg.id}/${queryArg.scope}/upsert`,
        method: "POST",
        body: queryArg.phraseUpsertRequest
      })
    }),
    phraseDelete: build.mutation<PhraseDeleteApiResponse, PhraseDeleteApiArg>({
      query: queryArg => ({
        url: `/phrases/${queryArg.id}/${queryArg.scope}/${queryArg.phrase}/delete`,
        method: "DELETE"
      })
    }),
    phraseText: build.query<PhraseTextApiResponse, PhraseTextApiArg>({
      query: queryArg => ({
        url: `/phrases/${queryArg.id}/${queryArg.scope}/${queryArg.phrase}/text`
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as phrase };
export type PhraseListApiResponse =
  /** status 200 List of all the "dot-phrases" defined for a scope, along with the description.
This is primarily intended for drop-down lists. The return list is guaranteed to be sorted
in ascending alphabetical order.
 */ PhraseList;
export type PhraseListApiArg = {
  id: Id;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  page?: Page;
  pagesz?: PageSize;
};
export type PhraseUpsertApiResponse =
  /** status 200 Sent in response to updating a phrase.
   */ PhraseModel;
export type PhraseUpsertApiArg = {
  id: Id;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Upsert a phrase.
   */
  phraseUpsertRequest: PhraseUpsertRequest;
};
export type PhraseDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PhraseDeleteApiArg = {
  id: Id;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  phrase: Phrase;
};
export type PhraseTextApiResponse =
  /** status 200 Sent in response to requesting a phrase's text.
   */ PhraseText;
export type PhraseTextApiArg = {
  id: Id;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  phrase: Phrase;
};
export type Sex = "MALE" | "FEMALE" | "UNKNOWN";
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type RoleId = number;
export type UserPracticeRoles = RoleId[];
export type LocationId = number;
export type LocationIds = LocationId[];
export type UserMetaPractice = {
  roles: UserPracticeRoles;
  locations: LocationIds;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_email?: number;
};
export type UserMetaPractices = {
  [key: string]: UserMetaPractice;
};
export type UserMetaLast = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
};
export type UserDosespotMeta = {
  patient_id?: number;
  proxy_clinician_id?: number;
  prescribing_clinician_id?: number;
};
export type UserMetadata = {
  practices: UserMetaPractices;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  last?: UserMetaLast;
  dosespot: UserDosespotMeta;
};
export type BloodType =
  | "A+"
  | "A-"
  | "B+"
  | "B-"
  | "AB+"
  | "AB-"
  | "O+"
  | "O-"
  | "UNKNOWN";
export type Diabetes = "TYPE_1" | "TYPE_2" | "GESTATIONAL" | "NONE";
export type HivStatus = "POSITIVE" | "NEGATIVE" | "UNKNOWN";
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type AllergySeverity = "MILD" | "MODERATE" | "SEVERE" | "FATAL";
export type Allergy = {
  icd10_code_details?: Icd10CodeDetails;
  name?: string;
  reaction?: string;
  severity?: AllergySeverity;
  notes?: string;
  date_of_onset?: string;
  allergy_id?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Allergies = Allergy[];
export type Immunization = {
  name: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt: number;
  /** A Pario date in YYYYMMDD format.
   */
  date?: number;
  notes?: string;
};
export type Immunizations = Immunization[];
export type MedicationStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "NONCOMPLIANT"
  | "DELETED";
export type Medication = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  lexicomp_product_id?: number;
  lexicomp_drug_id?: string;
  lexicomp_synonym_id?: number;
  lexicomp_synonym_type?: number;
  dosespot_id?: number;
  days_supply?: number;
  is_prescription?: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Medications = {
  [key: string]: Medication;
};
export type EddMethod = "LMP" | "ULTRASOUND" | "DATE_OF_CONCEPTION" | "UNKNOWN";
export type EstimatedDueDate = {
  method: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
};
export type Lmp = {
  last_menstrual_period?: string;
  estimate_id?: string;
};
export type PregnancyState =
  | "GESTATING"
  | "DELIVERED"
  | "MISCARRIED"
  | "STILLBORN"
  | "ECTOPIC"
  | "TERMINATED"
  | "POSTPARTUM"
  | "UNSPECIFIED"
  | "DELETED";
export type PrenatalFlowSheetEntry = {
  weeks?: string;
  days?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  encounter_start?: number;
  weight?: number;
  blood_pressure?: string;
  temperature?: number;
  fundus?: string;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  note?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
};
export type PrenatalFlowSheet = PrenatalFlowSheetEntry[];
export type LabOrderId = string;
export type LabOrders = LabOrderId[];
export type UserId = number;
export type UserIdList = UserId[];
export type LaborEvent = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: UserIdList;
  forms?: object;
};
export type LaborStage = {
  events: {
    [key: string]: LaborEvent;
  };
  progress_updates: LaborEvent[];
  medications: LaborEvent[];
  infant_progress_updates?: LaborEvent[];
  infant_medications?: LaborEvent[];
  recurring_events?: LaborEvent[];
  notes: LaborEvent[];
};
export type LaborModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  transfer?: object;
  stages: {
    [key: string]: LaborStage;
  };
  note?: string;
};
export type PregnancyOutcome =
  | "UNKNOWN"
  | "TAB (therapeautic abortion)"
  | "EAB (elective abortion)"
  | "SAB (miscarriage)"
  | "SBVD (stillbirth vaginal delivery)"
  | "SBCS (stillbirth cesarean delivery)"
  | "NSVD (vaginal delivery)"
  | "NSVB (vaginal birth)"
  | "VAVD (vacuum vaginal delivery)"
  | "FAVD (forceps vaginal delivery)"
  | "CS (cesarean)"
  | "VBAC (vaginal birth after previous cesarean)"
  | "NSVD (vaginal delivery) - Water"
  | "VBAC (vaginal birth after previous cesarean) - Water"
  | "EP (ectopic pregnancy)";
export type MaternityNeighborhoodPregnancyMetadata = {
  episode_of_care_id?: string;
  past_pregnancy_id?: string;
};
export type ClientCarePregnancyMetadata = {
  pregnancy_id?: string;
};
export type PregnancyMetadata = {
  maternity_neighborhood?: MaternityNeighborhoodPregnancyMetadata;
  client_care?: ClientCarePregnancyMetadata;
};
export type Pregnancy = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  edd?: EstimatedDueDate;
  lmp?: Lmp;
  state: PregnancyState;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: boolean;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor_minutes?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type Pregnancies = Pregnancy[];
export type AntibodyScreenDat = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met1 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met2 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type UserMedicalHistory = {
  blood_type?: BloodType;
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  diabetic?: Diabetes;
  hiv_status?: HivStatus;
  allergies?: Allergies;
  immunizations?: Immunizations;
  medications?: Medications;
  pregnancies?: Pregnancies;
  no_known_drug_allergies?: boolean;
  no_known_medications?: boolean;
  no_known_problems?: boolean;
  antibody_screen_DAT?: AntibodyScreenDat;
  met_1?: Met1;
  met_2?: Met2;
};
export type UserInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  metadata: UserMetadata;
  medical_history: UserMedicalHistory;
};
export type PhraseListItem = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  name: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_edited: number;
  last_editor: UserInfo;
};
export type PhraseList = PhraseListItem[];
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type Id = number;
export type IdType =
  | "form"
  | "group"
  | "message"
  | "practice"
  | "patient"
  | "medical_assistant"
  | "administrative"
  | "biller"
  | "location"
  | "pregnancy"
  | "provider"
  | "template"
  | "user"
  | "medication"
  | "progress_update"
  | "note"
  | "infant_medication"
  | "infant_progress_update"
  | "recurring_event";
export type Page = number;
export type PageSize = number;
export type PhraseModel = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  phrase: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** Expansion for a dot-phrase.
   */
  text: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  last_editor: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_edited: number;
};
export type PhraseUpsertRequest = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  phrase: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** Expansion for a dot-phrase.
   */
  text: string;
};
export type ErrorId = number;
export type Phrase = string;
export type PhraseText = string;
export const {
  usePhraseListQuery,
  useLazyPhraseListQuery,
  usePhraseUpsertMutation,
  usePhraseDeleteMutation,
  usePhraseTextQuery,
  useLazyPhraseTextQuery
} = injectedRtkApi;
