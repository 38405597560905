// External
import { sanitize } from "dompurify";

// Store
import { FormSection } from "@/store/services/form";
import { FormSubmission } from "@/store/services/patient";

// Helpers
import { convertUtcIntToLocalDate, convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";

// Components
import KeyValueGrid from "@/components/birthSummary/keyValueGrid";
import KeyValueItem from "@/components/birthSummary/keyValueItem";
import ValueItem from "@/components/birthSummary/valueItem";
import ObjectTableItem from "@/components/birthSummary/objectTableItem";
import ContentRenderer from "@/components/textArea/contentRenderer";

// Styles
import styles from "./styles.module.scss";
import { FIELD_TYPES } from "@/globals/constants/formSchema";
import dayjs from "dayjs";

interface ReadOnlyFormProps {
  section: FormSection;
  submission: FormSubmission;
}

export default function ReadOnlyForm({
  section,
  submission
}: ReadOnlyFormProps) {
  return (
    <KeyValueGrid>
      {section.fields.map((field, index) => {
        switch (field.type) {
          case "MULTI_DROPDOWN":
            return (
              <KeyValueItem keyLabel={field.label} key={index}>
                <ul>
                  {(
                    submission.data &&
                    (submission.data[field.name as keyof {}] as Array<string>)
                  )?.map((item, index) => {
                    if (field.type == "DATE") {
                      return (
                        <li key={index}>
                          {convertUtcIntToLocalDate(parseInt(item)).format("MM/DD/YYYY") || ""}
                        </li>
                      );
                    } else if (field.type == "DATETIME") {
                      return (
                        <li key={index}>
                          {convertUtcIntToLocalDatetime(parseInt(item)).format(
                            "MM/DD/YYYY"
                          ) || ""}
                        </li>
                      );
                    } else {
                      return <li key={index}>{item}</li>;
                    }
                  })}
                </ul>
              </KeyValueItem>
            );
          case "TEXTAREA":
            if ((field.name as string).indexOf("comments") >= 0) {
              return (
                <div key={index}>
                  <p className={styles.header}>{field.label}</p>
                  <ValueItem>
                    {submission.data && (
                      <ContentRenderer
                        content={
                          sanitize(
                            submission.data[field.name as keyof object]
                          ) as string
                        }
                        key={field.name}
                      />
                    )}
                  </ValueItem>
                </div>
              );
            } else {
              return (
                <KeyValueItem keyLabel={field.label} key={index}>
                  {submission.data && (
                    <ContentRenderer
                      content={
                        sanitize(
                          submission.data[field.name as keyof object]
                        ) as string
                      }
                      key={field.name}
                    />
                  )}
                </KeyValueItem>
              );
            }
          case "TABLE":
            if (!submission.data) {
              return <p></p>;
            }
            let submissionArray: Array<Record<string, any>> = [];
            let fieldNames: Array<string> = [];
            let fieldLabels: Record<string, string> = {};
            Object.keys(submission.data).forEach(key => {
              if (key.startsWith(`${field.name}-`)) {
                let keyString = key.substring(
                  key.indexOf(`${field.name}-`) + `${field.name}-`.length
                );

                const submissionRowIndex = parseInt(keyString.substring(0, 1));
                const submissionRowFieldName = keyString.substring(2);

                let submissionRowData: any;
                if (field.type == "DATE") {
                  submissionRowData =
                  convertUtcIntToLocalDate(
                      parseInt(submission.data[key as keyof {}])
                    ).format("MM/DD/YYYY") || "";
                } else if (field.type == "DATETIME") {
                  submissionRowData =
                    convertUtcIntToLocalDatetime(
                      submission.data[key as keyof {}]
                    ).format("MM/DD/YYYY") || "";
                } else {
                  submissionRowData = submission.data[key as keyof {}];
                }

                submissionArray[submissionRowIndex] = {
                  ...submissionArray[submissionRowIndex],
                  [submissionRowFieldName]: submissionRowData
                };
              }
            });
            field.subfields?.forEach(subfield => {
              if (subfield.name) {
                fieldNames.push(subfield.name || "");
                fieldLabels[subfield.name] = subfield.label;
              }
            });
            return (
              <div key={index}>
                <p className={styles.header}>{field.label}</p>
                <ObjectTableItem
                  orderedHeaders={fieldNames}
                  objectGrid={submissionArray}
                  headerLabels={fieldLabels}
                />
              </div>
            );
          case FIELD_TYPES.BOOL:
            let valueString = "";
            if (
              submission.data &&
              submission.data[field.name as keyof object] !== undefined
            ) {
              valueString =
                submission.data[field.name as keyof object] === true
                  ? "Yes"
                  : "No";
            }
            return (
              <KeyValueItem keyLabel={field.label} key={index}>
                <p>{valueString}</p>
              </KeyValueItem>
            );
        }
        return (
          <KeyValueItem keyLabel={field.label} key={index}>
            {field.type == "DATE" || field.type == "DATETIME" ? (
              <>
                {field.type == "DATE" && (
                  <p>
                    {submission.data &&
                    submission.data[field.name as keyof object]
                      ? convertUtcIntToLocalDate(
                          parseInt(submission.data[field.name as keyof object])
                        ).format("MM/DD/YYYY")
                      : ""}
                  </p>
                )}
                {field.type == "DATETIME" && (
                  <p>
                    {submission.data &&
                    submission.data[field.name as keyof object]
                      ? convertUtcIntToLocalDatetime(
                          submission.data[field.name as keyof object]
                        ).format("MM/DD/YYYY")
                      : ""}
                  </p>
                )}
              </>
            ) : (
              submission.data && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      submission.data[field.name as keyof object]
                    ) as string
                  }}
                  key={field.name}
                ></p>
              )
            )}
          </KeyValueItem>
        );
      })}
    </KeyValueGrid>
  );
}
