import * as generated from "./generated/pregnancy";

const pregnancy = generated.pregnancy.enhanceEndpoints({
  endpoints: {
    pregnancyUpsert: {
      invalidatesTags: ["Patients"]
    },
    pregnancyInfo: {
      providesTags: ["EDD", "Patients"]
    },
    listEddHistory: {
      providesTags: ["EDD"]
    },
    createEdd: {
      invalidatesTags: ["EDD", "Patients"]
    },
    updateEdd: {
      invalidatesTags: ["EDD", "Patients"]
    },
    deleteEdd: {
      invalidatesTags: ["EDD", "Patients"]
    },
    getLabFlowsheet: {
      providesTags: ["LabFlowsheet"]
    },
    createLabFlowsheetEntry: {
      invalidatesTags: ["LabFlowsheet"]
    },
    updateLabFlowsheetEntry: {
      invalidatesTags: ["LabFlowsheet"]
    },
    deleteLabFlowsheetEntry: {
      invalidatesTags: ["LabFlowsheet"]
    }
  }
});

export * from "./generated/pregnancy";
export default pregnancy;
export const {
  usePatientTransferRecordsMutation,
  usePregnancyUpsertMutation,
  usePregnancyListQuery,
  useLazyPregnancyListQuery,
  usePregnancyInfoQuery,
  useLazyPregnancyInfoQuery,
  useListEddHistoryQuery,
  useLazyListEddHistoryQuery,
  useCreateEddMutation,
  useDeleteEddMutation,
  useUpdateEddMutation,
  useGetLabFlowsheetQuery,
  useCreateLabFlowsheetEntryMutation,
  useUpdateLabFlowsheetEntryMutation,
  useDeleteLabFlowsheetEntryMutation
} = pregnancy;
