// external
import { NextRouter, useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

// utils
import { GridDataType } from "@/components/datagrid";
import { DataTag, metrics } from "@/components/datagrid";

// components
import Head from "@/components/datagrid/head";
import ContentRenderer from "@/components/textArea/contentRenderer";
import Icon from "@/components/icons";
// styles
import styles from "./styles.module.scss";
import clsx from "clsx";

export interface EddFlowSheetProps {
  data: GridDataType;
  headers: Array<string>;
  customFormattingFunction?: (
    metrics: metrics,
    metric: string,
    id: number | string
  ) => JSX.Element | string | number;
  onEdit: (estimateId: string, type: string) => void;
  onDelete: (estimateId: string) => void;
}
export default function EddFlowSheet({
  data,
  headers,
  customFormattingFunction,
  onEdit,
  onDelete
}: EddFlowSheetProps) {
  // local state
  const [openRowIds, setOpenRowIds] = useState<string[]>([]);

  // Toggles extra row drawer visibility
  const handleToggleRowOpen = (id: string) => {
    let newOpenRowIds;
    // if row is already open, close
    if (openRowIds.includes(id)) {
      newOpenRowIds = openRowIds.filter(openRowId => openRowId !== id);
    } else {
      // otherwise open it
      newOpenRowIds = [...openRowIds, id];
    }
    setOpenRowIds(newOpenRowIds);
  };

  const spring = {
    type: "spring",
    damping: 25,
    stiffness: 120
  };

  return (
    <div className={clsx(styles.FlowSheet, styles.EddFlowSheet)} data-cy="edd-flowsheet">
      <table className={styles.Datagrid}>
        <Head headers={headers} />
        <tbody>
          {data.map(({ metrics, id }) => (
            <Fragment key={id}>
              <AnimatePresence>
                <motion.tr layout transition={spring} key={`data-${id}`}>
                  {/* display this row as read-only */}
                  {headers.map(metric => {
                    if (metric == "edit") {
                      return (
                        <td key={`${metric}-${id}`}>
                          <button
                            onClick={() =>
                              onEdit(id as string, metrics.method as string)
                            }
                            type="button"
                            className={styles.editButton}
                          >
                            <Icon svg={"edit_gray"} width={16} height={16} />
                          </button>

                          <button
                            onClick={() => onDelete(id as string)}
                            type="button"
                            className={styles.editButton}
                          >
                            <Icon svg={"trash_grey"} width={16} height={16} />
                          </button>
                        </td>
                      );
                    }

                    if (metric == "note") {
                      return (
                        <td key={`${metric}-${id}`}>
                          <button
                            onClick={() => handleToggleRowOpen(id as string)}
                            type="button"
                            className={styles.editButton}
                          >
                            <Icon
                              svg="chevron_down_blue"
                              width={15}
                              flipped={openRowIds.includes(id as string)}
                            />
                          </button>
                        </td>
                      );
                    }

                    return (
                      <td key={`${metric}-${id}`} className={`${metric}-${id}`}>
                        {/* handle formatting exceptions */}
                        <p style={{ paddingLeft: 4 }}>
                          {customFormattingFunction &&
                            customFormattingFunction(metrics, metric, id)}
                        </p>
                      </td>
                    );
                  })}
                </motion.tr>
              </AnimatePresence>
              {openRowIds.includes(id as string) && (
                <tr>
                  <td colSpan={headers.length} className={styles.drawer}>
                    <ContentRenderer content={metrics.comment as string} />
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
