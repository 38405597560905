/* BirthProgressUpdate form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

/* Local Imports */

// components
import Input from "@/components/input";
import Button from "@/components/button";
import Clock from "../../../../public/svgs/clock.svg";
import ControlledCombobox from "@/components/input/controlledCombobox";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// constants
import { STYLES } from "@/globals/constants";
import { FORM_METRICS, FORM_VALUES } from "./constants";
import { EVENTS } from "@/components/flows/labor/constants";

// helpers
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// styles
import styles from "./styles.module.scss";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* BirthProgressUpdateform Typescript Interface */
interface BirthProgressUpdateformProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  defaultValues?: any;
  disabled?: boolean;
}

export default function BirthProgressUpdateform({
  onSubmit,
  defaultValues,
  disabled = false
}: BirthProgressUpdateformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    reset
  } = form;
  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  /* Effects */

  /* Event Handlers */

  const inputFields = [
    { name: "HR", type: "text" },
    { name: "RR", type: "text" },
    { name: "temp", type: "number" },
    { name: "color", type: "text" },
    { name: "inputs", type: "text" },
    { name: "outputs", type: "text" }
  ];

  return (
    <form
      data-cy="progress-update-form"
      className={clsx(styles.LaborForm, styles.BirthProgressUpdateForm)}
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data, reset);
      })}
    >
      <fieldset
        className={clsx(styles.time, styles.progressTime, styles.spanTwo)}
      >
        <legend>Start time</legend>
        <Controller
          name="start_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={defaultValues && defaultValues.start_time}
                value={value}
                hiddenLabel
              />
            );
          }}
        />
      </fieldset>

      <fieldset className={clsx(styles.progressSection, styles.spanSix)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>Vitals</legend>
        <div className={styles.inputs}>
          {inputFields.map(({ name, type }) => (
            <Input
              key={name}
              type={type}
              label={name.replace("_", " ")}
              name={name}
              id={name}
              fullWidth
              register={register}
              disabled={disabled}
            />
          ))}
          <ControlledCombobox
            options={FORM_VALUES[EVENTS.BIRTH][FORM_METRICS.MATERNAL_POS]}
            label="Maternal Position"
            name={"maternal_position"}
            control={control}
            isHorizontalLayout={false}
            errors={errors}
            fullWidth
            disabled={disabled}
          />
        </div>
      </fieldset>
      <fieldset className={clsx(styles.fullWidth, styles.progressTime)}>
        <legend>Comments</legend>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          hiddenLabel
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
        />
      </fieldset>
      <div className={styles.fullWidth}>
        <Button
          style={STYLES.FULL_WIDTH}
          type="submit"
          nativeButtonProps={{ disabled }}
        >
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}
