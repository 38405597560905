import { STATUS_KEYS } from "@/globals/constants";

// TEMP: the whole file is temporary, we will not store & manage task state locally in this way
const initialTasks = [
  {
    id: 1,
    items: [
      {
        title: "Dr. Eden Fromberg",
      },
      {
        title: "Review Patient Reports",
        subtitle: "Due Date: June 1st",
      },
    ],
    tags: [{ type: STATUS_KEYS.WARNING, label: "Incomplete" }],
    actions: [
      {
        label: "View",
        // onClick: () => console.log("to build view"),
      },
      {
        label: "Mark as Complete",
        // onClick: () => console.log("to mark as complete"),
      },
    ],
  },
  {
    id: 2,
    items: [
      {
        title: "Dr. Eden Fromberg",
      },
      {
        title: "Prepare Presentation",
        subtitle: "Due Date: June 3rd",
      },
    ],
    tags: [{ type: STATUS_KEYS.ERROR, label: "Urgent" }],
    actions: [
      {
        label: "View",
        // onClick: () => console.log("to build view"),
      },
      {
        label: "Mark as Complete",
        // onClick: () => console.log("to mark as complete"),
      },
    ],
  },
  {
    id: 3,
    items: [
      {
        title: "Dr. Eden Fromberg",
      },
      {
        title: "Follow up with Patients",
        subtitle: "Due Date: June 5th",
      },
    ],
    tags: [{ type: STATUS_KEYS.WARNING, label: "Incomplete" }],
    actions: [
      {
        label: "View",
        // onClick: () => console.log("to build view"),
      },
      {
        label: "Mark as Complete",
        // onClick: () => console.log("to mark as complete"),
      },
    ],
  },
];

const [baseTask] = initialTasks;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TaskItem {
  title: string;
  subtitle?: string;
}

interface TaskTag {
  type: string;
  label: string;
}

interface TaskAction {
  label: string;
  onClick: () => void;
}

interface BaseTask {
  id: number;
  items: TaskItem[];
  tags: TaskTag[];
  actions: TaskAction[];
}

type initialState = {
  tasks: BaseTask[];
};

const initialState: initialState = {
  // @ts-ignore
  tasks: initialTasks,
};

export const TaskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    addTask: (
      state,
      {
        payload,
      }: PayloadAction<{
        assignee: string;
        message: string;
        dueDate: string;
      }>
    ) => {
      const { assignee, message, dueDate } = payload;
      const task = {
        ...baseTask,
        id: state.tasks.length + 1,
        items: [
          { title: assignee },
          { title: message, subtitle: `Due date: ${dueDate}` },
        ],
      };
      // @ts-ignore
      state.tasks.push(task);
    },
  },
});

export const { addTask } = TaskSlice.actions;

export default TaskSlice.reducer;
