/* Copy Fax to Paitent Modal Template */

// External
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

// components
import Button from "@/components/button";
import ComboboxSelect from "@/components/input/combobox";

// globals, constants, helpers
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";

// store
import { setModalIsOpen } from "../modalSlice";
import { FaxId, useFaxCopyToPatientMutation } from "@/store/services/fax";
import { PatientInfo, UserId } from "@/store/services/patient";
import { RootState } from "@/store/store";
import { usePracticeSearchUsersQuery } from "@/store/services/practice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "../styles.module.scss";

export interface CopyFaxModalProps {
  selectedFaxIds: Array<FaxId>;
}

export default function CopyFaxes({ selectedFaxIds }: CopyFaxModalProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [copiedUser, setCopiedUser] = useState<UserId>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [copyFaxToPatient, { isLoading: isCopyLoading }] =
    useFaxCopyToPatientMutation();
  const { data: users, isSuccess: gotUsers } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  // Event handlers

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const handleConfirm = () => {
    if (!copiedUser) {
      setErrorMessage("Patient is required");
      return;
    }

    if (!selectedFaxIds) {
      return;
    }

    copyFaxToPatient({
      patientId: copiedUser,
      faxCopyToPatientRequest: {
        fax_ids: selectedFaxIds
      }
    })
      .unwrap()
      .then(data => {
        dispatch(
          addAlertToToastTrough({
            message: "Fax successfully copied",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(err => {
        setErrorMessage("Something went wrong copying the fax");
      });
  };

  return (
    <div className={clsx(styles.CopyFaxes)}>
      {gotUsers && !isCopyLoading ? (
        <>
          <div className={styles.message}>
            <p>Please select a patient to copy the faxes to.</p>
            <ComboboxSelect
              label="Patient"
              hiddenLabel
              options={([...users?.patients] || []).sort((a, b) =>
                a.last_name.localeCompare(b.last_name)
              )}
              selectOnInput
              onChange={(patient?: PatientInfo) => {
                if (patient) {
                  setCopiedUser(patient.user_id);
                }
              }}
              error={errorMessage}
              labelAcc={patient => (patient ? nameAndDOB(patient) : patient)}
              placeholder="Search patients"
            />
          </div>
          <div className={styles.buttons}>
            <Button style={STYLES.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
            <Button style={STYLES.PRIMARY} onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </>
      ) : (
        <Skeleton count={6} />
      )}
    </div>
  );
}
