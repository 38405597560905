import * as generated from "./generated/file";

const file = generated.file.enhanceEndpoints({
  addTagTypes: ["Patient-Files", "Practice-Files"],
  endpoints: {
    fileList: {
      providesTags: (_resp, _err, req) => {
        switch (req.scope) {
          case "patient":
            return [{ type: "Patient-Files", id: req.id }];
          case "practice":
            return [{ type: "Practice-Files", id: req.id }];
          default:
            return [];
        }
      }
    },
    fileUpload: {
      invalidatesTags: (_resp, _err, req) => {
        if (req.patientId === undefined) {
          return [{ type: "Practice-Files" }];
        } else {
          return [{ type: "Patient-Files", id: req.patientId }];
        }
      }
    },
    fileUpdate: {
      invalidatesTags: (_resp, _err, _req) => [
        "Patient-Files",
        "Practice-Files",
        "Tags"
      ]
    },
    fileDelete: {
      invalidatesTags: (_resp, _err, _req) => [
        "Patient-Files",
        "Practice-Files"
      ]
    },
    filePurge: {
      invalidatesTags: (_resp, _err, _req) => [
        "Patient-Files",
        "Practice-Files"
      ]
    },
    fileBatchUpdate: {
      invalidatesTags: (_resp, _err, _req) => [
        "Patient-Files",
        "Practice-Files"
      ]
    },
    files: {
      providesTags: (_result, _error, _arg) => [
        "Patient-Files",
        "Practice-Files",
        "ClaimOutcome",
        "Claim"
      ]
    },
    fileAnnotationCreate: {
      invalidatesTags: (_resp, _err, req) => [
        { type: "FileAnnotations", id: req.fileId }
      ]
    },
    fileAnnotationUpdate: {
      invalidatesTags: (_resp, _err, req) => [
        { type: "FileAnnotations", id: req.fileId }
      ]
    },
    fileAnnotationDelete: {
      invalidatesTags: (_resp, _err, req) => [
        { type: "FileAnnotations", id: req.fileId }
      ]
    },
    fileDetails: {
      providesTags: (_result, _error, { fileId }) => [
        { type: "FileAnnotations", id: fileId }
      ]
    }
  }
});

export * from "./generated/file";
export default file;
export const {
  useFileListQuery,
  useLazyFileListQuery,
  useFileUploadMutation,
  useFileGenerateDefaultUrlQuery,
  useLazyFileGenerateDefaultUrlQuery,
  useFileGenerateUrlMutation,
  useFileDetailsQuery,
  useLazyFileDetailsQuery,
  useFileUpdateMutation,
  useFileDeleteMutation,
  useFilePurgeMutation,
  useFilesQuery
} = file;
