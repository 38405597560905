import { useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  ErrorInfo,
  PatientInfo,
  useLazyPatientGetDoseSpotSsoUrlQuery,
  usePatientCloseDoseSpotMutation,
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "@/components/modal/modalSlice";

import { STATUS_KEYS } from "@/globals/constants";

import styles from "../styles.module.scss";

export interface RxModalProps {
  patient?: PatientInfo;
}
export default function RxModal({ patient }: RxModalProps) {
  const dispatch = useDispatch();

  // Get SSO URL from backend
  const [getSsoUrl, { data, error, isError }] =
    useLazyPatientGetDoseSpotSsoUrlQuery();

  const [handleClose] = usePatientCloseDoseSpotMutation();

  useEffect(() => {
    if (!patient?.user_id) {
      return
    }

    getSsoUrl({ patientId: patient.user_id });

    return () => {
      handleClose({patientId: patient.user_id});
    }
  }, [patient?.user_id]);

  useEffect(() => {
    if (isError) {
      dispatch(setModalIsOpen(false));

      // @ts-ignore
      const err : ErrorInfo = error.data as ErrorInfo;

      if (err?.user_facing) {
        dispatch(addAlertToToastTrough({
          message: err.message,
          type: STATUS_KEYS.ERROR
        }));

        for (const e of (err?.extra_data || [])) {
            dispatch(
              addAlertToToastTrough({
                message: e.message,
                type: STATUS_KEYS.ERROR
              })
            );
        }
      }
    }
  }, [error]);

  return (
    <div className={styles.RxModal}>
      <iframe src={data?.url} />
    </div>
  );
}
