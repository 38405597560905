/* Hospital form component */
/* External Imports */
import clsx from "clsx";
import { useForm } from "react-hook-form";
/* Local Imports */

// components
import Input from "@/components/input";
import Button from "@/components/button";

// constants
import { STYLES } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";

/* Hospitalform Typescript Interface */
interface HospitalformProps {
  onSubmit: (data: any) => void;
  defaultValues: any;
}

export default function Hospitalform({
  onSubmit,
  defaultValues,
}: HospitalformProps) {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm({ defaultValues });
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <form
      className={clsx(styles.LaborForm, styles.Hospitalform)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        type="time"
        label="Start time"
        name="start_time"
        id="start_time"
        fullWidth
        register={register}
      />
      <Input
        type="text"
        label="Name of hospital"
        name="hospital_name"
        id="hospital_name"
        fullWidth
      />
      <Input
        type="text"
        label="Transport from"
        name="transport_from"
        id="transport_from"
        fullWidth
      />
      <Input
        type="text"
        label="Hospital contact"
        name="hospital_contact"
        id="hospital_contact"
        fullWidth
      />
      <Input
        type="text"
        label="Comments"
        name="comments"
        id="comments"
        fullWidth
        register={register}
      />
      <Button style={STYLES.FULL_WIDTH} type="submit">
        Save to flow sheet
      </Button>
    </form>
  );
}
