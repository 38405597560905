/* transactionDetails Name */
/* External Imports */
import clsx from "clsx";
import { useMemo } from "react";
import { sanitize } from "isomorphic-dompurify";

/* Local Imports */

// components
import Datagrid from "@/components/datagrid";
import ContentRenderer from "@/components/textArea/contentRenderer";
// constants
import { STYLES, METRICS } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import { DESC } from "@/components/datagrid/sortHead";

// store
import { PatientTransaction } from "@/store/services/patient";

// styles
import styles from "../styles.module.scss";

/* transactionDetails Typescript Interface */
interface TransactionDetailsProps {
  transaction: PatientTransaction;
}

export default function TransactionDetails({
  transaction
}: TransactionDetailsProps) {
  /* Local State */

  /* Effects */

  /* Event Handlers */
  const transactionData = useMemo(() => {
    return [...(transaction?.adjustments || []), transaction]?.map(
      adjustment => ({
        id: transaction.transaction_id,
        metrics: {
          [METRICS.CHARGE]:
            transaction.type === "SERVICE_CHARGES" ? (
              <span className="error med">
                -$
                {/* if there has been an adjustment to the amount, display the most recent one */}
                {adjustment.amount}
              </span>
            ) : (
              <span className="success med">
                +$
                {adjustment.amount}
              </span>
            ),
          [METRICS.DATE]: adjustment.created_at,
          [METRICS.DATE_OF_SERVICE]: transaction.date_of_service,
          [METRICS.AUTHOR]: FORMAT.name(transaction.created_by),
          [METRICS.TRANSACTION_NOTE]: (
            <ContentRenderer
              content={sanitize(transaction.description || "")}
              classes="t5 xLight"
            />
          )
        }
      })
    );
  }, [transaction.adjustments]);

  return (
    <div className={clsx(styles.TransactionDetails)}>
      <Datagrid
        // @ts-ignore
        data={transactionData}
        gridType={METRICS.PAYMENTS}
        style={STYLES.SECONDARY}
        defaultSortDirection={DESC}
      />
    </div>
  );
}
