import Button from "@/components/button";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";
import ContentRenderer from "@/components/textArea/contentRenderer";
import {
  useTaskCompleteMutation,
  useTaskInfoQuery
} from "@/store/services/task";
import Skeleton from "react-loading-skeleton";
import { useDispatch } from "react-redux";

import styles from "../styles.module.scss";
import { setModalIsOpen } from "../modalSlice";

interface GenericTaskModalProps {
  taskId: number;
}

export default function GenericTaskModal({ taskId }: GenericTaskModalProps) {
  const dispatch = useDispatch();

  const [completeTask] = useTaskCompleteMutation();

  const { data: task, isSuccess: isTaskSuccess } = useTaskInfoQuery({
    taskId: taskId
  });

  const onSubmit = () => {
    completeTask({
      taskId: taskId
    });
    dispatch(setRightPaneOpen(false));
    dispatch(setModalIsOpen(false));
  };
  return (
    <div className={styles.TaskCompletionModal}>
      {isTaskSuccess ? (
        <>
          <h3>{task.generic?.task_title}</h3>
          <ContentRenderer content={task.generic?.task_text || ""} />
          <div className={styles.buttonContainer}>
            <Button type="button" onClick={onSubmit}>
              Complete
            </Button>
          </div>
        </>
      ) : (
        <>
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
          <Skeleton height="40" />
        </>
      )}
    </div>
  );
}
