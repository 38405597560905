import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Button from "@/components/button";

import { STATUS_KEYS, STYLES } from "@/globals/constants";

import { setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { UserId } from "@/store/services/patient";
import { FileListItem, useFileBatchUpdateMutation } from "@/store/services/file";

import styles from "../styles.module.scss"
import { setIds } from "@/components/datagrid/datagridSlice";


export interface ReleaseFilesConfirmationProps {
  patientId: UserId
  viewingFile?: FileListItem | null;
  files?: FileListItem[];
}

export default function ReleaseFilesConfirmation({ viewingFile, files }: ReleaseFilesConfirmationProps) {
  const dispatch = useDispatch();
  const [batchUpdate, { error, isError, isSuccess}] = useFileBatchUpdateMutation();

  useEffect(() => {
    if (isError) {
      console.error("failed to release files", error);
      dispatch(addAlertToToastTrough({
        message: "Failed to release file(s)!",
        type: STATUS_KEYS.ERROR
      }));
    }
    if (isSuccess) {
      dispatch(addAlertToToastTrough({
        message: "Successfully released file(s).",
        type: STATUS_KEYS.SUCCESS
      }));
    }
  }, [isSuccess, isError]);

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  }

  const handleConfirm = () => {
    const file_ids = (files || []).map(f => f.file_id);
    if (viewingFile) {
      file_ids.push(viewingFile.file_id);
    }
    batchUpdate({
      fileBatchUpdateRequest: {
        file_ids,
        viewable_by_patient: true
      }
    });
    dispatch(setIds([]));
    dispatch(setModalIsOpen(false));
  }

  return <div className={styles.ConfirmFileMove}>
    <div className={styles.warning}>
      Are you sure you want to release the below files? This will make them viewable to patients.
    </div>

    <div className={styles.paths}>
      <ul>
        { viewingFile
          ? <li>{ viewingFile.name}</li>
          : (files || []).map(file=> <li key={file.file_id}>{file.name}</li>)}
      </ul>
    </div>

    <div className={styles.buttons}>
      <Button style={STYLES.SECONDARY_FULL} onClick={handleCancel}>Cancel</Button>
      <Button style={STYLES.FULL_WIDTH} onClick={handleConfirm}>Confirm</Button>
    </div>
  </div>
}
