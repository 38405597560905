import TextArea, { TextAreaProps } from ".";
import { Controller, FieldValues, UseFormReturn } from "react-hook-form";

interface ControlledTextAreaProps<T extends FieldValues>
  extends TextAreaProps<T> {
  form: UseFormReturn<T>;
  rules?: any;
  required?: boolean;
}

export default function ControlledTextArea<T extends FieldValues>({
  name,
  rules = {},
  label,
  hiddenLabel = false,
  id,
  rows,
  cols,
  placeholder,
  noBorder,
  useMenuBar,
  submitOnEnter,
  form,
  disabled = false,
  onFocus,
  onBlur,
  required,
  onChange
}: ControlledTextAreaProps<T>) {
  return (
    <Controller
      name={name}
      rules={{ ...rules, required }}
      control={form.control}
      render={({ field: { onChange: formOnChange, value, name } }) => (
        <TextArea
          label={label}
          hiddenLabel={hiddenLabel}
          name={name}
          id={id}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          noBorder={noBorder}
          useMenuBar={useMenuBar}
          submitOnEnter={submitOnEnter}
          onChange={c => {
            if (onChange) {
              onChange(c);
            }
            formOnChange(c);
          }}
          content={value}
          setValue={form.setValue}
          disabled={disabled}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      )}
    />
  );
}
