/* Problem */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
import { sanitize } from "isomorphic-dompurify";
import dayjs from "dayjs";

/* Local Imports */

// components
import Button from "@/components/button";
import ButtonLink from "@/components/button/link";
import BasicAccordion from "@/components/accordions/basic";
import Archive from "../../../public/svgs/archive.svg";
import Vortex from "../../../public/svgs/vortex.svg";
import Icon from "@/components/icons";
import TextArea from "@/components/textArea";
import ContentRenderer from "@/components/textArea/contentRenderer";

// constants
import { STYLES, STATUS_KEYS, PAGES, METRICS } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";
// store
import { ProblemStatus } from "@/store/services/problem";
// styles
import styles from "./styles.module.scss";
import ControlledTextArea from "../textArea/controlledTextArea";

/* Problem Typescript Interface */
interface ProblemProps {
  id: number | string; // problem id or diagnosis name
  patientId: number; // id of patient associated with problem
  status?: ProblemStatus;
  isOpen?: boolean; // UI
  title: string;
  instances: {
    timestamp: number;
    problemId: number;
    noteContent?: string;
    encounterId?: number;
    date_of_onset?: string;
  }[];
  tagLabel?: string; // ICD10 code or "Note" // UI
  mostRecentTimestamp: number;
  onUpdateStatus: (
    problemId: number | string,
    status?: ProblemStatus,
    icd10?: number
  ) => void; // UI
  onUpdateNote: (problemId: number) => void; // UI
  form: any; // UI - form from useForm hook
  isExpanded?: boolean; // UI
}

export default function Problem({
  id,
  patientId,
  isOpen,
  title,
  status,
  instances,
  tagLabel = "",
  mostRecentTimestamp,
  onUpdateStatus,
  onUpdateNote,
  form,
  isExpanded = false
}: ProblemProps) {
  /* Redux */

  /* Local State */
  const [editProblemInstance, setEditProblemInstance] = useState<number | null>(
    null
  );

  /* Effects */
  /* Event Handlers */
  const handleSaveChanges = (problemId: number) => {
    onUpdateNote(problemId);
    setEditProblemInstance(null);
  };

  const handleEditProblem = (problemId: number, noteContent: string) => {
    setEditProblemInstance(problemId);
    // Since the text area is controlled, we have to actually set the value of it
    form.setValue(`problem-${problemId}-note`, noteContent);
  };

  return (
    <div className={clsx(styles.Problem)}>
      <BasicAccordion
        key={id}
        open={isOpen}
        title={title}
        tag={{
          type: STATUS_KEYS.INFO_GREY,
          label: tagLabel
        }}
        count={instances.length > 1 ? instances.length : null}
        buttonIsLeft
        smallTitle
        customLeftContent={
          <>
            <Button
              style={STYLES.ICON}
              // archive or reactivate problem on click
              onClick={e => {
                e?.stopPropagation();
                onUpdateStatus(
                  id,
                  (status === METRICS.ARCHIVED
                    ? METRICS.ACTIVE
                    : METRICS.ARCHIVED) as ProblemStatus
                );
              }}
            >
              {status === METRICS.ARCHIVED ? (
                // TODO: support reactivating problems
                // <Vortex stroke={styles.gray600} height={16} width={16} />
                <p />
              ) : (
                <Archive stroke={styles.gray600} height={14} width={14} />
              )}
            </Button>
          </>
        }
      >
        {instances &&
          instances.map(
            ({
              timestamp,
              problemId,
              noteContent = "(no comment added)",
              encounterId,
              date_of_onset
            }) => (
              <div className={styles.note} key={problemId}>
                <div className={styles.display}>
                  <div className={styles.left}>
                    <p className="t5 med">
                      {date_of_onset
                        ? dayjs(date_of_onset).format("MM/DD/YY")
                        : "-"}
                    </p>
                    <ContentRenderer
                      content={sanitize(noteContent)}
                      classes="t5 xLight"
                    />
                  </div>
                  <div className={styles.right}>
                    {/* Edit this note */}
                    {isExpanded && (
                      <>
                        <Button
                          style={STYLES.ICON}
                          onClick={() => {
                            problemId === editProblemInstance
                              ? handleSaveChanges(problemId)
                              : handleEditProblem(problemId, noteContent);
                          }}
                        >
                          <Icon
                            height={14}
                            svg={
                              problemId === editProblemInstance
                                ? "save"
                                : "edit"
                            }
                          />
                        </Button>
                        {/* Delete this note */}
                        <Button
                          style={STYLES.ICON}
                          onClick={() =>
                            onUpdateStatus(
                              problemId,
                              METRICS.DELETED as ProblemStatus
                            )
                          }
                        >
                          <Icon svg="delete" />
                        </Button>
                      </>
                    )}
                    {/* Go to encounter if encounter note */}
                    {encounterId && (
                      <ButtonLink
                        style={STYLES.NO_OUTLINE}
                        href={`${PAGES.ENCOUNTERS}/${encounterId}`}
                      >
                        {"->"}
                      </ButtonLink>
                    )}
                  </div>
                </div>

                {isExpanded && problemId === editProblemInstance && (
                  <div className={styles.text}>
                    <ControlledTextArea
                      hiddenLabel
                      label="problem commentary"
                      id={`problem-${problemId}-note`}
                      name={`problem-${problemId}-note`}
                      rows={3}
                      content={noteContent}
                      submitOnEnter={true}
                      form={form}
                    />
                  </div>
                )}
              </div>
            )
          )}
      </BasicAccordion>
    </div>
  );
}
