import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    faxSend: build.mutation<FaxSendApiResponse, FaxSendApiArg>({
      query: queryArg => ({
        url: `/fax`,
        method: "POST",
        body: queryArg.faxSendRequest
      })
    }),
    faxInboundList: build.query<
      FaxInboundListApiResponse,
      FaxInboundListApiArg
    >({
      query: queryArg => ({
        url: `/inbound-fax/${queryArg.scope}/${queryArg.id}/list`,
        params: {
          provider_unassigned: queryArg.providerUnassigned,
          patient_unassigned: queryArg.patientUnassigned,
          archived: queryArg.archived
        }
      })
    }),
    faxInboundUpdate: build.mutation<
      FaxInboundUpdateApiResponse,
      FaxInboundUpdateApiArg
    >({
      query: queryArg => ({
        url: `/inbound-fax/batch-update`,
        method: "POST",
        body: queryArg.faxInboundUpdateRequest
      })
    }),
    faxCopyToPatient: build.mutation<
      FaxCopyToPatientApiResponse,
      FaxCopyToPatientApiArg
    >({
      query: queryArg => ({
        url: `/inbound-fax/copy-to-patient/${queryArg.patientId}`,
        method: "POST",
        body: queryArg.faxCopyToPatientRequest
      })
    }),
    forwardInboundFax: build.mutation<
      ForwardInboundFaxApiResponse,
      ForwardInboundFaxApiArg
    >({
      query: queryArg => ({
        url: `/inbound-fax/${queryArg.faxId}/forward`,
        method: "POST",
        body: queryArg.faxForwardRequest
      })
    }),
    faxOutboundList: build.query<
      FaxOutboundListApiResponse,
      FaxOutboundListApiArg
    >({
      query: () => ({ url: `/outbound-fax/list` })
    }),
    faxList: build.query<FaxListApiResponse, FaxListApiArg>({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/fax/list`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as fax };
export type FaxSendApiResponse = /** status 200 ID of the sent fax.
 */ FaxId[];
export type FaxSendApiArg = {
  /** Payload for sending a fax for a particular patient.
   */
  faxSendRequest: FaxSendRequest;
};
export type FaxInboundListApiResponse =
  /** status 200 List of faxes that were sent to a practice or assigned to a user.
   */ FaxInboundListItem[];
export type FaxInboundListApiArg = {
  /** The scope of files to list. Must be one of: practice, location, provider, or user.
   */
  scope: FaxInboundScope;
  id: Id;
  /** If querying from practice scope, returns faxes that are not assigned to a provider.
   */
  providerUnassigned?: boolean;
  /** If querying from provider scope, returns faxes that are not assigned to a patient.
   */
  patientUnassigned?: boolean;
  /** If set to true, only returns archived faxes.
    Else, only returns unarchived faxes.
     */
  archived?: boolean;
};
export type FaxInboundUpdateApiResponse =
  /** status 200 ID of the updated inbound fax.
   */ FaxId[];
export type FaxInboundUpdateApiArg = {
  /** Payload for updating an inbound fax.
   */
  faxInboundUpdateRequest: FaxInboundUpdateRequest;
};
export type FaxCopyToPatientApiResponse = /** status 200 ID of the new file
 */ FileId[];
export type FaxCopyToPatientApiArg = {
  patientId: UserId;
  /** Payload for updating an inbound fax.
   */
  faxCopyToPatientRequest: FaxCopyToPatientRequest;
};
export type ForwardInboundFaxApiResponse =
  /** status 200 ID of the forwarded fax.
   */ FaxId[];
export type ForwardInboundFaxApiArg = {
  /** The ID of the fax to retrieve.
   */
  faxId: FaxId;
  /** Payload for forwarding a fax.
   */
  faxForwardRequest: FaxForwardRequest;
};
export type FaxOutboundListApiResponse =
  /** status 200 List of faxes that were sent from a practice.
   */ FaxOutboundListItem[];
export type FaxOutboundListApiArg = void;
export type FaxListApiResponse =
  /** status 200 List of faxes that were sent for a patient.
   */ FaxInfo[];
export type FaxListApiArg = {
  patientId: UserId;
  page?: Page;
  pagesz?: PageSize;
};
export type FaxId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type Phone = string;
export type FileId = number;
export type FaxSendRequest = {
  recipients: Phone[];
  files: FileId[];
};
export type FaxInboundListItem = {
  /** System-assigned unique ID of a fax.
   */
  fax_id: number;
  fax_name?: string;
  fax_size_bytes?: number;
  fax_url: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  sender: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  received_at?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed_at?: string;
  /** A human's common name - almost always just the first and last name concatenated with a space.
   */
  reviewed_by?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  archived_at?: string;
  /** A human's common name - almost always just the first and last name concatenated with a space.
   */
  patient_name?: string;
};
export type FaxInboundScope = "practice" | "provider";
export type Id = number;
export type FaxInboundUpdateRequest = {
  fax_ids: FaxId[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number | null;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed_at?: string | null;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  archived_at?: string | null;
};
export type UserId = number;
export type FaxCopyToPatientRequest = {
  fax_ids: FaxId[];
};
export type FaxForwardRequest = {
  faxes: FaxId[];
  recipients: Phone[];
};
export type FaxOutboundListItem = {
  /** System-assigned unique ID of a fax.
   */
  fax_id: number;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  recipient: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  sender_name: string;
  status: "SENDING" | "DELIVERED" | "CANCELED" | "FAILED";
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  sent: string;
  fax_url: string;
};
export type FaxInfo = {
  /** System-assigned unique ID of a fax.
   */
  fax_id: number;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  recipient: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  sender: number;
  status?: "SENDING" | "DELIVERED" | "CANCELED" | "FAILED";
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  sent: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  delivered?: number;
  files: FileId[];
};
export type Page = number;
export type PageSize = number;
export const {
  useFaxSendMutation,
  useFaxInboundListQuery,
  useLazyFaxInboundListQuery,
  useFaxInboundUpdateMutation,
  useFaxCopyToPatientMutation,
  useForwardInboundFaxMutation,
  useFaxOutboundListQuery,
  useLazyFaxOutboundListQuery,
  useFaxListQuery,
  useLazyFaxListQuery
} = injectedRtkApi;
