/* EncounterTimeEdit Name */
/* External Imports */
import clsx from "clsx";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";

/* Local Imports */

// components
import Input from "@/components/input";
import Button from "@/components/button";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  EncounterCreateUpdatePayload,
  EncounterInfo,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { EncounterType } from "@/store/services/location";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "../modalSlice";
// utils
import {
  convertLocalDatetimeToUtcInt,
  convertUtcIntToLocalDatetime
} from "@/components/scheduling/calendars/utils";

// styles
import styles from "../styles.module.scss";
import ControlledCombobox from "@/components/input/controlledCombobox";
import { usePracticeGetProvidersQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";
import Skeleton from "react-loading-skeleton";
import { FORMAT } from "@/globals/helpers/formatters";

/* EncounterDetails Typescript Interface */
interface EditEncounterDetailsProps {
  encounter: EncounterInfo;
}

export default function EditEncounterDetails({
  encounter
}: EditEncounterDetailsProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { handleSubmit, register, setValue, control, getValues } = useForm({
    defaultValues: {
      start_time: (encounter.start || encounter.appointment?.starts || encounter.created) ? convertUtcIntToLocalDatetime(encounter.start || encounter.appointment?.starts || encounter.created).format("HH:mm") : "",
      encounter_date: (encounter.start || encounter.appointment?.starts || encounter.created) ? convertUtcIntToLocalDatetime(encounter.start || encounter.appointment?.starts || encounter.created).format("YYYY-MM-DD") : "",
      end_time: (encounter.end || encounter.appointment?.ends) ? convertUtcIntToLocalDatetime(encounter.end || encounter.appointment?.ends as number).format("HH:mm") : "",
      provider: encounter.provider
    }
  });
  /* Redux */
  const [updateEncounter] = useEncounterUpdateMutation();

  const { data: providers } = usePracticeGetProvidersQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  const dispatch = useDispatch();

  /* Event Handlers */
  const onSubmit = (data: any) => {
    const { start_time, end_time, encounter_date } = data;
    const startTime = dayjs(`${encounter_date} ${start_time}`);
    const startTimestamp = convertLocalDatetimeToUtcInt(startTime);
    const encounterUpdatePayload: EncounterCreateUpdatePayload = {
      encounter_type: encounter.encounter_type as EncounterType,
      patient_id: encounter.patient.user_id,
      provider_id: data.provider ? data.provider.user_id : encounter.provider.user_id,
      start: startTimestamp
    };
    if (end_time) {
      const endTime = dayjs(`${encounter_date} ${end_time}`);
      const endTimestamp = convertLocalDatetimeToUtcInt(endTime);
      encounterUpdatePayload.end = endTimestamp;
    }
    updateEncounter({
      encounterId: encounter.encounter_id,
      encounterCreateUpdatePayload: encounterUpdatePayload
    })
      .unwrap()
      .then(r => {
        dispatch(
          addAlertToToastTrough({
            message: "Encounter details successfully updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() =>
        dispatch(
          addAlertToToastTrough({
            message: "Failed to update encounter details",
            type: STATUS_KEYS.ERROR
          })
        )
      );
  };
  return (
    <form
      className={clsx(styles.EncounterEdit)}
      onSubmit={handleSubmit(onSubmit)}
    >
      {providers ? (
        <ControlledCombobox
          options={providers}
          label="Encounter provider"
          name="provider"
          control={control}
          labelAcc={item => FORMAT.name(item)}
          isHorizontalLayout={false}
          fullWidth
          //@ts-ignore
          valueKey={encounter.provider}
        />
      ) : (
        <Skeleton height={24} />
      )}
      <Input
        type="date"
        label="Encounter Date"
        name="encounter_date"
        id="encounter_date"
        fullWidth
        register={register}
      />
      <div className={styles.timeContainer}>
        <Input
          type="time"
          label="Start time"
          name="start_time"
          id="start_time"
          register={register}
        />
        <Input
          type="time"
          label="End time"
          name="end_time"
          id="end_time"
          register={register}
        />
      </div>
      <Button style={STYLES.FULL_WIDTH} type="submit">
        Save
      </Button>
    </form>
  );
}
