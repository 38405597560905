/* Day Name */
/* External Imports */
import clsx from "clsx";
import { MouseEvent, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { motion } from "framer-motion";
/* Local Imports */

// components
import FixedMenu from "@/components/menus/fixedMenu";
import CalendarEventContent from "../appointments/calendarEventContent";
import DayContent from "../appointments/dayContent";

// constants
import { MODAL_TYPES } from "@/components/modal/dispatcher";

// store
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { useLocationGetListQuery } from "@/store/services/location";
import { RootState } from "@/store/store";
import {
  EventInfo,
  EventType,
  useEventListQuery
} from "@/store/services/event";
import { AppointmentInfo } from "@/store/services/scheduling";
import { setSelectedProviderId, setSelectedSlot } from "../calendarSlice";
import { useUserGetInfoQuery } from "@/store/services/user";
// utils
import {
  convertLocalDatetimeToUtcInt,
  convertUtcIntToLocalDatetime,
  userIdToHexCode
} from "../utils";
import { useColorByProvider } from "@/globals/helpers/customHooks";

// styles
import styles from "../styles.module.scss";
import ScheduleEvent from "../appointments/scheduleEvent";
import {
  setRightPaneContent,
  setRightPaneOpen
} from "@/components/drawer/drawerSlice";
import { useAccessibleCalStyles } from "@/globals/helpers/customHooks";

/* Day Typescript Interface */
interface DayProps {
  disabled?: boolean;
  appointments: AppointmentInfo[];
  slots: number[];
  providerId?: number;
}

export default function Day({
  disabled = false,
  appointments,
  slots,
  providerId
}: DayProps) {
  /* Redux */
  const dispatch = useDispatch();
  // get user created filters
  const {
    locationFilter,
    providerFilter,
    selectedSlot,
    detailDays,
    selectedProviderId
  } = useSelector((state: RootState) => state.calendar);

  // get user session  info
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  // get practices available to this user
  const { data: locations } = useLocationGetListQuery(
    { practiceId: sessionInfo?.practice_id as number },
    {
      skip: !sessionInfo || !sessionInfo.practice_id
    }
  );

  const colorByProvider = useColorByProvider();
  const accessibleStyles = useAccessibleCalStyles();

  const { data: allLocationEvents } = useEventListQuery({
    rangeStart: convertUtcIntToLocalDatetime(slots[0]).toISOString(),
    rangeEnd: convertUtcIntToLocalDatetime(
      slots[slots.length - 1]
    ).toISOString(),
    participantId: providerFilter.length === 1 ? providerFilter[0] : undefined
  });

  const { data: providerInfo } = useUserGetInfoQuery(
    { userId: providerId as number },
    { skip: !providerId }
  );

  /* Local State */

  const [otherEvents, setOtherEvents] = useState<any[]>([]);
  const [scheduleEvents, setScheduleEvents] = useState<any[]>([]);
  /* Effects */
  useEffect(() => {
    if (allLocationEvents) {
      const locationEvents = allLocationEvents.filter(
        event =>
          locationFilter.includes(event.location_id as number) && !event.all_day
      );
      // filter them by the provider
      let providerSpecificEvents = locationEvents;
      if (providerId) {
        providerSpecificEvents = locationEvents.filter(event =>
          event.participants.some(p => p.user_id === providerId)
        );
      }
      const scheduleEvents = providerSpecificEvents.filter(event => {
        if (event.event_type !== ("SCHEDULE" as EventType)) {
          return false;
        }
        const eventParticipants = event.participants.map(p => p.user_id);
        // Check if there's at least one participant in the providerFilter
        const hasMatchingParticipant = eventParticipants.some(userId =>
          providerFilter.includes(userId)
        );

        return hasMatchingParticipant;
      });

      setScheduleEvents(scheduleEvents);

      const otherEvents = providerSpecificEvents?.filter(
        event =>
          // include if not location id, because it's a global event
          // include if location id and in filter
          event.event_type !== ("SCHEDULE" as EventType) &&
          (!event.location_id || locationFilter.includes(event.location_id))
      );

      setOtherEvents(otherEvents);
    } else {
      setOtherEvents([]);
    }
  }, [allLocationEvents, providerFilter, locationFilter, providerId]);

  /* Event Handlers */

  // When calendar timeslot is clicked, launch create / edit appointment modal
  const handleSlotClick = (slot: number, item: string) => {
    const menuConfiguration = {
      appointment: MODAL_TYPES.APPOINTMENT,
      other_event: MODAL_TYPES.STAFF_SCHEDULE
    };

    let props = {};
    if (item === "appointment") {
      props = {
        appointment: {
          provider: providerInfo,
          starts: +slot,
          ends: convertLocalDatetimeToUtcInt(
            convertUtcIntToLocalDatetime(slot).add(30, "minute")
          )
        },
        // since its an appointment creation, open in edit mode
        isDefaultEditMode: true,
        title: "Schedule Appointment"
      };
      dispatch(setRightPaneOpen(true));

      dispatch(
        setRightPaneContent({
          type: menuConfiguration[item as keyof typeof menuConfiguration],
          props
        })
      );
    }

    if (item === "other_event") {
      props = {
        title: `Schedule event`,
        defaultValues: {
          select_participants: providerInfo ? [providerInfo] : undefined,
          shift: {
            starts: slot,
            ends: convertLocalDatetimeToUtcInt(
              convertUtcIntToLocalDatetime(slot).add(30, "minute")
            )
          },
          event_date: convertUtcIntToLocalDatetime(slot).format("YYYY-MM-DD"),
          select_location: locations?.find(
            location => location.location_id === locationFilter[0]
          )
        }
      };
      dispatch(setModalIsOpen(true));

      dispatch(
        setModalContent({
          type: menuConfiguration[item as keyof typeof menuConfiguration],
          props
        })
      );
    }
  };

  // When calendar timeslot is clicked, launch create / edit appointment modal
  const handleApptClick = (
    appointment: AppointmentInfo,
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    dispatch(
      setRightPaneContent({
        type: MODAL_TYPES.APPOINTMENT,
        props: {
          appointment,
          title: "Schedule Appointment"
        }
      })
    );
    dispatch(setRightPaneOpen(true));
  };

  const handleEventClick = (
    event: EventInfo,
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    dispatch(
      setModalContent({
        type: MODAL_TYPES.STAFF_SCHEDULE,
        props: {
          eventId: event?.event_id,
          title: `Schedule event`,
          defaultValues: {
            select_participants: event.participants,
            event_date: dayjs(event?.start_at).format("YYYY-MM-DD"),
            event_title: event.title,
            select_event_type: event.event_type,
            shift: {
              starts: convertLocalDatetimeToUtcInt(dayjs(event.start_at)),
              ends: convertLocalDatetimeToUtcInt(dayjs(event.end_at))
            },
            select_location: locations?.find(
              location => location.location_id === locationFilter[0]
            )
          }
        }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  const getOverlappingEvents = (
    slot: number,
    eventStart: string,
    index: number
  ) => {
    return otherEvents
      .filter(
        ({ start_at: start }) =>
          // NOTE: need to determine if we want to support them being able to schedule appts at 10:13
          // this will not work if we allow booking outside of slots
          start &&
          slot === convertLocalDatetimeToUtcInt(dayjs(start)) &&
          slots[index + 1] >=
            (convertLocalDatetimeToUtcInt(dayjs(start as string)) as number)
      )
      .filter(({ end_at }) => eventStart < end_at);
  };

  return (
    <div
      className={clsx(styles.Day, {
        [styles.disabled]: disabled,
        [styles.accessible]: accessibleStyles
      })}
      data-cy="day-view"
    >
      {/* map over operating hours in 30 min increments */}
      {appointments &&
        slots.map((slot: number, index) => (
          <div
            data-cy={`calendar-slot-${slot}-${providerId}`}
            key={slot + index}
            className={clsx(styles.slot)}
            onClick={() => {
              dispatch(
                setSelectedSlot(selectedSlot === `${slot}` ? null : `${slot}`)
              );
              if (providerId) {
                dispatch(setSelectedProviderId(providerId));
              }
            }}
            role="button"
          >
            {/* Filter for schedule events that start within this 30 minute slot */}

            {scheduleEvents
              ?.filter(
                ({ start_at: start }) =>
                  // NOTE: need to determine if we want to support them being able to schedule appts at 10:13
                  // this will not work if we allow booking outside of slots
                  start &&
                  slot === convertLocalDatetimeToUtcInt(dayjs(start)) &&
                  slots[index + 1] >=
                    (convertLocalDatetimeToUtcInt(
                      dayjs(start as string)
                    ) as number)
              )
              // Render any events returned from filter
              .map((currentEvent, idx) => {
                return (
                  <ScheduleEvent
                    tagLabel={
                      locations?.find(
                        ({ location_id }) =>
                          location_id === currentEvent.location_id
                      )?.name || ""
                    }
                    key={currentEvent.event_id}
                    backgroundColor={
                      colorByProvider
                        ? userIdToHexCode(currentEvent.participants[0].user_id)
                        : ""
                    }
                    offset={idx}
                    start={currentEvent.start_at}
                    end={currentEvent.end_at}
                  />
                );
              })}
            {/* Filter for events that start within this 30 minute slot */}
            {otherEvents
              ?.filter(
                ({ start_at: start }) =>
                  // NOTE: need to determine if we want to support them being able to schedule appts at 10:13
                  // this will not work if we allow booking outside of slots
                  start &&
                  slot === convertLocalDatetimeToUtcInt(dayjs(start)) &&
                  slots[index + 1] >=
                    (convertLocalDatetimeToUtcInt(
                      dayjs(start as string)
                    ) as number)
              )
              // Render any events returned from filter
              .map(currentEvent => {
                // figure out if there are any overlapping events
                const overlappingEvents = getOverlappingEvents(
                  slot,
                  currentEvent.start_at,
                  index
                );

                // get order of current appointment in the list of overlapping appointments
                const currentIdx = overlappingEvents.findIndex(
                  ({ event_id }) => currentEvent.event_id === event_id
                );

                // get the schedule events that take place in this slot, so any time between the start and end of the schedule
                // event that starts in this slot
                const scheduleEventsOffset = scheduleEvents.filter(
                  ({ start_at, end_at }) =>
                    convertLocalDatetimeToUtcInt(dayjs(start_at as string)) &&
                    (convertLocalDatetimeToUtcInt(
                      dayjs(start_at as string)
                    ) as number) <= slot &&
                    convertLocalDatetimeToUtcInt(dayjs(end_at as string)) &&
                    slot <
                      (convertLocalDatetimeToUtcInt(
                        dayjs(end_at as string)
                      ) as number)
                ).length;

                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    key={currentEvent.event_id}
                  >
                    <CalendarEventContent
                      event={currentEvent}
                      onClick={e => handleEventClick(currentEvent, e)}
                      offsetUnit={currentIdx} // offset the appointment according to its order in overlapping appts for this slot
                      scheduleEventsOffset={scheduleEventsOffset}
                    />
                  </motion.div>
                );
              })}
            {/* Filter for appointments that start within this 30 minute slot */}
            {appointments
              ?.filter(
                ({ starts: start }) =>
                  // NOTE: need to determine if we want to support them being able to schedule appts at 10:13
                  // this will not work if we allow booking outside of slots
                  // TODO: redo positioning logic to position based on absolute time & pixel position
                  start && slot === start && slots[index + 1] >= start
              )
              // Render any appointments returned from filter
              .map(currentAppointment => {
                // figure out if there are any overlapping appointments
                const overlappingAppts = appointments.filter(
                  ({ ends }) => currentAppointment.starts < ends
                );

                // get order of current appointment in the list of overlapping appointments
                const currentApptIdx = overlappingAppts.findIndex(
                  ({ appointment_id }) =>
                    currentAppointment.appointment_id === appointment_id
                );

                const overlappingEvents = getOverlappingEvents(
                  slot,
                  convertUtcIntToLocalDatetime(
                    currentAppointment.starts
                  ).toISOString(),
                  index
                );

                // get the schedule events that take place in this slot, so any time between the start and end of the schedule
                // event that starts in this slot
                const scheduleEventsOffset = scheduleEvents.filter(
                  ({ start_at, end_at }) =>
                    convertLocalDatetimeToUtcInt(dayjs(start_at as string)) &&
                    (convertLocalDatetimeToUtcInt(
                      dayjs(start_at as string)
                    ) as number) <= slot &&
                    convertLocalDatetimeToUtcInt(dayjs(end_at as string)) &&
                    slot <
                      (convertLocalDatetimeToUtcInt(
                        dayjs(end_at as string)
                      ) as number)
                ).length;

                const currentIdx = currentApptIdx + overlappingEvents.length;

                return (
                  <DayContent
                    appointment={currentAppointment}
                    onClick={e => handleApptClick(currentAppointment, e)}
                    key={currentAppointment.appointment_id}
                    offsetUnit={currentIdx} // offset the appointment according to its order in overlapping appts for this slot
                    scheduleEventsOffset={scheduleEventsOffset}
                  />
                );
              })}
            {/* if this is not a provider specific day slot (as in the multi provider view), only the slot needs to match */}
            {/* if there is a selectedProvider, it must be the same as the provider of the slot */}
            {selectedSlot &&
              selectedSlot === `${slot}` &&
              (!providerId ||
                (selectedProviderId && selectedProviderId === providerId)) && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <FixedMenu
                    menuItems={["appointment", "other_event"]}
                    menuItemsLabels={{
                      appointment: "appointment",
                      other_event: "event"
                    }}
                    onClick={item => {
                      dispatch(setSelectedSlot(null));
                      handleSlotClick(slot, item);
                    }}
                    noIcons
                  />
                </motion.div>
              )}
          </div>
        ))}
    </div>
  );
}
