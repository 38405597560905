/* Button Component */

// External
import clsx from "clsx";
import { useRouter } from "next/router";
import { ReactElement, useState, forwardRef } from "react";
import Link from "next/link";
import { Inter } from "next/font/google";

// styles
import styles from "./styles.module.scss";
import NotificationIcon from "../notificationIcon";

const font = Inter({
  weight: ["400", "500", "700"],
  subsets: ["latin"],
});

export interface ButtonLinkProps {
  href: string;
  style: string;
  children: string | ReactElement | ReactElement[];
  isNav?: boolean; // if its a navigation link
  isActive?: boolean;
  isNotification?: boolean;
  notificationNumber?: number | null;
  className?: string;
}

const Button = forwardRef(
  (
    {
      href,
      style,
      children,
      isNav,
      isNotification,
      notificationNumber,
    }: ButtonLinkProps,
    ref: any
  ) => {
    const router = useRouter();

    const [selected, toggleSelected] = useState<boolean>(false);

    const handleClick = () => {
      toggleSelected(!selected);
    };

    return (
      <Link
        href={href}
        tabIndex={0}
        onClick={handleClick}
        ref={ref}
        className={clsx(styles.Button, styles[style], font.className, {
          [styles.selected]: isNav ? href === router.pathname : selected, // for sidebar nav, should be highlighted when on page
        })}
      >
        {isNotification && (
          <NotificationIcon notificationNumber={notificationNumber} />
        )}
        {children}
      </Link>
    );
  }
);

Button.displayName = "Button";

export default function ButtonLink({
  href = "#",
  style,
  children,
  isActive,
  isNotification = false,
  notificationNumber,
  className
}: ButtonLinkProps) {
  return (
    <Link
      href={{
        pathname: href,
      }}
      className={clsx(styles.Button, styles[style], font.className, className, {
        [styles.selected]: isActive,
      })}
    >
      {isNotification && (
        <NotificationIcon notificationNumber={notificationNumber} />
      )}
      {children}
    </Link>
  );
}
