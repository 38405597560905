import clsx from "clsx";
import { Inter } from "next/font/google";
import { InputHTMLAttributes } from "react";
import { Control, Controller, FieldValue, FieldValues } from "react-hook-form";

import OptionsMenu from "@/components/optionsMenu";

import styles from "@/components/input/styles.module.scss";

const font = Inter({
  subsets: ["latin"],
});

interface ControlledOptionsFieldProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: FieldValue<FieldValues>;
  control: Control;
  options: any[];
  hiddenLabel?: boolean;
  isHorizontal?: boolean;
  disabled?: boolean;
  fitWidth?: boolean;
  centered?: boolean;
}

export default function ControlledOptionsField({
  label,
  name,
  control,
  options,
  hiddenLabel,
  isHorizontal,
  fitWidth = false,
  disabled = false,
  centered = false,
}: ControlledOptionsFieldProps) {
  return (
    <div
      className={clsx(styles.Input, styles.Generated, styles.OptionsField, {
        [styles.horizontal]: isHorizontal,
        [styles.fitWidth]: fitWidth,
        [styles.centered]: centered
      })}
      data-cy="input-options-field"
      data-cy-name={name}
    >
      <label htmlFor={name} className={clsx({ [styles.hidden]: hiddenLabel })}>
        {label}
      </label>
      <div className={clsx({
        [styles.centered]: centered
      })}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <OptionsMenu
              options={options}
              selectedOption={field.value}
              onClick={field.onChange}
              disabled={disabled}
            />
          )}
        />
      </div>
    </div>
  );
}
