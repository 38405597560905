import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    eventCreate: build.mutation<EventCreateApiResponse, EventCreateApiArg>({
      query: queryArg => ({
        url: `/event/create`,
        method: "POST",
        body: queryArg.eventCreateBody
      })
    }),
    eventUpdate: build.mutation<EventUpdateApiResponse, EventUpdateApiArg>({
      query: queryArg => ({
        url: `/event/${queryArg.eventId}/update`,
        method: "PUT",
        body: queryArg.eventUpdateBody
      })
    }),
    eventInfo: build.query<EventInfoApiResponse, EventInfoApiArg>({
      query: queryArg => ({ url: `/event/${queryArg.eventId}/info` })
    }),
    eventDelete: build.mutation<EventDeleteApiResponse, EventDeleteApiArg>({
      query: queryArg => ({
        url: `/event/${queryArg.eventId}/delete`,
        method: "DELETE"
      })
    }),
    eventList: build.query<EventListApiResponse, EventListApiArg>({
      query: queryArg => ({
        url: `/event/list`,
        params: {
          range_start: queryArg.rangeStart,
          range_end: queryArg.rangeEnd,
          location_id: queryArg.locationId,
          participant_id: queryArg.participantId,
          event_type: queryArg.eventType,
          all_day: queryArg.allDay
        }
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as event };
export type EventCreateApiResponse =
  /** status 200 Response for creating / updating an event.
   */ EventId;
export type EventCreateApiArg = {
  /** Payload for creating an event.
   */
  eventCreateBody: EventCreateBody;
};
export type EventUpdateApiResponse =
  /** status 200 Response for creating / updating an event.
   */ EventId;
export type EventUpdateApiArg = {
  eventId: EventId;
  /** Payload for creating an event.
   */
  eventUpdateBody: EventUpdateBody;
};
export type EventInfoApiResponse = /** status 200 Response for getting an event.
 */ EventInfo;
export type EventInfoApiArg = {
  eventId: EventId;
};
export type EventDeleteApiResponse =
  /** status 200 Response for deleting an event.
   */ EventId;
export type EventDeleteApiArg = {
  eventId: EventId;
};
export type EventListApiResponse =
  /** status 200 Response for deleting an event.
   */ EventInfo[];
export type EventListApiArg = {
  rangeStart?: string;
  rangeEnd?: string;
  locationId?: LocationId;
  participantId?: UserId;
  eventType?: EventType;
  allDay?: boolean;
};
export type EventId = string;
export type UserId = number;
export type EventType =
  | "out_of_office"
  | "internal"
  | "external"
  | "schedule"
  | "on_call_first"
  | "on_call_second"
  | "in_clinic"
  | "on_call_in_clinic_first"
  | "on_call_in_clinic_second";
export type EventCreateBody = {
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** The title of the event.
   */
  title: string;
  /** The description of the event.
   */
  description?: string;
  /** The start time of the event.
   */
  start_at: string;
  /** The end time of the event.
   */
  end_at: string;
  /** Whether the event is an all-day event.
   */
  all_day?: boolean;
  /** The participants of the event.
   */
  participants: UserId[];
  event_type: EventType;
};
export type EventUpdateBody = {
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** The title of the event.
   */
  title?: string;
  /** The description of the event.
   */
  description?: string;
  /** The start time of the event.
   */
  start_at?: string;
  /** The end time of the event.
   */
  end_at?: string;
  /** Whether the event is an all-day event.
   */
  all_day?: boolean;
  /** The participants of the event.
   */
  participants?: UserId[];
  event_type?: EventType;
};
export type EventParticipant = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The first name of the participant.
   */
  first_name: string;
  /** The last name of the participant.
   */
  last_name: string;
};
export type EventInfo = {
  /** System-assigned unique ID of an event. Used as a table linkage.
   */
  event_id: string;
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** The title of the event.
   */
  title: string;
  /** The description of the event.
   */
  description?: string;
  /** The start time of the event.
   */
  start_at: string;
  /** The end time of the event.
   */
  end_at: string;
  /** Whether the event is an all-day event.
   */
  all_day?: boolean;
  /** The participants of the event.
   */
  participants: EventParticipant[];
  created_by: EventParticipant;
  event_type: EventType;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
};
export type LocationId = number;
export const {
  useEventCreateMutation,
  useEventUpdateMutation,
  useEventInfoQuery,
  useLazyEventInfoQuery,
  useEventDeleteMutation,
  useEventListQuery,
  useLazyEventListQuery
} = injectedRtkApi;
