/* TestForm Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";

/* Local Imports */

// components
import GeneratedInput from "@/components/input/generated";
import GeneratedBoolField from "./bool";
import ControlledCombobox from "@/components/input/controlledCombobox";
import ControlledMultiselect from "@/components/input/controlledMultiselect";
import TextArea from "@/components/textArea";
import Button from "@/components/button";
import BasicAccordion from "@/components/accordions/basic";
import InputTable from "./table";

// constants
import { FIELD_TYPES } from "@/globals/constants/formSchema";
import { STYLES } from "@/globals/constants";

// store
import { FormFieldType, FormSection } from "@/store/services/form";
import { FormSubmission } from "@/store/services/patient";
import { FormFieldOptions } from "@/store/services/form";

// styles
import styles from "./styles.module.scss";

// utils
import { isValidDate } from "@/utils/time";
import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";
import PastPregnancies from "@/components/pastPregnancies";

/* TestForm Typescript Interface */
export interface DynamicFormProps {
  section: FormSection;
  submission?: FormSubmission;
  onCancel?: () => void;
  onSubmit?: (data: any) => void; // TODO: generate data type from schema
}

export default function DynamicFormSection({
  onCancel,
  onSubmit = () => {},
  section,
  submission
}: DynamicFormProps) {
  // Generate validation schema from Zod object generated by items
  // TODO: probably will need to generate schema separate from component type mapping
  // perhaps there is tooling for Zod to map from some schema
  //const schema: ZodType = z.object(buildZodObject(allFields));
  /* Incorporate useForm() from https://react-hook-form.com/
    to register our items for validation,
    handle validation on submit, track form state & errors */

  // construct defaults for each field from provided form submission
  const defaultValues: Record<string, any> = {};
  for (const [k, v] of Object.entries(submission?.data || {})) {
    defaultValues[k] = v;
  }
  const fields = Object.values(section.fields);
  const fieldTypes: Record<string, FormFieldType> = {};
  for (const field of fields) {
    fieldTypes[field.name as string] = field.type;

    // We only want to convert dates if they've been entered
    if (
      defaultValues[field.name as string] !== undefined &&
      (field.type == "DATE" || isValidDate(defaultValues[field.name as string]))
    ) {
      defaultValues[field.name as string] = convertUtcIntToLocalDate(
        defaultValues[field.name as string] as number
      ).format("YYYY-MM-DD");
    }
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({ defaultValues });

  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const _onSubmit = (data: any) => {
    for (const [k, v] of Object.entries(data)) {
      if (v && (fieldTypes[k] === "DATE" || isValidDate(v as string))) {
        data[k] = parseInt(dayjs(v as string).format("YYYYMMDD"));
      }
    }

    onSubmit(data);
  };

  return (
    <div className={clsx(styles.GeneratedFormSection)}>
      <form onSubmit={handleSubmit(_onSubmit)}>
        <fieldset>
          <legend>{section.label}</legend>
          {section.fields.map(field => {
            switch (field.type) {
              case FIELD_TYPES.TEXTAREA:
                return (
                  <TextArea
                    key={field.name}
                    label={field.label}
                    name={field.name as string}
                    id={field.name as string}
                    setValue={setValue}
                    content={defaultValues[field.name as string]}
                    placeholder={field.placeholder}
                  />
                );
              case FIELD_TYPES.BOOL:
                return (
                  <GeneratedBoolField
                    key={field.name}
                    label={field.label as string}
                    name={field.name as string}
                    control={control}
                  />
                );
              case FIELD_TYPES.SINGLE_DROPDOWN:
                return (
                  <ControlledCombobox
                    key={field.name}
                    label={field.label as string}
                    errors={errors}
                    name={field.name as string}
                    control={control}
                    options={field.options as FormFieldOptions}
                  />
                );
              case FIELD_TYPES.MULTI_DROPDOWN:
                return (
                  <ControlledMultiselect
                    key={field.name}
                    label={field.label as string}
                    errors={errors}
                    name={field.name as string}
                    control={control}
                    options={field.options as FormFieldOptions}
                  />
                );
              case FIELD_TYPES.TABLE:
                // if the field id is ob-history-row
                // then we want to skip rendering it here since the OB history table
                // is rendered separately
                if (field.name !== "ob-history-row") {
                  return (
                    <InputTable
                      key={field.name}
                      control={control}
                      defaultValues={defaultValues}
                      fieldName={field.name || ""}
                      subfields={field.subfields || []}
                      errors={errors}
                    />
                  );
                }
              default:
                return (
                  <GeneratedInput
                    key={field.name}
                    type={field.type}
                    label={field.label as string}
                    name={field.name as string}
                    register={register}
                    errors={errors}
                    defaultValue={defaultValues[field.name as string]}
                  />
                );
            }
          })}
        </fieldset>
        <div className={styles.footer}>
          <Button style={STYLES.SECONDARY} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
}
