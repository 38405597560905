/* Confirmation Modal Template */

// External
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
// styles
import styles from "../styles.module.scss";

// components
import Button from "@/components/button";
import TabMenu from "@/components/tabbed";
import Icon from "@/components/icons";
import Search from "@/components/input/search";
import ProblemList from "@/components/problemList";
// constants
import { STYLES, STATUS_KEYS } from "@/globals/constants";

// store
import { setModalIsOpen } from "../modalSlice";
import {
  PatientProblems,
  ProblemInfo,
  usePatientGetInfoQuery,
} from "@/store/services/patient";
import ImmunizationList from "@/components/immunizationsList";

export interface ProblemListModalProps {
  problems: PatientProblems;
  patientId: number;
}

export default function ProblemListModal({
  problems,
  patientId,
}: ProblemListModalProps) {
  const dispatch = useDispatch();

  const { data: patient } = usePatientGetInfoQuery({ patientId });

  // Event handlers

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const handleConfirm = () => {
    // dispatch confirmation event, e.g. deleting users
    // TODO: UPDATE PROBLEM LIST HERE
  };

  return (
    <div className={clsx(styles.ProblemList)}>
      <div className={styles.header}>
        <h4 className="t3">Immunization List</h4>
        <Button style={STYLES.ICON} onClick={handleCancel}>
          <Icon svg="close" width={12} />
        </Button>
      </div>
      <div className={styles.content}>
        {patient && (
          <ImmunizationList
            patientId={patientId}
            // @ts-ignore
            // TODO: once reference is updated, access immunizations via patient.metadata.immunizations
            immunizations={patient.metadata.immunizations}
            isExpanded
          />
        )}
      </div>
    </div>
  );
}
