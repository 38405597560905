import styles from "./styles.module.scss";

interface KeyValueItemProps {
  keyLabel: string;
  children?: JSX.Element | JSX.Element[];
}

export default function KeyValueItem({ keyLabel, children }: KeyValueItemProps) {
  return (
    <div className={styles.KeyValueItem}>
      <p className={styles.gridKey}>{keyLabel}</p>
      <div className={styles.gridValue}>{children}</div>
    </div>
  );
}
