// third-party
import dayjs from "dayjs";
import { FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
// components
import Input from "@/components/input";
import ComboboxSelect from "@/components/input/combobox";
// icon
import Delete from "../../../../public/svgs/delete_dynamic.svg";

// store
import {
  PregnancyUpsertApiArg,
  usePregnancyUpsertMutation
} from "@/store/services/pregnancy";
import {
  PatientInfo,
  PregnancyOutcome,
  Pregnancy,
  Sex
} from "@/store/services/patient";

// styles
import styles from "../styles.module.scss";

// constants
import {
  METRIC_LABELS,
  PREGNANCY_OUTCOMES,
  STATUS_KEYS,
  STYLES
} from "@/globals/constants";

// utils
import {
  convertLocalDatetimeToUtcInt,
  convertUtcIntToLocalDate
} from "@/components/scheduling/calendars/utils";
import Button from "@/components/button";
import { setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import WeightInput, {
  Weight,
  getWeightObjectFromGrams
} from "@/components/input/weightInput";

export interface PastPregnancyProps {
  preg?: Pregnancy;
  patient: PatientInfo;
}

export default function PastPregnancy({ patient, preg }: PastPregnancyProps) {
  const dispatch = useDispatch();
  const [editingPregnancyId, setEditingPregnancyId] = useState<number | null>(
    null
  );
  const [upsertPregnancy] = usePregnancyUpsertMutation();
  const [living, setLiving] = useState(preg?.living || false);

  // to support the controlled text area
  const complexForm = useForm();

  const handleDeleteRow = (pregnancyId: number) => {
    upsertPregnancy({
      patientId: patient?.user_id,
      pregnancyUpsertRequest: {
        pregnancy_id: pregnancyId,
        state: "DELETED"
      }
    })
      .unwrap()
      .then(() => {
        dispatch(setModalIsOpen(false));
        dispatch(
          addAlertToToastTrough({
            message: "Pregnancy deleted",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Pregnancy could not be deleted",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  // populate the note
  useEffect(() => {
    complexForm.setValue("comment", preg?.comment || "");
    if (preg?.weight) {
      complexForm.setValue("weight", getWeightObjectFromGrams(preg.weight));
    }
  }, [preg]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formData = new FormData(form);
    const formObject = Object.fromEntries(formData.entries());

    const gestationalAgeDays = parseInt(
      formObject["gestational_age_days"].toString() || "0"
    );
    const gestationalAgeWeeks = parseInt(
      formObject["gestational_age_weeks"].toString() || "0"
    );
    const gestationalAge = gestationalAgeDays + 7 * gestationalAgeWeeks;

    const lengthOfLaborHours = parseInt(
      formObject["length_of_labor_hours"].toString() || "0"
    );

    const req: PregnancyUpsertApiArg = {
      patientId: patient?.user_id,
      pregnancyUpsertRequest: {
        state: "UNSPECIFIED"
      }
    };
    if (formObject["delivery_date"]) {
      req.pregnancyUpsertRequest.delivery_date = convertLocalDatetimeToUtcInt(
        dayjs(formObject["delivery_date"].toString())
      );
    }
    if (gestationalAge > 0) {
      req.pregnancyUpsertRequest.gestational_age_at_delivery = gestationalAge;
    }
    if (lengthOfLaborHours > 0) {
      req.pregnancyUpsertRequest.length_of_labor_minutes =
        lengthOfLaborHours * 60;
    }
    if (formObject["outcome"]) {
      req.pregnancyUpsertRequest.outcome = formObject[
        "outcome"
      ].toString() as PregnancyOutcome;
    }
    if (formObject["living"] !== undefined) {
      req.pregnancyUpsertRequest.living = true;
    } else {
      req.pregnancyUpsertRequest.living = false;
    }
    if (complexForm.getValues("weight")) {
      req.pregnancyUpsertRequest.weight = (
        complexForm.getValues("weight") as Weight
      ).grams;
    }
    if (formObject["sex"]) {
      req.pregnancyUpsertRequest.sex = formObject["sex"].toString() as Sex;
    }
    if (formObject["name"]) {
      req.pregnancyUpsertRequest.name = formObject["name"].toString();
    }
    if (formObject["birthplace"]) {
      req.pregnancyUpsertRequest.birthplace =
        formObject["birthplace"].toString();
    }

    if (preg?.pregnancy_id) {
      req.pregnancyUpsertRequest.pregnancy_id = preg.pregnancy_id;
    }

    if (complexForm.getValues("comment")) {
      req.pregnancyUpsertRequest.comment = complexForm.getValues("comment");
    }

    req.pregnancyUpsertRequest.is_past_pregnancy = true;

    upsertPregnancy(req)
      .unwrap()
      .then(() => {
        dispatch(setModalIsOpen(false));
        dispatch(
          addAlertToToastTrough({
            message: "Pregnancy saved",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Pregnancy could not be saved",
            type: STATUS_KEYS.ERROR
          })
        );
      });
    setEditingPregnancyId(null);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.PastPregnancy}>
      <div className={styles.fieldWrapper}>
        <Input
          label="Delivery Date"
          name={`delivery_date`}
          id={`delivery_date`}
          value={
            preg?.delivery_date
              ? convertUtcIntToLocalDate(preg.delivery_date).format(
                  "YYYY-MM-DD"
                )
              : undefined
          }
          type="date"
        />
      </div>
      <p className={styles.fieldGroupTitle}>Gestational Age at Delivery</p>

      <div className={styles.fieldWrapper}>
        <Input
          label="Weeks"
          name={`gestational_age_weeks`}
          id={`gestational_age_weeks`}
          value={
            preg?.gestational_age_at_delivery
              ? Math.floor(preg?.gestational_age_at_delivery / 7)
              : undefined
          }
          type="number"
        />
        <Input
          label="Days"
          name={`gestational_age_days`}
          id={`gestational_age_days`}
          value={
            preg?.gestational_age_at_delivery
              ? preg?.gestational_age_at_delivery % 7
              : undefined
          }
          type="number"
        />
      </div>

      <div className={styles.fieldGroupTitle}>Length of Labor</div>

      <div className={styles.fieldWrapper}>
        <Input
          label="Hours"
          name={`length_of_labor_hours`}
          id={`length_of_labor_hours`}
          value={
            preg?.length_of_labor_minutes
              ? Math.floor((preg.length_of_labor_minutes || 0) / 60)
              : undefined
          }
          type="number"
        />
      </div>
      <div className={styles.fieldWrapper}>
        <ComboboxSelect
          label="Outcome"
          name={"outcome"}
          initialValue={preg?.outcome}
          value={preg?.outcome}
          options={PREGNANCY_OUTCOMES}
        />

        <Input
          label="Living"
          name={`living`}
          id={`living`}
          checked={living}
          type="checkbox"
          onChange={() => setLiving(!living)}
        />
      </div>

      <div className={styles.fieldGroupTitle}>Infant Demographics</div>
      <div className={styles.fieldWrapper}>
        <Controller
          name="weight"
          control={complexForm.control}
          render={({ field: { onChange, value } }) => (
            <WeightInput value={value as Weight} onChange={onChange} />
          )}
        />
        <ComboboxSelect
          label="Sex"
          name={"sex"}
          initialValue={preg?.sex}
          labelAcc={sex => (sex ? METRIC_LABELS[sex] : "")}
          options={["MALE", "FEMALE", "UNKNOWN"]}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <Input
          label="Name"
          name={`name`}
          id={`name`}
          value={preg?.name}
          type="text"
        />

        <Input
          label="Birthplace"
          name={`birthplace`}
          id={`birthplace`}
          value={preg?.birthplace}
          type="text"
        />
      </div>
      <div className={styles.fieldGroupTitle}>Notes</div>

      <ControlledTextArea
        form={complexForm}
        label="Notes about the pregnancy"
        name="comment"
        id="comment"
        rows={4}
      />
      <div className={styles.buttons}>
        {preg?.pregnancy_id ? (
          <div className={styles.fieldWrapper}>
            <Button
              type="button"
              style={STYLES.DELETE}
              onClick={() => handleDeleteRow(preg?.pregnancy_id as number)}
            >
              <Delete stroke={styles.errorText} width={15} height={17} />
              Delete Pregnancy
            </Button>
            <Button type="submit" style={STYLES.FULL_WIDTH}>
              Save
            </Button>
          </div>
        ) : (
          <Button type="submit" style={STYLES.FULL_WIDTH}>
            Save
          </Button>
        )}
      </div>
    </form>
  );
}
