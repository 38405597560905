const allowedProtocols = ["http", "https", "ws", "wss"] as const;
type Protocol = typeof allowedProtocols[number];

// Backend
export const BACKEND_PROTOCOL: Protocol = process.env.NEXT_PUBLIC_BACKEND_PROTOCOL as Protocol;
if (BACKEND_PROTOCOL === undefined || !allowedProtocols.includes(BACKEND_PROTOCOL)) {
    throw new Error(`BACKEND_PROTOCOL must be defined as an environment variable and be one of ${allowedProtocols}`);
}

export const BACKEND_HOST = process.env.NEXT_PUBLIC_BACKEND_HOST?.trim()
if (BACKEND_HOST === undefined) {
    throw new Error("BACKEND_HOST must be defined as an environment variable");
}

export const BACKEND_PORT = process.env.NEXT_PUBLIC_BACKEND_PORT?.trim()
if (BACKEND_PORT === undefined) {
    throw new Error("BACKEND_PORT must be defined as an environment variable");
}

export const BACKEND_WEBSOCKET_PROTOCOL: Protocol = process.env.NEXT_PUBLIC_BACKEND_WEBSOCKET_PROTOCOL as Protocol;
if (BACKEND_WEBSOCKET_PROTOCOL === undefined || !allowedProtocols.includes(BACKEND_WEBSOCKET_PROTOCOL)) {
    throw new Error(`BACKEND_WEBSOCKET_PROTOCOL must be defined as an environment variable and be one of ${allowedProtocols}`);
}

// Backend for frontend (BFF)
export const BFF_PROTOCOL: Protocol = process.env.NEXT_PUBLIC_BFF_PROTOCOL as Protocol;
if (BFF_PROTOCOL === undefined || !allowedProtocols.includes(BFF_PROTOCOL)) {
    throw new Error(`NEXT_PUBLIC_BFF_PROTOCOL must be defined as an environment variable and be one of ${allowedProtocols}`);
}

export const BFF_HOST = process.env.NEXT_PUBLIC_BFF_HOST?.trim()
if (BFF_HOST === undefined) {
    throw new Error("NEXT_PUBLIC_BFF_HOST must be defined as an environment variable");
}

export const BFF_PORT = process.env.NEXT_PUBLIC_BFF_PORT?.trim()
if (BFF_PORT === undefined) {
    throw new Error("NEXT_PUBLIC_BFF_PORT must be defined as an environment variable");
}

export const BACKEND_BASE_URL = `${BACKEND_PROTOCOL}://${BACKEND_HOST}:${BACKEND_PORT}`;
export const BFF_BASE_URL = `${BFF_PROTOCOL}://${BFF_HOST}:${BFF_PORT}`;

export const SYNCFUSION_LICENSE_KEY = process.env.NEXT_PUBLIC_SYNCFUSION_LICENSE?.trim() as string;
if (SYNCFUSION_LICENSE_KEY === undefined) {
    throw new Error("NEXT_PUBLIC_SYNCFUSION_LICENSE must be defined as an environment variable");
}

export const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID;
if (INTERCOM_APP_ID === undefined) {
    throw new Error("NEXT_PUBLIC_INTERCOM_APP_ID must be defined as an environment variable");
}
