/* Birth form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

/* Local Imports */

// components
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import Button from "@/components/button";
import Clock from "../../../../public/svgs/clock.svg";

// constants
import { FORM_METRIC_LABELS, FORM_VALUES } from "./constants";
import { EVENTS } from "@/components/flows/labor/constants";
import { STYLES } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store

// styles
import styles from "./styles.module.scss";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* Birthform Typescript Interface */
interface BirthformProps {
  onSubmit: (data: any) => void;
  defaultValues: any;
  startTime: number;
}

export default function Birthform({
  onSubmit,
  defaultValues,
  startTime,
}: BirthformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
  } = form;
  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  /* Effects */

  /* Event Handlers */
  useEffect(() => {
    setValue("start_time", startTime);
  }, [startTime]);

  const FIELDS = Object.entries(FORM_VALUES[EVENTS.BIRTH]);
  return (
    <form
      className={clsx(styles.LaborForm, styles.Birthform)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="start_time"
        control={control}
        render={({ field: { onChange, name, value } }) => {
          return (
            <DateTimeStamp
              name={name}
              onChange={onChange}
              initialValue={defaultValues && defaultValues.start_time || startTime }
              value={value}
            />
          );
        }}
      />
      {FIELDS &&
        FIELDS.map(([key, val]) => (
          <ControlledCombobox
            key={key}
            options={val}
            label={FORM_METRIC_LABELS[key]}
            name={key}
            control={control}
            isHorizontalLayout={false}
            errors={errors}
            fullWidth
          />
        ))}
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
          onBlur={() => {
            setTextAreaFocused(false);
          }}
        />
      </div>
      <div className={styles.fullWidth}>
        <Button style={STYLES.FULL_WIDTH} type="submit">
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}
