import * as generated from "./generated/provider";

const provider = generated.provider.enhanceEndpoints({
  endpoints: {
    providerGetOrders: {
      providesTags: ["LabOrder"]
    }
  }
});

export * from "./generated/provider";
export default provider;

export const {
  useProviderCreateMutation,
  useProviderUpdateMutation,
  useProviderGetInfoQuery,
  useLazyProviderGetInfoQuery,
  useProviderGetOrdersQuery,
  useLazyProviderGetOrdersQuery
} = provider;
