import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    userCreate: build.mutation<UserCreateApiResponse, UserCreateApiArg>({
      query: queryArg => ({
        url: `/user/create`,
        method: "POST",
        body: queryArg.userCreateAndSignup
      })
    }),
    userUpdate: build.mutation<UserUpdateApiResponse, UserUpdateApiArg>({
      query: queryArg => ({
        url: `/user/${queryArg.userId}/update`,
        method: "POST",
        body: queryArg.userModel
      })
    }),
    userGetInfo: build.query<UserGetInfoApiResponse, UserGetInfoApiArg>({
      query: queryArg => ({ url: `/user/${queryArg.userId}/info` })
    }),
    userGetNotifications: build.query<
      UserGetNotificationsApiResponse,
      UserGetNotificationsApiArg
    >({
      query: queryArg => ({
        url: `/user/${queryArg.userId}/notifications`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    userPasswordReset: build.mutation<
      UserPasswordResetApiResponse,
      UserPasswordResetApiArg
    >({
      query: queryArg => ({
        url: `/user/passwordreset`,
        method: "POST",
        body: queryArg.userPasswordReset
      })
    }),
    userSelectAuth: build.query<
      UserSelectAuthApiResponse,
      UserSelectAuthApiArg
    >({
      query: queryArg => ({ url: `/user/${queryArg.practiceId}/select` })
    }),
    dummy: build.query<DummyApiResponse, DummyApiArg>({
      query: queryArg => ({ url: `/.__/__.`, body: queryArg.systemTypes })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as user };
export type UserCreateApiResponse =
  /** status 200 This is sent in reply to a successful user create request.
   */ UserId;
export type UserCreateApiArg = {
  /** Payload required when a user signs up or is created by an admin. Gathered by the front end.
   */
  userCreateAndSignup: UserCreateAndSignup;
};
export type UserUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UserUpdateApiArg = {
  userId: UserId;
  /** Payload required when a user is updated by an admin.
   */
  userModel: UserModel;
};
export type UserGetInfoApiResponse =
  /** status 200 Sent to describe a single user in detail.
   */ UserInfo;
export type UserGetInfoApiArg = {
  userId: UserId;
};
export type UserGetNotificationsApiResponse =
  /** status 200 A list of notifications for a practice or location.
   */ NotificationList;
export type UserGetNotificationsApiArg = {
  userId: UserId;
  page?: Page;
  pagesz?: PageSize;
};
export type UserPasswordResetApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UserPasswordResetApiArg = {
  /** Payload required when a user requests a password reset.
   */
  userPasswordReset: UserPasswordReset;
};
export type UserSelectAuthApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UserSelectAuthApiArg = {
  practiceId: PracticeId;
};
export type DummyApiResponse = /** status 234 Dummy. */ number;
export type DummyApiArg = {
  /** Dummy. */
  systemTypes: SystemTypes;
};
export type UserId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type Sex = "MALE" | "FEMALE" | "UNKNOWN";
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type UserCreateAndSignup = {
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A SHA-256 hash for a user's password. We only ever send these hashes around in order to avoid ever sending
    the user's password in the clear.
     */
  password: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  ssn?: string;
};
export type ErrorId = number;
export type UserEmergencyContact = {
  name?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  phone_type?: "Home" | "Work" | "Mobile";
  relationship?: string;
};
export type UserEmergencyContacts = UserEmergencyContact[];
export type Address = {
  /** Address line 1. Mandatory. This can include the street number.
   */
  line1?: string;
  /** Optional line 2 of 3 for an address.
   */
  line2?: string;
  /** Optional line 3 of 3 for an address.
   */
  line3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** This is the optional, human-readable representation of the address. This is used
    when communicating addresses that come from sources that don't parse their addresses
    down into its constituent parts, like Health Gorilla.
     */
  display?: string;
};
export type RelationshipToSubscriber = "SELF" | "SPOUSE" | "GUARDIAN" | "OTHER";
export type Insurance = {
  insurance_id?: string;
  insurance_id_type?: 1 | 2 | 3 | 4 | 5 | 6;
  payor?: string;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  hg_org_id?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  payor_phone?: string;
  plan_type?: string;
  policy_or_group_number?: string;
  subscriber_id?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  subscriber_first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  subscriber_last_name?: string;
  /** A Pario date in YYYYMMDD format.
   */
  subscriber_dob?: number;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  subscriber_ssn?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  subscriber_phone?: string;
  subscriber_address?: Address;
  relationship_to_subscriber?: RelationshipToSubscriber;
};
export type UserInsurance = {
  self_pay?: boolean;
  primary?: Insurance;
  secondary?: Insurance;
  tertiary?: Insurance;
};
export type BloodType =
  | "A+"
  | "A-"
  | "B+"
  | "B-"
  | "AB+"
  | "AB-"
  | "O+"
  | "O-"
  | "UNKNOWN";
export type Diabetes = "TYPE_1" | "TYPE_2" | "GESTATIONAL" | "NONE";
export type HivStatus = "POSITIVE" | "NEGATIVE" | "UNKNOWN";
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type AllergySeverity = "MILD" | "MODERATE" | "SEVERE" | "FATAL";
export type Allergy = {
  icd10_code_details?: Icd10CodeDetails;
  name?: string;
  reaction?: string;
  severity?: AllergySeverity;
  notes?: string;
  date_of_onset?: string;
  allergy_id?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Allergies = Allergy[];
export type Immunization = {
  name: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt: number;
  /** A Pario date in YYYYMMDD format.
   */
  date?: number;
  notes?: string;
};
export type Immunizations = Immunization[];
export type MedicationStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "NONCOMPLIANT"
  | "DELETED";
export type Medication = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  lexicomp_product_id?: number;
  lexicomp_drug_id?: string;
  lexicomp_synonym_id?: number;
  lexicomp_synonym_type?: number;
  dosespot_id?: number;
  days_supply?: number;
  is_prescription?: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Medications = {
  [key: string]: Medication;
};
export type EddMethod = "LMP" | "ULTRASOUND" | "DATE_OF_CONCEPTION" | "UNKNOWN";
export type EstimatedDueDate = {
  method: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
};
export type Lmp = {
  last_menstrual_period?: string;
  estimate_id?: string;
};
export type PregnancyState =
  | "GESTATING"
  | "DELIVERED"
  | "MISCARRIED"
  | "STILLBORN"
  | "ECTOPIC"
  | "TERMINATED"
  | "POSTPARTUM"
  | "UNSPECIFIED"
  | "DELETED";
export type PrenatalFlowSheetEntry = {
  weeks?: string;
  days?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  encounter_start?: number;
  weight?: number;
  blood_pressure?: string;
  temperature?: number;
  fundus?: string;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  note?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
};
export type PrenatalFlowSheet = PrenatalFlowSheetEntry[];
export type LabOrderId = string;
export type LabOrders = LabOrderId[];
export type UserIdList = UserId[];
export type LaborEvent = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: UserIdList;
  forms?: object;
};
export type LaborStage = {
  events: {
    [key: string]: LaborEvent;
  };
  progress_updates: LaborEvent[];
  medications: LaborEvent[];
  infant_progress_updates?: LaborEvent[];
  infant_medications?: LaborEvent[];
  recurring_events?: LaborEvent[];
  notes: LaborEvent[];
};
export type LaborModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  transfer?: object;
  stages: {
    [key: string]: LaborStage;
  };
  note?: string;
};
export type PregnancyOutcome =
  | "UNKNOWN"
  | "TAB (therapeautic abortion)"
  | "EAB (elective abortion)"
  | "SAB (miscarriage)"
  | "SBVD (stillbirth vaginal delivery)"
  | "SBCS (stillbirth cesarean delivery)"
  | "NSVD (vaginal delivery)"
  | "NSVB (vaginal birth)"
  | "VAVD (vacuum vaginal delivery)"
  | "FAVD (forceps vaginal delivery)"
  | "CS (cesarean)"
  | "VBAC (vaginal birth after previous cesarean)"
  | "NSVD (vaginal delivery) - Water"
  | "VBAC (vaginal birth after previous cesarean) - Water"
  | "EP (ectopic pregnancy)";
export type MaternityNeighborhoodPregnancyMetadata = {
  episode_of_care_id?: string;
  past_pregnancy_id?: string;
};
export type ClientCarePregnancyMetadata = {
  pregnancy_id?: string;
};
export type PregnancyMetadata = {
  maternity_neighborhood?: MaternityNeighborhoodPregnancyMetadata;
  client_care?: ClientCarePregnancyMetadata;
};
export type Pregnancy = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  edd?: EstimatedDueDate;
  lmp?: Lmp;
  state: PregnancyState;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: boolean;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor_minutes?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type Pregnancies = Pregnancy[];
export type AntibodyScreenDat = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met1 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met2 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type UserMedicalHistory = {
  blood_type?: BloodType;
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  diabetic?: Diabetes;
  hiv_status?: HivStatus;
  allergies?: Allergies;
  immunizations?: Immunizations;
  medications?: Medications;
  pregnancies?: Pregnancies;
  no_known_drug_allergies?: boolean;
  no_known_medications?: boolean;
  no_known_problems?: boolean;
  antibody_screen_DAT?: AntibodyScreenDat;
  met_1?: Met1;
  met_2?: Met2;
};
export type RoleId = number;
export type UserPracticeRoles = RoleId[];
export type LocationId = number;
export type LocationIds = LocationId[];
export type UserMetaPractice = {
  roles: UserPracticeRoles;
  locations: LocationIds;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_email?: number;
};
export type UserMetaPractices = {
  [key: string]: UserMetaPractice;
};
export type UserMetaLast = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
};
export type UserDosespotMeta = {
  patient_id?: number;
  proxy_clinician_id?: number;
  prescribing_clinician_id?: number;
};
export type UserMetadata = {
  practices: UserMetaPractices;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  last?: UserMetaLast;
  dosespot: UserDosespotMeta;
};
export type UserModel = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  parent_ids?: UserId[];
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A SHA-256 hash for a user's password. We only ever send these hashes around in order to avoid ever sending
    the user's password in the clear.
     */
  password: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  email_verified_at?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  mobile_verified_at?: number;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  ssn?: string;
  /** Payment information for personal payments (personal CC etc).
   */
  payment?: object;
  emergency_contacts?: UserEmergencyContacts;
  insurance?: UserInsurance;
  medical_history: UserMedicalHistory;
  metadata: UserMetadata;
};
export type UserInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  metadata: UserMetadata;
  medical_history: UserMedicalHistory;
};
export type IdType =
  | "form"
  | "group"
  | "message"
  | "practice"
  | "patient"
  | "medical_assistant"
  | "administrative"
  | "biller"
  | "location"
  | "pregnancy"
  | "provider"
  | "template"
  | "user"
  | "medication"
  | "progress_update"
  | "note"
  | "infant_medication"
  | "infant_progress_update"
  | "recurring_event";
export type NotificationStatus = "INITIATED" | "SENT" | "DELIVERED" | "FAILED";
export type NotificationModel = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user?: number;
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  id?: number;
  id_type?: IdType;
  status?: NotificationStatus;
  /** Rendered template that was included in the notification.
   */
  rendered?: string;
};
export type NotificationList = NotificationModel[];
export type Page = number;
export type PageSize = number;
export type UserPasswordReset = {
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email: string;
};
export type PracticeId = number;
export type AcogTestModel = {
  acog_test_id: number;
  acog_test: string;
};
export type AcogObservationModel = {
  acog_test_id: number;
  acog_obs_id: number;
  acog_obs: string;
  observation_name: string;
  units?: string;
  sort_order: number;
};
export type ClaimStatus =
  | "CREATED"
  | "IN_REVIEW"
  | "HOLD"
  | "NON_BILLABLE"
  | "CHANGES_REQUIRED"
  | "ACCEPTED"
  | "SUBMITTED"
  | "CORRECTED_AND_RESUBMITTED"
  | "APPEAL_SUBMITTED"
  | "CLAIM_AT_SECONDARY"
  | "APPROVED"
  | "REJECTED_BY_CLEARINGHOUSE"
  | "PATIENT_BALANCE"
  | "DENIED_PENDING_PROVIDER_REVIEW"
  | "DENIED"
  | "PARTIALLY_DENIED"
  | "DELETED";
export type ClaimModel = {
  claim_id: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  status: ClaimStatus;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  claimed?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  contracted?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  reimbursed?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  deleted?: string;
};
export type CookieLocations = LocationId[];
export type AuthPermission =
  | "appointment:read"
  | "appointment:start"
  | "appointment:write"
  | "chargemaster:read"
  | "chargemaster:write"
  | "chargemaster:delete"
  | "claim:delete"
  | "claim:read"
  | "claim:write"
  | "encounter:delete"
  | "encounter:read"
  | "encounter:submit"
  | "encounter:write"
  | "event:delete"
  | "event:read"
  | "event:write"
  | "fax:read"
  | "fax:send"
  | "fax:write"
  | "file:delete"
  | "file:read"
  | "file:write"
  | "lab:read"
  | "lab:send"
  | "lab:write"
  | "pario:internal"
  | "patient:list"
  | "patient:read"
  | "patient:transfer"
  | "patient:write"
  | "payor:write"
  | "phrase:delete"
  | "phrase:read"
  | "phrase:write"
  | "recall:read"
  | "recall:write"
  | "rx:write"
  | "staff:write"
  | "staff:read"
  | "user:read"
  | "user:write";
export type Cookie = {
  /** Cookie version. Currently at version 1.
   */
  version: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  locations: CookieLocations;
  /** A set of permission bits. The highest bit is never used.
   */
  perms?: number;
  permissions: AuthPermission[];
  /** A boolean that defaults to false if not set.
   */
  is_ma?: boolean;
  /** A boolean that defaults to false if not set.
   */
  is_provider?: boolean;
  /** A boolean that defaults to false if not set.
   */
  is_admin?: boolean;
  /** A boolean that defaults to false if not set.
   */
  is_superadmin?: boolean;
  /** A boolean that defaults to false if not set.
   */
  is_patient?: boolean;
  /** A boolean that defaults to false if not set.
   */
  is_biller?: boolean;
};
export type AppointmentStatus =
  | "SCHEDULED"
  | "CONFIRMED"
  | "CANCELED"
  | "NO_SHOW"
  | "CHECKED_IN"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "CHECKED_OUT"
  | "RESCHEDULED";
export type AppointmentAttachment = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  display: string;
};
export type AppointmentAttachments = AppointmentAttachment[];
export type TaskType = "FORM" | "FILE" | "UPLOAD" | "GENERIC" | "DEMOGRAPHICS";
export type AppointmentTask = {
  display: string;
  /** System-assigned ID of a patient task.
   */
  task_id?: number;
  task_type: TaskType;
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  resource_id: number;
};
export type AppointmentTasks = AppointmentTask[];
export type MeridianMetadata = {
  meridian_id: string;
};
export type AppointmentModelMetadata = {
  meridian?: MeridianMetadata;
};
export type AppointmentModel = {
  /** System-assigned unique ID of an appointment. Used as a table linkage. Every appointment the system
    creates is given a unique ID that is used by other types to refer to that specific appointment
    (for example, all messages sent by the system to a user regarding a specific appointment).
     */
  appointment_id: number;
  /** Each practice and location can define their own list of standard appointment types, along with default
    durations, personnel and notes etc. This is the name of such an appointment type. It must be all lower case
    and have no spaces or special characters other than a hyphen. This will be unique within a practice
    and a location, each of which has their own lists, with the location list overriding any practice type
    that has the same name.
     */
  appointment_type: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id?: number;
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** A non-clinic location where an appointment or encounter can take place.
    For example: A patient's home, the phone, or a video call.
    This is configured per practice
     */
  alt_location?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  starts: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  ends: number;
  status: AppointmentStatus;
  /** Optional note limited to 4K in length.
   */
  chief_complaint?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  referring_provider_id?: number;
  referring_external_provider?: string;
  attachments?: AppointmentAttachments;
  tasks?: AppointmentTasks;
  message?: string;
  flagged: boolean;
  note?: string;
  metadata?: AppointmentModelMetadata;
};
export type TransactionType =
  | "PATIENT_PAYMENT"
  | "PAYER_PAYMENT"
  | "SERVICE_CHARGES"
  | "FEES"
  | "ADJUSTMENT";
export type TransactionPaymentMethod = "cash" | "check" | "credit";
export type TransactionProvider = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The first name of the participant.
   */
  first_name: string;
  /** The last name of the participant.
   */
  last_name: string;
};
export type TransactionPatient = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The first name of the participant.
   */
  first_name: string;
  /** The last name of the participant.
   */
  last_name: string;
};
export type TransactionsHistoryModel = {
  transaction_id?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  amount?: string;
  date_of_service?: string;
  type?: TransactionType;
  payment_method?: TransactionPaymentMethod;
  description?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at?: string;
  created_by?: TransactionProvider;
  patient_info?: TransactionPatient;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id?: number;
  /** Adjustments to the transaction, such as refunds or discounts.
    These adjusted transactions will not have their own adjustments.
     */
  adjustments?: TransactionsHistoryModel[];
};
export type PregnancyDueDateEstimationMethod =
  | "ULTRASOUND"
  | "LAST_MENSTRUAL_PERIOD"
  | "UNKNOWN";
export type EstimatedDueDateModel = {
  comment?: string;
  /** The length of the patient's menstrual cycle in days. This is only
    relevant for LMP estimates.
    Optional. Default is 28. ?
     */
  lmp_cycle_length?: number;
  /** The file ID of the document that was used associated with this estimate.
    e.g. the ultrasound image
     */
  file_id?: number;
  method?: PregnancyDueDateEstimationMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at?: string;
};
export type EncounterType =
  | "WELL_WOMAN"
  | "PRENATAL"
  | "LABOR"
  | "POSTPARTUM"
  | "INFANT"
  | "PHONE";
export type EncounterStatus = "IN_PROGRESS" | "SUBMITTED" | "DELETED";
export type HgLabTestId = string;
export type DiagnosisInfo = {
  cpt: number[];
  lab_tests?: HgLabTestId[];
  prescriptions?: number[];
};
export type EncounterDiagnoses = {
  [key: string]: DiagnosisInfo;
};
export type CptModifierId = number;
export type BillRecipients =
  | "PATIENT"
  | "PRIMARY_INSURANCE"
  | "SECONDARY_INSURANCE"
  | "TERTIARY_INSURANCE";
export type Coding = {
  cpt_id?: number;
  is_billable?: boolean;
  modifier_ids?: CptModifierId[];
  icd10_ids?: number[];
  units?: number;
  place_of_service?: string;
  type_of_service?: string;
  bill_recipient?: BillRecipients;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  fee?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  charge?: string;
  comment?: string;
};
export type Codings = Coding[];
export type InfantGrowthMeasurements = {
  date?: string;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight_grams?: number;
  standing_height_cm?: number;
  recumbent_length_cm?: number;
  head_circumference_cm?: number;
  respiratory_rate?: number;
  blood_pressure?: string;
  /** Temperature in Fahrenheit.
   */
  temperature?: number;
  pulse?: number;
};
export type EncounterDetails = {
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  when: number;
  note: string;
  diagnoses?: EncounterDiagnoses;
  codings?: Codings;
  /** System-assigned version of a resource. Many system types can change over time. Where they are referenced
    in othe types, the version number of the resource is also stored. This allows us to identify a resource as it
    existed at a specific point in time. For example, a patient info form may undergo many changes, and the data
    used and stored at any given time will be a specific version number of that form. Thus in the future if we
    need to query (perhaps for legal reasons) what exactly was the information a provider was using when they
    provided service, we can reference the patient form as it existed at that version.
     */
  health_history_version: number;
  infant_growth_measurements?: InfantGrowthMeasurements;
  [key: string]: any;
};
export type EncounterDetailsHistory = EncounterDetails[];
export type EncounterId = number;
export type EncounterMaternityNeighborhoodMetadata = {
  episode_id?: string;
  encounter_id?: number;
};
export type EncounterMetadata = {
  related?: EncounterId[];
  maternity_neighborhood?: EncounterMaternityNeighborhoodMetadata;
};
export type EncounterModel = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  encounter_type: EncounterType;
  /** Each practice and location can define their own list of standard appointment types, along with default
    durations, personnel and notes etc. This is the name of such an appointment type. It must be all lower case
    and have no spaces or special characters other than a hyphen. This will be unique within a practice
    and a location, each of which has their own lists, with the location list overriding any practice type
    that has the same name.
     */
  appointment_type?: string;
  status: EncounterStatus;
  details?: EncounterDetailsHistory;
  type_specific?: object;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  submitted?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  submitted_by?: number;
  metadata: EncounterMetadata;
};
export type FileStatus = 0 | 1 | 2 | 3;
export type FileModel = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** Name of a file (max 128 characters).
   */
  name: string;
  size: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** Number of times the file has been downloaded.
   */
  downloads: number;
  /** S3 ARN allocated to the file.
   */
  s3arn: string;
  status: FileStatus;
  /** In a number of places (most notably files) we allow the attachment of an arbitrary list of tags.
    Most tags are simple booleans but a few can have more complex information. A location and a practice
    can define the valid list of tags. Because this is a dictionary it guarantees unique tags.
    This is the type for a collection of tags (i.e one of these will be attached to each practice or location).
     */
  tags: object;
  metadata: object;
};
export type FaxInboundModel = {
  /** System-assigned unique ID of a fax.
   */
  fax_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  sent_from?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated?: number;
  metadata?: object;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  archived_at?: string;
  arn?: string;
  file_size_bytes?: number;
  file_name?: string;
};
export type FormFieldType =
  | "LABEL"
  | "TEXTAREA"
  | "TEXT"
  | "CHECKBOX"
  | "PHONE"
  | "DATE"
  | "TIME"
  | "DATETIME"
  | "SINGLE_DROPDOWN"
  | "MULTI_DROPDOWN"
  | "STATE"
  | "ZIPCODE"
  | "BOOL"
  | "TABLE"
  | "EMAIL"
  | "NUMBER"
  | "AMOUNT";
export type FormMinLengthValidation = {
  /** Minimum valid length for TEXT, TEXTAREA, EMAIL.
   */
  minLength?: number;
};
export type FormMaxLengthValidation = {
  /** Maximum string length for TEXT, TEXTAREA, EMAIL.
   */
  maxLength?: number;
};
export type FormMinimumValidation = {
  /** Minimum value for NUMBER, AMOUNT
   */
  minimum?: number;
};
export type FormMaximumValidation = {
  /** Maximum value for NUMBER, AMOUNT
   */
  maximum?: number;
};
export type FormCustomValidation = {
  /** The name of a custom validation function in the front-end. This must be just the name of the function,
    so it must be a valid Javascript name, and the front-end must ensure that this function is available
    when form validation happens. The function takes a single argument, which is the input object, and must
    return a single boolean, using true to indivate the field is valid, and false to indicate a problem.
     */
  custom?: string;
};
export type FormFieldValidations =
  | FormMinLengthValidation
  | FormMaxLengthValidation
  | FormMinimumValidation
  | FormMaximumValidation
  | FormCustomValidation;
export type FormFieldOptions = string[];
export type FormField = {
  /** Name of a form or field within a practice or location. Must be a valid HTML id / Javascript variable name and
    it must be unique within the containing entity.
     */
  name?: string;
  /** Label for a form field or section. How these are displayed is up to the front-end and no assumptions
    should be made about presentation. A field label is likely to be different to a section label.
     */
  label: string;
  type: FormFieldType;
  /** Label for a form field or section. How these are displayed is up to the front-end and no assumptions
    should be made about presentation. A field label is likely to be different to a section label.
     */
  subtitle?: string;
  description?: string;
  placeholder?: string;
  tags?: string[];
  /** Whether or not the field can be empty on form submission.
   */
  required?: boolean;
  validations?: FormFieldValidations;
  options?: FormFieldOptions;
  subfields?: FormField[];
};
export type FormSection = {
  /** An ID that can be used as a JavaScript variable name, or an HTML ID. Since HTML IDs are a lot more forgiving
    in their naming, the real restriction is Javascript. Thus, any fields of this type can not contains spaces or
    special characters, and cannot begin with an underscore or a digit. You know the rules.
     */
  id: string;
  /** Label for a form field or section. How these are displayed is up to the front-end and no assumptions
    should be made about presentation. A field label is likely to be different to a section label.
     */
  label: string;
  description?: string;
  fields: FormField[];
  /** Internal system defined flags. Should never be specified and is ignored if it is. This may be displayed for
    debugging purposes but the primary use of this field is for debugging.
     */
  flags?: number;
};
export type FormSections = FormSection[];
export type FormSchema = {
  /** Name of a form or field within a practice or location. Must be a valid HTML id / Javascript variable name and
    it must be unique within the containing entity.
     */
  name: string;
  /** Form title. This will be displayed to the user and included in reports.
   */
  title: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  sections: FormSections;
};
export type FormSchemas = FormSchema[];
export type FormModel = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  /** Name of a form or field within a practice or location. Must be a valid HTML id / Javascript variable name and
    it must be unique within the containing entity.
     */
  name: string;
  /** Form title. This will be displayed to the user and included in reports.
   */
  title: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  schemas: FormSchemas;
};
export type Icd10Model = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
  /** The WHO changes the ICD10 database every year in October. This version is an internal version number
    defined when we import the ICD10 database. When we switch to using a new version of the database we
    only need change the version number in one place in the backend code, and it will start using the
    specified version of the codes.
     */
  version: number;
};
export type InboxModel = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Unique ID for each message created by the system.
   */
  message_id: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  first_read: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_read: number;
  /** System level and defined flags. Very little should ever see let alone be able to set these.
   */
  flags: number;
};
export type ManualLabFlowsheetEntryObservationModel = {
  manual_lab_flowsheet_id: string;
  acog_obs_id: number;
  observation_value: string;
  units?: string;
  is_abnormal: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at?: string;
};
export type MessageFlags = 1 | 2 | 4 | 8 | 16 | 32;
export type FileId = number;
export type FileIds = FileId[];
export type MessagesModel = {
  /** Unique ID for each message created by the system.
   */
  message_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** The contents of an actual message, limited to 64K fully JSON-escaped.
   */
  body: string;
  /** The subject of a message.
   */
  subject: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** Number of times the message has been replied to.
   */
  replies?: number;
  /** Unique ID for each message created by the system.
   */
  parent?: number;
  /** Unique ID for each message created by the system.
   */
  root?: number;
  /** System level and defined flags. Very little should ever see let alone be able to set these.
   */
  flags: number;
  recipients: UserIdList;
  files?: FileIds;
};
export type NablaPreferences = {
  format: "bullet_points" | "paragraphs";
  template: "general_medicine" | "soap";
};
export type PracticeFeatureFlags = {
  /** Whether or not lab ordering is enabled.
   */
  lab_ordering_enabled: boolean;
  /** Whether or not claim lifecycle management is enabled.
   */
  clm_enabled: boolean;
  /** Whether or not infant charting is enabled.
   */
  infant_charting_enabled: boolean;
  /** Whether or not pronouns are enabled.
   */
  pronouns_enabled: boolean;
  /** Whether or not the rx feature / DoseSpot is enabled.
   */
  rx_enabled: boolean;
  /** Whether or not the messaging feature is enabled.
   */
  messaging_enabled: boolean;
  /** Whether or not labor charting is enabled.
   */
  labor_charting_enabled: boolean;
  /** Whether or not postpartum charting is enabled.
   */
  postpartum_charting_enabled: boolean;
  /** Whether or not prenatal charting is enabled.
   */
  prenatal_charting_enabled: boolean;
  /** Whether or not primary care patient charting is enabled.
   */
  primary_care_charting_enabled: boolean;
  /** Whether or not patient balances are enabled.
   */
  patient_balances_enabled: boolean;
  /** Whether or not male patients are enabled.
   */
  male_patients_enabled: boolean;
  /** Whether or not medical coding v2 is enabled.
   */
  medical_coding_v2_enabled: boolean;
  /** Whether or not multi-provider scheduling is enabled.
   */
  multi_provider_schedule_enabled: boolean;
  /** Whether or not patient recall is enabled.
   */
  patient_recall_enabled: boolean;
  /** Whether or not patient messaging on the facepage is enabled.
   */
  patient_messaging_enabled: boolean;
  /** Whether or not lab flowsheets are enabled.
   */
  lab_flowsheets_enabled: boolean;
  /** Whether or not the all appointments homepage view is enabled.
   */
  all_appointments_homepage_view_enabled: boolean;
  /** Whether or not patient checkout is enabled.
   */
  patient_checkout_enabled: boolean;
  /** Whether or not appointment reminders are enabled.
   */
  send_appointment_reminders_enabled: boolean;
  /** Whether or not the accessible calendar is enabled.
   */
  accessible_calendar_enabled: boolean;
  /** Whether or not auto-submit claim is enabled.
   */
  auto_submit_claim_enabled: boolean;
  /** Whether or not welcome emails are enabled.
   */
  welcome_emails_enabled: boolean;
};
export type OwnerId = number;
export type PracticeName = string;
export type Phone = string;
export type Email = string;
export type AddrLine1 = string;
export type AddrLine2 = string;
export type AddrLine3 = string;
export type City = string;
export type Zipcode = string;
export type Dict = object;
export type PhraseModel = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  phrase: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** Expansion for a dot-phrase.
   */
  text: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  last_editor: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_edited: number;
};
export type PhrasePreferences = {
  [key: string]: PhraseModel;
};
export type LaborMedications = string[];
export type ContactModel = {
  /** ULID identifying the contact */
  contact_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  name: string;
  description?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
};
export type PracticeContacts = ContactModel[];
export type PatientType =
  | "PROSPECTIVE"
  | "OB"
  | "GYN"
  | "INACTIVE"
  | "INFANT"
  | "PEDIATRIC"
  | "POSTPARTUM"
  | "LACTATION"
  | "PRIMARY_CARE";
export type PracticePreferences = {
  phrases: PhrasePreferences;
  labor_medications: LaborMedications;
  contacts?: PracticeContacts;
  default_patient_list_type?: PatientType;
};
export type PracticeMetaRole = {
  /** The name of the role. There is no requirement that this be unique as roles are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  /** A set of permission bits. The highest bit is never used.
   */
  perms: number;
};
export type PracticeMetaRoles = {
  [key: string]: PracticeMetaRole;
};
export type PracticeLabAccount = {
  account_code?: string;
  account_id: string;
  enabled: boolean;
};
export type PracticeLabAccounts = {
  [key: string]: PracticeLabAccount;
};
export type HgEnvironment = "PRODUCTION" | "SANDBOX";
export type PracticeHgMeta = {
  oauth_sub?: string;
  org_id?: string;
  accounts?: PracticeLabAccounts;
  env?: HgEnvironment;
};
export type PracticeMetadata = {
  roles: PracticeMetaRoles;
  health_gorilla?: PracticeHgMeta;
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  medical_history_form_id?: number;
};
export type DesiredBirthplaces = string[];
export type AltLocations = string[];
export type CalendarColorCoding = "appointment_type" | "provider_id";
export type PracticeModel = PracticeFeatureFlags & {
  practice_id: PracticeId;
  owner_id: OwnerId;
  name: PracticeName;
  phone?: Phone;
  fax?: Phone;
  email: Email;
  addr_line_1: AddrLine1;
  addr_line_2?: AddrLine2;
  addr_line_3?: AddrLine3;
  city: City;
  state: Statecode;
  zip: Zipcode;
  fields?: Dict;
  preferences: PracticePreferences;
  metadata: PracticeMetadata;
  desired_birthplaces: DesiredBirthplaces;
  alt_locations: AltLocations;
  calendar_color_coding: CalendarColorCoding;
};
export type OutboxModel = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Unique ID for each message created by the system.
   */
  message_id: number;
};
export type PracticeData = {
  type: PatientType;
};
export type FormSubmission = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  /** Version number of a form.
   */
  form_version: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  when: number;
  data: object;
};
export type FormSubmissionHistory = FormSubmission[];
export type PatientFormData = {
  [key: string]: FormSubmissionHistory;
};
export type PatientHgMetadata = {
  /** A URL identifying a FHIR-R4 Resource in Health Gorilla's systems.
   */
  resource_url?: string;
};
export type PatientLocations = LocationId[];
export type PatientMetadata = {
  health_gorilla?: PatientHgMetadata;
  locations: PatientLocations;
  meridian?: MeridianMetadata;
};
export type ParentIds = UserId[];
export type PatientModel = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  practice_data: PracticeData;
  location_data: object;
  form_data?: PatientFormData;
  metadata: PatientMetadata;
  parent_ids?: ParentIds;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  active_pregnancy_id?: number;
};
export type LocationType =
  | "MIDWIFE"
  | "BIRTHING_CENTER"
  | "MIDWIFERY_CLINIC"
  | "OBGYN_CLINIC"
  | "HOSPITAL"
  | "INTERNAL";
export type LabOrgInfo = {
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  id: string;
  name: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabLocationInfo = {
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id: string;
  name: string;
  address: Address;
  lab?: LabOrgInfo;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabObservationStatus =
  | "registered"
  | "preliminary"
  | "final"
  | "amended"
  | "cancelled"
  | "corrected";
export type ObservationSource = "LAB" | "MANUAL";
export type ValueQuantity = {
  value: string;
  unit?: string;
};
export type ReferenceRange = {
  low?: ValueQuantity;
  high?: ValueQuantity;
  text?: string;
};
export type PerformingSite = {
  name?: string;
  address?: Address;
  contact?: string;
};
export type LabObservationInfo = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id: string;
  /** An ID for a lab test.
   */
  test_id: string;
  /** Health Gorilla ID for an observation from lab results.
   */
  hg_id: string;
  status: LabObservationStatus;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id?: number;
  source: ObservationSource;
  display: string;
  code: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  issued: string;
  value?: string;
  unit?: string;
  reference_range?: ReferenceRange;
  interpretation?: string;
  note?: string;
  performing_site?: PerformingSite;
};
export type LabOrderStatus =
  | "NEW"
  | "SENT"
  | "IN_PROGRESS"
  | "ERROR"
  | "FULFILLED"
  | "CORRECTED"
  | "REVIEWED";
export type AoeAnswer = {
  aoe_id: string;
  response: string;
};
export type LabTestInfo = {
  /** An ID for a lab test.
   */
  test_id: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Code used by the lab to identify a test.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_to_lab?: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_from_lab?: string;
  observations: LabObservationInfo[];
  status?: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  aoe_answers?: AoeAnswer[];
};
export type LabTests = LabTestInfo[];
export type LabTestBundle = {
  /** System-assigned ID for a bundle. Formatted as a UUID.
   */
  bundle_uuid?: string;
  /** User-defined name for a bundle.
   */
  name: string;
  tests: LabTests;
};
export type LocationBookmarkedLab = {
  locations: {
    [key: string]: LabLocationInfo;
  };
  bundles: {
    [key: string]: LabTestBundle;
  };
};
export type LocationBookmarkedLabs = {
  [key: string]: LocationBookmarkedLab;
};
export type LocationGroupListItem = {
  /** System-assigned unique ID for a group of users.
   */
  group_id: number;
  /** The name of a location group. This is a collection of users (most likely staff but patients are allowed too)
    within the location. For example, all front desk staff.
     */
  name: string;
  /** System level and defined flags. Very little should ever see let alone be able to set these.
   */
  flags: number;
  members: UserIdList;
};
export type LocationGroups = {
  [key: string]: LocationGroupListItem;
};
export type LocationPreferences = {
  phrases: PhrasePreferences;
  labs?: LocationBookmarkedLabs;
  prenatal_test_sequence?: object;
  groups?: LocationGroups;
};
export type LocationDosespotMeta = {
  clinic_id?: number;
  clinic_key?: string;
};
export type LocationMetadata = {
  dosespot?: LocationDosespotMeta;
};
export type LocationModel = {
  /** System-assigned unique location identifier.
   */
  location_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** User ID of the user who owns the practice or location. An owner has extra priviliges. Every practice
    or location has one and only one owner, and by definition this user can see, modify or create anything
    within that practice / location. However, just because a user is an owner of one practice does not
    infer any rights or priviliges too other practices. Likewise a location owner has no extra powers
    outside of their location.
     */
  owner_id: number;
  /** The name of the location. There is no requirement that this be unique as locations are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  location_type: LocationType;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city: string;
  state: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip: string;
  fields?: object;
  preferences: LocationPreferences;
  metadata: LocationMetadata;
  /** A timezone in the form of a string. This is a standard timezone string, such as 'America/New_York'.
   */
  timezone: string;
  /** Tax identification number.
   */
  tin?: string;
  /** Clinical Laboratory Improvement Amendments ID. CLIA is a federal regulation that applies to labs in the
    United States.
     */
  clia?: string;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
};
export type PhraseIndices = 0 | 1 | 2;
export type PregnancyModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  state: PregnancyState;
  /** Foreign key to the estimated_due_date table. This is the most recent.
   */
  active_edd_id?: string;
  /** Foreign key to the estimated_due_date table. This is the LMP estimate.
    It can be the same as active_edd_id.
     */
  lmp_edd_id?: string;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: boolean;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type ProblemType = "NOTE" | "DIAGNOSIS";
export type ProblemStatus = "ACTIVE" | "ARCHIVED" | "DELETED";
export type ProblemModel = {
  /** System-assigned identifier for a problem.
   */
  problem_id: number;
  problem_type: ProblemType;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  status: ProblemStatus;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  created_by: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  archived?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  archived_by?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  deleted?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  deleted_by?: number;
  date_of_onset?: string;
};
export type ProblemNote = {
  /** Optional note limited to 4K in length.
   */
  content: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  at: number;
};
export type ProblemDiagnosisModel = {
  /** System-assigned identifier for a problem.
   */
  problem_id: number;
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  note_history?: ProblemNote[];
};
export type ProblemNoteModel = {
  /** System-assigned identifier for a problem.
   */
  problem_id: number;
  title: string;
  note_history?: ProblemNote[];
};
export type ProviderHgMetadata = {
  /** A URL identifying a FHIR-R4 Resource in Health Gorilla's systems.
   */
  resource_url?: string;
};
export type ProviderLocations = LocationId[];
export type ProviderMetadata = {
  health_gorilla?: ProviderHgMetadata;
  locations?: ProviderLocations;
};
export type ProviderModel = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  preferences?: object;
  metadata?: ProviderMetadata;
};
export type TemplateChannel = "SMS" | "EMAIL" | "VOICE";
export type TemplateModel = {
  /** System-assigned unique ID of a template. This is used as a table linkage.
   */
  template_id: number;
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  id: number;
  /** The name of the communications template. This name must be unique across the group.
   */
  name: string;
  /** System-assigned version associated with a template. Each time a template is edited the version
    number is incremented. This allows us to retrieve the exact contents of a message at any point
    in time.
     */
  version: number;
  channel: TemplateChannel;
  /** Brief description of a communications template. This is rendered in the user interface in a fairly small
    space so brevity is encouraged. This may also be used as a tooltip.
     */
  description?: string;
  /** If a template requires it, the message subject text. This can contain field variables.
   */
  subject?: string;
  /** The body text of the communication template. This can contain template expansion variables that the
    system replaces before sending the communication.
     */
  body: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
};
export type FormTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
};
export type FileTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Unique file ID to reference a file stored in S3.
   */
  template_file_id: number;
  /** Unique file ID to reference a file stored in S3.
   */
  submitted_file_id?: number;
};
export type UploadTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Text of a generic task.
   */
  prompt: string;
};
export type GenericTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Title of a generic task.
   */
  task_title: string;
  /** Text of a generic task.
   */
  task_text: string;
};
export type TaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  task_type: TaskType;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  assigner: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  assignee: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  associated_patient?: number;
  /** System-assigned ID of a patient task.
   */
  parent_task?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created_at: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated_at: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  completed_at?: number;
  form?: FormTaskModel;
  file?: FileTaskModel;
  upload?: UploadTaskModel;
  generic?: GenericTaskModel;
};
export type GsAndPs = {
  /** The number of times the patient has been pregnant, regardless of
    the outcome of the pregnancies.
     */
  gravida: number;
  /** The number of times the patient has given birth to a baby at or
    after 37 weeks gestation.
     */
  term_births: number;
  /** The number of times the patient has given birth to a baby before
    37 weeks gestation.
     */
  preterm_births: number;
  /** The number of times the patient has had a miscarriage or abortion.
   */
  abortions_miscarriages: number;
  /** The number of living children the patient has.
   */
  living_children: number;
};
export type LabOrderUrgency = "ROUTINE" | "URGENT" | "ASAP" | "STAT";
export type LabOrderBillRecipient =
  | "CLIENT"
  | "PATIENT"
  | "GUARANTOR"
  | "THIRD_PARTY";
export type LabOrderResults = Dict[];
export type LabOrderMetadata = {
  hg_request_group_url?: string;
  hg_request_result_url?: string;
};
export type LabOrderCorrection = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id?: string;
  change?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  when?: string;
};
export type LabOrderCorrections = LabOrderCorrection[];
export type LabOrderModel = {
  /** An ID for a lab order.
   */
  order_id: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** A human-readable label for the order.
   */
  label: string;
  /** A note on the order to be sent along to the servicing lab.
   */
  note?: string;
  status: LabOrderStatus;
  urgency: LabOrderUrgency;
  bill_recipient: LabOrderBillRecipient;
  /** A note sent back from the lab to be displayed at the order level.
   */
  result_note?: string;
  specimen_collected?: string;
  fasting: boolean;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id: string;
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id?: string;
  request?: object;
  results?: LabOrderResults;
  requisition_pdf_s3_key?: string;
  result_pdf_s3_key?: string;
  abn_pdf_s3_key?: string;
  metadata: LabOrderMetadata;
  corrections?: LabOrderCorrections;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  sent?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  fulfilled?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed?: string;
};
export type LabTestModel = {
  /** An ID for a lab test.
   */
  test_id: string;
  /** An ID for a lab order.
   */
  order_id: string;
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Human-readable version of a lab code.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_to_lab?: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_from_lab?: string;
  status?: LabOrderStatus;
};
export type LabObservationModel = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id: string;
  status: LabObservationStatus;
  /** An ID for a lab test.
   */
  test_id: string;
  /** Health Gorilla ID for an observation from lab results.
   */
  hg_id?: string;
  code: string;
  display: string;
  value?: string;
  reference_range?: string;
  units?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  issued?: string;
  performing_site?: PerformingSite;
  interpretation?: string;
  note?: string;
};
export type ClaimFileType = "HCFA" | "EOB";
export type AuthResource =
  | "annotation"
  | "appointment"
  | "claim"
  | "contact"
  | "cpt"
  | "edd"
  | "encounter"
  | "event"
  | "fax"
  | "file"
  | "form"
  | "group"
  | "health_history"
  | "icd"
  | "lab_hg"
  | "lablocation"
  | "labor_event"
  | "location"
  | "message"
  | "note"
  | "order"
  | "patient"
  | "payor"
  | "pharmacy"
  | "practice"
  | "pregnancy"
  | "problem"
  | "provider"
  | "recall"
  | "role"
  | "staff"
  | "task"
  | "template"
  | "transaction"
  | "user"
  | "manual_flowsheet_entry"
  | "task_author";
export type SystemTypes = {
  acog_test_model?: AcogTestModel;
  acog_observation_model?: AcogObservationModel;
  claim_model?: ClaimModel;
  cookie?: Cookie;
  appointment_model?: AppointmentModel;
  transactions_history_model?: TransactionsHistoryModel;
  estimated_due_date?: EstimatedDueDateModel;
  encounter_model?: EncounterModel;
  file_model?: FileModel;
  fax_inbound_model?: FaxInboundModel;
  form_model?: FormModel;
  icd10_model?: Icd10Model;
  inbox_model?: InboxModel;
  manual_lab_flowsheet_entry_observation_model?: ManualLabFlowsheetEntryObservationModel;
  message_flags?: MessageFlags;
  messages_model?: MessagesModel;
  nabla_preferences?: NablaPreferences;
  practice_model?: PracticeModel;
  outbox_model?: OutboxModel;
  patient_model?: PatientModel;
  location_model?: LocationModel;
  phrase_indices?: PhraseIndices;
  pregnancy_model?: PregnancyModel;
  problem_model?: ProblemModel;
  problem_diagnosis_model?: ProblemDiagnosisModel;
  problem_note_model?: ProblemNoteModel;
  provider_model?: ProviderModel;
  template_model?: TemplateModel;
  user_id_list?: UserIdList;
  user_model?: UserModel;
  user_metadata?: UserMetadata;
  task_model?: TaskModel;
  gs_and_ps?: GsAndPs;
  lab_order_model?: LabOrderModel;
  lab_test_model?: LabTestModel;
  lab_observation_model?: LabObservationModel;
  claim_file_type?: ClaimFileType;
  resource?: AuthResource;
  permission?: AuthPermission;
};
export const {
  useUserCreateMutation,
  useUserUpdateMutation,
  useUserGetInfoQuery,
  useLazyUserGetInfoQuery,
  useUserGetNotificationsQuery,
  useLazyUserGetNotificationsQuery,
  useUserPasswordResetMutation,
  useUserSelectAuthQuery,
  useLazyUserSelectAuthQuery,
  useDummyQuery,
  useLazyDummyQuery
} = injectedRtkApi;
