/* DateBadge Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";

/* Local Imports */

// components

// constants

// store

// styles
import styles from "../styles.module.scss";

/* DateBadge Typescript Interface */
interface DateBadgeProps {
  timestamp: string;
  isSelected: boolean;
}

export default function DateBadge({ timestamp, isSelected }: DateBadgeProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.DateBadge)}>
      <p
        className={clsx(styles.date, {
          [styles.highlighted]:
            timestamp === dayjs().startOf("day").toISOString(),
          [styles.selected]: isSelected,
        })}
      >
        {dayjs(timestamp).date()}
      </p>
    </div>
  );
}
