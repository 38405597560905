import * as generated from "./generated/encounter";

const encounter = generated.encounter.enhanceEndpoints({
  addTagTypes: ["Encounter"],
  endpoints: {
    encounterInfo: {
      providesTags: (_resp, _err, req) => {
        return [{ type: "Encounter", id: req.encounterId }];
      }
    },
    encounterUpdate: {
      invalidatesTags: ["Encounter", "InfantFlowSheet"]
    },
    encounterSubmit: {
      invalidatesTags: ["Encounter", "Claim", "ClaimOutcome"]
    },
    encounterCreate: {
      invalidatesTags: ["Encounter"]
    },
    encounterDelete: {
      invalidatesTags: ["Encounter", "Appointments"]
    },
    encounterList: {
      providesTags: ["Encounter"]
    },
    encounterLaborEventUpsert: {
      invalidatesTags: ["Patients"]
    },
    encounterLaborUpsert: {
      invalidatesTags: ["Patients"]
    },
    encounterLaborInfo: {
      providesTags: ["Patients"]
    },
    encounterLaborEventDelete: {
      invalidatesTags: ["Patients"]
    },
    encounterLaborRecurringEventCreate: {
      invalidatesTags: ["Patients"]
    },
    encounterLaborRecurringEventUpdate: {
      invalidatesTags: ["Patients"]
    },
    encounterLaborRecurringEventDelete: {
      invalidatesTags: ["Patients"]
    },
    encounterLaborSheet: {
      providesTags: ["Patients"]
    },
    encounterCreateFromAppointment: {
      invalidatesTags: ["Encounter", "Appointments"]
    }
  }
});

export * from "./generated/encounter";
export default encounter;
export const {
  useEncounterCreateMutation,
  useEncounterListQuery,
  useLazyEncounterListQuery,
  useEncounterUpdateMutation,
  useEncounterListVersionsQuery,
  useLazyEncounterListVersionsQuery,
  useEncounterInfoQuery,
  useLazyEncounterInfoQuery,
  useEncounterSubmitMutation,
  useEncounterDeleteMutation,
  useEncounterLaborEventDeleteMutation,
  useEncounterLaborEventUpsertMutation,
  useEncounterLaborInfoQuery,
  useEncounterLaborRecurringEventCreateMutation,
  useEncounterLaborRecurringEventDeleteMutation,
  useEncounterLaborRecurringEventUpdateMutation,
  useEncounterLaborSheetQuery,
  useEncounterLaborUpsertMutation,
  useLazyEncounterLaborInfoQuery,
  useLazyEncounterLaborSheetQuery
} = encounter;
