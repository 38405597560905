import Noteform from "@/components/forms/_labor/note";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import {
  EncounterInfo,
  IdType,
  LaborEvent,
  useEncounterLaborRecurringEventUpdateMutation
} from "@/store/services/encounter";
import { useDispatch } from "react-redux";
import { setModalIsOpen } from "../modalSlice";
import { STATUS_KEYS } from "@/globals/constants";

interface EditLaborSoapNoteProps {
  selectedStage: string;
  encounter: EncounterInfo;
  editingEvent: LaborEvent;
}

export default function EditLaborSoapNote({
  selectedStage,
  encounter,
  editingEvent
}: EditLaborSoapNoteProps) {
  const [updateRecurringEvent] =
    useEncounterLaborRecurringEventUpdateMutation();
  const dispatch = useDispatch();
  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };
  const handleUpdateRecurringEvent = (
    formData: any,
    laborEventId: string,
    scope: IdType
  ) => {
    const newFormData = {
      ...formData,
      start_time: formData.start_time
    };
    const laborEventUpdate = {
      event_id: laborEventId,
      forms: newFormData,
      start: parseInt(formData.start_time)
    };

    const payload = {
      encounterId: encounter.encounter_id,
      stage: selectedStage,
      laborEventId,
      scope,
      /** Payload for creating/updating a labor.
       */
      laborEventUpdate
    };
    updateRecurringEvent(payload)
      .unwrap()
      .then(res => {
        launchToast(`Labor successfully updated`, STATUS_KEYS.SUCCESS);
        dispatch(setModalIsOpen(false));
      })
      .catch(err =>
        launchToast(`Oops! Labor could not be updated`, STATUS_KEYS.ERROR)
      );
  };

  return (
    <Noteform
      onSubmit={v => handleUpdateRecurringEvent(v, editingEvent.event_id, "note")}
      defaultValues={editingEvent.forms}
    />
  );
}
