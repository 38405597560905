import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    patientGetList: build.query<
      PatientGetListApiResponse,
      PatientGetListApiArg
    >({
      query: queryArg => ({
        url: `/patient/list`,
        params: {
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          query: queryArg.query,
          type: queryArg["type"],
          order_by: queryArg.orderBy,
          ordering: queryArg.ordering
        }
      })
    }),
    practiceCreate: build.mutation<
      PracticeCreateApiResponse,
      PracticeCreateApiArg
    >({
      query: queryArg => ({
        url: `/practice/create`,
        method: "POST",
        body: queryArg.practiceModel
      })
    }),
    practiceList: build.query<PracticeListApiResponse, PracticeListApiArg>({
      query: queryArg => ({
        url: `/practice/list`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    practiceUpdate: build.mutation<
      PracticeUpdateApiResponse,
      PracticeUpdateApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/update`,
        method: "POST",
        body: queryArg.practiceModel
      })
    }),
    practiceInfo: build.query<PracticeInfoApiResponse, PracticeInfoApiArg>({
      query: queryArg => ({ url: `/practice/${queryArg.practiceId}/info` })
    }),
    practiceAppointmentsExport: build.query<
      PracticeAppointmentsExportApiResponse,
      PracticeAppointmentsExportApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/appointments/export`,
        params: {
          tz: queryArg.tz,
          location_id: queryArg.locationId,
          patient_id: queryArg.patientId,
          provider_id: queryArg.providerId,
          start: queryArg.start,
          end: queryArg.end,
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          status_in: queryArg.statusIn,
          status_not_in: queryArg.statusNotIn
        }
      })
    }),
    practiceAddUser: build.mutation<
      PracticeAddUserApiResponse,
      PracticeAddUserApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/add`,
        method: "POST",
        body: queryArg.practiceAddMember
      })
    }),
    practiceInvite: build.mutation<
      PracticeInviteApiResponse,
      PracticeInviteApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/invite`,
        method: "POST",
        body: queryArg.practiceInviteMember
      })
    }),
    practiceGetForms: build.query<
      PracticeGetFormsApiResponse,
      PracticeGetFormsApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/forms`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    practiceGetTemplates: build.query<
      PracticeGetTemplatesApiResponse,
      PracticeGetTemplatesApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/templates`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    practiceGetNotifications: build.query<
      PracticeGetNotificationsApiResponse,
      PracticeGetNotificationsApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/notifications`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    practiceGetProviders: build.query<
      PracticeGetProvidersApiResponse,
      PracticeGetProvidersApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/providers/list`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    practiceListAppointmentTypes: build.query<
      PracticeListAppointmentTypesApiResponse,
      PracticeListAppointmentTypesApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/appointment-types/list`
      })
    }),
    practiceSearchUsers: build.query<
      PracticeSearchUsersApiResponse,
      PracticeSearchUsersApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/user-search`,
        params: { scope: queryArg.scope }
      })
    }),
    practiceListRoles: build.query<
      PracticeListRolesApiResponse,
      PracticeListRolesApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/roles`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    practiceCreateRole: build.mutation<
      PracticeCreateRoleApiResponse,
      PracticeCreateRoleApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/role/create`,
        method: "POST",
        body: queryArg.roleModel
      })
    }),
    practiceUpdateRole: build.mutation<
      PracticeUpdateRoleApiResponse,
      PracticeUpdateRoleApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/role/${queryArg.roleId}/update`,
        method: "POST",
        body: queryArg.roleModel
      })
    }),
    practiceEditRoles: build.query<
      PracticeEditRolesApiResponse,
      PracticeEditRolesApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/role/${queryArg.roleId}/delete`
      })
    }),
    practiceAssignUserRole: build.mutation<
      PracticeAssignUserRoleApiResponse,
      PracticeAssignUserRoleApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/role/${queryArg.userId}/user-assign`,
        method: "POST",
        body: queryArg.rolesList
      })
    }),
    practiceCreateContact: build.mutation<
      PracticeCreateContactApiResponse,
      PracticeCreateContactApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/contact/create`,
        method: "POST",
        body: queryArg.createContactRequest
      })
    }),
    practiceUpdateContact: build.mutation<
      PracticeUpdateContactApiResponse,
      PracticeUpdateContactApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/contact/${queryArg.contactId}/update`,
        method: "POST",
        body: queryArg.updateContactRequest
      })
    }),
    practiceDeleteContacts: build.mutation<
      PracticeDeleteContactsApiResponse,
      PracticeDeleteContactsApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/contact/${queryArg.contactId}/delete`,
        method: "DELETE"
      })
    }),
    practiceListContacts: build.query<
      PracticeListContactsApiResponse,
      PracticeListContactsApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/contact/list`
      })
    }),
    practiceGetLabOrders: build.query<
      PracticeGetLabOrdersApiResponse,
      PracticeGetLabOrdersApiArg
    >({
      query: queryArg => ({
        url: `/practice/${queryArg.practiceId}/orders`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    tagCreate: build.mutation<TagCreateApiResponse, TagCreateApiArg>({
      query: queryArg => ({
        url: `/tags/${queryArg.practiceId}/create`,
        method: "POST",
        body: queryArg.tagCreateOrUpdatePayload
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as practice };
export type PatientGetListApiResponse =
  /** status 200 Abbreviated list of patients suitable for dropdown list population.
   */ PatientList;
export type PatientGetListApiArg = {
  page?: Page;
  pagesz?: PageSize;
  query?: string;
  type?: string;
  orderBy?:
    | "EDD"
    | "dob"
    | "name"
    | "pregnancy_delivery_date"
    | "desired_birthplace";
  ordering?: "ASC" | "DESC";
};
export type PracticeCreateApiResponse =
  /** status 200 Sent in response to a successful practice create API call.
   */ PracticeId;
export type PracticeCreateApiArg = {
  /** Create a new practice and assign its owner. The owner must already have been created through
    the users API. If the user ID provided has no primary practice assigned to it, this call will
    update the user record and make this practice their primary practice.
     */
  practiceModel: PracticeModel;
};
export type PracticeListApiResponse =
  /** status 200 Abbreviated list of practices suitable for dropdown list population.
   */ PracticeList;
export type PracticeListApiArg = {
  page?: Page;
  pagesz?: PageSize;
};
export type PracticeUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeUpdateApiArg = {
  practiceId: PracticeId;
  /** Create a new practice and assign its owner. The owner must already have been created through
    the users API. If the user ID provided has no primary practice assigned to it, this call will
    update the user record and make this practice their primary practice.
     */
  practiceModel: PracticeModel;
};
export type PracticeInfoApiResponse =
  /** status 200 Sent to describe a single practice in detail.
   */ PracticeModel;
export type PracticeInfoApiArg = {
  practiceId: PracticeId;
};
export type PracticeAppointmentsExportApiResponse =
  /** status 200 The practice appointment export PDF.
   */ EncounterExport200Response;
export type PracticeAppointmentsExportApiArg = {
  practiceId: PracticeId;
  tz?: Timezone;
  locationId?: LocationId;
  patientId?: UserId;
  providerId?: UserId;
  /** Start date for searching for a range of dates, including this date.
   */
  start?: Timestamp;
  /** End date for searching for a range of dates, including this date.
   */
  end?: Timestamp;
  page?: Page;
  pagesz?: PageSize;
  /** Statuses to include in search.
   */
  statusIn?: AppointmentStatus[];
  /** Statuses to exclude from search.
   */
  statusNotIn?: AppointmentStatus[];
};
export type PracticeAddUserApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeAddUserApiArg = {
  practiceId: PracticeId;
  /** Add an existing user by `user_id` to the requesting practice.
   */
  practiceAddMember: PracticeAddMember;
};
export type PracticeInviteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeInviteApiArg = {
  practiceId: PracticeId;
  /** Invite an external user by email to join Pario and the requesting practice.
   */
  practiceInviteMember: PracticeInviteMember;
};
export type PracticeGetFormsApiResponse =
  /** status 200 Abbreviated list of forms for the specified entity (practice or location).
   */ FormList;
export type PracticeGetFormsApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type PracticeGetTemplatesApiResponse =
  /** status 200 Abbreviated list of templates suitable for dropdown list population.
   */ TemplateList;
export type PracticeGetTemplatesApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type PracticeGetNotificationsApiResponse =
  /** status 200 A list of notifications for a practice or location.
   */ NotificationList;
export type PracticeGetNotificationsApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type PracticeGetProvidersApiResponse =
  /** status 200 Abbreviated list of providers suitable for dropdown list population.
   */ ProviderList;
export type PracticeGetProvidersApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type PracticeListAppointmentTypesApiResponse =
  /** status 200 List of practice types suitable for dropdown list population;
   */ AppointmentTypeList;
export type PracticeListAppointmentTypesApiArg = {
  practiceId: PracticeId;
};
export type PracticeSearchUsersApiResponse =
  /** status 200 Sent in response to a successful user search across a practice.
   */ PracticeUserSearchResults;
export type PracticeSearchUsersApiArg = {
  practiceId: PracticeId;
  /** Which type of ID does the previous ID element refer to.
   */
  scope?: IdType;
};
export type PracticeListRolesApiResponse =
  /** status 200 List of practice roles suitable for dropdown list population.
   */ RoleList;
export type PracticeListRolesApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type PracticeCreateRoleApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeCreateRoleApiArg = {
  practiceId: PracticeId;
  /** Create a new role within a practice and assign its permissions.
   */
  roleModel: RoleModel;
};
export type PracticeUpdateRoleApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeUpdateRoleApiArg = {
  practiceId: PracticeId;
  roleId: RoleId;
  /** Create a new role within a practice and assign its permissions.
   */
  roleModel: RoleModel;
};
export type PracticeEditRolesApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeEditRolesApiArg = {
  practiceId: PracticeId;
  roleId: RoleId;
};
export type PracticeAssignUserRoleApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeAssignUserRoleApiArg = {
  practiceId: PracticeId;
  userId: UserId;
  /** Assign one or more roles to a user within a practice. Specifying a negative value for a role will instead
    remove that role from the user.
     */
  rolesList: RolesList;
};
export type PracticeCreateContactApiResponse =
  /** status 200 Sent in response to successfully creating a practice contact.
   */ ContactModel;
export type PracticeCreateContactApiArg = {
  practiceId: PracticeId;
  /** Create a contact at a practice.
   */
  createContactRequest: CreateContactRequest;
};
export type PracticeUpdateContactApiResponse =
  /** status 200 Sent in response to successfully creating a practice contact.
   */ ContactModel;
export type PracticeUpdateContactApiArg = {
  practiceId: PracticeId;
  contactId: string;
  /** Update a contact at a practice.
   */
  updateContactRequest: UpdateContactRequest;
};
export type PracticeDeleteContactsApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PracticeDeleteContactsApiArg = {
  practiceId: PracticeId;
  contactId: string;
};
export type PracticeListContactsApiResponse =
  /** status 200 Sent in response to successfully listing the contacts at a practice.
   */ PracticeContacts;
export type PracticeListContactsApiArg = {
  practiceId: PracticeId;
};
export type PracticeGetLabOrdersApiResponse =
  /** status 200 Sent in response to successfully listing the orders at a practice.
   */ LabOrderInfo[];
export type PracticeGetLabOrdersApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type TagCreateApiResponse =
  /** status 200 This is sent in reply to a successful tag create request.
   */ TagId;
export type TagCreateApiArg = {
  practiceId: PracticeId;
  /** Create or update a tag for a location or a practice.
   */
  tagCreateOrUpdatePayload: TagCreateOrUpdatePayload;
};
export type PatientType =
  | "PROSPECTIVE"
  | "OB"
  | "GYN"
  | "INACTIVE"
  | "INFANT"
  | "PEDIATRIC"
  | "POSTPARTUM"
  | "LACTATION"
  | "PRIMARY_CARE";
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type Address = {
  /** Address line 1. Mandatory. This can include the street number.
   */
  line1?: string;
  /** Optional line 2 of 3 for an address.
   */
  line2?: string;
  /** Optional line 3 of 3 for an address.
   */
  line3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** This is the optional, human-readable representation of the address. This is used
    when communicating addresses that come from sources that don't parse their addresses
    down into its constituent parts, like Health Gorilla.
     */
  display?: string;
};
export type EddMethod = "LMP" | "ULTRASOUND" | "DATE_OF_CONCEPTION" | "UNKNOWN";
export type EstimatedDueDate = {
  method: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
};
export type Lmp = {
  last_menstrual_period?: string;
  estimate_id?: string;
};
export type PregnancyState =
  | "GESTATING"
  | "DELIVERED"
  | "MISCARRIED"
  | "STILLBORN"
  | "ECTOPIC"
  | "TERMINATED"
  | "POSTPARTUM"
  | "UNSPECIFIED"
  | "DELETED";
export type PrenatalFlowSheetEntry = {
  weeks?: string;
  days?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  encounter_start?: number;
  weight?: number;
  blood_pressure?: string;
  temperature?: number;
  fundus?: string;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  note?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
};
export type PrenatalFlowSheet = PrenatalFlowSheetEntry[];
export type LabOrderId = string;
export type LabOrders = LabOrderId[];
export type UserId = number;
export type UserIdList = UserId[];
export type LaborEvent = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: UserIdList;
  forms?: object;
};
export type LaborStage = {
  events: {
    [key: string]: LaborEvent;
  };
  progress_updates: LaborEvent[];
  medications: LaborEvent[];
  infant_progress_updates?: LaborEvent[];
  infant_medications?: LaborEvent[];
  recurring_events?: LaborEvent[];
  notes: LaborEvent[];
};
export type LaborModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  transfer?: object;
  stages: {
    [key: string]: LaborStage;
  };
  note?: string;
};
export type Sex = "MALE" | "FEMALE" | "UNKNOWN";
export type PregnancyOutcome =
  | "UNKNOWN"
  | "TAB (therapeautic abortion)"
  | "EAB (elective abortion)"
  | "SAB (miscarriage)"
  | "SBVD (stillbirth vaginal delivery)"
  | "SBCS (stillbirth cesarean delivery)"
  | "NSVD (vaginal delivery)"
  | "NSVB (vaginal birth)"
  | "VAVD (vacuum vaginal delivery)"
  | "FAVD (forceps vaginal delivery)"
  | "CS (cesarean)"
  | "VBAC (vaginal birth after previous cesarean)"
  | "NSVD (vaginal delivery) - Water"
  | "VBAC (vaginal birth after previous cesarean) - Water"
  | "EP (ectopic pregnancy)";
export type MaternityNeighborhoodPregnancyMetadata = {
  episode_of_care_id?: string;
  past_pregnancy_id?: string;
};
export type ClientCarePregnancyMetadata = {
  pregnancy_id?: string;
};
export type PregnancyMetadata = {
  maternity_neighborhood?: MaternityNeighborhoodPregnancyMetadata;
  client_care?: ClientCarePregnancyMetadata;
};
export type Pregnancy = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  edd?: EstimatedDueDate;
  lmp?: Lmp;
  state: PregnancyState;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: boolean;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor_minutes?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type BloodType =
  | "A+"
  | "A-"
  | "B+"
  | "B-"
  | "AB+"
  | "AB-"
  | "O+"
  | "O-"
  | "UNKNOWN";
export type Diabetes = "TYPE_1" | "TYPE_2" | "GESTATIONAL" | "NONE";
export type HivStatus = "POSITIVE" | "NEGATIVE" | "UNKNOWN";
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type AllergySeverity = "MILD" | "MODERATE" | "SEVERE" | "FATAL";
export type Allergy = {
  icd10_code_details?: Icd10CodeDetails;
  name?: string;
  reaction?: string;
  severity?: AllergySeverity;
  notes?: string;
  date_of_onset?: string;
  allergy_id?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Allergies = Allergy[];
export type Immunization = {
  name: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt: number;
  /** A Pario date in YYYYMMDD format.
   */
  date?: number;
  notes?: string;
};
export type Immunizations = Immunization[];
export type MedicationStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "NONCOMPLIANT"
  | "DELETED";
export type Medication = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  lexicomp_product_id?: number;
  lexicomp_drug_id?: string;
  lexicomp_synonym_id?: number;
  lexicomp_synonym_type?: number;
  dosespot_id?: number;
  days_supply?: number;
  is_prescription?: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Medications = {
  [key: string]: Medication;
};
export type Pregnancies = Pregnancy[];
export type AntibodyScreenDat = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met1 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met2 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type UserMedicalHistory = {
  blood_type?: BloodType;
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  diabetic?: Diabetes;
  hiv_status?: HivStatus;
  allergies?: Allergies;
  immunizations?: Immunizations;
  medications?: Medications;
  pregnancies?: Pregnancies;
  no_known_drug_allergies?: boolean;
  no_known_medications?: boolean;
  no_known_problems?: boolean;
  antibody_screen_DAT?: AntibodyScreenDat;
  met_1?: Met1;
  met_2?: Met2;
};
export type PatientListItem = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  type: PatientType;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  title?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  address?: Address;
  pregnancy?: Pregnancy;
  medical_history: UserMedicalHistory;
  signup_completed: boolean;
};
export type PatientList = {
  patients: PatientListItem[];
  total: number;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type Page = number;
export type PageSize = number;
export type PracticeId = number;
export type PracticeFeatureFlags = {
  /** Whether or not lab ordering is enabled.
   */
  lab_ordering_enabled: boolean;
  /** Whether or not claim lifecycle management is enabled.
   */
  clm_enabled: boolean;
  /** Whether or not infant charting is enabled.
   */
  infant_charting_enabled: boolean;
  /** Whether or not pronouns are enabled.
   */
  pronouns_enabled: boolean;
  /** Whether or not the rx feature / DoseSpot is enabled.
   */
  rx_enabled: boolean;
  /** Whether or not the messaging feature is enabled.
   */
  messaging_enabled: boolean;
  /** Whether or not labor charting is enabled.
   */
  labor_charting_enabled: boolean;
  /** Whether or not postpartum charting is enabled.
   */
  postpartum_charting_enabled: boolean;
  /** Whether or not prenatal charting is enabled.
   */
  prenatal_charting_enabled: boolean;
  /** Whether or not primary care patient charting is enabled.
   */
  primary_care_charting_enabled: boolean;
  /** Whether or not patient balances are enabled.
   */
  patient_balances_enabled: boolean;
  /** Whether or not male patients are enabled.
   */
  male_patients_enabled: boolean;
  /** Whether or not medical coding v2 is enabled.
   */
  medical_coding_v2_enabled: boolean;
  /** Whether or not multi-provider scheduling is enabled.
   */
  multi_provider_schedule_enabled: boolean;
  /** Whether or not patient recall is enabled.
   */
  patient_recall_enabled: boolean;
  /** Whether or not patient messaging on the facepage is enabled.
   */
  patient_messaging_enabled: boolean;
  /** Whether or not lab flowsheets are enabled.
   */
  lab_flowsheets_enabled: boolean;
  /** Whether or not the all appointments homepage view is enabled.
   */
  all_appointments_homepage_view_enabled: boolean;
  /** Whether or not patient checkout is enabled.
   */
  patient_checkout_enabled: boolean;
  /** Whether or not appointment reminders are enabled.
   */
  send_appointment_reminders_enabled: boolean;
  /** Whether or not the accessible calendar is enabled.
   */
  accessible_calendar_enabled: boolean;
  /** Whether or not auto-submit claim is enabled.
   */
  auto_submit_claim_enabled: boolean;
  /** Whether or not welcome emails are enabled.
   */
  welcome_emails_enabled: boolean;
};
export type OwnerId = number;
export type PracticeName = string;
export type Phone = string;
export type Email = string;
export type AddrLine1 = string;
export type AddrLine2 = string;
export type AddrLine3 = string;
export type City = string;
export type Zipcode = string;
export type Dict = object;
export type PhraseModel = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  phrase: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** Expansion for a dot-phrase.
   */
  text: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  last_editor: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_edited: number;
};
export type PhrasePreferences = {
  [key: string]: PhraseModel;
};
export type LaborMedications = string[];
export type ContactModel = {
  /** ULID identifying the contact */
  contact_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  name: string;
  description?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
};
export type PracticeContacts = ContactModel[];
export type PracticePreferences = {
  phrases: PhrasePreferences;
  labor_medications: LaborMedications;
  contacts?: PracticeContacts;
  default_patient_list_type?: PatientType;
};
export type PracticeMetaRole = {
  /** The name of the role. There is no requirement that this be unique as roles are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  /** A set of permission bits. The highest bit is never used.
   */
  perms: number;
};
export type PracticeMetaRoles = {
  [key: string]: PracticeMetaRole;
};
export type PracticeLabAccount = {
  account_code?: string;
  account_id: string;
  enabled: boolean;
};
export type PracticeLabAccounts = {
  [key: string]: PracticeLabAccount;
};
export type HgEnvironment = "PRODUCTION" | "SANDBOX";
export type PracticeHgMeta = {
  oauth_sub?: string;
  org_id?: string;
  accounts?: PracticeLabAccounts;
  env?: HgEnvironment;
};
export type PracticeMetadata = {
  roles: PracticeMetaRoles;
  health_gorilla?: PracticeHgMeta;
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  medical_history_form_id?: number;
};
export type DesiredBirthplaces = string[];
export type AltLocations = string[];
export type CalendarColorCoding = "appointment_type" | "provider_id";
export type PracticeModel = PracticeFeatureFlags & {
  practice_id: PracticeId;
  owner_id: OwnerId;
  name: PracticeName;
  phone?: Phone;
  fax?: Phone;
  email: Email;
  addr_line_1: AddrLine1;
  addr_line_2?: AddrLine2;
  addr_line_3?: AddrLine3;
  city: City;
  state: Statecode;
  zip: Zipcode;
  fields?: Dict;
  preferences: PracticePreferences;
  metadata: PracticeMetadata;
  desired_birthplaces: DesiredBirthplaces;
  alt_locations: AltLocations;
  calendar_color_coding: CalendarColorCoding;
};
export type PracticeListItem = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** The name of the practice. There is no requirement that this be unique as practice are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1: string;
  state: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
};
export type PracticeList = PracticeListItem[];
export type ErrorId = number;
export type EncounterExport200Response = {
  content: string;
};
export type Timezone = string;
export type LocationId = number;
export type Timestamp = number;
export type AppointmentStatus =
  | "SCHEDULED"
  | "CONFIRMED"
  | "CANCELED"
  | "NO_SHOW"
  | "CHECKED_IN"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "CHECKED_OUT"
  | "RESCHEDULED";
export type PracticeAddMember = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  practice_role: number;
};
export type PracticeInviteMember = {
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email: string;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last: string;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
};
export type FormListItem = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  /** Name of a form or field within a practice or location. Must be a valid HTML id / Javascript variable name and
    it must be unique within the containing entity.
     */
  name: string;
  /** Form title. This will be displayed to the user and included in reports.
   */
  title: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
};
export type FormList = FormListItem[];
export type TemplateChannel = "SMS" | "EMAIL" | "VOICE";
export type TemplateListItem = {
  /** System-assigned unique ID of a template. This is used as a table linkage.
   */
  id?: number;
  /** The name of the communications template. This name must be unique across the group.
   */
  name?: string;
  channel?: TemplateChannel;
  /** Brief description of a communications template. This is rendered in the user interface in a fairly small
    space so brevity is encouraged. This may also be used as a tooltip.
     */
  description?: string;
};
export type TemplateList = TemplateListItem[];
export type IdType =
  | "form"
  | "group"
  | "message"
  | "practice"
  | "patient"
  | "medical_assistant"
  | "administrative"
  | "biller"
  | "location"
  | "pregnancy"
  | "provider"
  | "template"
  | "user"
  | "medication"
  | "progress_update"
  | "note"
  | "infant_medication"
  | "infant_progress_update"
  | "recurring_event";
export type NotificationStatus = "INITIATED" | "SENT" | "DELIVERED" | "FAILED";
export type NotificationModel = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user?: number;
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  id?: number;
  id_type?: IdType;
  status?: NotificationStatus;
  /** Rendered template that was included in the notification.
   */
  rendered?: string;
};
export type NotificationList = NotificationModel[];
export type ProviderListItem = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
};
export type ProviderList = ProviderListItem[];
export type EncounterType =
  | "WELL_WOMAN"
  | "PRENATAL"
  | "LABOR"
  | "POSTPARTUM"
  | "INFANT"
  | "PHONE";
export type TaskType = "FORM" | "FILE" | "UPLOAD" | "GENERIC" | "DEMOGRAPHICS";
export type AppointmentTask = {
  display: string;
  /** System-assigned ID of a patient task.
   */
  task_id?: number;
  task_type: TaskType;
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  resource_id: number;
};
export type AppointmentTasks = AppointmentTask[];
export type AppointmentTypeDetails = {
  appointment_type: string;
  encounter_type: EncounterType;
  default_duration: number;
  /** A color hex code to use for this appointment type in the calendar. This is used to differentiate
    between different types of appointments in the calendar view.
     */
  appointment_hex?: string;
  default_tasks?: AppointmentTasks;
};
export type AppointmentTypeList = AppointmentTypeDetails[];
export type ParentIds = UserId[];
export type ProblemStatus = "ACTIVE" | "ARCHIVED" | "DELETED";
export type ProblemType = "NOTE" | "DIAGNOSIS";
export type ProblemInfo = {
  /** System-assigned identifier for a problem.
   */
  id: number;
  type: ProblemType;
  status: ProblemStatus;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  date_of_onset?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  created_by: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  archived?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  archived_by?: number;
};
export type ProblemNote = {
  /** Optional note limited to 4K in length.
   */
  content: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  at: number;
};
export type ProblemDiagnosisDetails = {
  diagnosis: Icd10CodeDetails;
  note?: ProblemNote;
};
export type ProblemDiagnosisInfo = ProblemInfo & ProblemDiagnosisDetails;
export type PatientProblemDiagnosis = {
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  lastUpdated: number;
  status: ProblemStatus;
  title: string;
  instances: ProblemDiagnosisInfo[];
};
export type PatientProblemDiagnoses = PatientProblemDiagnosis[];
export type ProblemNoteDetails = {
  title: string;
  note: ProblemNote;
};
export type ProblemNoteInfo = ProblemInfo & ProblemNoteDetails;
export type PatientProblemNotes = ProblemNoteInfo[];
export type PatientProblems = {
  diagnoses: PatientProblemDiagnoses;
  notes: PatientProblemNotes;
};
export type RelationshipToSubscriber = "SELF" | "SPOUSE" | "GUARDIAN" | "OTHER";
export type Insurance = {
  insurance_id?: string;
  insurance_id_type?: 1 | 2 | 3 | 4 | 5 | 6;
  payor?: string;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  hg_org_id?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  payor_phone?: string;
  plan_type?: string;
  policy_or_group_number?: string;
  subscriber_id?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  subscriber_first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  subscriber_last_name?: string;
  /** A Pario date in YYYYMMDD format.
   */
  subscriber_dob?: number;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  subscriber_ssn?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  subscriber_phone?: string;
  subscriber_address?: Address;
  relationship_to_subscriber?: RelationshipToSubscriber;
};
export type UserInsurance = {
  self_pay?: boolean;
  primary?: Insurance;
  secondary?: Insurance;
  tertiary?: Insurance;
};
export type PatientHgMetadata = {
  /** A URL identifying a FHIR-R4 Resource in Health Gorilla's systems.
   */
  resource_url?: string;
};
export type PatientLocations = LocationId[];
export type MeridianMetadata = {
  meridian_id: string;
};
export type PatientMetadata = {
  health_gorilla?: PatientHgMetadata;
  locations: PatientLocations;
  meridian?: MeridianMetadata;
};
export type PracticeData = {
  type: PatientType;
};
export type Bmi = string;
export type PatientMedicalHistory = UserMedicalHistory & {
  bmi: Bmi;
};
export type RoleId = number;
export type UserPracticeRoles = RoleId[];
export type LocationIds = LocationId[];
export type UserMetaPractice = {
  roles: UserPracticeRoles;
  locations: LocationIds;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_email?: number;
};
export type UserMetaPractices = {
  [key: string]: UserMetaPractice;
};
export type UserMetaLast = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
};
export type UserDosespotMeta = {
  patient_id?: number;
  proxy_clinician_id?: number;
  prescribing_clinician_id?: number;
};
export type UserMetadata = {
  practices: UserMetaPractices;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  last?: UserMetaLast;
  dosespot: UserDosespotMeta;
};
export type UserListItem = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  metadata: UserMetadata;
};
export type UserEmergencyContact = {
  name?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  phone_type?: "Home" | "Work" | "Mobile";
  relationship?: string;
};
export type UserEmergencyContacts = UserEmergencyContact[];
export type PatientInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  parent_ids?: ParentIds;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  sex: Sex;
  address?: Address;
  problems?: PatientProblems;
  location_data?: object;
  form_data?: object;
  insurance?: UserInsurance;
  pregnancy?: Pregnancy;
  metadata?: PatientMetadata;
  practice_data: PracticeData;
  medical_history: PatientMedicalHistory;
  primary_pharmacy_id?: string;
  note?: string;
  secret_note?: string;
  children: UserListItem[];
  emergency_contacts?: UserEmergencyContacts;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  ssn?: string;
  signup_completed: boolean;
};
export type UserInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  metadata: UserMetadata;
  medical_history: UserMedicalHistory;
};
export type PracticeUserSearchResults = {
  patients: PatientInfo[];
  providers: UserInfo[];
  medical_assistants: UserInfo[];
  administrative: UserInfo[];
  billers: UserInfo[];
};
export type RoleListItem = {
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
  /** The name of the role. There is no requirement that this be unique as roles are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  /** A set of permission bits. The highest bit is never used.
   */
  permissions: number;
};
export type RoleList = RoleListItem[];
export type RoleModel = {
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** The name of the role. There is no requirement that this be unique as roles are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  /** A set of permission bits. The highest bit is never used.
   */
  permissions: number;
};
export type RolesList = RoleId[];
export type ContactType = "REFERRING_PHYSICIAN" | "RECEIVING_HOSPITAL";
export type CreateContactRequest = {
  name: string;
  description?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  contact_type?: ContactType;
};
export type UpdateContactRequest = {
  name?: string;
  description?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
};
export type LabOrderPatientInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
};
export type LabOrderUrgency = "ROUTINE" | "URGENT" | "ASAP" | "STAT";
export type LabOrderBillRecipient =
  | "CLIENT"
  | "PATIENT"
  | "GUARANTOR"
  | "THIRD_PARTY";
export type LabObservationStatus =
  | "registered"
  | "preliminary"
  | "final"
  | "amended"
  | "cancelled"
  | "corrected";
export type ObservationSource = "LAB" | "MANUAL";
export type ValueQuantity = {
  value: string;
  unit?: string;
};
export type ReferenceRange = {
  low?: ValueQuantity;
  high?: ValueQuantity;
  text?: string;
};
export type PerformingSite = {
  name?: string;
  address?: Address;
  contact?: string;
};
export type LabObservationInfo = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id: string;
  /** An ID for a lab test.
   */
  test_id: string;
  /** Health Gorilla ID for an observation from lab results.
   */
  hg_id: string;
  status: LabObservationStatus;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id?: number;
  source: ObservationSource;
  display: string;
  code: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  issued: string;
  value?: string;
  unit?: string;
  reference_range?: ReferenceRange;
  interpretation?: string;
  note?: string;
  performing_site?: PerformingSite;
};
export type LabOrderStatus =
  | "NEW"
  | "SENT"
  | "IN_PROGRESS"
  | "ERROR"
  | "FULFILLED"
  | "CORRECTED"
  | "REVIEWED";
export type AoeAnswer = {
  aoe_id: string;
  response: string;
};
export type LabTestInfo = {
  /** An ID for a lab test.
   */
  test_id: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Code used by the lab to identify a test.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_to_lab?: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_from_lab?: string;
  observations: LabObservationInfo[];
  status?: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  aoe_answers?: AoeAnswer[];
};
export type LabOrderMetadata = {
  hg_request_group_url?: string;
  hg_request_result_url?: string;
};
export type LabOrderInfo = {
  /** An ID for a lab order.
   */
  order_id: string;
  patient: LabOrderPatientInfo;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id: string;
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** A human-readable label for the order.
   */
  label: string;
  /** A note on the order to be sent along to the servicing lab.
   */
  note?: string;
  /** A note sent back from the lab to be displayed at the order level.
   */
  result_note?: string;
  urgency: LabOrderUrgency;
  bill_recipient: LabOrderBillRecipient;
  specimen_collected?: string;
  fasting: boolean;
  tests: LabTestInfo[];
  icd10_codes?: Icd10CodeDetails[];
  status: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  sent?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  fulfilled?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed?: string;
  has_abn: boolean;
  metadata: LabOrderMetadata;
};
export type TagId = number;
export type TagJtype = "boolean" | "integer" | "string" | "object";
export type TagCreateOrUpdatePayload = {
  /** System-assigned unique ID of a tag. This is used as a table linkage.
   */
  tag_id?: number;
  /** The name of an individual tag.
   */
  name: string;
  /** Description of the tag for the user interface.
   */
  description?: string;
  jtype?: TagJtype;
};
export const {
  usePatientGetListQuery,
  useLazyPatientGetListQuery,
  usePracticeCreateMutation,
  usePracticeListQuery,
  useLazyPracticeListQuery,
  usePracticeUpdateMutation,
  usePracticeInfoQuery,
  useLazyPracticeInfoQuery,
  usePracticeAppointmentsExportQuery,
  useLazyPracticeAppointmentsExportQuery,
  usePracticeAddUserMutation,
  usePracticeInviteMutation,
  usePracticeGetFormsQuery,
  useLazyPracticeGetFormsQuery,
  usePracticeGetTemplatesQuery,
  useLazyPracticeGetTemplatesQuery,
  usePracticeGetNotificationsQuery,
  useLazyPracticeGetNotificationsQuery,
  usePracticeGetProvidersQuery,
  useLazyPracticeGetProvidersQuery,
  usePracticeListAppointmentTypesQuery,
  useLazyPracticeListAppointmentTypesQuery,
  usePracticeSearchUsersQuery,
  useLazyPracticeSearchUsersQuery,
  usePracticeListRolesQuery,
  useLazyPracticeListRolesQuery,
  usePracticeCreateRoleMutation,
  usePracticeUpdateRoleMutation,
  usePracticeEditRolesQuery,
  useLazyPracticeEditRolesQuery,
  usePracticeAssignUserRoleMutation,
  usePracticeCreateContactMutation,
  usePracticeUpdateContactMutation,
  usePracticeDeleteContactsMutation,
  usePracticeListContactsQuery,
  useLazyPracticeListContactsQuery,
  usePracticeGetLabOrdersQuery,
  useLazyPracticeGetLabOrdersQuery,
  useTagCreateMutation
} = injectedRtkApi;
