/* Newborn form component */
/* External Imports */
import { Controller, useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import dayjs from "dayjs";
/* Local Imports */

// components
import ControlledOptionsField from "../generated/options";
import Button from "@/components/button";
import Input from "@/components/input";
import Clock from "../../../../public/svgs/clock.svg";

// constants
import { NEWBORN_FORM } from "./constants";
import { STYLES } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store

// styles
import styles from "./styles.module.scss";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* ApgarForm Typescript Interface */
interface FirstLatchFormProps {
  onSubmit: (data: any) => void;
  defaultValues: any;
  startTime: number;
}

export default function FirstLatchForm({
  onSubmit,
  defaultValues,
  startTime,
}: FirstLatchFormProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
  } = useForm({ defaultValues });
  /* Redux */

  /* Local State */

  /* Effects */

  useEffect(() => {
    setValue("start_time", startTime);
  }, [startTime]);

  const totalLatchValue = useMemo(() => {
    if (!defaultValues) return 0;
    const latchEntries = Object.entries(defaultValues).filter(
      ([key]) => key !== "start_time"
    );
    const latchValues = latchEntries.map(([k, v]) => v);
    const totalValue = latchValues.reduce(
      (accumulator, currentValue) =>
        (accumulator as number) + (currentValue as number),
      0
    );
    return totalValue as number;
  }, [defaultValues]);

  return (
    <form className={styles.ApgarForm} onSubmit={handleSubmit(onSubmit)}>
      <Controller
          name="start_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={defaultValues && defaultValues.start_time || startTime}
                value={value}
                hiddenLabel
              />
            );
          }}
        />
      <div className={styles.header}>
        <div></div>
        <div></div>
        <div>Sign</div>
      </div>
      <div className={styles.body}>
        {NEWBORN_FORM.LATCH.map(({ id, label, scores }) => (
          <div key={id} className={styles.row}>
            <label>{label}</label>
            <ControlledOptionsField
              label={label}
              name={`${id}`}
              control={control}
              options={[
                { id: 0, label: "0" },
                { id: 1, label: "1" },
                { id: 2, label: "2" },
              ]}
              hiddenLabel
            />
            <div>
              {scores.map((val, idx) => (
                <p key={idx}>
                  {idx}= {val}
                </p>
              ))}
            </div>
          </div>
        ))}
        {/* // TODO: populate total scores */}
        <div className={styles.row}>
          <label>Total Score</label>
          <div>{totalLatchValue}</div>
        </div>
      </div>
      <div className={styles.fullWidth}>
        <Button style={STYLES.FULL_WIDTH} type="submit">
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}
