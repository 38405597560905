import { createSlice } from "@reduxjs/toolkit";

type initialState = {
  isOpen: boolean;
  content: { type: string | null, props?: {[key: string]: any } };

};

const initialState: initialState = {
  isOpen: false,
  content: { type: null },
};

export const ModalSlice = createSlice({
  name: "Modal",
  initialState,
  reducers: {
    setModalIsOpen: (state, { payload }) => {
      state.isOpen = payload;
    },
    setModalContent: (state, { payload }) => {
      if (payload && !payload.props) {
        console.error("No props passed to the modal dispatch, this will cause issues");
      }
      state.content = payload;
    },
  },
});

export const { setModalIsOpen, setModalContent } = ModalSlice.actions;

export default ModalSlice.reducer;
