/* DashboardCard */
/* External Imports */
import clsx from "clsx";
import { ReactElement } from "react";

/* Local Imports */

// components
import Icon from "@/components/icons";
import Tag, { TagProps } from "@/components/tag";
import ErrorBoundary from "@/components/errorBoundary";
import EmptyState from "../emptyState";
import Skeleton from "react-loading-skeleton";
// constants

// store

// styles
import styles from "./styles.module.scss";

/* DashboardCard Typescript Interface */
interface DashboardCardProps {
  title?: string;
  children: JSX.Element | JSX.Element[];
  tag?: TagProps;
  actions?: {
    handleAction?: () => void;
    actionIcon: { svg: string; customWidth?: number };
  }[];
  customElement?: ReactElement;
  customRightElement?: ReactElement;
  className?: string;
  isReadOnly?: boolean;
  isEmpty?: boolean;
  isLoading?: boolean;
  icon?: string;
}

export default function DashboardCard({
  title,
  children,
  actions,
  tag,
  customElement,
  customRightElement,
  className,
  isReadOnly = false,
  isEmpty,
  isLoading,
  icon
}: DashboardCardProps) {
  // defaults: to define default interaction in future iteration

  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.DashboardCard, className, {
        [styles.readOnly]: isReadOnly
      })}
    >
      <div className={styles.header}>
        <div className={styles.metaWrapper}>
          {icon && <Icon svg={icon} />}
          {title && <h4 className="t2 smBld">{title}</h4>}
          {tag && <Tag {...tag} />}
          {customElement && customElement}
        </div>
        <div className={styles.actionsWrapper}>
          {actions &&
            actions.map((action, i) => {
              const {
                actionIcon = { svg: "dot_menu_color", customWidth: 3 },
                handleAction
              } = action || {};
              return (
                <button onClick={handleAction} key={actionIcon.svg + i}>
                  <Icon svg={actionIcon.svg} width={actionIcon.customWidth} />
                </button>
              );
            })}
        </div>
        {customRightElement && customRightElement}
      </div>
      <div className={clsx(styles.content, { [styles.emptyState]: isEmpty })}>
        {isLoading ? (
          <Skeleton width={300} height={40} style={{ marginBottom: 16 }} />
        ) : isEmpty ? (
          <EmptyState />
        ) : (
          <ErrorBoundary isComponentScoped>{children}</ErrorBoundary>
        )}
      </div>
    </div>
  );
}
