import clsx from "clsx";
import React, { useRef, useState } from "react";

import styles from "./styles.module.scss";
import Icon from "../icons";

type Props = {
  onFilesAdded: (files: FileList) => void;
  isMobile?: boolean;
};

const FileUploader: React.FC<Props> = ({ onFilesAdded, isMobile }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer && e.dataTransfer.files) {
      onFilesAdded(e.dataTransfer.files);
    }

    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    setIsDragging(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onFilesAdded(e.target.files);
    }
  };

  return (
    <div
      className={clsx(styles.FileUploader, { [styles.isDragging]: isDragging })}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onClick={() => fileInputRef.current?.click()}
    >
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        multiple
      />
      <Icon svg="upload" width={40} height={40} />
      <p className="xLight">
        <span className="blue700 tMd">Click to upload</span>{" "}
        {!isMobile && "or drag and drop"}
      </p>
      <p className="t5 xLight">PDF, SVG, PNG, JPG or GIF</p>
    </div>
  );
};

export default FileUploader;
