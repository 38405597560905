// External
import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

// Components
import Checkbox from "@/components/input/checkbox";

// Store
import {
  EncounterInfo,
  FileListItem,
  PatientInfo,
  usePatientGetLabOrdersQuery
} from "@/store/services/patient";
import { useFileListQuery } from "@/store/services/file";
import { useEncounterListQuery } from "@/store/services/encounter";

// Styles
import styles from "../styles.module.scss";

export type AdditionalDataFormType = FieldValues & {
  demographics: boolean;
  health_history: boolean;
  problem_list: boolean;
  prenatal_flow_sheet: boolean;
  encounters: boolean;
  prenatal_labs: boolean;
  allergies: boolean;
  current_meds: boolean;
  all_files: boolean;
  all_labs: boolean;
};

interface AdditionalDataCheckListProps<T extends AdditionalDataFormType> {
  patient: PatientInfo;
  form: UseFormReturn<T>;
  genericRecords?: boolean;
}

export default function AdditionalDataCheckList({
  patient,
  form,
  genericRecords = false
}: AdditionalDataCheckListProps<AdditionalDataFormType>) {
  const { data: fileData } = useFileListQuery(
    {
      scope: "patient",
      id: patient.user_id,
      // TODO: We need to rework the entire UX for this so pagination makes sense
      pagesz: 1000,
    },
    {
      skip: !patient.user_id
    }
  );

  const { data: labData, isLoading: isLabsLoading } =
    usePatientGetLabOrdersQuery(
      {
        patientId: patient.user_id
      },
      {
        skip: !patient
      }
    );

  const { data: encounters, isLoading: isEncountersLoading } =
    useEncounterListQuery({
      scope: "patient",
      id: patient.user_id,
      pagesz: 100
    });

  const [hasAdditionalOptions, setHasAdditionalOptions] = useState<
    Record<string, boolean>
  >({
    demographics: true,
    health_history: true
  });

  const { register, watch, setValue } = form;

  // Additional file checkboxes
  const watchAll = watch();
  const watchAllFiles = watch("all_files");
  const watchAllLabs = watch("all_labs");
  const watchAllEncounters = watch("all_encounters");

  // Additional file checkbox tree side effects
  useEffect(() => {
    if (watchAllFiles) {
      fileData?.forEach((file: FileListItem) => {
        setValue(`file_${file.file_id}`, true);
      });
    }
  }, [watchAllFiles]);

  useEffect(() => {
    if (watchAllLabs) {
      labData?.forEach(lab => {
        setValue(`lab_${lab.order_id}`, true);
      });
    }
  }, [watchAllLabs]);

  useEffect(() => {
    if (watchAllEncounters) {
      encounters?.forEach((encounter: EncounterInfo) => {
        setValue(`encounter-${encounter.encounter_id}`, true);
      });
    }
  }, [watchAllEncounters]);

  useEffect(() => {
    // Value is the value of the whole form, name is the name of the input that changed
    const subscription = watch((value, { name }) => {
      // If what just changed was one of the "all"s then don't do anything here
      if (
        name === "all_encounters" ||
        name === "all_files" ||
        name === "all_labs" ||
        !name
      ) {
        return;
      }
      // If the key starts with one of our "all" keys and is false, set "all_*" to false
      if (name.startsWith("encounter-") && !value[name]) {
        setValue("all_encounters", false);
      }
      if (name.startsWith("file") && !value[name]) {
        setValue("all_files", false);
      }
      if (name.startsWith("lab") && !value[name]) {
        setValue("all_labs", false);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  // Conditionals for patient data
  useEffect(() => {
    // Conditionals for the different parts of the form
    const hasProblems = (patient.problems &&
      (patient.problems.diagnoses.length > 0 ||
        patient.problems.notes.length > 0)) as boolean;
    const hasFlowSheet = (patient.pregnancy?.flow_sheet &&
      genericRecords) as boolean;
    const hasEncounters = (encounters &&
      encounters.length > 0 &&
      genericRecords) as boolean;
    const hasLabs = (patient.pregnancy?.lab_orders &&
      patient.pregnancy.lab_orders.length > 0) as boolean;
    const hasAllergies = (patient.medical_history.allergies &&
      patient.medical_history.allergies.length > 0) as boolean;
    const hasCurrentMeds = (patient.medical_history.medications &&
      Object.entries(patient.medical_history.medications).length >
        0) as boolean;

    // Set the form values
    setValue("health_history", true);
    setValue("demographics", true);
    setValue("problem_list", hasProblems);
    setValue("prenatal_flow_sheet", hasFlowSheet);
    setValue("encounters", hasEncounters);
    setValue("prenatal_labs", hasLabs);
    setValue("allergies", hasAllergies);
    setValue("current_meds", hasCurrentMeds);

    // Set the additional options state
    setHasAdditionalOptions(oldOptions => ({
      ...oldOptions,
      current_meds: hasCurrentMeds,
      problem_list: hasProblems,
      prenatal_flow_sheet: hasFlowSheet,
      encounters: hasEncounters,
      prenatal_labs: hasLabs,
      allergies: hasAllergies
    }));
  }, [patient, encounters]);

  let additionalDataOptions = [
    {
      id: "demographics",
      label: "Demographics"
    },
    {
      id: "problem_list",
      label: "Problem List"
    },
    {
      id: "current_meds",
      label: "Current Medications"
    },
    {
      id: "allergies",
      label: "Allergies"
    },
    {
      id: "health_history",
      label: "Health History"
    },
    {
      id: "prenatal_flow_sheet",
      label: "Prenatal flow sheet"
    }
  ];

  const getEncounterLabel = (encounter: EncounterInfo) => {
    const apptType = encounter.appointment_type;
    let encType = encounter.encounter_type?.replaceAll("_", " ");
    if (encType && encType !== "") {
      encType = encType.slice(0, 1).concat(encType.slice(1).toLowerCase());
    }
    const dateString = dayjs(
      `${
        encounter.start || encounter.appointment?.starts || encounter.created
      }`,
      "YYYYMMDDHHmmss"
    ).format("MM/DD/YYYY");
    return apptType
      ? `${apptType} - ${dateString}`
      : `${encType} - ${dateString}`;
  };

  // Only prenatal labs in a labor transfer not generic
  if (!genericRecords) {
    additionalDataOptions.push({
      id: "prenatal_labs",
      label: "Prenatal Labs"
    });
  }

  return (
    <div className={styles.additionalChecks}>
      {genericRecords && (
        <>
          <div className={styles.additionalData}>
            <div className={styles.fileListChecks}>
              <p>Additional data</p>
              {additionalDataOptions.map(option => (
                <Checkbox
                  label={option.label}
                  id={option.id}
                  name={option.id}
                  register={register}
                  disabled={!hasAdditionalOptions[option.id]}
                  key={option.id}
                />
              ))}
            </div>
          </div>
          <div className={styles.additionalData}>
            <div className={styles.fileListChecks}>
              <p>Encounters</p>
              {isEncountersLoading && (
                <>
                  <Skeleton height="20" width="80%" />
                  <Skeleton height="20" width="80%" />
                  <Skeleton height="20" width="80%" />
                  <Skeleton height="20" width="80%" />
                </>
              )}
              {encounters && encounters.length > 0 ? (
                <>
                  <Checkbox
                    label="All encounters"
                    id="all_encounters"
                    name="all_encounters"
                    register={register}
                  />

                  <div className={styles.subCheckList}>
                    {encounters.map(encounter => (
                      <Checkbox
                        label={getEncounterLabel(encounter)}
                        id={`encounter-${encounter.encounter_id}`}
                        name={`encounter-${encounter.encounter_id}`}
                        register={register}
                        key={encounter.encounter_id}
                      />
                    ))}
                  </div>
                </>
              ) : (
                !isEncountersLoading && (
                  <p>This patient doesn't have any encounters.</p>
                )
              )}
            </div>
          </div>
        </>
      )}
      <div className={styles.additionalFiles}>
        <div className={styles.fileListChecks}>
          <p>Additional files</p>
          {fileData && fileData.length > 0 && (
            <>
              <Checkbox
                label="All files"
                id="all_files"
                name="all_files"
                register={register}
              />
              <div className={styles.subCheckList}>
                {fileData.map(file => (
                  <Checkbox
                    label={file.name}
                    register={register}
                    id={`file_${file.file_id}`}
                    name={`file_${file.file_id}`}
                    key={file.file_id}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.additionalFiles}>
        <div className={styles.fileListChecks}>
          <p>Additional labs</p>
          {isLabsLoading && (
            <>
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
              <Skeleton height="20" width="80%" />
            </>
          )}
          {labData && labData.length > 0 ? (
            <>
              <Checkbox
                label="All labs"
                id="all_labs"
                name="all_labs"
                register={register}
              />
              <div className={styles.subCheckList}>
                {labData.map(lab => (
                  <Checkbox
                    label={lab.label || ""}
                    register={register}
                    id={`lab_${lab.order_id}`}
                    name={`lab_${lab.order_id}`}
                    key={lab.order_id}
                  />
                ))}
              </div>
            </>
          ) : (
            !isLabsLoading && <p>Patient doesn't have any labs.</p>
          )}
        </div>
      </div>
    </div>
  );
}
