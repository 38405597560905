import { LaborModel } from "@/store/services/patient";
import KeyValueGrid from "../keyValueGrid";
import KeyValueItem from "../keyValueItem";
import ReadOnlySection from "../readOnlySection";
import SectionCol from "../sectionCol";
import SectionRow from "../sectionRow";
import TableItem from "../tableItem";
import { useEffect, useState } from "react";
import { getTimeElapsed } from "@/utils/api/time";
import ObjectTableItem from "../objectTableItem";
import {
  FIRST_STAGE_EVENTS,
  SECOND_STAGE_EVENTS,
  THIRD_STAGE_EVENTS
} from "@/components/flows/labor/constants";
import dayjs from "dayjs";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

interface SummaryStagesInfoProps {
  labor: LaborModel;
}

export default function SummaryStagesInfo({ labor }: SummaryStagesInfoProps) {
  const [romForm, setRomForm] = useState<Record<string, any>>({});
  const [fisrtStageTime, setFirstStageTime] = useState<string>("");
  const [secondStageTime, setSecondStageTime] = useState<string>("");
  const [thirdStageTime, setThirdStageTime] = useState<string>("");
  const [totalTime, setTotalTime] = useState<string>("");

  useEffect(() => {
    if (labor.stages?.labor?.events?.ROM) {
      setRomForm(labor.stages.labor.events.ROM.forms as Record<string, any>);
    }

    let last_stage_finished;

    /** TODO(Landon): Refactor this to be more DRY */
    const first_stage_start =
      labor.stages[FIRST_STAGE_EVENTS.START_STAGE]?.events?.[
        FIRST_STAGE_EVENTS.START_EVENT
      ];
    const first_stage_end =
      labor.stages[FIRST_STAGE_EVENTS.END_STAGE]?.events?.[
        FIRST_STAGE_EVENTS.END_EVENT
      ];
    if (
      first_stage_start &&
      first_stage_start.start &&
      first_stage_end &&
      first_stage_end.start
    ) {
      if (first_stage_end.start < first_stage_start.start) {
        setFirstStageTime(
          `The ${FIRST_STAGE_EVENTS.END_EVENT} timestamp is earlier than ${FIRST_STAGE_EVENTS.START_EVENT} timestamp. Please correct.`
        );
      } else {
        last_stage_finished = first_stage_end;
        const elaspedTime = getTimeElapsed(
          first_stage_start.start,
          first_stage_end.start
        );
        setFirstStageTime(elaspedTime);
      }
    } else {
      setFirstStageTime("First stage unfinished");
    }

    const second_stage_start =
      labor.stages[SECOND_STAGE_EVENTS.START_STAGE]?.events?.[
        SECOND_STAGE_EVENTS.START_EVENT
      ];
    const second_stage_end =
      labor.stages[SECOND_STAGE_EVENTS.END_STAGE]?.events?.[
        SECOND_STAGE_EVENTS.END_EVENT
      ];
    if (
      second_stage_start &&
      second_stage_start.start &&
      second_stage_end &&
      second_stage_end.start
    ) {
      if (second_stage_end.start < second_stage_start.start) {
        setSecondStageTime(
          `The ${SECOND_STAGE_EVENTS.END_EVENT} timestamp is earlier than ${SECOND_STAGE_EVENTS.START_EVENT} timestamp. Please correct.`
        );
      } else {
        last_stage_finished = second_stage_end;
        const elaspedTime = getTimeElapsed(
          second_stage_start.start,
          second_stage_end.start
        );
        setSecondStageTime(elaspedTime);
      }
    } else {
      setSecondStageTime("Second stage unfinished");
    }

    const third_stage_start =
      labor.stages[THIRD_STAGE_EVENTS.START_STAGE]?.events?.[
        THIRD_STAGE_EVENTS.START_EVENT
      ];
    const third_stage_end =
      labor.stages[THIRD_STAGE_EVENTS.END_STAGE]?.events?.[
        THIRD_STAGE_EVENTS.END_EVENT
      ];
    if (
      third_stage_start &&
      third_stage_start.start &&
      third_stage_end &&
      third_stage_end.start
    ) {
      if (third_stage_end.start < third_stage_start.start) {
        setThirdStageTime(
          `The ${THIRD_STAGE_EVENTS.END_EVENT} timestamp is earlier than ${THIRD_STAGE_EVENTS.START_EVENT} timestamp. Please correct.`
        );
      } else {
        last_stage_finished = third_stage_end;
        const elaspedTime = getTimeElapsed(
          third_stage_start.start,
          third_stage_end.start
        );
        setThirdStageTime(elaspedTime);
      }
    } else {
      setThirdStageTime("Third stage unfinished");
    }

    if (first_stage_start && last_stage_finished) {
      const elaspedTime = getTimeElapsed(
        first_stage_start.start,
        last_stage_finished.start
      );
      setTotalTime(elaspedTime);
    } else {
      setTotalTime("Unable to calculate total labor time.");
    }
  }, [labor]);

  return (
    <ReadOnlySection header="Stages of Labor">
      {romForm && (
        <SectionRow>
          {/* ROM TYPE */}
          <ObjectTableItem
            orderedHeaders={["ROM Type", "Color", "Volume", "Time"]}
            objectGrid={[
              {
                ["ROM Type"]: romForm.rupture_type,
                Color: romForm.fluid_appearance,
                Volume: romForm.fluid_volume,
                Time: convertUtcIntToLocalDatetime(romForm.start_time as number).format(
                  "MM/DD/YYYY HH:mm"
                )
              }
            ]}
          />
        </SectionRow>
      )}
      <SectionRow>
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="First Stage">
              <p>{fisrtStageTime}</p>
            </KeyValueItem>

            <KeyValueItem keyLabel="Second Stage">
              <p>{secondStageTime}</p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>

        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="Third Stage">
              <p>{thirdStageTime}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Total Time">
              <p>{totalTime}</p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
      </SectionRow>
    </ReadOnlySection>
  );
}
