import { createSlice } from "@reduxjs/toolkit";

type initialState = {
  payorId: string | null;
};

const initialState: initialState = {
  payorId: null
};

export const InsuranceSlice = createSlice({
  name: "Insurance",
  initialState,
  reducers: {
    setPayorId: (state, { payload }) => {
      state.payorId = payload;
    }
  }
});

export const { setPayorId } = InsuranceSlice.actions;
export default InsuranceSlice.reducer;
