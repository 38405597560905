// External
import clsx from "clsx";

// Components
import BoldIcon from "../../../public/svgs/rte_bold.svg";
import ItalicsIcon from "../../../public/svgs/rte_italics.svg";
import HeaderOneIcon from "../../../public/svgs/rte_header_1.svg";
import OrderedListIcon from "../../../public/svgs/rte_ordered_list.svg";
import BulletListIcon from "../../../public/svgs/rte_bullet_list.svg";
import CheckIcon from "../../../public/svgs/rte_check.svg";

// Styles
import styles from "./styles.module.scss";
import Tooltip, { TOOLTIP_POSITIONS } from "../tooltip";
import { Editor } from "@tiptap/core";

export interface MenuBarProps {
  editor: Editor | null;
}

export default function MenuBar({ editor }: MenuBarProps) {
  if (!editor) {
    return null;
  }

  /*
    All of these buttons initiate editor "commands" on the TipTap editor.
    Commands are anything that affect the editor in anyway and in this case
    these commands are entirely for affect the text. The first command in
    the function calls is "chain" which is a TipTap specific function to
    queue up all the commands you want to run with the TipTap specific "run"
    function. The second is "focus" which simply focuses the editor
    (ie textarea). The next command is the actual text affect we want to
    happen and they're fairly self-explanatory (toggleBold, toggleItalic, etc.).
    Then we run the "run" command which just executes all of our chained commands.

    The "can" function in the disabled prop is a "dry run" for the commands
    that come after it to see if those things are possible. In this instance,
    it's fairly useless but if, for example, we disabled text bolding while
    the text was in "code" (writing in monospace, code-like font) then the
    buttons would automatically disable themselves.
  */
  return (
    <div className={clsx(styles.MenuBar)}>
      <Tooltip tooltipText='Shortcut: ctrl/cmd + B' tooltipPosition={TOOLTIP_POSITIONS.RIGHT_ALIGNED_TOP}>
        <button
          type='button'
          onClick={() => {
            editor.chain().focus().toggleBold().run();
          }}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={clsx({ [styles.active]: editor.isActive("bold") }, [
            styles.textControl,
          ])}
        >
          <BoldIcon stroke={styles.gray400} />
        </button>
      </Tooltip>
      <Tooltip tooltipText='Shortcut: ctrl/cmd + I'>
        <button
          type='button'
          onClick={() => {
            editor.chain().focus().toggleItalic().run();
          }}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={clsx({ [styles.active]: editor.isActive("italic") }, [
            styles.textControl,
          ])}
        >
          <ItalicsIcon stroke={styles.gray400} />
        </button>
      </Tooltip>
      <Tooltip tooltipText='Shortcut: ctrl/cmd + alt + 1'>
        <button
          type='button'
          onClick={() => {
            editor.chain().focus().toggleHeading({ level: 1 }).run();
          }}
          className={clsx(
            { [styles.active]: editor.isActive("heading", { level: 1 }) },
            [styles.textControl]
          )}
        >
          <HeaderOneIcon stroke={styles.gray400} />
        </button>
      </Tooltip>
      <Tooltip tooltipText='Shortcut: ctrl/cmd + shift + 8'>
        <button
          type='button'
          onClick={() => {
            editor.chain().focus().toggleBulletList().run();
          }}
          className={clsx({ [styles.active]: editor.isActive("bulletList") }, [
            styles.textControl,
          ])}
        >
          <BulletListIcon stroke={styles.gray400} />
        </button>
      </Tooltip>
      <Tooltip tooltipText='Shortcut: ctrl/cmd + shift + 7'>
        <button
          type='button'
          onClick={() => {
            editor.chain().focus().toggleOrderedList().run();
          }}
          className={clsx({ [styles.active]: editor.isActive("orderedList") }, [
            styles.textControl,
          ])}
        >
          <OrderedListIcon stroke={styles.gray400} />
        </button>
      </Tooltip>
      <Tooltip tooltipText='Shortcut: ctrl/cmd + shift + 9'>
        <button
          type='button'
          onClick={() => {
            editor.chain().focus().toggleTaskList().run();
          }}
          className={clsx({ [styles.active]: editor.isActive("taskList") }, [
            styles.textControl,
          ])}
        >
          <CheckIcon stroke={styles.gray400} />
        </button>
      </Tooltip>
    </div>
  );
}
