// third-party
import clsx from "clsx";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import ControlledCombobox from "@/components/input/controlledCombobox";

// store
import {
  PatientInfo,
  PatientUpsertRequest,
  UserInsurance,
  useLazyPatientGetInfoQuery,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import {
  PayorInfo,
  usePayorGetInfoQuery,
  usePayorListQuery
} from "@/store/services/payor";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { Insurance, Statecode } from "@/store/services/user";

// constants
import styles from "../styles.module.scss";
import { STYLES, STATUS_KEYS, STATE_CODES } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "../../scheduling/calendars/utils";
import { useEffect } from "react";
import { setModalContent, setModalIsOpen } from "../../modal/modalSlice";
import { MODAL_TYPES } from "../../modal/dispatcher";
import { RootState } from "@/store/store";
import { setRightPaneContent } from "../../drawer/drawerSlice";
import Toggle from "@/components/input/toggle";

interface InsuranceFormProps {
  patientId: number;
  type: "PRIMARY" | "SECONDARY" | "TERTIARY";
  onUpdate?: () => void;
  // Whether or not it's viewed from the patient task in the sidebar
  isTaskView?: boolean;
}

let oldRelToSubscriber = "";

export default function InsuranceForm({
  patientId,
  type,
  onUpdate,
  isTaskView = false
}: InsuranceFormProps) {
  const dispatch = useDispatch();

  const { data: payors } = usePayorListQuery();
  const [upsertPatient] = usePatientUpsertMutation();
  const [getPatient] = useLazyPatientGetInfoQuery();

  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId
    },
    {
      skip: !patientId
    }
  );

  const newPayorId = useSelector((state: RootState) => state.insurance.payorId);

  const getDefaultValues = (defaultPatient: PatientInfo) => {
    let defaultValues: InsuranceWithSelfPay | undefined = {};
    switch (type) {
      case "PRIMARY":
        defaultValues = {
          ...defaultPatient.insurance?.primary,
          self_pay: defaultPatient.insurance?.self_pay
        };
        break;
      case "SECONDARY":
        defaultValues = { ...defaultPatient.insurance?.secondary };
        break;
      case "TERTIARY":
        defaultValues = { ...defaultPatient.insurance?.tertiary };
        break;
      default:
        throw Error("invalid insurance type");
    }
    if (defaultValues?.subscriber_dob && defaultValues?.subscriber_dob > 0) {
      // @ts-ignore
      defaultValues.subscriber_dob = defaultValues?.subscriber_dob
        ? convertUtcIntToLocalDatetime(defaultValues?.subscriber_dob).format(
            "YYYY-MM-DD"
          )
        : "";
    }
    return defaultValues;
  };

  const form = useForm<InsuranceWithSelfPay>();
  const { watch, setValue } = form;

  const relToSubscriber = watch("relationship_to_subscriber");
  const currentPayor = watch("payor");

  const { data: payorInfo } = usePayorGetInfoQuery(
    {
      // @ts-ignore payor not typed correctly
      payorId: currentPayor?.hg_id as string
    },
    {
      // @ts-ignore payor not typed correctly
      skip: !currentPayor?.hg_id
    }
  );

  // This sets the payor drop down when the patient and payors list are changed
  useEffect(() => {
    if (!patient) {
      return;
    }
    form.reset(getDefaultValues(patient));

    if (payors) {
      let currentInsurance;
      switch (type) {
        case "PRIMARY":
          currentInsurance = patient.insurance?.primary;
          break;
        case "SECONDARY":
          currentInsurance = patient.insurance?.secondary;
          break;
        case "TERTIARY":
          currentInsurance = patient.insurance?.tertiary;
          break;
        default:
          currentInsurance = null;
      }
      if (!currentInsurance) {
        return;
      }
      const payor = payors.find(
        payor => payor.hg_id === currentInsurance.hg_org_id
      );
      // @ts-ignore again the payor type is all messed up
      setValue("payor", payor);
      // @ts-ignore
      if (payor) setValue("payor_phone", payor.phoneNumber);
    }
  }, [patient, payors]);

  // This sets all the extra stuff in the form if we have it.
  useEffect(() => {
    if (!patient) {
      // No point if we don't have patient data
      return;
    }

    if (relToSubscriber == "SELF") {
      // autofill
      setValue("subscriber_phone", patient.phone || "");
      setValue("subscriber_first_name", patient.first_name || "");
      setValue("subscriber_last_name", patient.last_name || "");
      // The date input definitely returns a string, I don't know why it's typed as a number
      setValue(
        "subscriber_dob",
        // @ts-ignore
        dayjs(`${patient.dob}`, "YYYYMMDD").format("YYYY-MM-DD")
      );
      setValue("subscriber_ssn", patient.ssn || "");
      setValue("subscriber_address.line1", patient.address?.line1 || "");
      setValue("subscriber_address.line2", patient.address?.line2 || "");
      setValue("subscriber_address.line3", patient.address?.line3 || "");
      setValue("subscriber_address.city", patient.address?.city || "");
      setValue(
        "subscriber_address.state",
        (patient.address?.state || "") as Statecode
      );
      setValue("subscriber_address.zip", patient.address?.zip || "");
    } else if (oldRelToSubscriber == "SELF") {
      // If the current value doesn't equal self but the old value did equal self
      // This stops the data from clearing if you change it from "Guardian" to "Other" or something
      // clear
      setValue("subscriber_phone", "");
      setValue("subscriber_first_name", "");
      setValue("subscriber_last_name", "");
      // The date input definitely returns a string, I don't know why it's typed as a number
      setValue(
        "subscriber_dob",
        // @ts-ignore
        ""
      );
      setValue("subscriber_ssn", "");
      setValue("subscriber_address.line1", "");
      setValue("subscriber_address.line2", "");
      setValue("subscriber_address.line3", "");
      setValue("subscriber_address.city", "");
      setValue("subscriber_address.state", "" as Statecode);
      setValue("subscriber_address.zip", "");
    }
    // Set the value to our current value so when this runs next, it's the old value
    oldRelToSubscriber = relToSubscriber || "";
  }, [relToSubscriber]);

  // If the user has created a new payor via the modal, this will set that in the form
  useEffect(() => {
    if (!newPayorId) {
      return;
    }

    const newPayor = payors?.find(payor => payor.hg_id == newPayorId);

    // @ts-ignore payor value isn't typed correctly
    setValue("payor", newPayor);
  }, [payors, newPayorId]);

  // This will attempt to grab the phone number for the payor when it changes
  useEffect(() => {
    if (payorInfo) {
      // @ts-ignore payorInfo doesn't contain the phone number?
      setValue("payor_phone", payorInfo.phoneNumber || "");
    }
  }, [payorInfo]);

  const onAddNewPayor = () => {
    if (isTaskView) {
      dispatch(
        setRightPaneContent({
          type: MODAL_TYPES.ADD_NEW_PAYOR,
          props: { title: "Add new payor", isDrawer: true }
        })
      );
    } else {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.ADD_NEW_PAYOR,
          props: { title: "Add new payor" }
        })
      );
      dispatch(setModalIsOpen(true));
    }
  };
  interface InsuranceWithSelfPay extends Insurance {
    self_pay?: boolean;
  }

  const onSubmit = async (data: InsuranceWithSelfPay) => {
    if (!patient) {
      return;
    }

    const payor = data.payor as unknown as PayorInfo;

    if (data.subscriber_dob && data.subscriber_dob + "" != "") {
      data.subscriber_dob = parseInt(
        dayjs(data.subscriber_dob as unknown as string).format("YYYYMMDD")
      );
    } else {
      data.subscriber_dob = undefined;
    }

    const patientUpsertRequest: PatientUpsertRequest = {
      user_id: patient.user_id,
      insurance: patient.insurance as UserInsurance
    };

    switch (type) {
      case "PRIMARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          self_pay: data.self_pay,
          primary: {
            ...data,
            payor: payor?.name,
            hg_org_id: payor?.hg_id
          }
        };

        break;
      case "SECONDARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          secondary: {
            ...data,
            payor: payor?.name,
            hg_org_id: payor?.hg_id
          }
        };
        break;
      case "TERTIARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          tertiary: {
            ...data,
            payor: payor?.name,
            hg_org_id: payor?.hg_id
          }
        };
        break;
      default:
        throw Error("invalid insurance type");
    }

    try {
      await upsertPatient({ patientUpsertRequest }).unwrap();
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Insurance updated"
        })
      );
      if (onUpdate) {
        onUpdate();
      }
    } catch (err) {
      console.error(err);
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Failed to update insurance"
        })
      );
    }
  };

  return (
    <form
      className={clsx(styles.InsuranceForm)}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className={styles.wrapper}>
        {type === "PRIMARY" && (
          <Input
            id="self_pay"
            type="checkbox"
            label="Patient is Self Pay"
            name="self_pay"
            register={form.register}
          />
        )}
        <div className={styles.payorWrapper}>
          <ControlledCombobox
            label="Insurance Company"
            name="payor"
            options={payors || []}
            errors={form.formState.errors}
            control={form.control}
            isHorizontalLayout={false}
            labelAcc={(payor: PayorInfo) =>
              payor?.name + " - " + payor?.address
            }
            // @ts-ignore payor value isn't typed correctly
            valueKey={form.getValues("payor")?.hg_id || ""}
          />
          <p>- or -</p>
          <Button type="button" style={STYLES.PRIMARY} onClick={onAddNewPayor}>
            Add new payor
          </Button>
        </div>
        <Input
          id="payor_phone"
          type="tel"
          label="Insurance co. Phone"
          name="payor_phone"
          register={form.register}
        />

        <Input
          id="plan_type"
          type="text"
          label="Plan Type"
          name="plan_type"
          register={form.register}
          placeholder={"PPO, HMO, etc."}
        />

        <Input
          id="policy_or_group_number"
          type="text"
          label="Policy/Group Number"
          name="policy_or_group_number"
          register={form.register}
        />

        <Input
          id="subscriber_id"
          type="text"
          label="Subscriber/Member ID"
          name="subscriber_id"
          register={form.register}
        />

        <ControlledCombobox
          label="Relationship to Subscriber"
          name="relationship_to_subscriber"
          options={["SELF", "SPOUSE", "GUARDIAN", "OTHER"]}
          errors={form.formState.errors}
          control={form.control}
          isHorizontalLayout={false}
        />

        <Input
          label="Subscriber Phone"
          id="subscriber_phone"
          name="subscriber_phone"
          type="tel"
          register={form.register}
        />

        <Input
          id="subscriber_first_name"
          type="text"
          label="Subscriber First Name"
          name="subscriber_first_name"
          register={form.register}
        />

        <Input
          id="subscriber_last_name"
          type="text"
          label="Subscriber Last Name"
          name="subscriber_last_name"
          register={form.register}
        />

        <Input
          id="subscriber_dob"
          type="date"
          label="Subscriber Date of Birth"
          name="subscriber_dob"
          register={form.register}
        />

        <Input
          id="subscriber_ssn"
          type="text"
          label="Subscriber SSN"
          name="subscriber_ssn"
          register={form.register}
        />

        <Input
          id="subscriber_address.line1"
          type="text"
          label="Subscriber Address Line 1"
          name="subscriber_address.line1"
          register={form.register}
        />

        <Input
          id="subscriber_address.line2"
          type="text"
          label="Subscriber Address Line 2"
          name="subscriber_address.line2"
          register={form.register}
        />

        <Input
          id="subscriber_address.line3"
          type="text"
          label="Subscriber Address Line 3"
          name="subscriber_address.line3"
          register={form.register}
        />

        <Input
          id="subscriber_address.city"
          type="text"
          label="Subscriber City"
          name="subscriber_address.city"
          register={form.register}
        />

        <ControlledCombobox<InsuranceWithSelfPay, Statecode>
          label="Subscriber State"
          name="subscriber_address.state"
          errors={form.formState.errors}
          control={form.control}
          options={STATE_CODES}
          isHorizontalLayout={false}
        />

        <Input
          id="subscriber_address.zip"
          type="text"
          label="Subscriber Zip Code"
          name="subscriber_address.zip"
          register={form.register}
        />
      </div>
      <Button style={STYLES.FULL_WIDTH} type="submit">
        Update
      </Button>
    </form>
  );
}
