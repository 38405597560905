/* Appointmentation Modal Template */

// External
import clsx from "clsx";
import { useDispatch } from "react-redux";

// styles
import styles from "../styles.module.scss";

// components
import CreateAppointmentForm from "@/components/forms/createAppointment";

// constants

// store
import { AppointmentInfo } from "@/store/services/scheduling";

export interface AppointmentModalProps {
  appointment: Partial<AppointmentInfo>;
  isDefaultEmpty?: boolean;
  isReadOnly?: boolean;
  isDefaultEditMode?: boolean;
  isCheckingOut?: boolean;
}

export default function Appointment({
  appointment,
  isDefaultEmpty,
  isDefaultEditMode,
  isCheckingOut
}: AppointmentModalProps) {
  // Event handlers

  return (
    <div className={clsx(styles.Appointment)}>
      <CreateAppointmentForm
        appointment={appointment}
        isDefaultEmpty={isDefaultEmpty}
        isDefaultEditMode={isDefaultEditMode}
        isCheckingOut={isCheckingOut}
      />
    </div>
  );
}
