import * as generated from "./generated/medication";

const medication = generated.medication.enhanceEndpoints({
  endpoints: {
    medicationCreateForPatient: {
      invalidatesTags: ["Patients"]
    },
    medicationUpdateForPatient: {
      invalidatesTags: ["Patients"]
    },
    medicationDeleteFromPatient: {
      invalidatesTags: ["Patients"]
    },
  }
});

export * from "./generated/medication";
export default medication;
export const {
  useMedicationSearchQuery,
  useLazyMedicationSearchQuery,
  useMedicationDosageSearchQuery,
  useLazyMedicationDosageSearchQuery,
  useMedicationCreateForPatientMutation,
  useMedicationUpdateForPatientMutation,
  useMedicationDeleteFromPatientMutation,
} = medication;
