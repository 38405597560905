// TipTap
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Link from "@tiptap/extension-link";

interface ContentRendererProps {
  content: string;
  classes?: string;
}

export default function ContentRenderer({ content, classes="" }: ContentRendererProps) {
  let editor = useEditor(
    {
      editable: false,
      extensions: [
        StarterKit.configure({
          blockquote: false,
          codeBlock: false,
          heading: {
            levels: [1],
          },
          horizontalRule: false,
          code: false,
          strike: false,
        }),
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        Link.configure({
          HTMLAttributes: {
            onclick: "return false;"
          }
        }),
      ],
      content,
      editorProps: {
        attributes: {
          class: `rendered ${classes}`,
        },
      },
    },
    [content]
  );

  return <EditorContent editor={editor} />;
}
