import clsx from "clsx";
import styles from "./styles.module.scss";
import DashboardCard from "../cards/dashboardCard";

interface ReadOnlySectionProps {
  header: string;
  children: JSX.Element | JSX.Element[] | undefined;
  print?: boolean;
}

export default function ReadOnlySection({
  header,
  children,
  print = false,
}: ReadOnlySectionProps) {
  if (!print) {
    return (
      <DashboardCard title={header} className={styles.ReadOnlySection}>
        <div className={styles.sectionBody}>{children}</div>
      </DashboardCard>
    )
  }


  return (
    <div className={clsx({[styles.ReadOnlySection]: true, [styles.print]: print})}>
      <div className={styles.sectionHeader}>
        <h3>{header}</h3>
      </div>
      <div className={styles.sectionBody}>{children}</div>
    </div>
  );
}
