import * as generated from "./generated/form";

const form = generated.form.enhanceEndpoints({});

export * from "./generated/form";
export default form;
export const {
  useFormUpdateMutation,
  useFormGetInfoQuery,
  useLazyFormGetInfoQuery,
  useFormGetSpecificInfoQuery,
  useLazyFormGetSpecificInfoQuery,
  useFormCreateMutation,
} = form;
