import { createSlice } from "@reduxjs/toolkit";

type initialState = {
  electronicOrderingEnabled: boolean;
  isSubmittingOrder: boolean;
};

const initialState: initialState = {
  electronicOrderingEnabled: false,
  isSubmittingOrder: false
};

export const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    setElectronicOrderingEnabled: (state, action) => {
      state.electronicOrderingEnabled = action.payload;
    },
    setIsSubmittingOrder: (state, action) => {
      state.isSubmittingOrder = action.payload;
    }
  }
});

export const { setElectronicOrderingEnabled, setIsSubmittingOrder } =
  wizardSlice.actions;

export default wizardSlice.reducer;
