import { PatientInfo } from "@/store/services/patient";
import ObjectTableItem from "./objectTableItem";
import { useEffect, useState } from "react";
import ReadOnlySection from "./readOnlySection";

interface ReadOnlyMedicationListProps {
  patient: PatientInfo;
  print?: boolean;
}

export default function ReadOnlyMedicationList({
  patient,
  print = false,
}: ReadOnlyMedicationListProps) {

  const [medicationsArray, setMedicationsArray] = useState<
    Array<Record<string, any>>
  >([]);

  useEffect(() => {
    if (patient.medical_history.medications) {
      setMedicationsArray(Object.values(patient.medical_history.medications));
    }
  }, [patient]);

  const MEDICATION_HEADERS = [
    "name",
    "dosage",
    "comment",
    "status",
    "start",
    "end",
  ];

  return (
    <ReadOnlySection header="Current medications" print={print}>
      {medicationsArray && (
        <ObjectTableItem
          orderedHeaders={MEDICATION_HEADERS}
          objectGrid={medicationsArray}
        />
      )}
    </ReadOnlySection>
  );
}
