import dayjs from "dayjs";
import { Pregnancy } from "@/store/services/patient";
import {
  convertUtcIntToLocalDatetime,
  convertLocalDatetimeToUtcDate
} from "@/components/scheduling/calendars/utils";

export function parseParioDate(dateInt: number | null | undefined) {
  if (!dateInt || dateInt < 0) return null;

  let dateString = dateInt.toString();

  // Ensure the string is in the correct format 'YYYYMMdd'
  if (dateString.length < 8) {
    return null;
  }

  let year = parseInt(dateString.substring(0, 4));
  let month = parseInt(dateString.substring(4, 6)) - 1; // JavaScript counts months from 0 to 11
  let day = parseInt(dateString.substring(6, 8));

  return new Date(year, month, day);
}

export function parseParioTimestamp(dateInt: number) {
  let dateString = dateInt.toString();

  if (dateString.length < 14) {
    console.error("Invalid timestamp integer format");
    return null;
  }

  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(4, 6)) - 1;
  const day = parseInt(dateString.substring(6, 8));

  const hour = parseInt(dateString.substring(8, 10));
  const minute = parseInt(dateString.substring(10, 12));
  const second = parseInt(dateString.substring(12, 14));

  return new Date(year, month, day, hour, minute, second);
}

export function dateToParioDate(date: Date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // JavaScript months are 0-11
  let day = date.getDate();

  // Pad month and day with leading zeros if necessary
  let monthString = month < 10 ? "0" + month : "" + month;
  let dayString = day < 10 ? "0" + day : "" + day;

  let dateString = "" + year + monthString + dayString;

  return parseInt(dateString, 10);
}

export function dateToParioTimestamp(date: Date): number {
  let year = date.getFullYear();
  let month = date.getMonth() + 1; // JavaScript months are 0-11
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();

  // Pad month, day, hours, minutes and seconds with leading zeros if necessary
  let monthString = month < 10 ? "0" + month : "" + month;
  let dayString = day < 10 ? "0" + day : "" + day;
  let hoursString = hours < 10 ? "0" + hours : "" + hours;
  let minutesString = minutes < 10 ? "0" + minutes : "" + minutes;
  let secondsString = seconds < 10 ? "0" + seconds : "" + seconds;

  let dateString =
    "" +
    year +
    monthString +
    dayString +
    hoursString +
    minutesString +
    secondsString;

  return parseInt(dateString, 10);
}

export const calculateCurrentPregnancyWeek = (preg: Pregnancy) => {
  const today = dayjs().startOf("day");
  if (preg.edd?.estimated_due_date) {
    const edd = dayjs(preg.edd?.estimated_due_date);
    const weekDiff = edd.diff(today, "weeks");
    return 40 - weekDiff;
  } else {
    null;
  }
};

export const calculateCurrentPostpartumWeek = (preg: Pregnancy) => {
  const today = dayjs().startOf("day");
  if (preg.delivery_date) {
    const delivered = dayjs(parseParioDate(preg.delivery_date || 0));
    const weekDiff = today.diff(delivered, "weeks");
    return weekDiff;
  } else {
    null;
  }
};

export const calculateTotalPregnancyWeeks = (preg: Pregnancy) => {
  if (preg.edd?.estimated_due_date) {
    let endDate = dayjs().startOf("day");
    if (preg.delivery_date) {
      endDate = dayjs(parseParioDate(preg.delivery_date)).startOf("day");
    } else {
      endDate = dayjs().startOf("day");
    }
    const edd = dayjs(preg.edd?.estimated_due_date);
    if (edd.isBefore(endDate)) {
      return 40 + endDate.diff(edd, "weeks");
    } else {
      return 40;
    }
  } else {
    return 40; // default to 40
  }
};

export const getTimeElapsed = (
  timestamp: number | undefined,
  endTime: number | undefined
) => {
  if (!timestamp) return "";
  const now = dayjs();
  const end = (endTime && convertUtcIntToLocalDatetime(endTime)) || now;
  const start = convertUtcIntToLocalDatetime(timestamp);
  // time elapsed in milliseconds
  const timeElapsed = end.diff(start);

  // Convert the duration to hours, minutes, and seconds
  const secondsElapsed = Math.floor(timeElapsed / 1000); // Convert milliseconds to seconds
  const hours = Math.floor(secondsElapsed / 3600);
  const minutes = Math.floor((secondsElapsed % 3600) / 60);
  const seconds = secondsElapsed % 60;

  const formattedHours = hours < 10 ? `0${hours}` : hours;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const parseDateFromString = (val: string) => {
  const parioDate = convertLocalDatetimeToUtcDate(dayjs());

  const lowercaseVal = val.toLowerCase();
  const isPM = lowercaseVal.includes("pm");
  const timeString = lowercaseVal.replace(/[^z0-9]/g, ""); // Remove non-numeric characters
  let parioTime;
  // case user enters "9"
  if (timeString.length === 1) {
    parioTime = "0" + timeString + "0000";
    // case user enter "10 or 09"
  } else if (timeString.length === 2) {
    parioTime = timeString + "0000";
    // case user enter 900 (9am) or 103 (1:03)
  } else if (timeString.length === 3) {
    parioTime = "0" + timeString + "00";
  } else if (timeString.length === 4) {
    parioTime = timeString + "00";
  } else if (timeString.length === 6) {
    parioTime = timeString;
  }

  if (parioTime) {
    if (isPM) {
      parioTime = parseInt(parioTime) + 120000;
    }
  }

  const dateString = parioDate.toString() + parioTime?.toString();

  const datNum = parseInt(dateString);

  // Get the current local time

  const localTime = dayjs();

  // Get the current UTC time
  const offset = localTime.utcOffset() / 60;

  const t = dayjs(`${datNum}`);
  let utcDate;
  if (offset > 0) {
    utcDate = t.add(offset, "hours");
  } else [(utcDate = t.subtract(offset, "hours"))];

  const dateInteger = parseInt(utcDate.format("YYYYMMDDHHmmss"));
  return dateInteger;
};
