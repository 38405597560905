import React, { useState } from "react";
import { Control, Controller, FieldErrors } from "react-hook-form";
import dayjs from "dayjs";

import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import Icon from "@/components/icons";

import { FormField } from "@/store/services/form";
import { parseParioDate } from "@/utils/api/time";

interface TableProps {
  fieldName: string;
  subfields: FormField[];
  defaultValues: Record<string, any>;
  control: Control;
  errors: FieldErrors;
}

type Rows = Record<number, Record<string, any>>;

const fieldTypeMap: Record<string, string> = {
  DATE: "date",
  NUMBER: "number",
  TEXT: "text",
  EMAIL: "email",
  BOOL: "checkbox",
};

const InputTable: React.FC<TableProps> = ({
  fieldName,
  subfields,
  defaultValues,
  control,
  errors,
}) => {
  // Aggregate all default values for this table
  const tablePrefix = `${fieldName}-`;
  const initialRows = Object.entries(defaultValues).reduce(
    (acc, [key, value]) => {
      if (key.startsWith(tablePrefix)) {
        const [indexStr, ...rest] = key.replace(tablePrefix, "").split("-");
        const index = parseInt(indexStr);
        const fieldName = rest.join("-");
        return {
          ...acc,
          [index]: {
            ...acc[index],
            [fieldName]: value,
          },
        };
      }
      return acc;
    },
    {} as Rows
  );

  const [rows, setRows] = useState<Rows>(initialRows);
  const [newRows, setNewRows] = useState<number[]>([]);

  const handleDeleteRow = (indexToDelete: number) => {
    // Handle deletion of existing and new rows
    if (newRows.includes(indexToDelete)) {
      setNewRows(newRows.filter((index) => index !== indexToDelete));
    } else {
      const updatedRows = { ...rows };
      delete updatedRows[indexToDelete];
      setRows(updatedRows);
    }

    subfields.forEach((subfield) => {
      control.unregister(`${fieldName}-${indexToDelete}-${subfield.name}`);
    });
  };

  const addNewRow = () => {
    const newIndex = Object.keys(rows).length + newRows.length;
    setNewRows([...newRows, newIndex]);
  };

  return (
    <table>
      <thead>
        <tr>
          {subfields.map((field) => (
            <th key={field.name}>{field.label}</th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>
        {Object.entries(rows).map(([index, item]) => (
          <tr key={`item-${index}`}>
            {renderRowCells(subfields, fieldName, index, item, control, errors)}
            <td>
              <button
                type="button"
                onClick={() => handleDeleteRow(parseInt(index))}
              >
                <Icon svg="trash_grey" />
              </button>
            </td>
          </tr>
        ))}
        {newRows.map((index) => (
          <tr key={`new-item-${index}`}>
            {renderRowCells(
              subfields,
              fieldName,
              index.toString(),
              {},
              control,
              errors
            )}
            <td>
              <button type="button" onClick={() => handleDeleteRow(index)}>
                <Icon svg="trash_grey" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={subfields.length + 1}>
            <button onClick={addNewRow}>+ Add Row</button>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

function renderRowCells(
  subfields: FormField[],
  fieldName: string,
  index: string,
  item: Record<string, any>,
  control: Control,
  errors: FieldErrors
) {
  return subfields.map((subfield) => (
    <td key={subfield.name}>
      <Controller
        name={`${fieldName}-${index}-${subfield.name}`}
        defaultValue={item[subfield.name as string]}
        control={control}
        render={({ field }) => {
          switch (subfield.type) {
            case "SINGLE_DROPDOWN":
              return (
                <ControlledCombobox
                  hiddenLabel
                  label=""
                  errors={errors}
                  name={`${fieldName}-${index}-${subfield.name}`}
                  control={control}
                  options={subfield.options || []}
                  isHorizontalLayout={false}
                />
              );
            case "BOOL":
              return (
                <Input
                  label=""
                  {...field}
                  value={field.value}
                  id={`${fieldName}-${index}-${subfield.name}`}
                  hiddenLabel
                  type={fieldTypeMap[subfield.type] || "text"}
                  isCondensed
                />
              );
            default:
              return (
                <Input
                  label=""
                  {...field}
                  value={
                    subfield.type === "DATE"
                      ? dayjs(parseParioDate(field.value || 0)).format(
                          "YYYY-MM-DD"
                        )
                      : field.value
                  }
                  id={`${fieldName}-${index}-${subfield.name}`}
                  hiddenLabel
                  type={fieldTypeMap[subfield.type] || "text"}
                  isLarge={subfield.name === "comments"}
                  isCondensed
                />
              );
          }
        }}
      />
    </td>
  ));
}

export default InputTable;
