import { useEffect } from "react";
import { useDispatch } from "react-redux";

import Button from "@/components/button";

import { STATUS_KEYS, STYLES } from "@/globals/constants";

import { setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { FileListItem, useFileDeleteMutation } from "@/store/services/file";

import styles from "../styles.module.scss"


export interface DeleteFilesConfirmationProps {
  viewingFile: FileListItem | null;
  setViewingFile: (file: FileListItem | null) => void;
  files: FileListItem[];
}

export default function DeleteFilesConfirmation({ setViewingFile, viewingFile, files }: DeleteFilesConfirmationProps) {
  const dispatch = useDispatch();
  const [deleteFile, { error, isError, isSuccess }] = useFileDeleteMutation();

  useEffect(() => {
    if (isError) {
      console.error("failed to delete files", error);
      dispatch(addAlertToToastTrough({
        message: "Failed to delete file(s)!",
        type: STATUS_KEYS.ERROR
      }));
    }
    if (isSuccess) {
      dispatch(addAlertToToastTrough({
        message: "Successfully deleted file(s).",
        type: STATUS_KEYS.SUCCESS
      }));
      if (viewingFile) {
        setViewingFile(null);
      }
    }
  }, [isSuccess, isError]);

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  }

  const handleConfirm = () => {
    deleteFile({
      fileDeletePurgeRequest: files.map(f => f.file_id)
    });
    dispatch(setModalIsOpen(false));
  }

  return <div className={styles.DeleteFilesConfirmationModal}>
    <div className={styles.warning}>
      Are you sure you want to delete the below files? This cannot be undone.
    </div>

    <div className={styles.paths}>
      <ul>
        { viewingFile
          ? <li>{ viewingFile.name}</li>
          : files.map(file=> <li key={file.file_id}>{file.name}</li>)}
      </ul>
    </div>

    <div className={styles.buttons}>
      <Button style={STYLES.SECONDARY} onClick={handleCancel}>Cancel</Button>
      <Button style={STYLES.PRIMARY} onClick={handleConfirm}>Confirm</Button>
    </div>
  </div>
}
