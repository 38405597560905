import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    formUpdate: build.mutation<FormUpdateApiResponse, FormUpdateApiArg>({
      query: queryArg => ({
        url: `/form/${queryArg.formId}/update`,
        method: "POST",
        body: queryArg.formCreateUpdateBodyRequest
      })
    }),
    formGetInfo: build.query<FormGetInfoApiResponse, FormGetInfoApiArg>({
      query: queryArg => ({ url: `/form/${queryArg.formId}/info` })
    }),
    formGetSpecificInfo: build.query<
      FormGetSpecificInfoApiResponse,
      FormGetSpecificInfoApiArg
    >({
      query: queryArg => ({
        url: `/form/${queryArg.formId}/version/${queryArg.version}/info`
      })
    }),
    formCreate: build.mutation<FormCreateApiResponse, FormCreateApiArg>({
      query: queryArg => ({
        url: `/location/${queryArg.locationId}/form/create`,
        method: "POST",
        body: queryArg.formCreateUpdateBodyRequest
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as form };
export type FormUpdateApiResponse =
  /** status 200 This is sent in reply to a successful form create or update request. Since an update is really a copy
of an existing form at a new version, there is a new form ID associated with the update so that is what
is returned. Contains a form ID and version.
 */ FormCreateUpdateReply;
export type FormUpdateApiArg = {
  /** The ID of an existing form.
   */
  formId: FormId;
  /** JSON payload for creating/updating a form. Please note that this is slightly different to the actual model,
    which includes an array of schemas. Both the create and update call only take a single schema as an argument.
     */
  formCreateUpdateBodyRequest: FormCreateUpdateBodyRequest;
};
export type FormGetInfoApiResponse =
  /** status 200 Full information for a single form at the specified version.
   */ FormInfo;
export type FormGetInfoApiArg = {
  /** The ID of an existing form.
   */
  formId: FormId;
};
export type FormGetSpecificInfoApiResponse =
  /** status 200 Full information for a single form at the specified version.
   */ FormInfo;
export type FormGetSpecificInfoApiArg = {
  /** The ID of an existing form.
   */
  formId: FormId;
  version: Version;
};
export type FormCreateApiResponse =
  /** status 200 This is sent in reply to a successful form create or update request. Since an update is really a copy
of an existing form at a new version, there is a new form ID associated with the update so that is what
is returned. Contains a form ID and version.
 */ FormCreateUpdateReply;
export type FormCreateApiArg = {
  locationId: LocationId;
  /** JSON payload for creating/updating a form. Please note that this is slightly different to the actual model,
    which includes an array of schemas. Both the create and update call only take a single schema as an argument.
     */
  formCreateUpdateBodyRequest: FormCreateUpdateBodyRequest;
};
export type FormCreateUpdateReply = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  /** Version number of a form.
   */
  version: number;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type FormId = number;
export type FormFieldType =
  | "LABEL"
  | "TEXTAREA"
  | "TEXT"
  | "CHECKBOX"
  | "PHONE"
  | "DATE"
  | "TIME"
  | "DATETIME"
  | "SINGLE_DROPDOWN"
  | "MULTI_DROPDOWN"
  | "STATE"
  | "ZIPCODE"
  | "BOOL"
  | "TABLE"
  | "EMAIL"
  | "NUMBER"
  | "AMOUNT";
export type FormMinLengthValidation = {
  /** Minimum valid length for TEXT, TEXTAREA, EMAIL.
   */
  minLength?: number;
};
export type FormMaxLengthValidation = {
  /** Maximum string length for TEXT, TEXTAREA, EMAIL.
   */
  maxLength?: number;
};
export type FormMinimumValidation = {
  /** Minimum value for NUMBER, AMOUNT
   */
  minimum?: number;
};
export type FormMaximumValidation = {
  /** Maximum value for NUMBER, AMOUNT
   */
  maximum?: number;
};
export type FormCustomValidation = {
  /** The name of a custom validation function in the front-end. This must be just the name of the function,
    so it must be a valid Javascript name, and the front-end must ensure that this function is available
    when form validation happens. The function takes a single argument, which is the input object, and must
    return a single boolean, using true to indivate the field is valid, and false to indicate a problem.
     */
  custom?: string;
};
export type FormFieldValidations =
  | FormMinLengthValidation
  | FormMaxLengthValidation
  | FormMinimumValidation
  | FormMaximumValidation
  | FormCustomValidation;
export type FormFieldOptions = string[];
export type FormField = {
  /** Name of a form or field within a practice or location. Must be a valid HTML id / Javascript variable name and
    it must be unique within the containing entity.
     */
  name?: string;
  /** Label for a form field or section. How these are displayed is up to the front-end and no assumptions
    should be made about presentation. A field label is likely to be different to a section label.
     */
  label: string;
  type: FormFieldType;
  /** Label for a form field or section. How these are displayed is up to the front-end and no assumptions
    should be made about presentation. A field label is likely to be different to a section label.
     */
  subtitle?: string;
  description?: string;
  placeholder?: string;
  tags?: string[];
  /** Whether or not the field can be empty on form submission.
   */
  required?: boolean;
  validations?: FormFieldValidations;
  options?: FormFieldOptions;
  subfields?: FormField[];
};
export type FormSection = {
  /** An ID that can be used as a JavaScript variable name, or an HTML ID. Since HTML IDs are a lot more forgiving
    in their naming, the real restriction is Javascript. Thus, any fields of this type can not contains spaces or
    special characters, and cannot begin with an underscore or a digit. You know the rules.
     */
  id: string;
  /** Label for a form field or section. How these are displayed is up to the front-end and no assumptions
    should be made about presentation. A field label is likely to be different to a section label.
     */
  label: string;
  description?: string;
  fields: FormField[];
  /** Internal system defined flags. Should never be specified and is ignored if it is. This may be displayed for
    debugging purposes but the primary use of this field is for debugging.
     */
  flags?: number;
};
export type FormSections = FormSection[];
export type FormSchema = {
  /** Name of a form or field within a practice or location. Must be a valid HTML id / Javascript variable name and
    it must be unique within the containing entity.
     */
  name: string;
  /** Form title. This will be displayed to the user and included in reports.
   */
  title: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  sections: FormSections;
};
export type FormCreateUpdateBodyRequest = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  /** Version number of a form.
   */
  version: number;
  schema: FormSchema;
};
export type FormInfo = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** Version number of a form.
   */
  version: number;
  /** Name of a form or field within a practice or location. Must be a valid HTML id / Javascript variable name and
    it must be unique within the containing entity.
     */
  name: string;
  /** Form title. This will be displayed to the user and included in reports.
   */
  title: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  sections: FormSections;
};
export type Version = number;
export type LocationId = number;
export const {
  useFormUpdateMutation,
  useFormGetInfoQuery,
  useLazyFormGetInfoQuery,
  useFormGetSpecificInfoQuery,
  useLazyFormGetSpecificInfoQuery,
  useFormCreateMutation
} = injectedRtkApi;
