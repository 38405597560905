import { createSlice } from "@reduxjs/toolkit";

type initialState = {
  selectedRowIds: any[];
};

const initialState: initialState = {
  selectedRowIds: [],
};

export const gridSlice = createSlice({
  name: "datagrid",
  initialState,
  reducers: {
    addId: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.selectedRowIds.push(action.payload);
    },
    removeId: (state, action) => {
      state.selectedRowIds = state.selectedRowIds.filter(
        (rId) => rId !== action.payload
      );
    },
    setIds: (state, action) => {
      state.selectedRowIds = action.payload;
    },
  },
});

export const { addId, removeId, setIds } = gridSlice.actions;

export default gridSlice.reducer;
