/* allergy */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sanitize } from "isomorphic-dompurify";
import dayjs from "dayjs";
/* Local Imports */

// components
import Button from "@/components/button";
import BasicAccordion from "@/components/accordions/basic";
import Icon from "@/components/icons";
import ControlledTextArea from "../textArea/controlledTextArea";
import ContentRenderer from "@/components/textArea/contentRenderer";

// constants
import { STYLES, STATUS_KEYS, METRIC_LABELS } from "@/globals/constants";

// store
import {
  Allergy as AllergyType,
  PatientInfo,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

/* allergy Typescript Interface */
interface allergyProps {
  patientId: number; // id of patient associated with allergy
  isOpen?: boolean; // UI
  allergy: AllergyType;
  form: UseFormReturn;
  isExpanded?: boolean; // UI
  patient?: PatientInfo;
  onEdit?: () => void;
}

export default function Allergy({
  isOpen,
  isExpanded = false,
  form,
  allergy,
  patient,
  onEdit
}: allergyProps) {
  const dispatch = useDispatch();
  /* Redux */
  const [updatePatient] = usePatientUpsertMutation();

  /* Local State */
  const [isEditMode, setIsEditMode] = useState(false);
  /* Effects */
  /* Event Handlers */
  const handleUpdateAllergy = () => {
    onEdit && onEdit();
  };
  const handleDeleteAllergy = () => {
    let allergies = patient?.medical_history.allergies;
    if (!patient) {
      launchToast(
        "Oops, this allergy could not be associated with a patient",
        STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
      );
      return;
    }
    // if allergy is of type icd10
    // find and delete the allergy with the corresponding icd10 code
    if (allergy.icd10_code_details) {
      if (
        allergies?.find(
          a =>
            a.icd10_code_details?.icd_id === allergy.icd10_code_details?.icd_id
        )
      ) {
        allergies = allergies.filter(
          a =>
            a.icd10_code_details?.icd_id !== allergy.icd10_code_details?.icd_id
        );
      } else {
        launchToast(
          "Oops, this allergy could not be found",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
        return;
      }
    } else {
      // if allergy is of type allergy_id
      // find and delete the allergy with the corresponding allergy_id
      if (allergy.allergy_id) {
        if (allergies?.find(a => a.allergy_id === allergy.allergy_id)) {
          allergies = allergies.filter(
            a => a.allergy_id !== allergy.allergy_id
          );
        } else {
          launchToast(
            "Oops, this allergy could not be found",
            STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
          );
          return;
        }
      }
    }
    updatePatient({
      patientUpsertRequest: {
        user_id: patient?.user_id,
        medical_history: {
          ...patient?.medical_history,
          allergies
        }
      }
    })
      .unwrap()
      .then(() =>
        // user alert
        launchToast(
          "Allergy deleted successfully.",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        )
      )
      .catch(() =>
        launchToast(
          "An error occurred while deleting allergy.",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        )
      );
  };

  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  return (
    <div className={clsx(styles.allergy)}>
      <BasicAccordion
        open={isOpen}
        title={allergy.icd10_code_details?.description || allergy.name || ""}
        tag={{
          type: STATUS_KEYS.INFO_GREY,
          label: allergy.icd10_code_details?.name || "Note"
        }}
        smallTitle
        buttonIsLeft
        customLeftContent={
          <div className="flex">
            <p className="xLight med">
              {allergy.date_of_onset
                ? dayjs(allergy.date_of_onset).format("MM/DD/YY")
                : ""}
            </p>
            {isExpanded && (
              <Button style={STYLES.ICON} onClick={handleUpdateAllergy}>
                <Icon svg="edit" />
              </Button>
            )}
            <Button style={STYLES.ICON} onClick={handleDeleteAllergy}>
              <Icon svg="delete" />
            </Button>
          </div>
        }
      >
        <div className={styles.note}>
          <div className={styles.display}>
            <div>
              <p className="t5 med">
                {allergy.severity ? METRIC_LABELS[allergy?.severity] : ""}{" "}
                {allergy.reaction}
              </p>
              <ContentRenderer
                content={sanitize(allergy.notes || "")}
                classes="t5 xLight"
              />
            </div>
          </div>

          {isExpanded && isEditMode && (
            <div className={styles.text}>
              <ControlledTextArea
                hiddenLabel
                label="allergy commentary"
                id={`allergy-${allergy.icd10_code_details?.icd_id}-note`}
                name={`allergy-${allergy.icd10_code_details?.icd_id}-note`}
                rows={3}
                placeholder={allergy.notes}
                form={form}
              />
            </div>
          )}
        </div>
      </BasicAccordion>
    </div>
  );
}
