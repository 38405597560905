import * as generated from "./generated/coding";

const coding = generated.coding.enhanceEndpoints({});

export * from "./generated/coding";
export default coding;
export const {
  useCodingSearchIcd10Query,
  useLazyCodingSearchIcd10Query,
  useCodingIcd10LookupMutation,
} = coding;
