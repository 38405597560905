import * as generated from "./generated/fax";

const fax = generated.fax.enhanceEndpoints({
  addTagTypes: ["Inbound-Faxes"],
  endpoints: {
    faxInboundList: {
      providesTags: ["Inbound-Faxes"]
    },
    faxInboundUpdate: {
      invalidatesTags: ["Inbound-Faxes"]
    },
    faxCopyToPatient: {
      invalidatesTags: ["Inbound-Faxes"]
    }
  }
});

export * from "./generated/fax";
export default fax;
export const { useFaxSendMutation, useFaxListQuery, useLazyFaxListQuery } = fax;
