/* Create Patient Modal Template */

// External
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z, ZodType } from "zod";
import libphonenumber from 'google-libphonenumber';

// styles
import styles from "./styles.module.scss";

// components
import Button from "@/components/button";
import GeneratedInput from "@/components/input/generated";
import ControlledCombobox from "@/components/input/controlledCombobox";

// constants
import { STYLES, STATUS_KEYS, METRIC_LABELS } from "@/globals/constants";
import {
  FIELD_TYPES,
  FORM_SCHEMA,
  buildZodObject
} from "@/globals/constants/formSchema";
import { useCustomPatientDemographics } from "@/globals/helpers/customHooks";
// store
import { RootState } from "@/store/store";
import {
  usePatientUpsertMutation,
  PatientUpsertApiArg,
  PatientInfo
} from "@/store/services/patient";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import KeyValueGrid from "../birthSummary/keyValueGrid";
import KeyValueItem from "../birthSummary/keyValueItem";
import { convertUtcIntToLocalDate } from "../scheduling/calendars/utils";
import { FormFieldOptions } from "@/store/services/form";
import { useMediaQuery } from "react-responsive";
export interface PatientFormProps {
  patient?: Partial<PatientInfo>;
  isReadOnly?: boolean;
  onUpdate?: () => void;
}

export default function PatientGeneralInfoForm({
  patient,
  isReadOnly,
  onUpdate
}: PatientFormProps) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const customPatientDemographicsFields = useCustomPatientDemographics();
  // create or update a patient
  const [upsertPatient] = usePatientUpsertMutation();

  // validation schema definition
  const schema: ZodType = z.object(
    buildZodObject(customPatientDemographicsFields)
  );
  type FormSchemaType = z.infer<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: patient && getInitialValues(patient)
  });

  /* Local State */

  /* Event handlers */

  // on "Update" button click
  const onSubmit = (data: any) => {
    const {
      dob: dob,
      email: email,
      first_name: first_name,
      last_name: last_name,
      phone: mobile,
      addr_line_1: line1,
      addr_line_2: line2,
      addr_line_3: line3,
      city: city,
      state: state,
      zip: zip,
      sex: sex,
      pronouns: pronouns,
      ssn
    } = data;

    const dobAsInt = parseInt((dob as string).split("-").join(""));

    // dispatch to backend
    // UPSERT PATIENT
    const updatePatient = async () => {
      const req: PatientUpsertApiArg = {
        patientUpsertRequest: {
          user_id: patient?.user_id,
          location_ids: sessionInfo?.locations || [],
          sex,
          pronouns,
          ssn,
          dob: dobAsInt,
          first_name,
          last_name,
          address: {
            line1,
            line2,
            line3,
            city,
            state,
            zip
          }
        }
      };
      if (email) {
        req.patientUpsertRequest.email = email;
      }
      if (mobile) {
        req.patientUpsertRequest.mobile = mobile;
      }

      const patientId = await upsertPatient(req).unwrap();

      // on successful upsert, display success toast
      if (patientId) {
        // if patient exists and is being updated
        let message = "Patient information successfully updated";

        dispatch(
          addAlertToToastTrough({
            message,
            type: STATUS_KEYS.SUCCESS
          })
        );
      }
    };

    if (onUpdate) {
      onUpdate();
    }
    updatePatient();
  };

  if (!isReadOnly) {
    return (
      <form
        className={clsx(styles.PatientGeneralInfo)}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Patient information section */}
        {/* set open to default if patient is for new patient */}
        {customPatientDemographicsFields?.map(
          ({ fieldType, fieldID: field, options }) => {
            let inputType = "text";
            switch (fieldType) {
              case FIELD_TYPES.DATE:
              case FIELD_TYPES.DATE_REQ:
                inputType = "date";
                break;
              case FIELD_TYPES.SINGLE_DROPDOWN:
                inputType = "select";
                break;
              case FIELD_TYPES.PHONE:
                inputType = "tel";
                break;
              default:
                inputType = "text";
                break;
            }
            return (
              <div data-cy={"field-" + field} key={field}> {
                fieldType !== FIELD_TYPES.SINGLE_DROPDOWN ? (
                  <GeneratedInput
                    key={field}
                    type={inputType}
                    label={field.replaceAll("_", " ").toLocaleLowerCase()}
                    name={field}
                    id={field}
                    register={register}
                    errors={errors}
                    fullWidth={isMobile}
                  />
                ) : (
                  <ControlledCombobox
                    key={field}
                    label={field.replaceAll("_", " ").toLocaleLowerCase()}
                    errors={errors}
                    name={field}
                    control={control}
                    options={options as FormFieldOptions}
                  isHorizontalLayout={!isMobile}
                  />
                )
              } </div>);
        })}
        <Button style={STYLES.FULL_WIDTH} type="submit">
          Update
        </Button>
      </form>
    );
  } else {
    return (
      <KeyValueGrid>
        {customPatientDemographicsFields?.map(
          ({ fieldType, fieldID: field }) => {
            if (
              fieldType == FIELD_TYPES.DATE ||
              fieldType == FIELD_TYPES.DATE_REQ
            ) {
              if (!patient) {
                return <p key={field}></p>;
              }
              const val = getInitialValues(patient)[field as keyof {}];
              const formattedVal =
                convertUtcIntToLocalDate(val).format("MM/DD/YYYY");

              return (
                <KeyValueItem
                  keyLabel={field.replaceAll("_", " ").toLocaleLowerCase()}
                  key={field}
                >
                  {patient ? <p data-cy={"field-" + field}>{formattedVal}</p> : <p></p>}
                </KeyValueItem>
              );
            } else {
              return (
                <KeyValueItem
                  keyLabel={field.replaceAll("_", " ").toLocaleLowerCase()}
                  key={field}
                >
                  {patient ? (
                    <p data-cy={"field-" + field}>
                      {METRIC_LABELS[
                            getInitialValues(patient)[field as keyof {}]
                          ] || getInitialValues(patient)[field as keyof {}]}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </KeyValueItem>
              );
            }
          }
        )}
      </KeyValueGrid>
    );
  }
}

function getInitialValues(patient: Partial<PatientInfo>) {
  let phoneStr = "--";
  try {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const phone = phoneUtil.parse(patient.phone, "US");
    phoneStr = phoneUtil.formatInOriginalFormat(phone, 'US')
  } catch(e) {
    console.error("PHONE: ", patient.phone, e);
  }

  const defaults: Record<string, any> = {
    first_name: patient?.first_name ? patient?.first_name : "",
    last_name: patient?.last_name ? patient?.last_name : "",
    dob: patient?.dob
      ? convertUtcIntToLocalDate(patient?.dob).format("YYYY-MM-DD")
      : "",
    addr_line_1: patient?.address?.line1 || "",
    addr_line_2: patient?.address?.line2 || "",
    addr_line_3: patient?.address?.line3 || "",
    city: patient?.address?.city || "",
    state: patient?.address?.state || "",
    zip: patient?.address?.zip || "",
    sex: patient?.sex || "",
    pronouns: patient?.pronouns || "",
    phone: phoneStr,
    email: patient?.email || "",
    ssn: patient.ssn || ""
  };

  return defaults;
}
