import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { parseJwt, getCookie } from "@/utils/cookies";
import { Cookie } from "@/store/services/user";

export type AuthInfo = {
  isAuthenticated: boolean;
  sessionInfo: Cookie | null;
};

export const initialState: AuthInfo = {
  isAuthenticated: false,
  sessionInfo: null
};

export const testInitialState: AuthInfo = {
  isAuthenticated: true,
  sessionInfo: {
    email: "provider@test.com",
    first: "Landon",
    is_admin: false,
    is_biller: false,
    is_ma: false,
    is_patient: false,
    is_provider: true,
    last: "Keough",
    locations: [1, 2],
    perms: 4472688747740943,
    practice_id: 1,
    suffix: null,
    title: null,
    user_id: 1,
    version: 1
  } as Cookie
};

export const testAuthSlice = createSlice({
  name: "auth",
  initialState: testInitialState,
  reducers: {
    setIsAuthenticated: (state: AuthInfo, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      if (!state.isAuthenticated) {
        state.sessionInfo = null;
      } else {
        let cookie = getCookie("X-Pario-Auth");
        if (cookie === undefined) cookie="eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InByb3ZpZGVyQHRlc3QuY29tIiwiZmlyc3QiOiJQb3J0aWEiLCJpc19hZG1pbiI6ZmFsc2UsImlzX2JpbGxlciI6ZmFsc2UsImlzX21hIjpmYWxzZSwiaXNfcGF0aWVudCI6ZmFsc2UsImlzX3Byb3ZpZGVyIjp0cnVlLCJsYXN0IjoiS2VvdWdoIiwibG9jYXRpb25zIjpbMSwyXSwicGVybXMiOjQ0NzI2ODg3NDc3NDA5NDMsInByYWN0aWNlX2lkIjoxLCJzdWZmaXgiOm51bGwsInRpdGxlIjpudWxsLCJ1c2VyX2lkIjoxLCJ2ZXJzaW9uIjoxLCJpc3MiOiJwYXJpbyIsInN1YiI6ImF1dGgiLCJleHAiOjE3MDY5NDI2NDIsIm5iZiI6MTcwNjkxMzg0MiwiaWF0IjoxNzA2OTEzODQyfQ.hPfPKZ_Kk1REahFG7WHy8jsFJUor-qijIehSOY0SPrvCS_bXzU1yWpdjF5G4Qqbs5NAZIDLBu-jEGcriruvsxA";

        state.sessionInfo = parseJwt(cookie) as Cookie;
      }
    },
    setSessionInfo: (state: AuthInfo, action: PayloadAction<Cookie>) => {
      state.isAuthenticated = true;
      state.sessionInfo = action.payload;
    },
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuthenticated: (state: AuthInfo, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
      if (!state.isAuthenticated) {
        state.sessionInfo = null;
      } else {
        const cookie = getCookie("X-Pario-Auth");
        if (cookie === undefined) return;

        state.sessionInfo = parseJwt(cookie) as Cookie;
      }
    }
  }
});

export const { setIsAuthenticated } = authSlice.actions;

export default authSlice.reducer;
