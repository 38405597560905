/* External Imports */
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
/* Local Imports */

// components
import Tabbed from "@/components/tabbed";
import CalendarControls from "./calendarControls";
import MonthView from "../views/monthView";
import NewView from "../views/scheduleView";

// constants
// store
import { RootState } from "@/store/store";
import { setDetailDays } from "../calendarSlice";
import { setTabId } from "@/components/tabbed/tabSlice";

// styles
import styles from "../styles.module.scss";

// utils
import {
  useCustomScheduleViews,
  useDefaultCalendarView
} from "@/globals/helpers/customHooks";
import { useEffect } from "react";

/* Calendar Typescript Interface */
interface CalendarProps {}

export default function ScheduleView({}: CalendarProps) {
  const dispatch = useDispatch();
  /* Redux */
  const { selectedDate, detailDays } = useSelector(
    (state: RootState) => state.calendar
  );

  const customScheduleViews = useCustomScheduleViews();
  const defaultCalendarView = useDefaultCalendarView();

  interface ComponentMap {
    [key: string]: React.ComponentType<any>;
  }

  /* Local State */
  const CALENDAR_MAP: ComponentMap = {
    month: MonthView,
    week: NewView,
    day: NewView,
    multi: NewView
  };

  /* Effects */
  useEffect(() => {
    dispatch(
      setDetailDays({
        timestamp: selectedDate.timestamp,
        view: defaultCalendarView
      })
    );
    dispatch(setTabId(defaultCalendarView));
  }, []);

  return (
    <Tabbed
      noPadding
      leftAligned
      initialSelectedTab={defaultCalendarView}
      tabs={customScheduleViews}
      onChange={id => {
        dispatch(
          setDetailDays({ timestamp: selectedDate.timestamp, view: id })
        );
        dispatch(setTabId(id));
      }}
    >
      {tab => {
        const Calendar = CALENDAR_MAP[tab];

        return (
          detailDays && (
            <div className={clsx(styles.Calendar, styles.Schedule)}>
              <CalendarControls tab={tab} days={detailDays} adjustUp />
              <Calendar days={detailDays} />
            </div>
          )
        );
      }}
    </Tabbed>
  );
}
