/* AddTransaction Name */
/* External Imports */
import clsx from "clsx";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
/* Local Imports */

// components
import Input from "@/components/input";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import Button from "@/components/button";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  TransactionId,
  TransactionType,
  UserId,
  usePatientCreateTransactionMutation
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "../modalSlice";

// styles
import styles from "../styles.module.scss";

/* AddTransaction Typescript Interface */
interface AddTransactionProps {
  patientId: UserId;
  type: TransactionType;
  transactionId?: TransactionId;
}

export default function AddTransaction({
  patientId,
  type,
  transactionId
}: AddTransactionProps) {
  const dispatch = useDispatch();
  const form = useForm();
  const dateOfService = form.watch("date_of_service");
  const amount = form.watch("amount");

  /* Redux */

  const [createTransaction] = usePatientCreateTransactionMutation();

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const onSubmit = (data: any) => {
    createTransaction({
      patientId,
      patientTransactionCreatePayload: {
        modifies_transaction_id: transactionId,
        date_of_service: data.date_of_service,
        amount: data.amount,
        description: data.description,
        type // type of service passed from props
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Transaction Added",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Transaction Failed",
            type: STATUS_KEYS.ERROR
          })
        );
        dispatch(setModalIsOpen(false));
      });
  };
  return (
    <div className={clsx(styles.AddTransaction)}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        {type !== "ADJUSTMENT" && (
          <Input
            type="date"
            name="date_of_service"
            id="date_of_service"
            label="Date of Service"
            register={form.register}
            fullWidth
            required
            value={dayjs().format("YYYY-MM-DD")}
          />
        )}
        <Input
          type="string"
          name="amount"
          id="amount"
          label="Amount"
          register={form.register}
          fullWidth
          required
        />
        {type !== "ADJUSTMENT" && (
          <ControlledTextArea
            name="description"
            id="description"
            label="Note"
            form={form}
            rows={2}
          />
        )}
        <div className={styles.buttons}>
          <Button
            style={STYLES.FULL_WIDTH}
            type="submit"
            nativeButtonProps={{
              disabled: !amount || (type !== "ADJUSTMENT" && !dateOfService)
            }}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
}
