/* Share Records Modal Template */
// External
import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { jsPDF } from "jspdf";
import Skeleton from "react-loading-skeleton";
import libphonenumber from "google-libphonenumber";

// components
import Input from "@/components/input";
import Button from "@/components/button";
import ReadOnlyPrenatalFlowSheet from "@/components/prenatalFlowSheet/readOnlyPrenatalFlowSheet";
import ReadOnlySection from "@/components/birthSummary/readOnlySection";
import ReadOnlyProblemList from "@/components/birthSummary/readOnlyProblemList";
import ReadOnlyMedicationList from "@/components/birthSummary/readOnlyMedicationList";
import ReadOnlyAllergyList from "@/components/birthSummary/readOnlyAllergyList";
import ReadOnlyDemographics from "@/components/birthSummary/readOnlyDemographics";
import ObjectTableItem from "@/components/birthSummary/objectTableItem";
import AdditionalDataCheckList, {
  AdditionalDataFormType
} from "@/components/forms/_labor/transferDecisionForm/additionalDataCheckList";
import HealthHistoryFormSection from "@/components/healthHistory/healthHistoryFormSection";

// Globals, helpers, constants
import {
  METRICS,
  METRIC_LABELS,
  STATUS_KEYS,
  STYLES
} from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store
import { RootState } from "@/store/store";
import { setModalContent, setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { FORMAT } from "@/globals/helpers/formatters";
import {
  Pregnancy, ErrorInfo,
  usePatientGetFormQuery,
  usePatientGetInfoQuery
} from "@/store/services/patient";
import { PatientTransferRecordsApiArg, usePatientTransferRecordsMutation } from "@/store/services/pregnancy";
import { useEncounterListQuery } from "@/store/services/encounter";
import { usePracticeInfoQuery } from "@/store/services/practice";
import { useFormGetSpecificInfoQuery } from "@/store/services/form";
import { useProviderGetInfoQuery } from "@/store/services/provider";

// styles
import styles from "../styles.module.scss";
import FaxContact from "@/components/input/faxContact";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import ContentRenderer from "@/components/textArea/contentRenderer";
import { getBlobFromBase64String } from "@/globals/helpers/fileHelpers";
import { MODAL_TYPES } from "../dispatcher";

export interface NewMessageModalProps {
  patientId: number;
}

export default function ShareRecordsModal({ patientId }: NewMessageModalProps) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [encounterList, setEncounterList] = useState<number[]>([]);
  const [faxContactError, setFaxContactError] = useState<boolean>(false);
  const [transferReasonContent, setTransferReasonContent] = useState<string>("");

  const { data: patient } = usePatientGetInfoQuery(
    { patientId },
    { skip: !patientId }
  );
  const { data: provider } = useProviderGetInfoQuery(
    { providerId: sessionInfo?.user_id as number },
    { skip: !sessionInfo }
  );
  const { data: practice } = usePracticeInfoQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo || !sessionInfo.practice_id }
  );
  const formID = practice?.metadata.medical_history_form_id as number;
  const { data: encounters } = useEncounterListQuery(
    {
      scope: "patient",
      id: patientId,
      pagesz: 100
    },
    {
      skip: !patientId
    }
  );
  const getFormRequest = useFormGetSpecificInfoQuery(
    {
      formId: formID as number,
      version: 0
    },
    {
      skip: !formID
    }
  );
  const getSubmissionRequest = usePatientGetFormQuery(
    {
      formId: formID as number,
      patientId: patientId as number
    },
    {
      skip: !formID || !patientId
    }
  );

  const [transferData, transferResponse] = usePatientTransferRecordsMutation();

  const transferDataRef = useRef<any>(null);

  const WATCH_KEYS = [
    // Forms to add to PDF
    "demographics",
    "problem_list",
    "current_meds",
    "allergies",
    "health_history",
    "prenatal_labs",
    "prenatal_flow_sheet",
    "encounters",
    // User inputed data for cover sheet and fax
    "recipient_name",
    "transfer_reasons",
    "other_reasons",
    "provider_name",
    "transport_from",
    "provider_phone"
  ];
  const form = useForm<AdditionalDataFormType>({
    defaultValues: {
      provider_phone: practice?.phone || provider?.phone
    }
  });
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors }
  } = form;

  const watches: Record<string, any> = {};

  WATCH_KEYS.forEach(key => {
    watches[key] = watch(key);
  });

  const transferReason = watch("transferReason");

  useEffect(() => {
    if (provider) {
      setValue("provider_name", FORMAT.name(provider));
      let phoneStr = practice?.phone || provider?.phone;
      if (phoneStr) {
        const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
        const phone = phoneUtil?.parse(phoneStr, "US");
        phoneStr = phoneUtil.formatInOriginalFormat(phone, "US") || "";
        setValue("provider_phone", phoneStr);
      }
    }
  }, [provider, practice]);

  // Effect for watching the encounter
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (!name?.startsWith("encounter-")) {
        return;
      }
      let attachEncounters: number[] = [];
      Object.keys(value).forEach(key => {
        if (!key.startsWith("encounter-") || value[key] === false) {
          return;
        }
        const encounterId = parseInt(key.slice(10));
        attachEncounters.push(encounterId);
      });
      setEncounterList(attachEncounters);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (transferResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Failed to share records",
          type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
    }
  }, [transferResponse]);

  const pastEncounterData = useMemo(() => {
    let list: Record<string, any>[] = [];
    encounters?.forEach(encounter => {
      if (encounterList.includes(encounter.encounter_id)) {
        list.push({
          [METRICS.DATE]: encounter.start || encounter.created,
          [METRICS.PROVIDER]:
            encounter.provider && FORMAT.name(encounter.provider),
          [METRICS.ENCOUNTER_TYPE]:
            METRIC_LABELS[encounter.encounter_type as string] || "",
          [METRICS.STATUS]:
            encounter.status === "SUBMITTED" && encounter.submitted
              ? "Complete"
              : "Incomplete",
          note: encounter.note || ""
        });
      }
    });
    return list;
  }, [encounters, encounterList]);

  const getBase64String = (blob: any) => {
    return new Promise<string>((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () =>
        resolve(
          (reader.result as string)
            .split(",")[1]
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=+$/, "")
        );
      reader.readAsDataURL(blob);
    });
  };

  const createTransferPdf = async (data: any) => {
    const doc = new jsPDF({
      format: "a4",
      unit: "px",
      hotfixes: ["px_scaling"]
    });

    setTransferReasonContent(transferReason);
    transferDataRef.current.style.display = "block";

    const fileKeys = Object.keys(data).filter(
      key => data[key] && /^file_/.test(key)
    );
    const fileIds = fileKeys.map(key => parseInt(key.substring(5)));

    const orderKeys: string[] = [];
    Object.keys(data).forEach(key => {
      if (data[key] && /^lab_order_/.test(key)) {
        orderKeys.push(key.slice(4));
      }
    });

    const pageDivs = document.querySelectorAll(
      ".RecordTransferPdf > div"
    ) as NodeListOf<HTMLElement>;
    pageDivs?.forEach(div => {
      const pageSize = 1121;
      const totalPages = Math.floor(div.clientHeight / 1121);
      div.style.height = pageSize * totalPages + "px";
    });

    await doc.html(transferDataRef.current, { autoPaging: "text", async callback(pdf: jsPDF) {
      pdf.deletePage(pdf.internal.pages.length);
      const pdfBlob = pdf.output("blob");
      const fallbackPDFBase64 = await getBase64String(pdfBlob);

      if (!fallbackPDFBase64) {
        dispatch(addAlertToToastTrough({
            message: "Failed to create transfer PDF. Please try again.",
            type: STATUS_KEYS.ERROR
        }));
        return;
      }

      const payload: PatientTransferRecordsApiArg = {
        patientId: patient?.user_id as number,
        patientTransferPayload: {
          file_as_base64_string: fallbackPDFBase64,
          additional_files: fileIds,
          order_ids: orderKeys,
        }
      };
      const previewPDFBase64 = await transferData(payload).unwrap().catch(err => {
        console.error(err);
        const error: ErrorInfo = err.data as ErrorInfo;

        if (error?.user_facing) {
          dispatch(addAlertToToastTrough({
            message: error.message,
            type: STATUS_KEYS.ERROR
          }));
        } else {
          dispatch(addAlertToToastTrough({
            message: "Failed to generate transfer preview",
            type: STATUS_KEYS.ERROR
          }));
        }
      });
      if (!previewPDFBase64) {
        return;
      }

      dispatch(setModalIsOpen(true));
      dispatch(setModalContent({
        type: MODAL_TYPES.PREVIEW_TRANSFER,
        props: {
          title: "Transfer",
          url: URL.createObjectURL(getBlobFromBase64String(previewPDFBase64)),
          onSubmit: async () => {
            if (!data.custom_recipient) {
              dispatch(addAlertToToastTrough({
                message: "Recipient can't be blank",
                type: STATUS_KEYS.ERROR
              }));
            }

            payload.patientTransferPayload.destination = data.custom_recipient;
            transferData(payload).unwrap().catch(err => {
              console.error(err);
              dispatch(addAlertToToastTrough({
                message: "Failed to send transfer",
                type: STATUS_KEYS.ERROR
              }));
            }).then(() => {
              dispatch(setModalIsOpen(false));
              dispatch(addAlertToToastTrough({
                message: "Successfully sent transfer",
                type: STATUS_KEYS.SUCCESS
              }));
            });;
          }
        }
      }));
    }})
  };

  if (!patient) {
    return <p>Loading...</p>;
  }

  if (transferResponse.isLoading) {
    return (
      <div className={styles.ShareRecordsModal}>
        <Skeleton height={40} />
        <br />
        <Skeleton height={40} />
        <br />
        <Skeleton height={40} />
        <br />
        <Skeleton height={40} />
      </div>
    );
  }

  return (
    <div className={styles.ShareRecordsModal}>
      <form onSubmit={handleSubmit(createTransferPdf)}>
        <Input
          type="text"
          label="Recipient Name*"
          name="recipient_name"
          id="recipient_name"
          register={register}
          fullWidth
          rules={{
            required: true
          }}
          error={errors.recipient_name && "Recipient can't be blank"}
        />
        <FaxContact
          label="Recipient Fax"
          required
          onChange={item => {
            if (item) {
              setFaxContactError(false);
            }
            setValue("custom_recipient", item?.fax)
          }}
        />
        {faxContactError && <p className="error">Recipient can't be blank</p>}
        <Input
          label="Sender Name*"
          name="provider_name"
          id="provider_name"
          type="text"
          fullWidth
          placeholder="Provider's name"
          register={register}
          rules={{ required: true }}
          error={
            errors.deciding_provider && "Please enter a name for the provider"
          }
        />
        <Input
          label="Sender Phone*"
          name="provider_phone"
          id="provider_phone"
          type="text"
          fullWidth
          placeholder="(888) 555 4444"
          register={register}
          rules={{
            required: true,
            minLength: 10
          }}
          error={
            errors.provider_phone &&
            "Please check the provider phone and try again."
          }
        />
        <div className={styles.fullWidth}>
          <ControlledTextArea
            name="transferReason"
            form={form}
            label="Note"
            id="transferReason"
            rows={4}
          />
        </div>
        <br />
        <p className={styles.fullWidth}>
          <strong>Select what records to share</strong>
        </p>
        {patient && (
          <div className={styles.fullWidth}>
            <AdditionalDataCheckList
              patient={patient}
              form={form}
              genericRecords
            />
          </div>
        )}
        <div className={styles.fullWidth}>
          <Button style={STYLES.FULL_WIDTH} type="submit">
            Preview & Fax
          </Button>
        </div>
      </form>
      {/******
       * Transfer PDF below, not shown to user
       */}
      <div
        id="transfer_data"
        className="RecordTransferPdf"
        ref={transferDataRef}
        style={{ display: "none" }}
      >
        <div className="coverSheet">
          <h1>Record Transfer</h1>
          <p>Patient: {FORMAT.name(patient)}</p>
          <p>
            Patient DOB:{" "}
            {convertUtcIntToLocalDatetime(patient.dob).format("MM/DD/YYYY")}
          </p>
          <p>Patient phone: {patient.phone as string}</p>
          <p>Contact phone: {watches.provider_phone}</p>
          <p>Provider: {watches.provider_name}</p>
          <p>Practice: {watches.transport_from}</p>
          <p>Recipient: {watches.recipient_name}</p>
          <p>
            Note: <ContentRenderer content={transferReasonContent} />
          </p>
        </div>

        {patient && watches.demographics && (
          <div>
            <ReadOnlyDemographics patient={patient} print />
          </div>
        )}

        {patient &&
          patient.problems &&
          (patient.problems.diagnoses.length > 0 ||
            patient.problems.notes.length > 0) &&
          watches.problem_list && (
            <div>
              <ReadOnlyProblemList patientId={patient.user_id} print />
            </div>
          )}

        {patient && watches.current_meds && (
          <div>
            <ReadOnlyMedicationList patient={patient} print />
          </div>
        )}

        {patient && watches.allergies && (
          <div>
            <ReadOnlyAllergyList patient={patient} print />
          </div>
        )}

        {patient &&
          watches.health_history &&
          getFormRequest &&
          getFormRequest.isSuccess &&
          getFormRequest.data &&
          getSubmissionRequest.isSuccess &&
          getSubmissionRequest.data && (
            <div>
              {getFormRequest.data.sections.map(section => (
                <HealthHistoryFormSection
                  key={section.label}
                  section={section}
                  submission={getSubmissionRequest.data}
                  print
                />
              ))}
            </div>
          )}

        {patient && watches.prenatal_flow_sheet && (
          <div>
            <ReadOnlyPrenatalFlowSheet
              pregnancy={patient.pregnancy as Pregnancy}
              print
            />
          </div>
        )}

        {watches.encounters &&
          pastEncounterData &&
          pastEncounterData.length > 0 && (
            <ReadOnlySection header="Encounters" print>
              <ObjectTableItem
                orderedHeaders={[
                  METRICS.DATE,
                  METRICS.ENCOUNTER_TYPE,
                  METRICS.PROVIDER,
                  METRICS.STATUS
                ]}
                objectGrid={pastEncounterData || []}
                additionalRowDataHeader="note"
              />
            </ReadOnlySection>
          )}
      </div>
    </div>
  );
}
