/* ConfirmDeleteEncounter Modal Template */

// External
import clsx from "clsx";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

// components
import Button from "@/components/button";

// constants
import { STYLES, STATUS_KEYS } from "@/globals/constants";

// store
import { setModalIsOpen } from "../modalSlice";
import {
  EncounterInfo,
  EncounterType,
  useEncounterDeleteMutation,
} from "@/store/services/encounter";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "../styles.module.scss";

export interface ConfirmDeleteEncounterModalProps {
  message: string;
  title: string;
  encounter: EncounterInfo;
}

export default function ConfirmDeleteEncounter({
  message,
  encounter
}: ConfirmDeleteEncounterModalProps) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [deleteEncounter] = useEncounterDeleteMutation();
  // Event handlers

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const handleConfirm = () => {
    // dispatch ConfirmDeleteEncounter event, e.g. deleting users
    handleDeleteEncounter();
  };

  const handleDeleteEncounter = () => {
    deleteEncounter({ encounterId: encounter.encounter_id })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Encounter successfully deleted",
            type: STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
          })
        );
        // close modal
        dispatch(setModalIsOpen(false));
        // go back
        router.back();
      })
      .catch(() =>
        dispatch(
          addAlertToToastTrough({
            message: "Failed to delete encounter",
            type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
          })
        )
      );
  };

  return (
    <div className={clsx(styles.Confirm)}>
      <div className="padded">{message}</div>
      <div className="padded flex apart">
        <Button style={STYLES.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
        <Button style={STYLES.PRIMARY} onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
}
