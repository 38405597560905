export const getBase64StringFromBlod = (blob: any) => {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      resolve(
        (reader.result as string)
          .split(",")[1]
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=+$/, "")
      );
    reader.readAsDataURL(blob);
  });
};

export const getBlobFromBase64String = (base64: string) => {
    const binaryString = atob(base64);
    const binaryArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
        binaryArray[i] = binaryString.charCodeAt(i);
    }
    const blob = new Blob([binaryArray], {type: 'application/pdf' });
    return blob;
}
