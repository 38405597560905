// External
import dayjs from "dayjs";

// Components
import Clock from "../../../../public/svgs/clock.svg";

// Styles
import styles from "./styles.module.scss";
import { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";

interface DateTimeStampProps {
  // TODO(Landon); better typing
  onChange: (content: any) => void;
  initialValue?: number;
  name: string;
  value: any;
  label?: string;
  hiddenLabel?: boolean;
  disabled?: boolean;
}

export default function DateTimeStamp({
  onChange,
  initialValue,
  name,
  value,
  label = "Time",
  hiddenLabel = false,
  disabled = false
}: DateTimeStampProps) {
  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    if (!initialValue) {
      return;
    }
    // Initial value will be a UTC int
    const utcString = `${initialValue}`;
    const utcDateTime = dayjs.utc(utcString, "YYYYMMDDHHmmss");
    setDateTime(utcDateTime.local().format("YYYYMMDDHHmmss"));
  }, [initialValue]);

  useEffect(() => {
    // Here we check if the value is false or if it's "" because we set
    // the internal state to "" which will change the value causing rerenders
    // since "" doesn't return as falsey
    if (!value || value == "") {
      setDateTime("");
      return;
    }
    // Initial value will be a UTC int
    const utcString = `${value}`;
    const utcDateTime = dayjs.utc(utcString, "YYYYMMDDHHmmss");
    setDateTime(utcDateTime.local().format("YYYYMMDDHHmmss"));
  }, [value]);

  const handleChangeDate = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    // Since this is a date, we can use DayJS to help with the formatting
    // "YYYY-MM-DD" is the standard html date input format
    // "YYYYMMDD" is our date format
    const dateString = dayjs(value, "YYYY-MM-DD").format("YYYYMMDD");
    // Since we're just changing the date, we'll slice the current time value
    // and add it to our date
    const newDateTime = dateString + dateTime.slice(8);
    setDateTime(newDateTime);
  };

  const handleChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    // Since we're just changing the time, we'll slice the current date value
    // and add our time to it
    let newDateTime = "";
    if (dateTime.length < 8) {
      // If we don't have a date in our time string, we set it to today. So
      // if the user JUST edits the time, it doesn't get messed up
      newDateTime += dayjs().format("YYYYMMDD");
    } else {
      // Otherwise, get the date we already have
      newDateTime += dateTime.slice(0, 8);
    }
    // Convert the time value from HH:mm:ss to HHmmss
    const timeString = value.replaceAll(":", "");
    // Add the time string we just created
    newDateTime += timeString;
    setDateTime(newDateTime);
  };

  useEffect(() => {
    const utcDateTime = dayjs(dateTime, "YYYYMMDDHHmmss")
      .utc()
      .format("YYYYMMDDHHmmss");
    const utcInt = parseInt(utcDateTime);
    onChange(utcInt);
  }, [dateTime]);

  return (
    <div className={styles.time}>
      <label
        htmlFor="time"
        className={clsx({ [styles.hiddenLabel]: hiddenLabel })}
      >
        {label}
      </label>
      <div className={styles.timeInputs}>
        <div className={styles.inputWrapper}>
          <input
            step={1}
            data-cy="input-el"
            type="time"
            id="time"
            name="time"
            value={dayjs(dateTime, "YYYYMMDDHHmmss").format("HH:mm:ss")}
            onChange={handleChangeTime}
            disabled={disabled}
          />
        </div>
        <div className={styles.inputWrapper}>
          <input
            step="any"
            data-cy="input-el"
            type="date"
            id="date"
            name="date"
            value={dayjs(dateTime, "YYYYMMDDHHmmss").format("YYYY-MM-DD")}
            onChange={handleChangeDate}
            disabled={disabled}
          />
        </div>

        <button
          onClick={() => {
            setDateTime(dayjs().format("YYYYMMDDHHmmss"));
          }}
          type={"button"}
          disabled={disabled}
        >
          <Clock stroke={styles.primary700} width={16} height={16} />
          Now
        </button>
      </div>
    </div>
  );
}
