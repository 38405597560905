import { createSlice } from "@reduxjs/toolkit";

export const billingSlice = createSlice({
  name: "billing",
  initialState: {
    billingNotificationsCount: 0,
    incompleteEncountersCount: 0
  },
  reducers: {
    setBillingNotificationsCount: (state, action) => {
      state.billingNotificationsCount = action.payload;
    },
    setIncompleteEncountersCount: (state, action) => {
      state.incompleteEncountersCount = action.payload;
    }
  }
});

export const { setBillingNotificationsCount, setIncompleteEncountersCount } = billingSlice.actions;

export default billingSlice.reducer;
