import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import {
    PatientInfo,
  usePatientSubmitFormMutation
} from "@/store/services/patient";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import Demographics from "@/components/dashboard/_sections/demographics";

// store
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useTaskCompleteMutation } from "@/store/services/task";
import { TaskId } from "@/store/services/task";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";

import styles from "../styles.module.scss";
import Button from "@/components/button";

export interface DemographicsTaskModalProps {
  patientId: number;
    title: string;
    taskId?: TaskId; // optional, only if form submission associated with task
}

export default function DemographicsTaskModal({
  patientId,
  taskId
}: DemographicsTaskModalProps) {
  const dispatch = useDispatch();
  const [submitForm] = usePatientSubmitFormMutation();
  const [completeTask] = useTaskCompleteMutation();
  const handleCompleteTask = () => {

    dispatch(setRightPaneOpen(false));
    // if there is an associated task, mark it complete after form submission
    if (taskId) {
        completeTask({ taskId })
        .unwrap()
        .then(() => {
            dispatch(
            addAlertToToastTrough({
                message: "Task marked complete",
                type: STATUS_KEYS.SUCCESS
            })
            );
        })
        .catch(() => {
            dispatch(
            addAlertToToastTrough({
                message: "Oops! Task could not be marked complete",
                type: STATUS_KEYS.ERROR
            })
            );
        });
    }
  };

  return (
    <div className={styles.DemographicsTaskModal}>
      {/* only load form once submission data for this specific form is available */}
          {patientId && <Demographics patientId={patientId} isTaskView />}
          <Button style={STYLES.FULL_WIDTH} onClick={handleCompleteTask}>Submit</Button>
    </div>
  );
}
