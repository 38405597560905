// third-party
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

// store
import {
  PatientUpsertRequest,
  UserInsurance,
  useLazyPatientGetInfoQuery,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import {
  PayorInfo,
  usePayorGetInfoQuery,
  usePayorListQuery
} from "@/store/services/payor";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { Insurance, Statecode } from "@/store/services/user";

// constants
import { STATUS_KEYS } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "../../scheduling/calendars/utils";
import { useEffect } from "react";
import { setModalContent, setModalIsOpen } from "../../modal/modalSlice";
import { MODAL_TYPES } from "../../modal/dispatcher";
import { RootState } from "@/store/store";
import { setRightPaneContent } from "../../drawer/drawerSlice";
import InsuranceReadOnly from "./insuranceReadOnly";
import InsuranceForm from "./insuranceForm";
import EmptyState from "@/components/cards/emptyState";

interface InsuranceformProps {
  patientId: number;
  type: "PRIMARY" | "SECONDARY" | "TERTIARY";
  isReadOnly?: boolean;
  onUpdate?: () => void;
  // Whether or not it's viewed from the patient task in the sidebar
  isTaskView?: boolean;
}

let oldRelToSubscriber = "";

export default function InsuranceInformation({
  patientId,
  type,
  isReadOnly = false,
  onUpdate,
  isTaskView = false,
}: InsuranceformProps) {
  const dispatch = useDispatch();

  const { data: payors } = usePayorListQuery();
  const [upsertPatient] = usePatientUpsertMutation();
  const [getPatient] = useLazyPatientGetInfoQuery();

  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId
    },
    {
      skip: !patientId
    }
  );

  const newPayorId = useSelector((state: RootState) => state.insurance.payorId);

  const getDefaultValues = async () => {
    const defaultPatient = await getPatient({ patientId }).unwrap();
    let defaultValues: Insurance | undefined = {};
    switch (type) {
      case "PRIMARY":
        defaultValues = { ...defaultPatient.insurance?.primary };
        break;
      case "SECONDARY":
        defaultValues = { ...defaultPatient.insurance?.secondary };
        break;
      case "TERTIARY":
        defaultValues = { ...defaultPatient.insurance?.tertiary };
        break;
      default:
        throw Error("invalid insurance type");
    }
    if (defaultValues?.subscriber_dob && defaultValues?.subscriber_dob > 0) {
      // @ts-ignore
      defaultValues.subscriber_dob = defaultValues?.subscriber_dob
        ? convertUtcIntToLocalDatetime(defaultValues?.subscriber_dob).format(
            "YYYY-MM-DD"
          )
        : "";
    }
    return defaultValues;
  };

  const form = useForm<Insurance>({ defaultValues: getDefaultValues });
  const { watch, setValue } = form;

  const relToSubscriber = watch("relationship_to_subscriber");
  const currentPayor = watch("payor");

  const { data: payorInfo } = usePayorGetInfoQuery(
    {
      // @ts-ignore payor not typed correctly
      payorId: currentPayor?.hg_id as string
    },
    {
      // @ts-ignore payor not typed correctly
      skip: !currentPayor?.hg_id
    }
  );

  useEffect(() => {
    if (!patient) {
      // No point if we don't have patient data
      return;
    }

    if (relToSubscriber == "SELF") {
      // autofill
      setValue("subscriber_phone", patient.phone || "");
      setValue("subscriber_first_name", patient.first_name || "");
      setValue("subscriber_last_name", patient.last_name || "");
      // The date input definitely returns a string, I don't know why it's typed as a number
      setValue(
        "subscriber_dob",
        // @ts-ignore
        dayjs(`${patient.dob}`, "YYYYMMDD").format("YYYY-MM-DD")
      );
      setValue("subscriber_ssn", patient.ssn || "");
      setValue("subscriber_address.line1", patient.address?.line1 || "");
      setValue("subscriber_address.line2", patient.address?.line2 || "");
      setValue("subscriber_address.line3", patient.address?.line3 || "");
      setValue("subscriber_address.city", patient.address?.city || "");
      setValue(
        "subscriber_address.state",
        (patient.address?.state || "") as Statecode
      );
      setValue("subscriber_address.zip", patient.address?.zip || "");
    } else if (oldRelToSubscriber == "SELF") {
      // If the current value doesn't equal self but the old value did equal self
      // This stops the data from clearing if you change it from "Guardian" to "Other" or something
      // clear
      setValue("subscriber_phone", "");
      setValue("subscriber_first_name", "");
      setValue("subscriber_last_name", "");
      // The date input definitely returns a string, I don't know why it's typed as a number
      setValue(
        "subscriber_dob",
        // @ts-ignore
        ""
      );
      setValue("subscriber_ssn", "");
      setValue("subscriber_address.line1", "");
      setValue("subscriber_address.line2", "");
      setValue("subscriber_address.line3", "");
      setValue("subscriber_address.city", "");
      setValue("subscriber_address.state", "" as Statecode);
      setValue("subscriber_address.zip", "");
    }
    // Set the value to our current value so when this runs next, it's the old value
    oldRelToSubscriber = relToSubscriber || "";
  }, [relToSubscriber]);

  useEffect(() => {
    if (!newPayorId) {
      return;
    }

    const newPayor = payors?.find(payor => payor.hg_id == newPayorId);

    // @ts-ignore payor value isn't typed correctly
    setValue("payor", newPayor);
  }, [payors, newPayorId]);

  useEffect(() => {
    if (payorInfo) {
      // @ts-ignore payorInfo doesn't contain the phone number?
      setValue("payor_phone", payorInfo.phoneNumber || "");
    }
  }, [payorInfo]);

  const onAddNewPayor = () => {
    if (isTaskView) {
      dispatch(
        setRightPaneContent({
          type: MODAL_TYPES.ADD_NEW_PAYOR,
          props: { title: "Add new payor", isDrawer: true }
        })
      );
    } else {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.ADD_NEW_PAYOR,
          props: { title: "Add new payor" }
        })
      );
      dispatch(setModalIsOpen(true));
    }
  };

  const onSubmit = async (data: Insurance) => {
    if (!patient) {
      return;
    }

    const payor = data.payor as unknown as PayorInfo;

    if (data.subscriber_dob && data.subscriber_dob + "" != "") {
      data.subscriber_dob = parseInt(
        dayjs(data.subscriber_dob as unknown as string).format("YYYYMMDD")
      );
    } else {
      data.subscriber_dob = undefined;
    }

    const patientUpsertRequest: PatientUpsertRequest = {
      user_id: patient.user_id,
      insurance: patient.insurance as UserInsurance
    };

    switch (type) {
      case "PRIMARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          primary: {
            ...data,
            payor: payor.name,
            hg_org_id: payor.hg_id
          }
        };

        break;
      case "SECONDARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          secondary: {
            ...data,
            payor: payor.name,
            hg_org_id: payor.hg_id
          }
        };
        break;
      case "TERTIARY":
        patientUpsertRequest.insurance = {
          ...patientUpsertRequest.insurance,
          tertiary: {
            ...data,
            payor: payor.name,
            hg_org_id: payor.hg_id
          }
        };
        break;
      default:
        throw Error("invalid insurance type");
    }

    try {
      await upsertPatient({ patientUpsertRequest }).unwrap();
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Insurance updated"
        })
      );
      if (onUpdate) {
        onUpdate();
      }
    } catch (err) {
      console.error(err);
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Failed to update insurance"
        })
      );
    }
  };

  if (isReadOnly) {
    if (patient && patient.insurance && patient.insurance.primary) {
      return <InsuranceReadOnly patientId={patientId} type={type} />;
    }
    return <EmptyState />;
  }
  return <InsuranceForm patientId={patientId} type={type} onUpdate={() => onUpdate && onUpdate()} />;
}
