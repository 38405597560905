/* AvatarPlaceholder Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// AvatarPlaceholders

// constants

// store

// styles
import styles from "./styles.module.scss";
import { userIdToHexCode } from "../scheduling/calendars/utils";

/* AvatarPlaceholder Typescript Interface */
interface AvatarPlaceholderProps {
  character: string;
  isLarge?: boolean;
  isSmall?: boolean;
  isInverse?: boolean;
  isXSmall?: boolean;
  userId?: number;
}

export default function AvatarPlaceholder({
  character,
  isLarge = false,
  isSmall = false,
  isXSmall = false,
  isInverse = false,
  userId
}: AvatarPlaceholderProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.AvatarPlaceholder, {
        [styles.large]: isLarge,
        [styles.inverse]: isInverse,
        [styles.small]: isSmall,
        [styles.xSmall]: isXSmall
      })}
      style={{ backgroundColor: userId ? userIdToHexCode(userId) : undefined }}
    >
      {character}
    </div>
  );
}
