import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useCombobox } from "downshift";
import { UseFormRegister, FieldValues } from "react-hook-form";

// Local
// components
import Icon from "../icons";
// styles
import styles from "./styles.module.scss";

interface DropdownProps {
  options: any[];
  name: string;
  id: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  value?: string | null;
  onChange?: (item: any) => void;
  onSelect?: (item: any) => void;
  hiddenLabel?: boolean;
  labelAcc?: (item: any) => string;
  valAcc?: (item: any) => string;
  clear?: boolean;
  isClearOnSelect?: boolean;
  register?: UseFormRegister<FieldValues>;
  customSlot?: (el: any) => JSX.Element;
  truncateResultsTo?: number;
  fullWidth?: boolean;
  disabled?: boolean;
}

export default function Search({
  placeholder = "Search",
  name,
  id,
  label,
  options = [],
  isClearOnSelect,
  onChange,
  onSelect,
  hiddenLabel = false,
  labelAcc = item => item,
  clear = false,
  register,
  customSlot,
  truncateResultsTo,
  fullWidth = false,
  disabled = false
}: DropdownProps) {
  const [inputItems, setInputItems] = useState(options);
  const inputRef = useRef<HTMLInputElement>();

  const {
    isOpen,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    openMenu,
    selectItem
  } = useCombobox({
    items: inputItems,
    itemToString(item) {
      return item ? labelAcc(item) : "";
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (onSelect && selectedItem) {
        onSelect(selectedItem);
      }
      if (isClearOnSelect) {
        selectItem(null);
        // Blur the input after it's cleared and selected
        // @ts-ignore
        if (inputRef.current) inputRef?.current?.blur();
      }
    },
    onInputValueChange: ({ inputValue = "" }) => {
      if (onChange) {
        onChange(inputValue);
      }

      setInputItems(
        options &&
          options.filter(item =>
            labelAcc(item).toLowerCase().includes(inputValue.toLowerCase())
          )
      );
    }
  });

  // reset input selection from outside component
  useEffect(() => {
    if (clear) selectItem(null);
  }, [clear]);

  return (
    <div
      className={clsx(styles.Input, styles.Select, {
        [styles.fullWidth]: fullWidth
      })}
    >
      <label
        {...getLabelProps()}
        className={clsx({ [styles.hidden]: hiddenLabel })}
      >
        {label}
      </label>
      <div className={clsx(styles.inputWrapper, styles.paddedLeft)}>
        <div className={styles.iconStart}>
          <Icon svg="search" width={20} />
        </div>
        <input
          {...getInputProps({
            // @ts-ignore
            ref: inputRef && inputRef,
            ...(register && name && register(name)),
            disabled
          })}
          placeholder={placeholder}
        />
      </div>
      <ul
        {...getMenuProps()}
        className={clsx({ [styles.open]: isOpen && inputItems.length > 0 })}
      >
        {isOpen &&
          inputItems.map((item, index) => (
            <li
              style={
                highlightedIndex === index ? { backgroundColor: "#bde4ff" } : {}
              }
              key={`${item}${index}`}
              {...getItemProps({ item, index })}
            >
              {(customSlot && customSlot(item)) || (item && labelAcc(item))}
            </li>
          ))}
      </ul>
    </div>
  );
}
