// External
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import clsx from "clsx";

// Api
import { PhraseList, PhraseListItem } from "@/store/services/generated/phrase";

// Styles
import styles from "./suggestionList.module.scss";

export interface CustomExtensionOptions {
  items: PhraseList;
  command: ({ phrase }: { phrase: PhraseListItem }) => void;
}

const SuggestionList = forwardRef(
  ({ items, command }: CustomExtensionOptions, ref) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const selectItem = (index: number) => {
      const phrase = items[index];

      if (phrase) {
        command({ phrase });
      }
    };

    const upHandler = () => {
      setSelectedIndex((selectedIndex + items.length - 1) % items.length);
    };

    const downHandler = () => {
      setSelectedIndex((selectedIndex + 1) % items.length);
    };

    const enterHandler = () => {
      selectItem(selectedIndex);
    };

    useEffect(() => setSelectedIndex(0), [items]);

    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }: { event: KeyboardEvent }) => {
        if (event.key === "ArrowUp") {
          upHandler();
          return true;
        }

        if (event.key === "ArrowDown") {
          downHandler();
          return true;
        }

        if (event.key === "Enter") {
          enterHandler();
          return true;
        }

        return false;
      },
    }));

    return (
      <div className={styles.Items}>
        {items.length ? (
          items.map(({ name }, index) => (
            <button
              className={clsx(styles.item, {
                [styles.isSelected]: index == selectedIndex,
              })}
              key={index}
              onClick={() => selectItem(index)}
            >
              {name}
            </button>
          ))
        ) : (
          <div className='item'>No result</div>
        )}
      </div>
    );
  }
);
SuggestionList.displayName = "SuggestionList";

export default SuggestionList;
