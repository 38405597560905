import type { NextResponse } from "next/server";

export function getCookie(name: string) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
      return parts.pop()?.split(';').shift();
  }
}

export function replaceDomain(cookie: string, domain: string) {
  const [prefix, remainder] = cookie.split("; domain=");
  const suffix = (remainder || "").split(";");
  suffix.shift();

  return [prefix, `domain=${domain}`, ...suffix].join("; ");
}

export function unsetCookie(cookieName: string) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

const loginRedirectCookieName = "X-Pario-Login-Redirect-URL";

export function setLoginRedirectUrl(url: string, response: NextResponse) {
    response.cookies.set(loginRedirectCookieName, url);
}

export function getLoginRedirectUrl(): string | undefined {
    const urlEncoded = getCookie(loginRedirectCookieName);
    if (urlEncoded === undefined) {
        return undefined;
    }

    return decodeURIComponent(urlEncoded);
}

export function unsetLoginRedirectUrl() {
    unsetCookie(loginRedirectCookieName);
}

export function parseJwt(token: string) {
    if (!token) { return; }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
}
