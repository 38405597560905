import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    files: build.query<FilesApiResponse, FilesApiArg>({
      query: queryArg => ({
        url: `/files`,
        params: { file_ids: queryArg.fileIds }
      })
    }),
    fileList: build.query<FileListApiResponse, FileListApiArg>({
      query: queryArg => ({
        url: `/files/${queryArg.scope}/${queryArg.id}/list`,
        params: {
          file_name: queryArg.fileName,
          tag_name: queryArg.tagName,
          page: queryArg.page,
          pagesz: queryArg.pagesz
        }
      })
    }),
    fileUpload: build.mutation<FileUploadApiResponse, FileUploadApiArg>({
      query: queryArg => ({
        url: `/files/upload`,
        method: "POST",
        body: queryArg.body,
        params: {
          patient_id: queryArg.patientId,
          pregnancy_id: queryArg.pregnancyId,
          location_id: queryArg.locationId
        }
      })
    }),
    fileGenerateDefaultUrl: build.query<
      FileGenerateDefaultUrlApiResponse,
      FileGenerateDefaultUrlApiArg
    >({
      query: queryArg => ({ url: `/files/${queryArg.fileId}/url` })
    }),
    fileGenerateUrl: build.mutation<
      FileGenerateUrlApiResponse,
      FileGenerateUrlApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/url`,
        method: "POST",
        body: queryArg.fileUrlRequest
      })
    }),
    fileDetails: build.query<FileDetailsApiResponse, FileDetailsApiArg>({
      query: queryArg => ({ url: `/files/${queryArg.fileId}/details` })
    }),
    fileUpdate: build.mutation<FileUpdateApiResponse, FileUpdateApiArg>({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/update`,
        method: "POST",
        body: queryArg.fileUpdateRequest
      })
    }),
    fileDelete: build.mutation<FileDeleteApiResponse, FileDeleteApiArg>({
      query: queryArg => ({
        url: `/files/delete`,
        method: "POST",
        body: queryArg.fileDeletePurgeRequest
      })
    }),
    filePurge: build.mutation<FilePurgeApiResponse, FilePurgeApiArg>({
      query: queryArg => ({
        url: `/files/purge`,
        method: "POST",
        body: queryArg.fileDeletePurgeRequest
      })
    }),
    fileBatchUpdate: build.mutation<
      FileBatchUpdateApiResponse,
      FileBatchUpdateApiArg
    >({
      query: queryArg => ({
        url: `/files/batch-update`,
        method: "POST",
        body: queryArg.fileBatchUpdateRequest
      })
    }),
    fileBatchCopy: build.mutation<
      FileBatchCopyApiResponse,
      FileBatchCopyApiArg
    >({
      query: queryArg => ({
        url: `/files/batch-copy`,
        method: "POST",
        body: queryArg.fileBatchCopyRequest
      })
    }),
    fileAnnotationCreate: build.mutation<
      FileAnnotationCreateApiResponse,
      FileAnnotationCreateApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/annotation/create`,
        method: "POST",
        body: queryArg.fileAnnotationCreateRequest
      })
    }),
    fileAnnotationUpdate: build.mutation<
      FileAnnotationUpdateApiResponse,
      FileAnnotationUpdateApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/annotation/${queryArg.annotationId}/update`,
        method: "POST",
        body: queryArg.fileAnnotationUpdateRequest
      })
    }),
    fileAnnotationDelete: build.mutation<
      FileAnnotationDeleteApiResponse,
      FileAnnotationDeleteApiArg
    >({
      query: queryArg => ({
        url: `/files/${queryArg.fileId}/annotation/${queryArg.annotationId}/delete`,
        method: "POST"
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as file };
export type FilesApiResponse = /** status 200 List of requested file types.
 */ FileList;
export type FilesApiArg = {
  /** A list of file IDs to retrieve.
   */
  fileIds?: FileId[];
};
export type FileListApiResponse = /** status 200 List of requested file types.
 */ FileList;
export type FileListApiArg = {
  /** The scope of files to list. Must be one of: practice, location, provider, or user.
   */
  scope: FileScope;
  /** This is used to filter returned files to include only those containing
    the provided string as a substring of the file's name.
     */
  fileName?: string;
  /** This is used to filter returned files to include only those tagged with
    the provided tag.
     */
  tagName?: TagName;
  id: Id;
  page?: Page;
  pagesz?: PageSize;
};
export type FileUploadApiResponse =
  /** status 200 This is sent in reply to a successful file create request.
   */ FileCreateReply;
export type FileUploadApiArg = {
  patientId?: UserId;
  pregnancyId?: PregnancyId;
  locationId?: LocationId;
  /** Payload required when a file is created. This provides the basic metadata stored for the file and makes it
    possible to upload the file contents to S3.
     */
  body: FormData;
};
export type FileGenerateDefaultUrlApiResponse =
  /** status 200 Temporary S3 retrieval URL.
   */ string;
export type FileGenerateDefaultUrlApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
};
export type FileGenerateUrlApiResponse =
  /** status 200 Temporary S3 retrieval URL.
   */ string;
export type FileGenerateUrlApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** Payload for creating an S3 download URL using custom parameters. This is forward-looking and the backend doesn't
    actually implement this yet.
     */
  fileUrlRequest: FileUrlRequest;
};
export type FileDetailsApiResponse = /** status 200 Temporary S3 retrieval URL.
 */ FileDetails;
export type FileDetailsApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
};
export type FileUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileUpdateApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** Payload to update a file.
   */
  fileUpdateRequest: FileUpdateRequest;
};
export type FileDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileDeleteApiArg = {
  /** Payload required for deleting one or more files. This is an array of file numbers. Absolutely no confirmation
    is requested so be very careful.
     */
  fileDeletePurgeRequest: FileDeletePurgeRequest;
};
export type FilePurgeApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FilePurgeApiArg = {
  /** Payload required for deleting one or more files. This is an array of file numbers. Absolutely no confirmation
    is requested so be very careful.
     */
  fileDeletePurgeRequest: FileDeletePurgeRequest;
};
export type FileBatchUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileBatchUpdateApiArg = {
  /** Payload to update a batch of files.
   */
  fileBatchUpdateRequest: FileBatchUpdateRequest;
};
export type FileBatchCopyApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileBatchCopyApiArg = {
  /** Payload to copy a batch of files.
   */
  fileBatchCopyRequest: FileBatchCopyRequest;
};
export type FileAnnotationCreateApiResponse = /** status 200 Annotation id
 */ AnnotationId;
export type FileAnnotationCreateApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** Payload to create an annotation on a file.
   */
  fileAnnotationCreateRequest: FileAnnotationCreateRequest;
};
export type FileAnnotationUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileAnnotationUpdateApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** The ID of a previously created annotation.
   */
  annotationId: AnnotationId;
  /** Payload to update an annotation on a file.
   */
  fileAnnotationUpdateRequest: FileAnnotationUpdateRequest;
};
export type FileAnnotationDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type FileAnnotationDeleteApiArg = {
  /** The ID of a previously created file.
   */
  fileId: FileId;
  /** The ID of a previously created annotation.
   */
  annotationId: AnnotationId;
};
export type FileUploader = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id?: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name?: string;
};
export type FileListItem = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** Name of a file (max 128 characters).
   */
  name: string;
  /** In a number of places (most notably files) we allow the attachment of an arbitrary list of tags.
    Most tags are simple booleans but a few can have more complex information. A location and a practice
    can define the valid list of tags. Because this is a dictionary it guarantees unique tags.
    This is the type for a collection of tags (i.e one of these will be attached to each practice or location).
     */
  tags: object;
  size: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  viewable_by_patient?: boolean;
  url: string;
  uploaded_by: FileUploader;
  has_annotations?: boolean;
};
export type FileList = FileListItem[];
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type FileId = number;
export type FileScope = "practice" | "location" | "patient" | "pregnancy";
export type TagName = string;
export type Id = number;
export type Page = number;
export type PageSize = number;
export type FileCreateReply = {
  [key: string]: FileId;
};
export type UserId = number;
export type PregnancyId = number;
export type LocationId = number;
export type FileUrlRequest = object;
export type FileStatus = 0 | 1 | 2 | 3;
export type FileAnnotation = {
  /** System-assigned unique ID of an annotation. Used as a table linkage.
   */
  annotation_id: number;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated?: string;
  annotation: string;
};
export type FileDetails = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** Name of a file (max 128 characters).
   */
  name: string;
  size: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique location identifier.
   */
  location_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  viewable_by_patient?: boolean;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** Number of times the file has been downloaded.
   */
  downloads: number;
  status: FileStatus;
  /** S3 ARN allocated to the file.
   */
  s3arn?: string;
  /** In a number of places (most notably files) we allow the attachment of an arbitrary list of tags.
    Most tags are simple booleans but a few can have more complex information. A location and a practice
    can define the valid list of tags. Because this is a dictionary it guarantees unique tags.
    This is the type for a collection of tags (i.e one of these will be attached to each practice or location).
     */
  tags: object;
  metadata: object;
  url: string;
  uploaded_by: FileUploader;
  annotations: FileAnnotation[];
};
export type ErrorId = number;
export type TagId = number;
export type FileUpdateRequest = {
  /** Name of a file (max 128 characters).
   */
  name: string;
  tags: TagId[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  viewable_by_patient?: boolean;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  date_of_service?: string;
};
export type FileDeletePurgeRequest = FileId[];
export type FileBatchUpdateRequest = {
  file_ids: FileId[];
  tags?: TagId[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  viewable_by_patient?: boolean;
  date_of_service?: string;
};
export type FileBatchCopyRequest = {
  file_ids: FileId[];
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
};
export type AnnotationId = number;
export type FileAnnotationCreateRequest = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  annotation: string;
};
export type FileAnnotationUpdateRequest = {
  /** System-assigned unique ID of an annotation. Used as a table linkage.
   */
  annotation_id: number;
  annotation: string;
};
export const {
  useFilesQuery,
  useLazyFilesQuery,
  useFileListQuery,
  useLazyFileListQuery,
  useFileUploadMutation,
  useFileGenerateDefaultUrlQuery,
  useLazyFileGenerateDefaultUrlQuery,
  useFileGenerateUrlMutation,
  useFileDetailsQuery,
  useLazyFileDetailsQuery,
  useFileUpdateMutation,
  useFileDeleteMutation,
  useFilePurgeMutation,
  useFileBatchUpdateMutation,
  useFileBatchCopyMutation,
  useFileAnnotationCreateMutation,
  useFileAnnotationUpdateMutation,
  useFileAnnotationDeleteMutation
} = injectedRtkApi;
