import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useCombobox } from "downshift";

import Icon from "@/components/icons"; // Import your icon component

import styles from "./styles.module.scss";

interface MultiSelectProps {
  label: string;
  options: any[];
  onChange: (selectedItems: any[]) => void;
  placeholder?: string;
  required?: boolean;
  hiddenLabel?: boolean;
  labelAcc?: (item: any) => string;
  valAcc?: (item: any) => string;
  error?: string;
  fullWidth?: boolean;
  isDisabled?: boolean;
  initialValue: any[];
  value?: any;
  valueKey?: string;
  backgroundColorAcc?: (item: any) => string;
  name?: string;
}

export default function MultiSelect({
  label,
  options = [],
  onChange,
  placeholder = "Select",
  required = false,
  hiddenLabel = false,
  error,
  fullWidth = false,
  isDisabled = false,
  labelAcc = d => d,
  valAcc = d => d,
  initialValue,
  backgroundColorAcc,
  name,
  value,
  valueKey
}: MultiSelectProps) {
  const [selectedItems, setSelectedItems] = useState<any[]>(initialValue || []);
  const [items, setItems] = useState(options || []);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    onChange(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (valueKey && value) {
      setSelectedItems(value);
    }
  }, [valueKey]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    reset,
    openMenu
  } = useCombobox({
    items,
    selectedItem: null,
    itemToString(item) {
      return item ? labelAcc(item) : "";
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem && !selectedItems.includes(selectedItem)) {
        setSelectedItems([...selectedItems, selectedItem]);
        openMenu();
        const unSelectedOptions = items.filter(
          item => valAcc(item) !== valAcc(selectedItem)
        );
        setItems(unSelectedOptions);
      }
    },
    onInputValueChange: ({ inputValue = "" }) => {
      const unselected = options.filter(item => !selectedItems.includes(item));
      const filteredItems = unselected.filter(item => {
        const inputValueLowerCase = inputValue?.toLowerCase().trim();
        const itemLowerCase = labelAcc(item).toLowerCase();

        if (inputValueLowerCase === "") {
          return true;
        }

        return (
          itemLowerCase?.includes(inputValueLowerCase) ||
          itemLowerCase?.includes(inputValueLowerCase.replace(/\s/g, ""))
        );
      });
      setItems(filteredItems);
    }
  });

  const handleRemoveItem = (itemToRemove: any) => {
    const updatedItems = selectedItems.filter(
      item => valAcc(item) !== valAcc(itemToRemove)
    );
    setSelectedItems(updatedItems);
    if (!items.includes(itemToRemove)) {
      const updatedOptions = [...items, itemToRemove];
      setItems(updatedOptions);
    }
  };

  return (
    <div
      data-cy={`multi-select-${name}`}
      className={clsx(styles.Input, styles.Select, styles.MultiSelect, {
        [styles.fullWidth]: fullWidth
      })}
    >
      <label
        {...getLabelProps()}
        className={clsx({ [styles.hidden]: hiddenLabel })}
      >
        {label}
      </label>
      <div className={styles.inputWrapper}>
        <div className={styles.selectedItems}>
          {selectedItems.map(item => (
            <div
              key={valAcc(item)}
              className={styles.selectedItem}
              style={{
                backgroundColor: backgroundColorAcc
                  ? backgroundColorAcc(item)
                  : ""
              }}
            >
              {item ? labelAcc(item) : ""}
              <button
                className={styles.removeButton}
                onClick={() => handleRemoveItem(item)}
                type="button"
              >
                <Icon svg="close_grey" width={8} height={8} />
              </button>
            </div>
          ))}
          <input
            {...getInputProps({
              ref: inputRef,
              placeholder,
              disabled: isDisabled
            })}
            className={clsx({ [styles.hasError]: error })}
            data-cy={`multi-select-input-${name}`}
          />
        </div>
        <button
          className={styles.close}
          {...getToggleButtonProps()}
          type="button"
        >
          <Icon svg="caret_down" width={10} />
        </button>
        {items.length > 0 && (
          <ul
            {...getMenuProps()}
            className={clsx({
              [styles.open]: isOpen
            })}
          >
            {isOpen &&
              items &&
              items.map((item, index) => (
                <li
                  style={{
                    backgroundColor:
                      highlightedIndex === index ? "#F9FAFB" : null
                  }}
                  data-cy={`multi-select-option-${valAcc(item)}`}
                  key={valAcc(item)}
                  {...getItemProps({
                    item,
                    index
                  })}
                >
                  {item ? labelAcc(item) : ""}
                </li>
              ))}
          </ul>
        )}
      </div>
      {error && <div className={styles.validationError}>{error}</div>}
    </div>
  );
}
