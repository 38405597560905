import { PatientInfo } from "@/store/services/patient";
import SectionRow from "./sectionRow";
import SectionCol from "./sectionCol";
import KeyValueGrid from "./keyValueGrid";
import KeyValueItem from "./keyValueItem";
import { convertUtcIntToLocalDate } from "../scheduling/calendars/utils";
import ReadOnlySection from "./readOnlySection";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

interface ReadOnlyDemographicsProps {
  patient: PatientInfo;
  print?: boolean;
}

export default function ReadOnlyDemographics({
  patient,
  print = false
}: ReadOnlyDemographicsProps) {
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  return (
    <ReadOnlySection header="Demographics" print={print}>
      <SectionRow>
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="First name">
              <p>{patient.first_name}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Last name">
              <p>{patient.last_name}</p>
            </KeyValueItem>
            {featureFlags?.pronouns_enabled && (
              <KeyValueItem keyLabel="Pronouns">
                <p>{patient.pronouns}</p>
              </KeyValueItem>
            )}
            <KeyValueItem keyLabel="Date of birth">
              <p>
                {convertUtcIntToLocalDate(patient.dob).format("MM/DD/YYYY")}
              </p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Sex">
              <p>{patient.sex}</p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="Phone">
              <p>{patient.phone}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Email">
              <p>{patient.email}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Address">
              <p>{patient.address?.line1}</p>
              <p>{patient.address?.line2}</p>
              <p>{patient.address?.line3}</p>
              <p>
                {patient.address?.city}, {patient.address?.state}{" "}
                {patient.address?.zip}
              </p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
      </SectionRow>
    </ReadOnlySection>
  );
}
