import * as generated from "./generated/chargemaster";

const chargemaster = generated.chargemaster.enhanceEndpoints({});

export * from "./generated/chargemaster";
export default chargemaster;

export const {
  useChargemasterCreateEntryMutation,
  useChargemasterGetEntriesMutation,
  useChargemasterDeleteEntryMutation,
  useChargemasterUpdateEntryMutation
} = chargemaster;
