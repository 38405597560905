import { EVENTS } from "@/components/flows/labor/constants";

const FORM_METRICS = {
  FLUID_APP: "fluid_appearance",
  FLUID_VOL: "fluid_volume",
  RUPT_TYPE: "rupture_type",
  MATERNAL_POS: "maternal_position",
  PRESENTATION: "presentation",
  FETAL_POS: "fetal_position",
  DELIVERY_TYPE: "delivery_type",
  NUCHAL_CORD: "nuchal_cord",
  NUCHAL_HAND: "nuchal_hand",
  DELIVERY: "delivery", // Added for "placenta" form
  PRESENTATION_AT_DELIVERY: "presentation_at_delivery", // Added for "placenta" form
  VESSELS: "vessels", // Added for "placenta" form
  INTACT: "intact", // Added for "placenta" form
  APPEARANCE: "appearance", // Added for "placenta" form
  EPISIOTOMY: "episiotomy",
  EPISIOTOMY_TYPE: "episiotomy_type",
  VAGINAL_LACERATIONS: "vaginal_lacerations",
  LACERATION_DEGREE: "laceration_degree",
  REPAIR: "repair",
  TRANSFER_TYPE: "transfer_type",
  TRANSFER_REASON: "transfer_reason",
  CONDITION_AT_TRANSPORT: "condition_at_transport",
  AMBULANCE_CALLED: "ambulance_called",
  START_TIME: "start_time",
  END_TIME: "end_time",
  COMMENTS: "comments"
};

const FORM_VALUES = {
  [EVENTS.ROM]: {
    [FORM_METRICS.FLUID_APP]: [
      "Clear",
      "Light meconium",
      "Medium meconium",
      "Thick meconium",
      "Term meconium"
    ],
    [FORM_METRICS.FLUID_VOL]: ["Scant", "Moderate", "Large"],
    [FORM_METRICS.RUPT_TYPE]: ["SROM", "AROM", "PROM"]
  },
  [EVENTS.BIRTH]: {
    [FORM_METRICS.MATERNAL_POS]: [
      "Semi-sit",
      "H&K",
      "Supine",
      "Side-lying",
      "Squat",
      "Sitting",
      "McRoberts",
      "Kneeling",
      "Stand",
      "Hanging",
      "Lithotomy",
      "Other"
    ],
    [FORM_METRICS.PRESENTATION]: [
      "Flexed Cephalic",
      "Compound Cephalic",
      "Military",
      "Brow",
      "Face",
      "Frank Breech",
      "Complete Breech",
      "Footling Breech",
      "Kneeling Breech",
      "Shoulder",
      "Breech Type Unknown",
      "Cephalic Attitude Unknown",
      "Unknown",
      "Other"
    ],
    [FORM_METRICS.FETAL_POS]: [
      "ROA",
      "ROT",
      "ROP",
      "LOA",
      "LOT",
      "LOP",
      "RSA",
      "RST",
      "RSP",
      "LSA",
      "LST"
    ],
    [FORM_METRICS.DELIVERY_TYPE]: ["Vaginal", "Cesarean"],
    [FORM_METRICS.NUCHAL_CORD]: ["Y", "N"],
    [FORM_METRICS.NUCHAL_HAND]: ["Right", "Left", "None"]
  },
  [EVENTS.PLACENTA]: {
    [FORM_METRICS.DELIVERY]: ["Spontaneous", "Assisted"],
    [FORM_METRICS.PRESENTATION_AT_DELIVERY]: ["Duncan", "Schultz", "Other"],
    [FORM_METRICS.VESSELS]: ["3 Vessel Cord", "2 Vessel Cord"],
    [FORM_METRICS.INTACT]: ["Y", "N"],
    [FORM_METRICS.APPEARANCE]: ["Normal", "Abnormal"]
  },
  [EVENTS.LACERATION]: {
    [FORM_METRICS.EPISIOTOMY_TYPE]: [
      "Midline",
      "LML (Left Medio-Lateral)",
      "RML (Right Medio-Lateral)",
      "Perineal Snip"
    ],
    [FORM_METRICS.LACERATION_DEGREE]: [
      "1st Degree",
      "2nd Degree",
      "3rd Degree",
      "4th Degree",
      "Sulcal"
    ],
    [FORM_METRICS.REPAIR]: ["Y", "N"]
  },
  [EVENTS.TRANSPORT]: {
    [FORM_METRICS.TRANSFER_TYPE]: [
      "intrapartum",
      "newborn postpartum",
      "maternal postpartum"
    ],
    [FORM_METRICS.TRANSFER_REASON]: [
      "1st stage arrest",
      "2nd stage arrest",
      "cord prolapse",
      "dehydration",
      "HTN disorder",
      "malposition/presentation",
      "Exhaustion",
      "need pain relief",
      "s/sx infection",
      "light/thin meconium",
      "thick/particulate meconium",
      "non-reassuring FHTs",
      "placental abruption",
      "uterine rupture",
      "prolonged labor (>24 hours)",
      "prolonged ROM",
      "other"
    ],
    [FORM_METRICS.CONDITION_AT_TRANSPORT]: ["stable", "unstable", "critical"],
    [FORM_METRICS.AMBULANCE_CALLED]: ["Y", "N"]
  }
};

const FORM_METRIC_LABELS = {
  [FORM_METRICS.FLUID_APP]: "Fluid Appearance",
  [FORM_METRICS.FLUID_VOL]: "Volume",
  [FORM_METRICS.RUPT_TYPE]: "Rupture Type",
  [FORM_METRICS.MATERNAL_POS]: "Maternal Position",
  [FORM_METRICS.PRESENTATION]: "Presentation",
  [FORM_METRICS.FETAL_POS]: "Fetal Position",
  [FORM_METRICS.DELIVERY_TYPE]: "Delivery Type",
  [FORM_METRICS.NUCHAL_CORD]: "Nuchal Cord",
  [FORM_METRICS.NUCHAL_HAND]: "Nuchal Hand",
  [FORM_METRICS.DELIVERY]: "Delivery", // Added for "placenta" form
  [FORM_METRICS.PRESENTATION_AT_DELIVERY]: "Presentation at Delivery", // Added for "placenta" form
  [FORM_METRICS.VESSELS]: "Vessels", // Added for "placenta" form
  [FORM_METRICS.INTACT]: "Intact", // Added for "placenta" form
  [FORM_METRICS.APPEARANCE]: "Appearance", // Added for "placenta" form
  [FORM_METRICS.EPISIOTOMY_TYPE]: "Episiotomy Type",
  [FORM_METRICS.VAGINAL_LACERATIONS]: "Vaginal Lacerations",
  [FORM_METRICS.LACERATION_DEGREE]: "Laceration Degree",
  [FORM_METRICS.REPAIR]: "Repair",
  [FORM_METRICS.TRANSFER_TYPE]: "Type",
  [FORM_METRICS.TRANSFER_REASON]: "Reason",
  [FORM_METRICS.CONDITION_AT_TRANSPORT]: "Condition at Transport",
  [FORM_METRICS.AMBULANCE_CALLED]: "Ambulance Called",
  [FORM_METRICS.START_TIME]: "Start Time",
  [FORM_METRICS.END_TIME]: "End Time",
  [FORM_METRICS.COMMENTS]: "Comments"
};

const NEWBORN_FORM = {
  demographics: [
    { id: "first_name", label: "Baby's First Name" },
    { id: "birth_date", label: "Birth Date", type: "date" },
    { id: "last_name", label: "Baby's Last Name" },
    { id: "birth_time", label: "Birth Time" },
    {
      id: "baby_sex",
      label: "Sex",
      type: "select",
      options: ["MALE", "FEMALE"]
    }
  ],

  // TODO: Are these correct? I added some to match with the Infant
  // Growth Sheet but I'm not sure if any of it is incorrect
  // For example, chest is in inches and everything else is metrics....
  infantGrowth: [
    { id: "birth_weight", label: "Weight" },
    { id: "baby_chest_width", label: "Chest (in)" },
    { id: "standing_height", label: "Standing Height (cm)" },
    { id: "head_circumference", label: "Head Circumference (cm)" },
    { id: "recumbent_length", label: "Recumbent Length (cm)" },
    { id: "EGA", label: "EGA (weeks)" }
  ],

  parentVitals: [
    { id: "parent_name", label: "Parent's Name" },
    {
      id: "parent_gender",
      label: "Gender",
      type: "select",
      options: ["female", "non-binary", "male", "other"]
    },
    { id: "length", label: "Length (in)" },
    { id: "OFC", label: "OFC (in)" },
    { id: "parent_temp", label: "Temp (°F)" },
    { id: "parent_birth_time", label: "Birth Time" },
    { id: "parent_respirations", label: "Respirations" }
  ],

  LATCH: [
    {
      id: "latch",
      label: "Latch",
      scores: [
        "Too sleepy or reluctant; No latch achieved",
        "Repeated attempts; Hold nipple in mouth stimulate to suck",
        "Grasps breast; Tongue down; Lips flanged; Rhythmic sucking"
      ]
    },
    {
      id: "aud_swallow",
      label: "Audible Swallowing",
      scores: [
        "None",
        "A few with stimulation",
        "Spontaneous and intermittent <24 hours old; Spontaneous and frequent >24 hours old"
      ]
    },
    {
      id: "nip_type",
      label: "Type of Nipple",
      scores: ["Inverted", "Flat", "Everted (after stimulation)"]
    },
    {
      id: "comf_mother",
      label: "Comfort of Mother (Breast/Nipple)",
      scores: [
        "Engorged; cracked; bleeding; large blisters or bruises - Severe discomfort",
        "Filling Reddened/small blisters or bruises - Mild/moderate discomfort",
        "Soft; Tender"
      ]
    },
    {
      id: "hold_position",
      label: "Hold (Positioning)",
      scores: [
        "Full assist (staff holds infant at breast)",
        "Minimal assist (i.e., elevate head of bed; place pillows for support.); Teach one side; mother does other; Staff holds and then mother takes over",
        "No assist from staff; Mother able to position/hold infant"
      ]
    }
  ],
  APGAR: [
    {
      id: "heart_rate",
      label: "Heart Rate",
      scores: ["Absent", "Below 100 bpm", "Above 100 bpm"]
    },
    {
      id: "resp_effect",
      label: "Respiratory Effect",
      scores: ["Absent", "Slow, irregular, gasping", "Good crying"]
    },
    {
      id: "musc_tone",
      label: "Muscle Tone",
      scores: ["Flaccid", "Some flexion of extremities", "Active motion"]
    },
    {
      id: "reflex_irrit",
      label: "Reflex Irritability",
      scores: [
        "None",
        "Grimace, some reaction to stim",
        "Cries when stimulated"
      ]
    },
    {
      id: "color",
      label: "Color",
      scores: ["Pale blue", "Body pink, extremities blue", "Completely pink"]
    }
  ],

  reflexes: [
    { id: "babinski", label: "Babinski" },
    { id: "palmar", label: "Palmar" },
    { id: "plantar", label: "Planter" },
    { id: "moro", label: "Moro" },
    { id: "sucking", label: "Sucking" },
    { id: "swallowing", label: "Swallowing" },
    { id: "step", label: "Step" },
    { id: "tonic_neck", label: "Tonic Neck" }
  ],

  physical: [
    {
      id: "skin",
      label: "Skin",
      options: [
        { id: "normal", label: "Normal" },
        { id: "polycythemia", label: "Polycythemia" },
        { id: "jaundice", label: "Jaundice" },
        { id: "desquamation", label: "Desquamation" },
        { id: "lanugo", label: "Lanugo" },
        { id: "birth_marks", label: "Birth Marks" }
      ]
    },
    {
      id: "head",
      label: "Head/Neck",
      options: [
        { id: "normal", label: "Normal" },
        { id: "molding", label: "Molding" },
        { id: "caput", label: "Caput" },
        { id: "bruising", label: "Bruising" },
        { id: "cephalohematoma", label: "Cephalohematoma" },
        { id: "fontanelles", label: "Fontanelles" }
      ]
    },

    {
      id: "eyes",
      label: "Eyes",
      options: [
        { id: "normal", label: "Normal" },
        { id: "red_spots", label: "Red Spots" },
        { id: "jaundice", label: "Jaundice" },
        { id: "pupils", label: "Pupils" },
        { id: "tracking", label: "Tracking" }
      ]
    },

    {
      id: "vit_k",
      label: "Vitamin K Administered",
      options: [
        { id: "PO", label: "PO" },
        { id: "IM", label: "IM" },
        { id: "declined", label: "Declined" }
      ]
    },
    {
      id: "ENT",
      label: "ENT",
      options: [
        { id: "ear_plac", label: "Ear Placement" },
        { id: "sound", label: "Reactivity to Sound" },
        { id: "lips", label: "Lips" }
      ]
    },
    {
      id: "eye_proph",
      label: "Eye Prophylaxis",
      options: [
        { id: "administered", label: "Administered" },
        { id: "declined", label: "Declined" }
      ]
    },
    { id: "thorax", label: "Thorax (retractions)" },
    {
      id: "abdomen",
      label: "Abdomen",
      options: [
        { id: "vessel_cord", label: "3 Vessel Cord" },
        { id: "no_masses", label: "No Masses" },
        { id: "BT+", label: "BT+" }
      ]
    },
    {
      id: "heart",
      label: "Heart",
      options: [{ id: "normal", label: "Normal" }]
    },
    {
      id: "femoral_pulses",
      label: "Femoral Pulses",
      options: [{ id: "normal", label: "Normal" }]
    },
    {
      id: "muscle_tone",
      label: "Muscle Tone",
      options: [{ id: "normal", label: "Normal" }]
    },
    {
      id: "genitals",
      label: "Genitals",
      options: [
        { id: "testes_desc", label: "Testes Descended" },
        { id: "edema", label: "Edema" },
        { id: "labia", label: "Labia" },
        { id: "clitoris", label: "Clitoris" }
      ]
    },
    {
      id: "spine",
      label: "Spine/Anus",
      options: [
        { id: "sinuses", label: "Sinuses" },
        { id: "anus_patent", label: "Anus Patent" }
      ]
    },
    {
      id: "lungs",
      label: "Lungs",
      options: [{ id: "normal", label: "Normal" }]
    },
    {
      id: "extremeties",
      label: "Extremities",
      options: [
        { id: "fingers", label: "Fingers" },
        { id: "toes", label: "Toes" },
        { id: "clavicles", label: "Clavicles" }
      ]
    }
  ],

  physical_v2: [
    {
      id: "general_appearance",
      label: "General Appearance",
      options: [
        { id: "normal", label: "Active, alert, crying with stimulation" },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "tone",
      label: "Tone",
      options: [
        { id: "normal", label: "Normal tone - Well flexed, all extremities" },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "skin",
      label: "Skin",
      options: [
        {
          id: "normal",
          label:
            "Normal - Warm, pink, dry, intact. Vernix present in creases of skin folds."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "birthmarks",
      label: "Birthmarks",
      options: [
        { id: "normal", label: "Normal - None noted." },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "head_neck",
      label: "Head/Neck",
      options: [
        {
          id: "normal",
          label:
            "Normal - Normocephalic. Fontanells soft and flat. No cephalohematoma or caput. No webbing of neck."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "eyes",
      label: "Eyes",
      options: [
        {
          id: "normal",
          label: "Normal - Sclera white, conjunctiva pink. No discharge."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "red_reflexes",
      label: "Red Reflexes",
      options: [
        { id: "normal", label: "Normal - Noted bilaterally." },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "mouth_palate",
      label: "Mouth and Palate",
      options: [
        {
          id: "normal",
          label:
            "Normal - Pink, moist. Hard and soft palate intact. No lip or tongue tie noted. No epstein pearls."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "ears",
      label: "Ears",
      options: [
        {
          id: "normal",
          label:
            "Normal - Cartilage soft with appropriate recoil. Pinna above canthus bilaterally. Not pits or tags."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "heart_sounds",
      label: "Heart Sounds",
      options: [
        { id: "normal", label: "Normal - RRR. No murmurs appreciated." },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "pulses",
      label: "Pulses",
      options: [
        {
          id: "normal",
          label:
            "Normal - Brachial and femoral pulses strong and equal bilaterally."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "lungs",
      label: "Lungs",
      options: [
        {
          id: "normal",
          label:
            "Normal - CTAB. No crackles or wheezes appreciated in all 4 quadrants."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "abdomen",
      label: "Abdomen",
      options: [
        {
          id: "normal",
          label:
            "Normal - Soft, round, symmetrical. No palpable masses. Bowel sounds present."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "umbilicus",
      label: "Umbilicus",
      options: [
        {
          id: "normal",
          label:
            "Normal- 3VC. Cord clamp intact. No erythema, edema, heat, or discharge."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "genitourinary_male",
      label: "Genitourinary",
      options: [
        {
          id: "normal",
          label:
            "Normal - Urethra appears intact. No obvious hypospadias or epispadias. Testes descended bilaterally."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "genitourinary_female",
      label: "Genitourinary",
      options: [
        {
          id: "normal",
          label:
            "Normal - Labia majora covers labia minora. No pseudomenses. Urethra appears patent."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "anus",
      label: "Anus",
      options: [
        { id: "normal", label: "Normal - Appears patent." },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "hips",
      label: "Hips",
      options: [
        {
          id: "normal",
          label:
            "Normal - Symmetric knee height and gluteal folds. Negative Barlow’s and Ortolani’s."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "spine",
      label: "Spine",
      options: [
        {
          id: "normal",
          label:
            "Normal - Straight, intact. No sacral dimple, pitting, or hair tuft."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "clavicles",
      label: "Clavicles",
      options: [
        { id: "normal", label: "Normal - Intact, no crepitus bilaterally." },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "musculoskeletal",
      label: "Musculoskeletal",
      options: [
        { id: "normal", label: "Normal - Full ROM all extremities." },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "muscle_tone",
      label: "Muscle Tone",
      options: [
        { id: "normal", label: "Normal" },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "nipples",
      label: "Nipples",
      options: [
        {
          id: "normal",
          label:
            "Chest with normal contour with nipples near the mid-clavicular line. Round and symmetric with proper number and placement of nipples."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    },
    {
      id: "nares",
      label: "Nares",
      options: [
        {
          id: "normal",
          label: "Normal - Patent."
        },
        { id: "abnormal", label: "Abnormal" }
      ]
    }
  ],

  physical_two: [
    {
      id: "gest_age",
      label: "Estimated Gestational Age",
      options: [
        { id: "34", label: "34" },
        { id: "35", label: "35" },
        { id: "36", label: "36" },
        { id: "37", label: "37" },
        { id: "38", label: "38" },
        { id: "39", label: "39" },
        { id: "40", label: "40" },
        { id: "41", label: "41" },
        { id: "42", label: "42" },
        { id: "43", label: "43" }
      ]
    },
    {
      id: "vernix",
      label: "Vernix",
      options: [
        { id: "cover", label: "Covers body, thick layer" },
        { id: "back_scalp", label: "Back, scalp, in creases" },
        { id: "scant", label: "Scant in creases" },
        { id: "none", label: "No vernix" }
      ]
    },
    {
      id: "breast_tissue",
      label: "Breast Tissue and Areola",
      options: [
        { id: "areola", label: "Areola raised" },
        { id: "1mm_nodule", label: "1-2 mm nodule" },
        { id: "3mm_nodule", label: "3-5 mm nodule" },
        { id: "5mm_nodule", label: "5-6 mm nodule" },
        { id: "7mm_nodule", label: "7-10 mm nodule" }
      ]
    },
    {
      id: "ear_form",
      label: "Ear Form",
      options: [
        { id: "incurving_superior", label: "Beginning incurving superior" },
        { id: "incurving_upper", label: "Incurving upper 2/3 pinnae" },
        { id: "incurving_lobe", label: "Well-defined incurving to lobe" }
      ]
    },
    {
      id: "ear_cart",
      label: "Ear Cartilage",
      options: [
        { id: "scant_folding", label: "Scant, ret. slowly from folding" },
        { id: "spring_folding", label: "Thin, springs back from folding" },
        { id: "pinnae_firm", label: "Pinnae firm, remains erect from head" }
      ]
    },
    {
      id: "sole_creases",
      label: "Sole Creases",
      options: [
        { id: "1_ant", label: "1-2 ant." },
        { id: "2_ant", label: "2-3 ant." },
        { id: "2_sole", label: "Anterior 2/3 sole" },
        { id: "involve_heel", label: "Involving heel" },
        { id: "entire_sole", label: "Entire sole" }
      ]
    },
    {
      id: "skin_appearance",
      label: "Skin (thickness and appearance)",
      options: [
        { id: "smooth", label: "Smooth, no edema" },
        { id: "no_desq", label: "Thicker, no desquamation, few vessels" },
        { id: "some_desq", label: "Some desquamation" },
        { id: "more_desq", label: "Thicker, desquamation entire body" }
      ]
    },
    {
      id: "nail_plates",
      label: "Nail Plates",
      options: [
        { id: "to_fingertips", label: "Nails to fingertips" },
        { id: "above_fingertips", label: "Well past tips" }
      ]
    },
    {
      id: "hair",
      label: "Hair",
      options: [
        { id: "fine_wooly", label: "Fine/wooly, bunches out from head" },
        { id: "silky_flat", label: "Silky single strands, lays flat" },
        { id: "receding", label: "Receding" }
      ]
    },
    {
      id: "lanugo",
      label: "Lanugo",
      options: [
        { id: "only_body", label: "None on face, present on body" },
        { id: "shoulders", label: "Present on shoulders" },
        { id: "no_lanugo", label: "No lanugo" }
      ]
    },
    {
      id: "labia",
      label: "Labia and Clitoris",
      options: [
        {
          id: "prominent_clitoris",
          label: "Prominent clitoris, labia small, widely separated"
        },
        {
          id: "partially_obscured_clitoris",
          label: "Labia majora larger, nearly cover clitoris"
        },
        { id: "covered_clitoris", label: "Labia minora and clitoris covered" }
      ]
    },
    {
      id: "testes",
      label: "Testes",
      options: [
        { id: "inguinal_canal", label: "Palpable in inguinal canal" },
        { id: "upper_scrotum", label: "In upper scrotum" },
        { id: "lower_scrotum", label: "In lower scrotum" }
      ]
    },
    {
      id: "scrotum",
      label: "Scrotum",
      options: [
        { id: "few_rugae", label: "Few Rugae" },
        { id: "anterior_rugae", label: "Anterior Rugae" },
        { id: "cover_rugae", label: "Rugae Cover" },
        { id: "pendulous", label: "Pendulous" }
      ]
    },
    {
      id: "skull_firm",
      label: "Skull Firmness",
      options: [
        {
          id: "soft_fontanelle",
          label: "Soft to 1 in from anterior fontanelle"
        },
        {
          id: "spongy_fontanelle",
          label: "Spongy at edges of fontanelle, center firm"
        },
        {
          id: "bones_displaceable",
          label: "Bones hard, sutures easily displaced"
        },
        { id: "bones_hard", label: "Hard, can't displace" }
      ]
    }
  ]
};

export { FORM_VALUES, FORM_METRICS, FORM_METRIC_LABELS, NEWBORN_FORM };
