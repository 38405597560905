// External
import dayjs from "dayjs";
import { sanitize } from "dompurify";

// Components
import ContentRenderer from "@/components/textArea/contentRenderer";

// Globals, constants, helpers
import { METRIC_LABELS } from "@/globals/constants";
import { PROGRESS_UPDATE_LABELS } from "../flows/labor/constants";

// Styles
import styles from "./styles.module.scss";

interface ObjectTableItemProps {
  orderedHeaders: Array<string>;
  headerLabels?: Record<string, string>;
  objectGrid: Array<Record<string, any>>;
  additionalRowDataHeader?: string;
}

export default function ObjectTableItem({
  orderedHeaders,
  headerLabels,
  objectGrid,
  additionalRowDataHeader,
}: ObjectTableItemProps) {
  return (
    <table className={styles.TableItem}>
      <thead>
        <tr>
          {orderedHeaders.map((item, index) => (
            <th key={index}>
              {headerLabels
                ? headerLabels[item] || item
                : PROGRESS_UPDATE_LABELS[item] || METRIC_LABELS[item] || item}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {objectGrid.map((object, index) => (
          <>
            <tr key={index}>
              {orderedHeaders.map((key: string, index: number) => {
                if (key.indexOf("date") != -1) {
                  return (
                    <td key={index}>
                      {dayjs(object[key] + "").format("MM/DD/YYYY")}
                    </td>
                  );
                } else {
                  return <td key={index}>{object[key]}</td>;
                }
              })}
            </tr>
            {additionalRowDataHeader && object[additionalRowDataHeader] && (
              <td colSpan={orderedHeaders.length} className={styles.border}>
                <ContentRenderer content={sanitize(object[additionalRowDataHeader])} />
              </td>
            )}
          </>
        ))}
      </tbody>
    </table>
  );
}
