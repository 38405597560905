/* DayContent Appointment Display */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { MouseEvent } from "react";

/* Local Imports */

// components
import Tag from "@/components/tag";

// constants
import {
  EVENT_COLOR_MAP,
  STATUS_KEYS,
  METRIC_LABELS,
  METRICS
} from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import { convertUtcIntToLocalDatetime, userIdToHexCode } from "../utils";

// store
import { UserInfo } from "@/store/services/encounter";

// styles
import styles from "../styles.module.scss";
import Icon from "@/components/icons";

/* DayContent Typescript Interface */
interface DayContentProps {
  event: any;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  offsetUnit?: number;
  scheduleEventsOffset?: number;
}

export default function CalendarEventContent({
  event,
  onClick,
  offsetUnit = 0,
  scheduleEventsOffset = 0
}: DayContentProps) {
  const { start_at, end_at, participants } = event;

  // get appointment duration in minutes
  const duration = dayjs(end_at).diff(dayjs(start_at), "minutes");
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.DayContent)}>
      <button
        onClick={onClick}
        className={clsx(
          styles.appointmentCell,
          styles[
            EVENT_COLOR_MAP[event?.event_type?.toLowerCase()] ||
              STATUS_KEYS.INFO
          ],
          { [styles.overlapping]: offsetUnit }
        )}
        style={{
          height: `calc(${(duration / 15) * 100}% - 2px`,
          width: `calc(100% - 22px - ${
            offsetUnit * 22 + // offset my number of events or appointments
            scheduleEventsOffset * 12 + // offset by number of schedule events
            scheduleEventsOffset +
            1 // offset by 1px for border
          }px`,
          left:
            offsetUnit * 22 + // offset my number of events or appointments
            scheduleEventsOffset * 12 + // offset by number of schedule events
            scheduleEventsOffset +
            1 // offset by 1px for border
        }}
      >
        <div className={styles.wrapper}>
          <div className={styles.participantBadges}>
            <Icon
              svg={
                event?.event_type?.toLowerCase() === "out_of_office"
                  ? "calendar-minus"
                  : "users"
              }
              width={10}
              height={10}
            />
            {participants.map((p: UserInfo) => (
              <p
                key={p.user_id}
                className={styles.participantIndicator}
                style={{ backgroundColor: userIdToHexCode(p.user_id) }}
              />
            ))}
          </div>
          <p className="med t5">{event.title}</p>
          <div className={styles.quickView}>
            <p className="t5 xLight">
              {dayjs(start_at).format("h:mma")} -{" "}
              {dayjs(end_at).format("h:mma")}
            </p>
          </div>
          {participants.map((p: UserInfo, idx: number) => (
            <p key={p.user_id} className="t5 gray400">
              {FORMAT.name(p)}
              {idx === participants.length - 1 ? "" : ","}
            </p>
          ))}
        </div>
      </button>
    </div>
  );
}
