/* ControlledCombobox Name */
/* External Imports */
import {
  FieldValues,
  FieldErrors,
  FieldValue,
  Controller,
} from "react-hook-form";

/* Local Imports */

// components
import MultiSelect from "./multiselect";

// constants
import { METRIC_LABELS } from "@/globals/constants";
// store

// styles

/* ControlledCombobox Typescript Interface */
interface ControlledMultiselectProps {
  label: string; // input label
  name: FieldValue<FieldValues>; // input name
  errors?: FieldErrors<FieldValues>; // errors received from parent form
  control: any;
  options: any[];
  labelAcc?: (item: any) => void;
  valAcc?: (item: any) => void;
  rules?: any;
}

export default function ControlledMultiselect({
  label,
  name,
  errors,
  control,
  options,
  labelAcc,
  valAcc,
  rules
}: ControlledMultiselectProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <MultiSelect
          label={label}
          options={options}
          onChange={(v) => onChange(v)}
          labelAcc={(item) =>
            (labelAcc && labelAcc(item)) || METRIC_LABELS[item] || item
          }
          valAcc={(item) => (valAcc && valAcc(item)) || item}
          error={
            errors?.[name] &&
            ((errors?.[name]?.message as string) ||
              "This field is " + errors?.[name]?.type)
          }
          initialValue={value}
          fullWidth
        />
      )}
    />
  );
}
