/* ProblemList */
/* External Imports */
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Fuse from "fuse.js";
import { v4 } from "uuid";
import Skeleton from "react-loading-skeleton";

/* Local Imports */

// components
import Button from "@/components/button";
import ComboboxSelect from "@/components/input/combobox";
import CreateAllergy from "./createAllergy";
import Allergy from "./allergy";
import NoKnownToggle from "./noKnownToggle";
// constants
import { STYLES, STATUS_KEYS } from "@/globals/constants";
// store

import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import {
  Allergies,
  Allergy as AllergyType,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
// styles
import styles from "./styles.module.scss";
import dayjs from "dayjs";

/* ProblemList Typescript Interface */
interface ProblemListProps {
  isExpanded?: boolean;
  patientId: number;
}

export default function AllergyList({
  isExpanded = false,
  patientId
}: ProblemListProps) {
  /* Redux */

  const dispatch = useDispatch();
  const [updatePatient] = usePatientUpsertMutation();
  const { data: patient } = usePatientGetInfoQuery({ patientId });
  /* Local State */

  const [isAddAllergyMode, setisAddAllergyMode] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [allergyFormValues, setAllergyFormValues] = useState({});

  // allergies to display when user filters
  const [filteredAllergies, setFilteredAllergies] = useState(
    patient?.medical_history.allergies
  );
  /* Form Validation & Management using react-hook-form */

  // react-hook-form hook configuration
  const form = useForm();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = form;

  useEffect(() => {
    form.reset(allergyFormValues);
  }, [allergyFormValues]);

  // clear search term when user exits add allergy mode
  useEffect(() => {
    setSearchTerm("");
  }, [isAddAllergyMode]);

  /* Effects */
  useEffect(() => {
    const allergies = patient?.medical_history?.allergies;
    if (allergies) {
      const allergiesFuse = new Fuse(allergies, {
        keys: [
          "icd10_code_details.description",
          "icd10_code_details.name",
          "reaction",
          "notes",
          "description",
          "severity",
          "name"
        ]
      });

      const handleFilterAllergies = (term: string) => {
        // filter allergies using fuzzy search if user inputs more than 3 characters g
        if (allergies && term?.length > 2) {
          // search through note type allergies
          const filteredItems = allergiesFuse.search(term);
          return filteredItems.map(({ item }) => item);
        } else {
          // reset to all allergies when user clears input
          return allergies;
        }
      };
      /* Fuse Search Configuration */

      const newFilteredAllergies = handleFilterAllergies(searchTerm);
      setFilteredAllergies(newFilteredAllergies as Allergies);
    }
  }, [patient, searchTerm]);

  /* Event Handlers */

  // handle add allergy button
  const onSubmit = (data: any) => {
    const allergy: AllergyType = {
      icd10_code_details: data.select_icd10 || undefined,
      reaction: data.allergy_reaction || undefined,
      notes: data.allergy_new_note || undefined,
      severity: data.select_severity || undefined,
      date_of_onset: data.date_of_onset || undefined,
      name: data.name || undefined,
      allergy_id: data.allergy_id,
      created_at: dayjs().toISOString()
    };

    let allergies = patient?.medical_history.allergies;

    // if non-icd10 allergy, add to array if the name changes, otherwise update the allergy

    // Check if allergies exist and if the ICD10 code already exists in the array
    if (allergies && allergies.length > 0) {
      const existingAllergyIndex = allergies.findIndex(a =>
        // if its an icd10 allergy, check if the icd10 code exists in the array
        {
          if (allergy.icd10_code_details?.icd_id) {
            return (
              a.icd10_code_details?.icd_id ===
              allergy.icd10_code_details?.icd_id
            );
          } else {
            // if its a non-icd10 allergy, check if the allergy_id exists in the array
            return a.allergy_id === allergy.allergy_id;
          }
        }
      );

      if (existingAllergyIndex !== -1) {
        // Modify existing allergy using map
        allergies = allergies.map((a, index) =>
          index === existingAllergyIndex ? { ...a, ...allergy } : a
        );
      } else {
        // Add new allergy
        allergies = [...allergies, allergy];
      }
    } else {
      // If no allergies exist, create a new array with the current allergy
      allergies = [allergy];
    }

    updatePatient({
      patientUpsertRequest: {
        user_id: patientId,
        medical_history: {
          ...patient?.medical_history,
          allergies
        }
      }
    })
      .unwrap()
      .then(() => {
        setisAddAllergyMode(false);
        // user alert
        launchToast(
          "Allergy saved successfully.",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
      })
      .catch(() =>
        launchToast(
          "An error occurred while saving allergy.",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        )
      );
    reset();
  };

  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  return (
    <div
      className={clsx(styles.AllergyList, { [styles.isExpanded]: isExpanded })}
    >
      {isAddAllergyMode ? (
        <CreateAllergy
          onCancel={() => setisAddAllergyMode(false)}
          onSubmit={handleSubmit(onSubmit)}
          form={form}
        />
      ) : (
        <div>
          {isExpanded && (
            <div className={styles.filterWrapper}>
              <Controller
                name="search_allergies"
                control={control}
                render={({ field: { onChange } }) => (
                  <ComboboxSelect
                    label="Search allergies"
                    placeholder="Search allergies"
                    hiddenLabel
                    options={filteredAllergies || []}
                    labelAcc={allergy => `${allergy?.notes}`}
                    onChange={v => onChange(v)}
                    onSearch={setSearchTerm}
                    noMenu
                    fullWidth
                  />
                )}
                rules={{ required: false }}
              />
            </div>
          )}
          <div className={styles.content}>
            {/* // Add toggle component to mark no known drug allergies */}
            {patient ? (
              <NoKnownToggle
                patientId={patient.user_id}
                toggleField="no_known_drug_allergies"
              />
            ) : (
              <Skeleton />
            )}
            {filteredAllergies && filteredAllergies.length > 0 ? (
              filteredAllergies.map(allergy => (
                // accordion for each allergy
                <Allergy
                  key={allergy.icd10_code_details?.icd_id}
                  patientId={patientId}
                  allergy={allergy}
                  form={form}
                  isExpanded={isExpanded}
                  patient={patient}
                  onEdit={() => {
                    setAllergyFormValues({
                      select_icd10: allergy.icd10_code_details
                        ? allergy.icd10_code_details
                        : "",
                      allergy_reaction: allergy.reaction,
                      select_severity: allergy.severity,
                      allergy_new_note: allergy.notes,
                      date_of_onset: allergy.date_of_onset,
                      name: allergy.name,
                      allergy_id: allergy.allergy_id
                    });
                    setisAddAllergyMode(true);
                  }}
                />
              ))
            ) : (
              <div className="emptyState">
                No allergies recorded for {patient?.first_name}
              </div>
            )}
          </div>
        </div>
      )}
      {isExpanded && !isAddAllergyMode && (
        <div className={styles.addButton}>
          <Button
            style={STYLES.PRIMARY}
            onClick={() => {
              setAllergyFormValues({
                select_icd10: "",
                allergy_reaction: "",
                select_severity: "",
                allergy_new_note: "",
                date_of_onset: "",
                name: "",
                allergy_id: v4()
              });
              setisAddAllergyMode(true);
            }}
          >
            Add New Allergy
          </Button>
        </div>
      )}
    </div>
  );
}
