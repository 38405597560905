/* ViewFile Name */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// ViewFiles

// constants
import ViewBody from "@/components/viewBody";

// store
// styles
import styles from "../styles.module.scss";

/* ViewFile Typescript Interface */
interface ViewFileProps {
  title: string;
  url: string;
  fileId?: string;
}

export default function ViewFile({ title, url, fileId }: ViewFileProps) {
  /* Redux */
  /* Local State */

  /* Effects */

  /* Event Handlers */
  const getFileExtension = (filename: string) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const renderFileContent = () => {
    const fileExtension = getFileExtension(title).toLowerCase();
    switch (fileExtension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <img src={url} alt="Displayed File" width={720} height={500} />;
      case "pdf":
        return (
          <embed
            src={url}
            type="application/pdf"
            width="100%"
            height="100%"
            style={{ minHeight: "70vh" }}
          />
        );
      default:
        return <p>Unsupported file type.</p>;
    }
  };

  return (
    <div className={clsx(styles.ViewFile)}>
      <div className={styles.contentViewer}>{renderFileContent()}</div>
    </div>
  );
}
