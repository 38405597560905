import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

// local
import { TemplateContent } from "@/globals/constants/types";


interface drawerState {
  drawerOpen: boolean;
  rightPaneOpen: boolean;
  leftPaneOpen: boolean;
  leftPaneContent: TemplateContent;
  rightPaneContent: TemplateContent;
  pastContent: TemplateContent;
  childId: string | null;
  drawerState: {
    [metricKey: string]: string;
  } | null;
};

const initialState: drawerState = {
  drawerOpen: false,
  rightPaneOpen: false,
  leftPaneOpen: false,
  leftPaneContent: { type: null },
  rightPaneContent: { type: null },
  pastContent: {type: null},
  childId: null,
  drawerState: null,
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    setDrawerOpen: (state, { payload } : PayloadAction<drawerState["drawerOpen"]>) => {
      state.drawerOpen = payload;
    },
    setChildId: (state, { payload } : PayloadAction<drawerState["childId"]>) => {
      state.childId = payload;
    },
    setRightPaneOpen: (state, { payload } : PayloadAction<drawerState["rightPaneOpen"]>) => {
      state.rightPaneOpen = payload;
      if (!payload) state.rightPaneContent = { type: null };
    },
    setLeftPaneOpen: (state, { payload } : PayloadAction<drawerState["leftPaneOpen"]>) => {
      state.leftPaneOpen = payload;
    },
    setLeftPaneContent: (state, { payload } : PayloadAction<drawerState["leftPaneContent"]>) => {
      state.leftPaneContent = payload;
    },
    setRightPaneContent: (state, { payload } : PayloadAction<drawerState["rightPaneContent"]>) => {
      if (state.rightPaneContent) {
        state.pastContent = state.rightPaneContent;
      }
      state.rightPaneContent = payload;
    },
    rightPaneContentGoBack: (state, { payload }) => {
      if (state.pastContent) {
        state.rightPaneContent = state.pastContent;
      }
    }
  },
});

export const { setDrawerOpen, setChildId, setRightPaneOpen, setLeftPaneOpen, setLeftPaneContent, setRightPaneContent, rightPaneContentGoBack } =
  drawerSlice.actions;

export default drawerSlice.reducer;
