/* Button Component */

// External
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

// components
import Tab from "./tab";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { TagProps } from "../tag";
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";
import { setTabId } from "./tabSlice";
export interface TabMenuTab {
  id: string;
  label: string;
  tag?: TagProps;
  notification?: { isNotification: boolean; notificationNumber: number };
}
export interface TabMenuProps {
  tabs: TabMenuTab[];
  children: (tab: string) => JSX.Element;
  noPadding?: boolean;
  customElement?: JSX.Element;
  leftAligned?: boolean;
  initialSelectedTab?: string;
  onChange?: (id: string) => void;
  style?: "" | typeof STYLES.SECONDARY | typeof STYLES.LEGACY;
}

export default function TabMenu({
  tabs = [],
  children,
  noPadding = false,
  customElement,
  leftAligned = false,
  initialSelectedTab,
  onChange,
  style = ""
}: TabMenuProps) {
  /* Redux */
  const dispatch = useDispatch();
  // if tab gets externally set via another component
  const externallySetTabId = useSelector(
    (state: RootState) => state.tabMenu.tabId
  );
  /* State */
  const [firstTab] = tabs;

  const [selectedTab, setSelectedTab] = useState(
    initialSelectedTab || firstTab?.id
  );

  // If tabs change we need to reset the initially selected tab since it might not
  // be the same
  useEffect(() => {
    if (!tabs.map(({ id }) => id).includes(selectedTab)) {
      setSelectedTab(initialSelectedTab || firstTab?.id);
    }
  }, [tabs]);

  useEffect(() => {
    dispatch(setTabId(selectedTab));
  }, [selectedTab]);

  const handleClick = (id: string) => {
    setSelectedTab(id);
    onChange && onChange(id);
  };

  /* Effects */
  useEffect(() => {
    if (
      externallySetTabId &&
      tabs.map(({ id }) => id).includes(externallySetTabId)
    ) {
      setSelectedTab(externallySetTabId);
    }
  }, [externallySetTabId]);

  return (
    <div className={clsx(styles.Tabbed, styles[style])}>
      <div
        className={clsx(styles.tabMenu, {
          [styles.unpadded]: noPadding,
          [styles.leftAligned]: leftAligned
        })}
        role={"tablist"}
      >
        {tabs.map(({ label, id, tag, notification }) => (
          <div key={id} className={styles.cornerRounder} data-cy={`${id}-tab`}>
            <Tab
              label={label}
              selected={id === selectedTab}
              onClick={() => handleClick(id)}
              tag={
                notification?.isNotification
                  ? {
                      label: notification?.notificationNumber?.toString(),
                      type: STATUS_KEYS.WARNING
                    }
                  : tag
              }
              key={id}
            />
          </div>
        ))}
        <div className={styles.end}>{customElement}</div>
      </div>
      <section
        role="tabpanel"
        aria-labelledby={selectedTab}
        tabIndex={0}
        className={styles.tabContent}
      >
        {children(selectedTab)}
      </section>
    </div>
  );
}
