// Imports ---------------------------------------------------------------------
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";

import Select from "react-select";
import { ErrorMessage } from "@hookform/error-message";

import Button from "@/components/button";
import Input from "@/components/input";
import MultiSelect from "@/components/input/multiselect";
import Toggle from "@/components/input/toggle";

import { FORMAT } from "@/globals/helpers/formatters";

import { ProviderListItem } from "@/store/services/practice";

import { LocationListItem } from "@/store/services/location";
import { EventType } from "@/store/services/event";
import { PracticeUserSearchResults } from "@/store/services/practice";

import { STYLES } from "@/globals/constants";
import { SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";
import styles from "../../styles.module.scss";

// Props -----------------------------------------------------------------------
export type StaffScheduleFormValues = {
  shift: {
    starts?: number;
    ends?: number;
  };
  select_location: LocationListItem;
  select_participants?: ProviderListItem[];
  select_event_type?: EventType;
  event_date?: string;
  event_title?: string;
  all_day: boolean;
};

// Component -------------------------------------------------------------------
export interface SetStaffScheduleRendererProps {
  onSubmit: (data: StaffScheduleFormValues) => void;
  isSchedule: boolean;
  fromCalEventTypes: { value: EventType; label: string }[];
  fromProviderScheduleEventTypes: { value: EventType; label: string }[];
  locationOptions: { value: LocationListItem; label: string }[];
  startOptions: { value: number; label: string }[];
  endOptions: { value: number; label: string }[];
  users: PracticeUserSearchResults;
  eventId?: string;
  handleDelete: (eventId: string) => void;
  defaultValues: Partial<StaffScheduleFormValues>;
  allDayToggleVisible: (eventType: EventType) => boolean;
}

export default function SetStaffScheduleRenderer({
  defaultValues,
  onSubmit,
  isSchedule,
  fromCalEventTypes,
  fromProviderScheduleEventTypes,
  locationOptions,
  startOptions,
  endOptions,
  eventId,
  users,
  handleDelete,
  allDayToggleVisible, // Only certain event types should have the all day toggle
}: SetStaffScheduleRendererProps) {
  const form = useForm<StaffScheduleFormValues>({ defaultValues });

  const eventType = form.watch("select_event_type");
  const allDay = form.watch("all_day");

  return (
    <form
      id="setStaffSchedule"
      className={clsx(styles.SetStaffSchedule)}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      {!isSchedule && (
        <>
          <Input
            id="event_title"
            name="event_title"
            type="text"
            label="Title"
            fullWidth
            register={form.register}
          />
          <Controller
            name="select_event_type"
            control={form.control}
            render={({ field: { value, onChange } }) => (
              <>
                <label htmlFor="select_event_type">Event Type*</label>
                <Select
                  form="setStaffSchedule"
                  required
                  aria-label="Event Type*"
                  id="select_event_type"
                  theme={SelectDefaultTheme}
                  options={fromCalEventTypes}
                  value={fromCalEventTypes.find(
                    option => value && option.value === value.toLowerCase()
                  )}
                  onChange={v => {
                    if (!v || !v.value) return;

                    onChange(v.value);
                  }}
                />
              </>
            )}
          />
        </>
      )}
      {isSchedule && (
        <Controller
          name="select_event_type"
          control={form.control}
          render={({ field: { value, onChange } }) => (
            <>
              <label htmlFor="select_event_type">Type*</label>
              <Select
                openMenuOnFocus
                form="setStaffSchedule"
                required
                aria-label="Type*"
                id="select_event_type"
                theme={SelectDefaultTheme}
                options={fromProviderScheduleEventTypes}
                value={fromProviderScheduleEventTypes.find(
                  option => value && option.value === value.toLowerCase()
                )}
                onChange={v => {
                  if (!v || !v.value) return;

                  onChange(v.value);
                }}
              />
            </>
          )}
        />
      )}
      <Controller
        name="select_location"
        control={form.control}
        render={({ field: { value, onChange } }) => (
          <>
            <label htmlFor="select_location">Location*</label>
            <Select
              form="setStaffSchedule"
              required
              id="select_location"
              aria-label="Location*"
              theme={SelectDefaultTheme}
              options={locationOptions}
              value={(() => {
                const match = locationOptions?.find(
                  opt => value?.location_id === opt.value.location_id
                );
                return match;
              })()}
              onChange={v => {
                if (!v || !v.value) return;
                onChange(v.value);
              }}
            />
          </>
        )}
      />

      {((eventType && allDayToggleVisible(eventType)) || allDay) && <Toggle
        label="All Day"
        onChange={() => form.setValue("all_day", !allDay)}
        labelIsRight
        initialChecked={allDay}
      />}

      {!allDay && <div className={"flex apart"}>
        <Controller
          name="shift.starts"
          control={form.control}
          render={({ field: { value, onChange } }) => (
            <div className={styles.timeSelector}>
              <label htmlFor="select_start">Start*</label>
              <Select
                form="setStaffSchedule"
                name="shift.starts"
                id="select_start"
                required
                filterOption={(candidate, input) => {
                  // Normalize the input
                  const normalizedInput = input
                    .toLowerCase()
                    .replace(/\s/g, "");

                  // Parse the candidate time (assuming it's in the label)
                  const [targetHour, targetMinute, targetPeriod] =
                    candidate.label
                      .match(/(\d+):(\d+)\s*(AM|PM)/i)
                      ?.slice(1) || [];
                  if (!targetHour || !targetMinute || !targetPeriod)
                    return false;

                  // Create regex patterns based on the candidate time
                  const hourPattern = targetHour.padStart(2, "0");
                  const minutePattern = targetMinute.padStart(2, "0");
                  const periodPattern = targetPeriod.toLowerCase();

                  const patterns = [
                    `^${hourPattern}:?${minutePattern}${periodPattern}$`,
                    `^${parseInt(targetHour)}:?${minutePattern}${periodPattern}$`,
                    `^${parseInt(targetHour)}${periodPattern}$`,
                    `^${parseInt(targetHour)}:?${minutePattern}$`,
                    `^${parseInt(targetHour)}$`
                  ];

                  // Test the input against all patterns
                  return (
                    patterns.some(pattern =>
                      new RegExp(pattern).test(normalizedInput)
                    ) ||
                    candidate.label.toLowerCase().includes(normalizedInput)
                  );
                }}
                theme={SelectDefaultTheme}
                options={startOptions}
                value={startOptions.find(opt => opt.value === value)}
                onChange={v => {
                  if (!v || !v.value) return;
                  onChange(v.value);
                  const start = form.getValues("shift.starts");
                  const end = form.getValues("shift.ends");
                  if (start && end && start >= end) {
                    form.setError("shift.starts", {
                      type: "custom",
                      message: "Start time must be before end time"
                    });
                  } else {
                    form.clearErrors("shift.starts");
                  }
                }}
              />
              {form.formState.errors.shift?.starts && (
                <ErrorMessage
                  errors={form.formState.errors}
                  name="shift.starts"
                />
              )}
            </div>
          )}
        />

        <Controller
          name="shift.ends"
          control={form.control}
          render={({ field: { value, onChange } }) => (
            <div className={styles.timeSelector}>
              <label htmlFor="select_end">End*</label>
              <Select
                name="shift.ends"
                form="setStaffSchedule"
                id="select_end"
                required
                filterOption={(candidate, input) => {
                  // Normalize the input
                  const normalizedInput = input
                    .toLowerCase()
                    .replace(/\s/g, "");

                  // Parse the candidate time (assuming it's in the label)
                  const [targetHour, targetMinute, targetPeriod] =
                    candidate.label
                      .match(/(\d+):(\d+)\s*(AM|PM)/i)
                      ?.slice(1) || [];
                  if (!targetHour || !targetMinute || !targetPeriod)
                    return false;

                  // Create regex patterns based on the candidate time
                  const hourPattern = targetHour.padStart(2, "0");
                  const minutePattern = targetMinute.padStart(2, "0");
                  const periodPattern = targetPeriod.toLowerCase();

                  const patterns = [
                    `^${hourPattern}:?${minutePattern}${periodPattern}$`,
                    `^${parseInt(targetHour)}:?${minutePattern}${periodPattern}$`,
                    `^${parseInt(targetHour)}${periodPattern}$`,
                    `^${parseInt(targetHour)}:?${minutePattern}$`,
                    `^${parseInt(targetHour)}$`
                  ];

                  // Test the input against all patterns
                  return (
                    patterns.some(pattern =>
                      new RegExp(pattern).test(normalizedInput)
                    ) ||
                    candidate.label.toLowerCase().includes(normalizedInput)
                  );
                }}
                theme={SelectDefaultTheme}
                options={endOptions}
                value={endOptions.find(opt => opt.value === value)}
                onChange={v => {
                  if (!v || !v.value) return;
                  form.setValue("shift.ends", v.value);
                  const start = form.getValues("shift.starts");
                  const end = form.getValues("shift.ends");
                  if (start && end && start >= end) {
                    console.log("setting error on ends");
                    form.setError("shift.ends", {
                      type: "custom",
                      message: "End time must be after start time"
                    });
                  } else {
                    form.clearErrors("shift.ends");
                  }
                }}
              />
              {form.formState.errors.shift?.ends && (
                <ErrorMessage
                  errors={form.formState.errors}
                  name="shift.ends"
                />
              )}
            </div>
          )}
        />
      </div>}

      {!isSchedule && (
        <>
          <Controller
            name={"select_participants"}
            control={form.control}
            render={({ field: { onChange, value } }) => (
              <MultiSelect
                options={
                  [
                    ...(users?.providers || []),
                    ...(users?.medical_assistants || [])
                  ] || []
                }
                initialValue={value || []}
                label="Participants"
                labelAcc={provider => provider && FORMAT.name(provider)}
                valAcc={provider => provider && provider.user_id}
                backgroundColorAcc={provider => provider && "#FFFFFF"}
                onChange={onChange}
                fullWidth
              />
            )}
          />
        </>
      )}
      <div className={styles.buttons}>
        <Button style={STYLES.FULL_WIDTH} type="submit">
          Save
        </Button>
        <Button
          style={STYLES.SECONDARY_FULL}
          onClick={() => handleDelete(eventId as string)}
          nativeButtonProps={{ disabled: !eventId }}
        >
          Delete
        </Button>
      </div>
    </form>
  );
}
