// External
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";

// Store
import { STATUS_KEYS, STATUS_TYPES, STYLES } from "@/globals/constants";
import { setModalContent } from "../modalSlice";
import { MODAL_TYPES } from "../dispatcher";
import {
  useCreateEddMutation,
  useUpdateEddMutation
} from "@/store/services/pregnancy";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { EstimatedDueDate } from "@/store/services/patient";

// Components
import Input from "@/components/input";
import Button from "@/components/button";
import Alert from "@/components/alert";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// Styles
import styles from "../styles.module.scss";

interface NewDateOfConceptionEstimateProps {
  pregnancyId: number;
  defaultEdd?: EstimatedDueDate;
}

export default function NewDateOfConceptionEstimate({
  pregnancyId,
  defaultEdd
}: NewDateOfConceptionEstimateProps) {
  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: {
      date_of_conception: "",
      comments: "",
      date_of_method: ""
    }
  });
  const { handleSubmit, formState, register, setValue, watch } = form;

  const [createEdd, { isSuccess, isError }] = useCreateEddMutation();
  const [updateEdd, { isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateEddMutation();

  const [calculatedEdd, setCalculatedEdd] = useState<string>("2024-04-05");
  const [isLmpWarning, setLmpWarning] = useState<boolean>(false);

  const dateOfConception = watch("date_of_conception");

  useEffect(() => {
    const doc = dateOfConception ? dayjs(dateOfConception) : dayjs();
    

    const edd = doc.add(266, "day");
    setCalculatedEdd(edd.format("MM/DD/YYYY"));
  }, [dateOfConception]);

  useEffect(() => {
    if (defaultEdd) {
      // Reverse engineer the EDD math to get LMP
      const edd = dayjs(defaultEdd.estimated_due_date);
      const dateOfConception = edd.subtract(266, "day");

      // Set the local state variables
      setValue("comments", defaultEdd.comment as string);
      setValue("date_of_conception", dateOfConception.format("YYYY-MM-DD"));
      setValue("date_of_method", defaultEdd.date_of_method as string);
    }
  }, [defaultEdd]);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      const successMsg = isSuccess
        ? "Successfully created EDD"
        : "Successfully updated EDD";
      dispatch(
        setModalContent({
          type: MODAL_TYPES.DATING,
          props: {
            title: "Estimated Due Dates",
            pregnancyId: pregnancyId
          }
        })
      );
      dispatch(
        addAlertToToastTrough({
          message: successMsg,
          type: STATUS_KEYS.SUCCESS
        })
      );
    }
  }, [isSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (isError || isUpdateError) {
      const errorMsg = isError
        ? "Something went wrong creating EDD"
        : "Something went wrong updating EDD";
      dispatch(
        addAlertToToastTrough({
          message: errorMsg,
          type: STATUS_KEYS.ERROR
        })
      );
    }
  }, [isError, isUpdateError]);

  const handleBackClick = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.DATING,
        props: {
          title: "Estimated Due Dates",
          pregnancyId: pregnancyId
        }
      })
    );
  };

  const onSubmit = (data: any) => {
    if (defaultEdd) {
      // There's a defaultEdd (meaning we're editing one) so update
      updateEdd({
        pregnancyId: pregnancyId,
        eddId: defaultEdd.estimate_id as string,
        estimatedDueDateUpdate: {
          estimated_due_date: dayjs(calculatedEdd).format("YYYY-MM-DD"),
          method: "DATE_OF_CONCEPTION",
          date_of_method: data.date_of_conception,
          comment: data.comments,
        }
      });
    } else {
      // No defaultEdd (we're not editing) so create a new one
      createEdd({
        pregnancyId: pregnancyId,
        estimatedDueDate: {
          estimated_due_date: dayjs(calculatedEdd).format("YYYY-MM-DD"),
          method: "DATE_OF_CONCEPTION",
          date_of_method: data.date_of_conception,
          comment: data.comments,
        }
      });
    }
  };

  return (
    <div className={styles.LmpEstimate} data-cy="lmp-estimate-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
          <div className={styles.lmp}>
            <Input
              type="date"
              label="Date of Conception"
              name="date_of_conception"
              id="date_of_conception"
              rules={{
                required: true,
                onBlur: () => {
                  setLmpWarning(
                    dayjs(form.getValues("date_of_conception")).isSameOrBefore(
                      dayjs().subtract(1, "year").format("YYYY-MM-DD")
                    )
                  );
                }
              }}
              error={formState.errors.date_of_conception && "Required"}
              register={register}
            />
            <span
              className={styles.hasTooltip}
              title="EDD is calculated automatically by adding 266 days to the date of conception"
            >
              <p className={styles.eddLabel}>
                EDD <sup>?</sup>
              </p>
              <p className={styles.eddText} data-cy="edd-text">
                {calculatedEdd}
              </p>
            </span>
          </div>
        </div>
        <div className={styles.commentsContainer}>
          <ControlledTextArea
            label="Comments"
            id="comments"
            name="comments"
            form={form}
          />
        </div>
        <Alert
          message="Estimate is more than a year in the past, is this correct?"
          type={STATUS_TYPES[STATUS_KEYS.WARNING]}
          isHidden={!isLmpWarning}
        />
        <div className={styles.buttonContainer}>
          <Button
            style={STYLES.SECONDARY}
            onClick={handleBackClick}
            type="button"
          >
            Back
          </Button>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
}
