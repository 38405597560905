/* CalendarHeader Name */
/* External Imports */
import { useEffect, useMemo } from "react";
import clsx from "clsx";
import dayjs from "dayjs";

/* Local Imports */

// components
import Icon from "@/components/icons";

// constants
import { METRICS } from "@/globals/constants";

// store
import { EventInfo, useEventListQuery } from "@/store/services/event";

// utils
import { userIdToHexCode } from "./utils";

// styles
import styles from "./styles.module.scss";

/* CalendarHeader Typescript Interface */

interface CalendarHeaderProps {
  isWeekView?: boolean;
  days: string[]; // ISO timestamps
  isMulti?: boolean;
}

export default function CalendarHeader({
  isWeekView = false,
  days
}: CalendarHeaderProps) {
  const { data: allDayEvents } = useEventListQuery({
    rangeStart: days[0],
    rangeEnd: days[days.length],
    allDay: true
  });

  // memoize the allDayScheduleEvents into an object of date to events for easy lookup
  const allDayScheduleEventsByDate = useMemo(() => {
    const eventsByDate: Record<string, EventInfo[]> = {};
    const allDayScheduleEvents = allDayEvents?.filter(event =>
      [
        METRICS.ON_CALL_FIRST,
        METRICS.ON_CALL_SECOND,
        METRICS.IN_CLINIC,
        METRICS.ON_CALL_IN_CLINIC_FIRST,
        METRICS.ON_CALL_IN_CLINIC_SECOND
      ].includes(event.event_type?.toLowerCase())
    );
    allDayScheduleEvents?.forEach(event => {
      const date = dayjs(event.start_at).startOf("day").toISOString();
      if (!eventsByDate[date]) {
        eventsByDate[date] = [];
      }
      eventsByDate[date].push(event);
    });
    return eventsByDate;
  }, [allDayEvents, days]);

  let weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const getEventIcon = (event: EventInfo) => {
    if (event.event_type.includes("ON_CALL_IN_CLINIC")) {
      return "on_call_in_clinic";
    } else if (event.event_type.includes("ON_CALL")) {
      return "on_call";
    } else {
      return "in_office";
    }
  };

  const getEventNumber = (event: EventInfo) => {
    if (event.event_type.includes("FIRST")) {
      return "1";
    } else if (event.event_type.includes("SECOND")) {
      return "2";
    } else {
      return "";
    }
  };

  return (
    <>
      {isWeekView ? (
        <>
          <div className={clsx(styles.gridCell, styles.header)} />
          {days &&
            days.map(day => {
              return (
                <div key={day} className={clsx(styles.gridCell, styles.header)}>
                  <div
                    className={clsx(styles.dateWrapper, {
                      [styles.selected]:
                        day === dayjs().startOf("day").toISOString()
                    })}
                  >
                    <>
                      <p>{dayjs(day as string).format("ddd")}</p>
                      <p>{dayjs(day as string).date()}</p>
                    </>
                  </div>
                  <div className={styles.allDayWrapper}>
                    {allDayScheduleEventsByDate?.[day]?.map(event => (
                      <div
                        key={event.event_id}
                        className={clsx(styles.allDayEvent, {
                          [styles.withText]: getEventNumber(event)
                        })}
                        style={{
                          backgroundColor: event.participants?.[0]
                            ? userIdToHexCode(event.participants?.[0]?.user_id)
                            : ""
                        }}
                      >
                        <Icon
                          svg={getEventIcon(event)}
                          height={14}
                          width={14}
                        />

                        {getEventNumber(event)}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
        </>
      ) : (
        weekdays &&
        weekdays.map(weekday => (
          <div key={weekday} className={clsx(styles.gridCell, styles.header)}>
            <p className={styles.disabled}>
              <span>{weekday}</span>
            </p>
          </div>
        ))
      )}
    </>
  );
}
