// External
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";

// Globals, helpers, constants
import { FORMAT } from "@/globals/helpers/formatters";
import { convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

// Store
import { LaborModel } from "@/store/services/pregnancy";
import { usePatientGetInfoQuery } from "@/store/services/patient";
import {
  EncounterInfo,
} from "@/store/services/encounter";
import { LocationInfo } from "@/store/services/location";

// Components
import Button from "@/components/button";
import SummaryBirthInfo from "./_sections/SummaryBirthInfo";

// Section components
import SummaryPatientInfo from "@/components/birthSummary/_sections/SummaryPatientInfo";
import SummaryNewbornInfo from "@/components/birthSummary/_sections/SummaryNewbornInfo";
import SummaryTransferInfo from "@/components/birthSummary/_sections/SummaryTransferInfo";
import SummaryMedicationsInfo from "@/components/birthSummary/_sections/SummaryMedicationsInfo";
import SummarySignificantEventsInfo from "@/components/birthSummary/_sections/SummarySignificantEvents";
import SummaryPlacentaPerineumInfo from "@/components/birthSummary/_sections/SummaryPlacentaPerineumInfo";
import SummaryStagesInfo from "@/components/birthSummary/_sections/SummaryStagesInfo";

// Styles
import styles from "./styles.module.scss";

interface BirthSummaryProps {
  patientId: number;
  labor: LaborModel;
  location: LocationInfo;
  encounter: EncounterInfo;
  birthDate?: number;
}

export default function BirthSummary({
  patientId,
  labor,
  location,
  encounter,
  birthDate
}: BirthSummaryProps) {
  const router = useRouter();
  /* Redux */
  const dispatch = useDispatch();

  // Get the patient info
  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId: patientId
    },
    { skip: !patientId }
  );
  // Get the infant info
  const { data: infantData } = usePatientGetInfoQuery(
    {
      patientId: patient?.pregnancy?.infant_id as number
    },
    {
      skip: !patient || !patient?.pregnancy?.infant_id
    }
  );

  if (!labor) {
    return <></>;
  }

  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  return (
    <div className={styles.BirthSummary}>
      <div className={styles.summaryHeader}>
        <Button style={STYLES.PRIMARY} onClick={() => router.back()}>
          &lt; Back to labor
        </Button>
        <h3>Birth Summary</h3>
        <p>
          Patient Name: <span>{patient && FORMAT.name(patient)}</span>
        </p>
        {encounter.start && (
          <p>
            Date:{" "}
            <span>
              {convertUtcIntToLocalDatetime(encounter.start).format(
                "MM/DD/YYYY"
              )}
            </span>
          </p>
        )}
      </div>

      {patient && <SummaryPatientInfo patient={patient} />}
      {patient && infantData && (
        <SummaryNewbornInfo
          patient={patient}
          labor={labor}
          infant={infantData}
        />
      )}

      {/** Birth info */}
      {patient && (
        <SummaryBirthInfo
          patient={patient}
          labor={labor}
          encounter={encounter}
        />
      )}

      {/** Stages of labor */}
      <SummaryStagesInfo labor={labor} />

      {/** Placenta and perinuem */}
      <SummaryPlacentaPerineumInfo labor={labor} />

      {/** Significant events (resuscitation and hemmorhage) */}
      <SummarySignificantEventsInfo labor={labor} />

      {/** Transfer */}
      {patient && <SummaryTransferInfo patient={patient} labor={labor} />}

      {/** Medications */}
      <SummaryMedicationsInfo labor={labor} />
    </div>
  );
}
