/* Tab Component */

// External
import clsx from "clsx";
// styles
import styles from "./styles.module.scss";
import Tag, { TagProps } from "../tag";
import NotificationIcon from "@/components/notificationIcon";

export interface TabProps {
  label: string;
  selected: boolean;
  onClick: () => void;
  tag?: TagProps;
  isNotification?: boolean;
  notificationNumber?: number | null;
}

export default function Tab({
  label,
  selected,
  onClick,
  tag,
  isNotification,
  notificationNumber
}: TabProps) {
  return (
    <button
      role="tab"
      className={clsx(styles.Tab, {
        [styles.selected]: selected
      })}
      onClick={onClick}
      aria-selected={selected}
      aria-controls={label}
      data-cy={`tab-${label?.toLowerCase()}`}
    >
      {label}
      {isNotification && (
        <NotificationIcon notificationNumber={notificationNumber} />
      )}
      {tag && <Tag label={tag.label} type={tag.type} />}
    </button>
  );
}
