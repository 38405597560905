// third-party
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

// components
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import ComboboxSelect from "@/components/input/combobox";
import Icon from "@/components/icons";
import Input from "@/components/input";
import LoadingIcon from "../../../../public/svgs/loading-icon.svg";

// store
import {
  useLazyMedicationSearchQuery,
  useMedicationDosageSearchQuery,
  useMedicationCreateForPatientMutation,
  useMedicationUpdateForPatientMutation,
  useMedicationDeleteFromPatientMutation,
  MedicationCreate,
  UserId,
} from "@/store/services/medication";
import { Medication } from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";

// constants
import {
  STATUS_KEYS,
  STYLES,
  METRICS,
  METRIC_LABELS,
} from "@/globals/constants";
import styles from "../styles.module.scss";

// utils
import { parseParioDate } from "@/utils/api/time";
import { MODAL_TYPES } from "../dispatcher";

interface MedicationFormProps {
  userId: UserId;
  medication?: Medication;
  uuid: string;
}

function MedicationForm({ userId, medication, uuid }: MedicationFormProps) {
  const dispatch = useDispatch();
  const defaultValues = {
    name: medication?.name,
    dosage: medication?.dosage,
    status: medication?.status || "ACTIVE",
    start: medication?.start
      ? dayjs(parseParioDate(medication?.start)).format("YYYY-MM-DD")
      : undefined,
    end: medication?.end
      ? dayjs(parseParioDate(medication?.end)).format("YYYY-MM-DD")
      : undefined,
    comment: medication?.comment,
    lexicomp_drug_id: medication?.lexicomp_drug_id,
    lexicomp_synonym_id: medication?.lexicomp_synonym_id,
  };

  // @ts-ignore
  const form = useForm<MedicationCreate>({ defaultValues });
  const {
    formState: { errors },
    reset,
    register,
    control,
    getValues,
    handleSubmit,
  } = form;

  useEffect(() => {
    // @ts-ignore
    reset(defaultValues);
    if (medication) {
      searchMeds({ term: medication.name });
    }
  }, [medication]);

  const [searchMeds, { data: matchingMeds }] = useLazyMedicationSearchQuery();
  const { data: availableDosages } = useMedicationDosageSearchQuery(
    {
      name: getValues("name"),
    },
    { skip: getValues("name") === undefined || getValues("name")?.length < 3 }
  );
  const [addUserMedication] = useMedicationCreateForPatientMutation();
  const [updateUserMedication] = useMedicationUpdateForPatientMutation();
  const [removeMedication] = useMedicationDeleteFromPatientMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleRemove = async () => {
    try {
      setIsLoading(true);
      await removeMedication({ userId, uuid }).unwrap();
      dispatch(
        addAlertToToastTrough({
          message: "Medication removed",
          type: STATUS_KEYS.SUCCESS,
        })
      );
      dispatch(
        setModalContent({
          type: MODAL_TYPES.ADD_MEDICATION,
          props: {
            userId: userId,
            medication: null,
            uuid: null,
            title: "Add Medication",
          },
        })
      );
      setIsLoading(false);
      dispatch(setModalIsOpen(false));
    } catch (err) {
      console.error(err);
      setIsLoading(false);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to remove medication",
          type: STATUS_KEYS.ERROR,
        })
      );
    }
  };

  const onSubmit = async (data: MedicationCreate) => {
    if (isLoading) {
      return;
    }
    if (!medication) {
      data.start = data.start
        ? parseInt(dayjs(data.start as unknown as string).format("YYYYMMDD"))
        : undefined;
      data.end = data.end
        ? parseInt(dayjs(data.end as unknown as string).format("YYYYMMDD"))
        : undefined;
      try {
        setIsLoading(true);
        await addUserMedication({
          userId,
          medicationCreate: data,
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: "Medication added",
            type: STATUS_KEYS.SUCCESS,
          })
        );
        setIsLoading(false);
        dispatch(setModalIsOpen(false));
      } catch (err) {
        console.error(err);
        setIsLoading(false);
        dispatch(
          addAlertToToastTrough({
            message: "Whoops! Failed to add medication",
            type: STATUS_KEYS.ERROR,
          })
        );
      }
    } else {
      data.start = data.start
        ? parseInt(dayjs(data.start as unknown as string).format("YYYYMMDD"))
        : undefined;
      data.end = data.end
        ? parseInt(dayjs(data.end as unknown as string).format("YYYYMMDD"))
        : undefined;
      try {
        setIsLoading(true);
        await updateUserMedication({
          userId,
          medicationUpdate: {
            uuid,
            dosespot_id: medication.dosespot_id as number,
            ...data,
          },
        }).unwrap();
        dispatch(
          addAlertToToastTrough({
            message: "Medication added",
            type: STATUS_KEYS.SUCCESS,
          })
        );
        setIsLoading(false);
        dispatch(setModalIsOpen(false));
      } catch (err) {
        console.error(err);
        setIsLoading(false);
        dispatch(
          addAlertToToastTrough({
            message: "Whoops! Failed to add medication",
            type: STATUS_KEYS.ERROR,
          })
        );
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(styles.AddMedication)}
    >
      <div className={clsx(styles.FieldGrid)}>
        {
          /* Medication dropdown: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Controller
              name={"name"}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <ComboboxSelect
                  label={"Medication"}
                  options={matchingMeds || []}
                  onSearch={(v: string) => {
                    if (v.length > 2) {
                      searchMeds({ term: v });
                      onChange(v);
                    }
                  }}
                  onChange={async (v: string) => {
                    onChange(v);
                    if (v) {
                    }
                  }}
                  error={errors?.[name] && (errors?.[name]?.message as string)}
                  value={value}
                  required
                />
              )}
            />
          ) : (
            <div>
              <p className={clsx(styles.FieldTitle)}>Name</p>
              <p>{medication?.name}</p>
            </div>
          )
        }

        {
          /* Dosage dropdown: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Controller
              name={"dosage"}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <ComboboxSelect
                  label={"Dosage"}
                  options={availableDosages || []}
                  onChange={(v: string) => {
                    onChange(v);
                  }}
                  onSearch={(v: string) => {
                    onChange(v);
                  }}
                  value={value}
                  error={errors?.[name] && (errors?.[name]?.message as string)}
                />
              )}
            />
          ) : (
            <div>
              <p>Dosage</p>
              <p>{medication?.dosage}</p>
            </div>
          )
        }


        {
          /* Medication start date: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Input
              id="start"
              name="start"
              type="date"
              label="Start"
              //@ts-ignore
              register={register}
            />
          ) : (
              <div>
                <p>Start</p>
                <p>{medication?.start ? dayjs(parseParioDate(medication?.start)).format("MM/DD/YYYY"): "-"}</p>
              </div>
          )
        }

        {
          /* Medication end date: Prescriptions are read only */
          !medication?.is_prescription ? (
            <Input
              id="end"
              name="end"
              type="date"
              label="End"
              //@ts-ignore
              register={register}
            />
          ) : (
            <div>
              <p>End</p>
              <p>{medication?.end ? dayjs(parseParioDate(medication?.end)).format("MM/DD/YYYY"): "-"}</p>
            </div>
          )
        }

        {/* Status dropdown */}
        <Controller
          name={"status"}
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <ComboboxSelect
              label={"Status"}
              options={[
                METRICS.ACTIVE_STATUS,
                METRICS.INACTIVE,
                METRICS.NONCOMPLIANT,
              ]}
              onChange={(v: string) => {
                onChange(v);
              }}
              noFilter
              labelAcc={(v) => METRIC_LABELS[v]}
              value={value}
              error={errors?.[name] && (errors?.[name]?.message as string)}
            />
          )}
        />
      </div>

      {/* Comments */}
      <div className={styles.Comments}>
        <ControlledTextArea
          id="comment"
          name="comment"
          label="Comments"
          form={form}
          placeholder="Add any comments here"
        />
      </div>

      <div className={styles.Buttons} style={{ display: "flex", justifyContent: "space-between" }}>
        {
          /* Can not remove prescriptions since they are managed by dosespot */
          (medication && !medication?.is_prescription) ? (
            <Button
              type="button"
              style={STYLES.SECONDARY}
              onClick={handleRemove}
              nativeButtonProps={{ disabled: isLoading }}
            >
              {isLoading ? <LoadingIcon class={styles.loadingIcon} /> : "Remove"}
            </Button>
          ) : (
            null
          )
        }

        <Button
          type="submit"
          style={!medication ? STYLES.FULL_WIDTH : STYLES.PRIMARY}
          nativeButtonProps={{ disabled: isLoading }}
        >
          {isLoading ? (
            <LoadingIcon class={styles.loadingIcon} />
          ) : medication ? (
            "Update"
          ) : (
            "Add"
          )}
        </Button>
      </div>
    </form>
  );
}

export default MedicationForm;
