/* Transport form component */
/* External Imports */
import clsx from "clsx";
import { useForm } from "react-hook-form";
/* Local Imports */

// components
import ControlledCombobox from "@/components/input/controlledCombobox";
import Button from "@/components/button";

// constants
import { FORM_METRIC_LABELS, FORM_VALUES } from "./constants";
import { EVENTS } from "@/components/flows/labor/constants";
import { STYLES } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";

/* Transportform Typescript Interface */
interface TransportformProps {
  onSubmit: (data: any) => void;
}

export default function Transportform({ onSubmit }: TransportformProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  const FIELDS = Object.entries(FORM_VALUES[EVENTS.TRANSPORT]);

  return (
    <form
      className={clsx(styles.LaborForm, styles.Transportform)}
      onSubmit={handleSubmit(onSubmit)}
    >
      {FIELDS &&
        FIELDS.map(([key, val]) => (
          <ControlledCombobox
            key={key}
            options={val}
            label={FORM_METRIC_LABELS[key]}
            name={key}
            control={control}
            errors={errors}
            isHorizontalLayout={false}
            fullWidth
          />
        ))}
      <Button style={STYLES.FULL_WIDTH} type="submit">
        Save to flow sheet
      </Button>
    </form>
  );
}
