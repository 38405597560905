import { createSlice } from "@reduxjs/toolkit";
import { LabOrder, Encounter } from "@/globals/constants/types";


export type Patient = {
    encounters: { [encounterID: number]: Encounter };
}

export type Patients = {
    [patientID: number]: Patient
}

const initialState: Patients = {};

function assignLabOrderToEncounterReducer(
    patients: Patients,
    { payload: { order, encounter } }: { payload: {order: LabOrder; encounter: Encounter} }
) {
    if (patients[order.patient_id] === undefined) {
        patients[order.patient_id] = { encounters: {} };
    }
    if (patients[order.patient_id].encounters[order.encounter_id] === undefined) {
        patients[order.patient_id].encounters[order.encounter_id] = { ...encounter, orders: [...encounter.orders, order]};
    }

    patients[order.patient_id].encounters[order.encounter_id] = { ...encounter, orders: [...encounter.orders, order]};

    return patients;
}

function deleteOrderReducer(
  patients: Patients,
  { payload: order }: { payload: LabOrder }
) {
  patients[order.patient_id].encounters[order.encounter_id].orders =
    patients[order.patient_id]
      ?.encounters[order.encounter_id]
      ?.orders.filter(o => o.id != order.id);

  return patients;
}

function addPatientReducer(
  patients: Patients,
  { payload: patientID }: { payload: number }
) {
  if (patients[patientID] === undefined) {
    patients[patientID] = { encounters: {} };
  }

  return patients;
}

export const patientsSlice = createSlice({
    name: "patients",
    initialState,
    reducers: {
        assignLabOrderToEncounter: assignLabOrderToEncounterReducer,
        deleteOrder: deleteOrderReducer,
        addPatient: addPatientReducer,
    }
});

export const {
  addPatient,
  assignLabOrderToEncounter,
  deleteOrder
} = patientsSlice.actions;
export default patientsSlice.reducer;
