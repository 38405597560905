import Suggestion, { SuggestionOptions } from "@tiptap/suggestion";
import { Node } from "@tiptap/core";
import { PluginKey, TextSelection } from "@tiptap/pm/state";

import dotPhrasesSuggestions from "./dotPhrasesSuggestions";

// This object defines a custom TipTap Extension called dotPhrases
// that has a suggestion module (to pop up a list to select from when
// the user types "."), inserts the actual dot phrase, and has a custom
// command to cycle through the variables within the dot phrase
export type DotPhrasesOptions = {
  suggestion: Omit<SuggestionOptions, "editor">;
};

export const DotPhrasesPluginKey = new PluginKey("dotPhrases");

// Needed to declare a custom TipTap Command
declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    dotPhrases: {
      /**
       * Set the next variable as selected
       */
      selectNextVariable: () => ReturnType;
    };
  }
}

export const DotPhrases = Node.create<DotPhrasesOptions>({
  name: "dotPhrases",

  // Adds a custom implementation of the suggestion option
  addOptions() {
    return {
      suggestion: dotPhrasesSuggestions
    };
  },

  // Default settings
  group: "inline",

  inline: true,

  selectable: false,

  atom: true,

  // This adds a custom TipTap "Command" to select the next variable.
  // Commands are used to affect the content in some way, for example,
  // bolding text is also a command.
  addCommands() {
    return {
      selectNextVariable:
        () =>
        ({ commands }: { commands: any }) => {
          const { state } = this.editor.view;
          const { doc, tr } = state;

          let getNext = true;
          doc.descendants((node, pos) => {
            if (!getNext) {
              return false;
            }
            if (node.isText) {
              const text = node.text as string;
              const match = /\*\*\*/.exec(text);
              if (match) {
                const from = pos + match.index;
                const to = from + match[0].length;
                tr.setSelection(TextSelection.create(tr.doc, from, to));
                commands.setTextSelection(tr.selection);
                commands.scrollIntoView();
                getNext = false;
              }
            }
          });
          return false;
        }
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: ({ editor }) => {
        editor.chain().selectNextVariable().focus().scrollIntoView().run();
        return true;
      }
    };
  },

  // I believe this adds our custom implementation of suggestion to
  // Prose Mirror which TipTap is a wrapper for
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      })
    ];
  }
});
