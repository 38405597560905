// External
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

// Globals, constants, helpers
import { METRICS, STATUS_KEYS } from "@/globals/constants";
import { setModalIsOpen } from "../modalSlice";

// Components
import Button from "@/components/button";
import Datagrid, { GridDataType } from "@/components/datagrid";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import Input from "@/components/input";

// Store
import { useFileListQuery } from "@/store/services/file";
import { usePracticeGetFormsQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";
import { useTaskCreateMutation } from "@/store/services/task";
import { TaskType } from "@/store/services/encounter";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// Styles
import styles from "../styles.module.scss";
import { setIds } from "@/components/datagrid/datagridSlice";

interface TaskCreationInfoProps {
  taskType: TaskType;
  assigneeId: number;
}

export default function TaskCreationInfo({
  taskType,
  assigneeId
}: TaskCreationInfoProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const form = useForm();
  const { register, handleSubmit } = form;

  const [selectedForms, setSelectedForms] = useState<number[]>([]);

  // NOTE: If the practice has more than 25 file forms or 25 html forms, this
  // will cause issues for them. Since this is a rare use case, our bandaid is
  // just a large pagesize but this could need a fix in the future.
  const { data: forms } = usePracticeGetFormsQuery(
    {
      practiceId: sessionInfo?.practice_id as number,
      pagesz: 25,
      page: 0
    },
    {
      skip: !sessionInfo || !sessionInfo.practice_id
    }
  );

  const { data: files } = useFileListQuery(
    {
      scope: "practice",
      id: sessionInfo?.practice_id as number,
      tagName: "form",
      pagesz: 25,
      page: 0
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  const [createTask, createTaskResponse] = useTaskCreateMutation();

  const availableForms: GridDataType = useMemo(() => {
    const _forms = (forms || [])
      .map(form => ({
        id: form.form_id,
        metrics: {
          task_type: "FORM",
          resource_id: form.form_id,
          form_title: form.title
        }
      }))
      .concat(
        (files || [])
          .map(file => ({
            id: file.file_id,
            metrics: {
              task_type: "FILE",
              resource_id: file.file_id,
              form_title: file.name
            }
          }))
      )
      .sort((a, b) => a.metrics.form_title.localeCompare(b.metrics.form_title));

    return _forms;
  }, [forms, files]);

  useEffect(() => {
    if (createTaskResponse.isSuccess) {
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Successfully created task"
        })
      );
      dispatch(setModalIsOpen(false));
    }

    if (createTaskResponse.isError) {
      dispatch(
        addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Unabled to create task"
        })
      );
    }
  }, [createTaskResponse]);

  const onUploadSubmit = (data: any) => {
    createTask({
      taskCreatePayload: {
        task_type: "UPLOAD",
        assignee: assigneeId,
        upload: {
          upload_prompt: data.taskDescription
        }
      }
    });
  };

  const onGenericSubmit = (data: any) => {
    createTask({
      taskCreatePayload: {
        task_type: "GENERIC",
        assignee: assigneeId,
        generic: {
          task_text: data.taskDescription,
          task_title: data.taskTitle
        }
      }
    });
  };

  const handleFormSubmit = () => {
    const filteredForms: GridDataType = availableForms.filter(form =>
      selectedForms.includes(parseInt(form.id as string))
    );

    filteredForms.forEach(form => {
      if (form.metrics.task_type == "FORM") {
        createTask({
          taskCreatePayload: {
            task_type: form.metrics.task_type as TaskType,
            assignee: assigneeId,
            form: {
              form_id: form.metrics.resource_id as number
            }
          }
        });
      } else {
        createTask({
          taskCreatePayload: {
            task_type: form.metrics.task_type as TaskType,
            assignee: assigneeId,
            file: {
              template_file_id: form.metrics.resource_id as number
            }
          }
        });
      }
    });
    setSelectedForms([]);
    dispatch(setIds([]));
  };

  const handleCheckboxClick = (fileID: number) => {
    const newSelectedForms = new Set(selectedForms);
    if (newSelectedForms.has(fileID)) {
      newSelectedForms.delete(fileID);
    } else {
      newSelectedForms.add(fileID);
    }
    setSelectedForms(Array.from(newSelectedForms));
    dispatch(setIds(Array.from(newSelectedForms)));
  };

  if (createTaskResponse.isLoading) {
    return (
      <div className={styles.TaskCreationInfo}>
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </div>
    )
  }


  switch (taskType) {
    case "FORM":
    case "FILE":
      return (
        <div
          className={styles.TaskCreationInfo}
          data-cy="task-creation-info-modal"
        >
          <div className={styles.titleContainer}>
            <p>
              <strong>Which forms would you like to assign?</strong>
            </p>
          </div>
          <Datagrid
            data={availableForms}
            gridType={METRICS.FORM_LIST}
            selectableRows
            onCheckboxClick={handleCheckboxClick}
          />
          <div className={styles.buttonContainer}>
            <Button type="button" onClick={() => handleFormSubmit()} nativeButtonProps={{disabled: selectedForms.length === 0}}>
              Submit
            </Button>
          </div>
        </div>
      );
    case "UPLOAD":
      return (
        <div
          className={styles.TaskCreationInfo}
          data-cy="task-creation-info-modal"
        >
          <form onSubmit={handleSubmit(onUploadSubmit)}>
            <ControlledTextArea
              label="What should they upload?"
              placeholder="Enter instructions here"
              name="taskDescription"
              required
              id="taskDescription"
              form={form}
            />
            <div className={styles.buttonCotainer}>
              <Button type="submit">Create Task</Button>
            </div>
          </form>
        </div>
      );
    case "GENERIC":
      return (
        <div
          className={styles.TaskCreationInfo}
          data-cy="task-creation-info-modal"
        >
          <form onSubmit={handleSubmit(onGenericSubmit)}>
            <Input
              type="text"
              name="taskTitle"
              id="taskTitle"
              label="Title of the task"
              register={register}
            />
            <ControlledTextArea
              label="What should they do?"
              placeholder="Enter instructions here"
              name="taskDescription"
              required
              id="taskDescription"
              form={form}
            />
            <div className={styles.buttonContainer}>
              <Button type="submit">Create Task</Button>
            </div>
          </form>
        </div>
      );
    case "DEMOGRAPHICS":
      throw new Error("taskCreationInfo should not be used for demographics tasks");
  }
}
