// third-party
import {
  FieldValues,
  FieldErrors,
  Path,
  Controller,
  Control
} from "react-hook-form";

// components
import ComboboxSelect from "./combobox";

// constants
import { METRIC_LABELS } from "@/globals/constants";

interface ControlledComboboxProps<T extends FieldValues, V> {
  label: string; // input label
  name: Path<T>; // input name
  errors?: FieldErrors<T>; // errors received from parent form
  control: Control<T>;
  options: V[];
  hiddenLabel?: boolean;
  isHorizontalLayout?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  labelAcc?: (item: any) => string;
  valueKey?: string;
}

export default function ControlledCombobox<T extends FieldValues, V>({
  label,
  name,
  errors,
  control,
  options,
  hiddenLabel,
  isHorizontalLayout = true,
  fullWidth,
  disabled = false,
  required = false,
  labelAcc,
  valueKey
}: ControlledComboboxProps<T, V>) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={{ required }}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState
      }) => (
        <ComboboxSelect
          hiddenLabel={hiddenLabel}
          label={label}
          options={options}
          name={name}
          isHorizontalLayout={isHorizontalLayout}
          onChange={v => onChange(v)}
          labelAcc={item =>
            labelAcc
              ? labelAcc(item)
              : METRIC_LABELS[item] || item.label || item
          }
          initialValue={value}
          error={
            errors?.[name] &&
            ((errors?.[name]?.message as string) ||
              "This field is " + errors?.[name]?.type)
          }
          fullWidth={fullWidth}
          isDisabled={disabled}
          value={value}
          valueKey={valueKey}
        />
      )}
    />
  );
}
