// External
import { useDispatch } from "react-redux";
import { SubmitHandler, useForm } from "react-hook-form";

// Store
import { setModalIsOpen } from "../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import {
  HealthHistoryEntry,
  HealthHistorySection,
  useDeleteHealthHistoryEntryMutation,
  useUpdateHealthHistoryEntryMutation
} from "@/store/services/patient";

// Components
import Button from "@/components/button";
import Input from "@/components/input";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

// Styles
import styles from "../styles.module.scss";

export interface EditHealthHistoryEntryProps {
  section: HealthHistorySection;
  entry: HealthHistoryEntry;
  patientId: number;
}

export function EditHealthHistoryEntry({
  section,
  entry,
  patientId
}: EditHealthHistoryEntryProps) {
  const dispatch = useDispatch();

  const [updateEntry] = useUpdateHealthHistoryEntryMutation();
  const [deleteEntry] = useDeleteHealthHistoryEntryMutation();

  type EntryFormData = Partial<HealthHistoryEntry>;

  const form = useForm<EntryFormData>({
    defaultValues: {
      title: entry.title,
      date_of_onset: entry.date_of_onset,
      note: entry.note
    }
  });

  const handleDeleteEntry = () => {
    deleteEntry({
      patientId,
      healthHistoryId: entry.id
    })
      .unwrap()
      .then(() => {
        dispatch(addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Successfully deleted entry"
        }))
        dispatch(setModalIsOpen(false));
      })
      .catch(err => {
        console.error(err);
        dispatch(addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Unable to delete entry"
        }))
      });
  };

  const onSubmit: SubmitHandler<EntryFormData> = data => {
    updateEntry({
      patientId,
      healthHistoryId: entry.id,
      patientHealthHistoryUpdate: {
        title: data.title,
        note: data.note || "",
        date_of_onset: data.date_of_onset || undefined
      }
    })
      .unwrap()
      .then(() => {
        dispatch(addAlertToToastTrough({
          type: STATUS_KEYS.SUCCESS,
          message: "Successfully updated entry"
        }))
        dispatch(setModalIsOpen(false));
      })
      .catch(err => {
        console.error(err);
        dispatch(addAlertToToastTrough({
          type: STATUS_KEYS.ERROR,
          message: "Unable to update entry"
        }))
      });
  };

  return (
    <form
      onSubmit={form.handleSubmit(onSubmit)}
      className={styles.EditHealthHistory}
    >
      <div className={styles.inputContainer}>
        <Input
          type="text"
          label="Title"
          name="title"
          id="title"
          register={form.register}
          fullWidth
        />
        <Input
          type="date"
          label="Date of Onset"
          name="date_of_onset"
          id="dateOfOnset"
          register={form.register}
        />
      </div>
      <ControlledTextArea label="Note" name="note" id="entryNote" form={form} />
      <div className={styles.buttonContainer}>
        <Button style={STYLES.DELETE} type="button" onClick={handleDeleteEntry}>
          Delete entry
        </Button>
        <Button style={STYLES.PRIMARY} type="submit">
          Save entry
        </Button>
      </div>
    </form>
  );
}
