/* Confirmation Modal Template */

// External
import clsx from "clsx";
import { useDispatch } from "react-redux";

// styles
import styles from "../styles.module.scss";

// components
import Button from "@/components/button";
import Icon from "@/components/icons";
import AllergyList from "@/components/allergyList";

// constants
import { STYLES } from "@/globals/constants";

// store
import { setModalIsOpen } from "../modalSlice";
import { usePatientGetInfoQuery } from "@/store/services/patient";

export interface ProblemListModalProps {
  patientId: number;
}

export default function ProblemListModal({ patientId }: ProblemListModalProps) {
  const dispatch = useDispatch();

  const { data: patient } = usePatientGetInfoQuery({ patientId });

  // Event handlers

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };
  return (
    <div className={clsx(styles.ProblemList)}>
      <div className={styles.content}>
        {patient && <AllergyList patientId={patientId} isExpanded />}
      </div>
    </div>
  );
}
