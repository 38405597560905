/* External Imports */
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import dayjs, { ManipulateType } from "dayjs";
import { useMemo } from "react";
import clsx from "clsx";

/* Local Imports */

// components
import Button from "@/components/button";
import Icon from "@/components/icons";
import Search from "@/components/input/search";

// constants
import { STYLES, APP_KEYS, TAG_COLOR_MAP } from "@/globals/constants";

// store
import { setDetailDays } from "../calendarSlice";
import { RootState } from "@/store/store";
import {
  AppointmentInfo,
  useAppointmentsGetListQuery
} from "@/store/services/scheduling";
import {
  convertUtcIntToLocalDatetime,
  convertLocalDatetimeToUtcInt,
  getDay
} from "../utils";
import { FORMAT } from "@/globals/helpers/formatters";

// styles
import styles from "../styles.module.scss";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import {
  setRightPaneContent,
  setRightPaneOpen
} from "@/components/drawer/drawerSlice";

const CalendarControls = ({
  tab,
  days,
  adjustUp = true,
  showSearch = true
}: {
  tab: string;
  days: any[];
  adjustUp: boolean;
  showSearch?: boolean;
}) => {
  /* Redux */
  const dispatch = useDispatch();
  const { selectedDate, detailView, locationFilter } = useSelector(
    (state: RootState) => state.calendar
  );
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: appointments } = useAppointmentsGetListQuery(
    {
      id: sessionInfo?.practice_id as number,
      scope: "practice"
    },
    { skip: !sessionInfo?.practice_id }
  );

  /* Local State */
  /* Event Handlers */
  const handleDecrementDate = () => {
    let unit = tab;
    if (tab === APP_KEYS.MULTI) {
      unit = "day";
    }
    const previousUnit = dayjs(selectedDate.timestamp)
      .subtract(1, unit as ManipulateType)
      .toISOString();
    const payload = { view: tab, timestamp: previousUnit };
    dispatch(setDetailDays(payload));
  };

  const handleIncrementDate = () => {
    let unit = tab;
    if (tab === APP_KEYS.MULTI) {
      unit = "day";
    }
    const nextUnit = dayjs(selectedDate.timestamp)
      .add(1, unit as ManipulateType)
      .toISOString();
    const payload = { view: tab, timestamp: nextUnit };

    dispatch(setDetailDays(payload));
  };

  const handleSetDate = (appointment?: AppointmentInfo) => {
    const now = dayjs().toISOString();
    const payload = {
      view: tab,
      timestamp: appointment?.starts
        ? convertUtcIntToLocalDatetime(appointment?.starts).toISOString()
        : now
    };
    dispatch(setDetailDays(payload));
    if (appointment) {
      dispatch(
        setRightPaneContent({
          type: MODAL_TYPES.APPOINTMENT,
          props: {
            appointment,
            title: "Schedule Appointment"
          }
        })
      );
      dispatch(setRightPaneOpen(true));
    }
  };

  // calendar title formatting by view type
  const title = useMemo(() => {
    if (detailView === APP_KEYS.MONTH) {
      return dayjs(selectedDate.timestamp).format("MMMM YYYY");
    }
    if (tab === APP_KEYS.WEEK && days?.length > 0) {
      const [first] = days;
      const last = days[days.length - 1];
      return `${dayjs(first).format("MMM D")} - ${dayjs(last).format("MMM D")}`;
    }
    return dayjs(selectedDate.timestamp).format("MMMM DD YYYY");
  }, [tab, days, selectedDate, detailView]);

  return (
    <div
      className={clsx(styles.CalendarControls, { [styles.adjustUp]: adjustUp })}
    >
      <div className={styles.monthNav}>
        <Button style={STYLES.ICON} onClick={() => handleDecrementDate()}>
          <Icon svg="chevron_left" height={12} />
        </Button>
        <div className="t2 tMd">{title}</div>
        <Button style={STYLES.ICON} onClick={() => handleIncrementDate()}>
          <Icon svg="chevron_right" height={12} />
        </Button>
      </div>
      <Button style={STYLES.SECONDARY} onClick={() => handleSetDate()}>
        Today
      </Button>
      {appointments && appointments?.length > 0 && (
        <div className={styles.end}>
          {showSearch && (
            <Search
              options={appointments}
              name="search_appts"
              id="search_appts"
              label="Search Appointments"
              hiddenLabel
              placeholder="Search appointments"
              labelAcc={({ patient, provider, start_at: start }) =>
                `${patient.first_name} ${patient.last_name} - ${
                  provider.first_name
                } ${provider.last_name} - ${dayjs(start).format("M/D/YYYY")}`
              }
              customSlot={apt => appointmentDropdownSlot(apt, handleSetDate)}
              isClearOnSelect
              truncateResultsTo={5}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CalendarControls;

function appointmentDropdownSlot(
  appointment: AppointmentInfo,
  handleClick: (appointment?: AppointmentInfo) => void
) {
  const { patient, provider, starts, ends } = appointment;
  return (
    <button
      className={clsx(
        styles.AppointmentDropdownSlot,
        styles[TAG_COLOR_MAP[appointment.appointment_type]]
      )}
      onClick={() => handleClick(appointment)}
    >
      <p>{FORMAT.name(patient)}</p>
      <p>{dayjs(convertUtcIntToLocalDatetime(starts)).format("dddd MMM D")}</p>
      <div className={styles.subtitle}>
        {dayjs(convertUtcIntToLocalDatetime(starts)).format("h:mma")}-
        {dayjs(convertUtcIntToLocalDatetime(ends)).format("h:mma")}
      </div>

      <div className={styles.subtitle}>{FORMAT.name(provider)}</div>
    </button>
  );
}
