/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Button from "@/components/button";

// constants
import { STYLES } from "@/globals/constants";

// styles
import styles from "../styles.module.scss";

/* Preview Transfer Typescript Interface */
interface PreviewTransferProps {
  url: string;
  onSubmit: () => void;
}

export default function PreviewTransfer({
  url,
  onSubmit
}: PreviewTransferProps) {
  return (
    <div className={clsx(styles.ViewFile)}>
      <embed
        src={url}
        type="application/pdf"
        width="100%"
        height="100%"
        style={{ minHeight: "70vh" }}
      />
      <Button
        type="button"
        style={STYLES.FULL_WIDTH}
        onClick={() => onSubmit()}
      >
        Send
      </Button>
    </div>
  );
}
