import React from "react";
import { Provider } from "react-redux";
import { wrapper } from "../store/store";
import { SkeletonTheme } from "react-loading-skeleton"; // loading skeleton theme
import { IntercomProvider } from "@/utils/intercom/IntercomProvider";


// A super-simple mock of a redux store
const ProviderWrapper = ({ children, ...rest }: any) => {
  const { store } = wrapper.useWrappedStore(rest);

  // Global configuration for SkeletonTheme from react-loading-skeleton library
  // https://github.com/dvtng/react-loading-skeleton
  const skeletonThemeConfig = {
    color: "rgb(208, 213, 221)", // Change the skeleton color here
    highlightColor: "rgba(242, 244, 247, 1)", // Change the highlight color here
    duration: 1.5 // Change the animation speed here (in seconds)
    // Add more configuration options as needed
  };

  return (
    <Provider store={store}>
      <IntercomProvider>
        <SkeletonTheme {...skeletonThemeConfig}>{children}</SkeletonTheme>
      </IntercomProvider>
    </Provider>
  );
};

export default ProviderWrapper;
