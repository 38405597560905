// External
import { FieldValues, UseFormRegister, Path } from "react-hook-form";
import clsx from "clsx";

// styles
import styles from "./styles.module.scss";


export interface InputProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  id: string;
  defaultChecked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  register?: UseFormRegister<T>;
  error?: string;
  disabled?: boolean;
  // Validation rules for react form hook
  rules?: Record<string, any>;
}

export default function Checkbox<T extends FieldValues>({
  label,
  name,
  id,
  defaultChecked,
  register,
  error,
  disabled,
  rules = {},
  onChange,
}: InputProps<T>) {
  return (
    <div
      className={clsx(styles.Input, styles.Checkbox, {
        [styles.disabled]: disabled,
      })}
    >
      <label>
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={defaultChecked}
          
          disabled={disabled}
          onChange={onChange}
          // if using with useform library
          {...(register && name && register(name, rules))}
        />
        {label}
      </label>
      {error && <div className={styles.validationError}>{error}</div>}
    </div>
  );
}
