// External
import { useMemo } from "react";

// Constants
import { NEWBORN_FORM } from "../constants";

// Components
import ControlledCombobox from "@/components/input/controlledCombobox";
import ControlledOptionsField from "../../generated/options";
import { NewbornFormPartsProps } from "./newborn";
import Input from "@/components/input";

// Styles
import styles from "../styles.module.scss";
import Button from "@/components/button";
import { usePatientGetInfoQuery } from "@/store/services/patient";

interface NewbornPhysicalProps extends NewbornFormPartsProps {
  newbornId: number;
}

export default function NewbornPhysical({
  disabled = false,
  form,
  previousExamData,
  newbornId
}: NewbornPhysicalProps) {
  const {
    control,
    formState: { errors },
    getValues
  } = form;

  const { data: infantInfo } = usePatientGetInfoQuery({
    patientId: newbornId,
  })

  const isVersion2 = useMemo(() => {
    return !(previousExamData && previousExamData?.version !== "2");
  }, [previousExamData]);

  const formFields = useMemo(() => {
    // If we don't know the sex, don't filter at all.
    if (infantInfo?.sex === "UNKNOWN") {
      return NEWBORN_FORM.physical_v2;
    }
    // If the patient is male, filter out the female one and vice versa
    const genitourinaryFieldFilter = infantInfo?.sex == "MALE" ? "genitourinary_female" : "genitourinary_male";
    return NEWBORN_FORM.physical_v2.filter(({id}) => id != genitourinaryFieldFilter)
  }, [infantInfo])

  if (isVersion2) {
    return (
      <>
        {formFields.map(({ id, label, options }) => (
          <div key={id} className={styles.fieldPair}>
            <ControlledCombobox
              key={id}
              options={options || []}
              label={label}
              name={id}
              control={control}
              errors={errors}
              isHorizontalLayout
              fullWidth
              disabled={disabled}
              valueKey={getValues(id)}
            />
            <Input
              type="text"
              name={`${id}-comment`}
              id={`${id}-comment`}
              label="Comment"
              register={form.register}
              isHorizontalLayout={true}
            />
          </div>
        ))}
      </>
    );
  } else {
    return (
      <>
        {NEWBORN_FORM.physical.map(({ id, label, options }) =>
          id === "thorax" ? (
            <div key={id} className={styles.row}>
              <label>{label}</label>
              <ControlledOptionsField
                label={label}
                name={id}
                key={id}
                control={control}
                options={[
                  { id: "yes", label: "Yes" },
                  { id: "no", label: "No" }
                ]}
                hiddenLabel
                disabled={disabled}
              />
            </div>
          ) : (
            <ControlledCombobox
              key={id}
              options={options || []}
              label={label}
              name={id}
              control={control}
              errors={errors}
              isHorizontalLayout
              fullWidth
              disabled={disabled}
              valueKey={getValues(id)}
            />
          )
        )}
      </>
    );
  }
}
