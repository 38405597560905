/* Confirmation Modal Template */

// External
import clsx from "clsx";
import { Inter } from "next/font/google";
import LoadingIcon from "../../../../public/svgs/loading-icon.svg";

// styles
import styles from "../styles.module.scss";

const font = Inter({
  weight: ["400", "500", "700"],
  subsets: ["latin"],
});



export default function LoadingSpinner() {
  return (
    <div className={clsx(styles.LoadingSpinner, font.className)}>
      <LoadingIcon className={clsx(styles.loadingIcon, styles.dark)} />
    </div>
  );
}
