import * as generated from "./generated/system"

const system = generated.system.enhanceEndpoints({});

export * from "./generated/system";
export default system;
export const {
  useGetFeatureFlagsQuery,
  useLazyGetFeatureFlagsQuery,
  useReportFrontendErrorMutation
} = system;
