/* VitalsProgressUpdate form component - generic progress update for admission and transfer */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
/* Local Imports */

// components
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import Button from "@/components/button";
import Clock from "../../../../public/svgs/clock.svg";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import ControlledOptionsField from "@/components/forms/generated/options";

// constants
import { STYLES } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store
import { FORM_METRICS, FORM_VALUES } from "./constants";
import { EVENTS } from "@/components/flows/labor/constants";

// styles
import styles from "./styles.module.scss";
import ComboboxSelect from "@/components/input/combobox";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* ProgressUpdateform Typescript Interface */
interface VitalsProgressUpdateformProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  defaultValues?: any;
  disabled?: boolean;
}

export default function VitalsProgressUpdateform({
  onSubmit,
  defaultValues,
  disabled = false
}: VitalsProgressUpdateformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    reset
  } = form;
  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);

  /* Effects */
  // Scroll the form into view on first render
  useEffect(() => {
    if (formRef?.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  /* Event Handlers */

  const contractionFields = [
    { name: "freq", type: "text" },
    { name: "dur", type: "text" },
    { name: "strength", type: "text" }
  ];

  const progressFields = [
    { name: "dil", type: "number" },
    { name: "eff", type: "text" },
    { name: "station", type: "number" }
  ];

  const vitalInputs = [
    { name: "BP", type: "text" },
    { name: "heart_rate", type: "number" },
    { name: "temp", type: "number" },
    { name: "inputs", type: "text" },
    { name: "outputs", type: "text" }
  ];

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues,
        start_time: defaultValues.start_time,
        listening_device: defaultValues.listening_device
      });
    } else {
      setValue("start_time", dayjs().utc().format("YYYYMMDDHHmmss"));
    }
  }, [defaultValues]);

  return (
    <form
      data-cy="progress-update-form"
      className={clsx(styles.LaborForm, styles.ProgressUpdateform)}
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data, reset);
      })}
      ref={formRef}
    >
      <div style={{ display: "none" }}>
        <Input
          register={register}
          type="text"
          name="triage_vitals"
          label="triage vitals hidden"
          id="triage_vitals"
        />
      </div>
      <fieldset
        className={clsx(styles.time, styles.fullWidth, styles.progressTime)}
      >
        <legend>Start time</legend>
        <Controller
          name="start_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                initialValue={defaultValues && defaultValues.start_time}
                value={value}
                hiddenLabel
              />
            );
          }}
        />
      </fieldset>

      {/** Contractions section */}
      <fieldset className={clsx(styles.progressSection, styles.spanFour)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>
          Contractions
        </legend>
        {contractionFields.map(({ name, type }) => (
          <div className={styles.spanTwo} key={name}>
            <Input
              key={name}
              type={type}
              label={name.replace("_", " ")}
              name={name}
              id={name}
              fullWidth
              register={register}
              disabled={disabled}
              fitWidth
            />
          </div>
        ))}
      </fieldset>

      {/** Cervical Exam section */}
      <fieldset className={clsx(styles.progressSection, styles.spanFour)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>
          Cervical Exam
        </legend>

        {progressFields.map(({ name, type }) => (
          <div className={styles.spanTwo} key={name}>
            <Input
              key={name}
              type={type}
              label={name.replace("_", " ")}
              name={name}
              id={name}
              fullWidth
              register={register}
              disabled={disabled}
              fitWidth
            />
          </div>
        ))}
      </fieldset>

      {/** Vitals section */}
      <fieldset className={clsx(styles.progressSection, styles.fullWidth)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>Vitals</legend>

        {vitalInputs.map(({ name, type }, index) => (
          <Input
            key={name}
            type={type}
            label={name.replace("_", " ")}
            name={name}
            id={name}
            fullWidth
            register={register}
            disabled={disabled}
          />
        ))}
        <div style={{ minWidth: "200px" }}>
          <ControlledCombobox
            options={FORM_VALUES[EVENTS.BIRTH][FORM_METRICS.MATERNAL_POS]}
            label="Maternal Position"
            name={"maternal_position"}
            control={control}
            isHorizontalLayout={false}
            errors={errors}
            fullWidth
            disabled={disabled}
          />
        </div>
      </fieldset>

      <fieldset className={clsx(styles.fullWidth, styles.progressTime)}>
        <legend>Comments</legend>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          hiddenLabel
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
        />
      </fieldset>
      <div className={styles.fullWidth}>
        <Button
          style={STYLES.FULL_WIDTH}
          type="submit"
          nativeButtonProps={{ disabled }}
        >
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}
