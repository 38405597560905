// constants
import { METRIC_LABELS, METRIC_LABEL_OVERRIDES } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";

export interface HeadProps {
  headers: string[];
  hasEmpty?: boolean;
  hasEmptyEnd?: boolean;
}

export default function Head({
  headers,
  hasEmpty = false,
  hasEmptyEnd
}: HeadProps) {
  return (
    <thead>
      <tr>
        {/* empty header */}
        {hasEmpty && <th className={styles.collapse}></th>}
        {headers &&
          headers.map(metric => (
            <th key={metric}>
              {METRIC_LABEL_OVERRIDES[metric] ||
                METRIC_LABELS[metric] ||
                metric}
            </th>
          ))}
        {hasEmptyEnd && <th className={styles.narrow}></th>}
      </tr>
    </thead>
  );
}
