/* CreateAllergy */
/* External Imports */
import clsx from "clsx";
import { Controller, UseFormReturn } from "react-hook-form";
import { motion } from "framer-motion";

/* Local Imports */

// components
import ComboboxSelect from "@/components/input/combobox";
import Button from "@/components/button";
import Input from "@/components/input";
import ControlledTextArea from "../textArea/controlledTextArea";

// constants
import {
  ALLERGY_SEVERITITES,
  METRIC_LABELS,
  STYLES
} from "@/globals/constants";

// store
import { useLazyCodingSearchIcd10Query } from "@/store/services/coding";

// styles
import styles from "./styles.module.scss";

/* CreateAllergy Typescript Interface */
interface CreateAllergyProps {
  onSubmit: () => void;
  onCancel: () => void;
  form: UseFormReturn;
}

export default function CreateAllergy({
  onSubmit,
  onCancel,
  form
}: CreateAllergyProps) {
  /* Redux */
  const [searchICD10, { data: icd10codes }] = useLazyCodingSearchIcd10Query();
  const { register, control, getValues, setValue } = form;
  /* Local State */

  /* Effects */

  /* Event Handlers */
  return (
    <motion.div
      className={clsx(styles.CreateAllergy)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <form onSubmit={(onSubmit)}>
        <div className={styles.filterWrapper}>
          <Controller
            name="select_icd10"
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState
            }) => (
              <ComboboxSelect
                label="Select diagnosis"
                options={icd10codes || []}
                labelAcc={item => `${item?.name} - ${item?.description}`}
                onChange={v => {
                  if (
                    // prevents infinite renders since value is an object
                    !form.formState?.defaultValues?.select_icd10?.icd_id ||
                    v?.icd_id !==
                      form.formState?.defaultValues?.select_icd10?.icd_id
                  ) {
                    onChange(v);
                  }
                }}
                onSearch={term => {
                  // get ICD10 codes from API
                  if (term.length >= 3) searchICD10({ term });
                }}
                fullWidth
                value={value}
                error={form.formState.errors.select_icd10 && "Required"}
              />
            )}
          />
          <span className="tMd t5">or</span>
          <Input
            label="Create allergy title"
            id="name"
            name="name"
            type="text"
            register={register}
            required={!getValues("select_icd10")}
            fullWidth
          />
        </div>
        <Input
          label="Reaction"
          id="allergy_reaction"
          name="allergy_reaction"
          type="text"
          register={register}
          fullWidth
        />
        <div className="grid2">
          <Controller
            name="select_severity"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <ComboboxSelect
                label="Severity"
                placeholder="Select reaction severity"
                options={ALLERGY_SEVERITITES}
                labelAcc={item => METRIC_LABELS[item]}
                onChange={v => onChange(v)}
                fullWidth
                initialValue={value}
                value={value}
                error={form.formState.errors.select_severity && "Required"}
              />
            )}
          />
          <Input
            label="Date of onset"
            id="date_of_onset"
            name="date_of_onset"
            type="date"
            register={register}
            fullWidth
          />
        </div>
        <ControlledTextArea
          label="Comments"
          id={`allergy_new_note`}
          name={`allergy_new_note`}
          placeholder="add a note about this allergy"
          setValue={setValue}
          form={form}
        />
        <div className={styles.buttons}>
          <Button style={STYLES.SECONDARY} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save Allergy</Button>
        </div>
      </form>
    </motion.div>
  );
}
