import clsx from "clsx";
import styles from "./styles.module.scss";

interface TableItemProps {
  headers: string[];
  values: Array<Array<string>>;
  tightDisplay?: boolean;
}

export default function TableItem({
  headers,
  values,
  tightDisplay = false
}: TableItemProps) {
  return (
    <table className={clsx(styles.TableItem, {[styles.tightDisplay]: tightDisplay})}>
      <thead>
        <tr>
          {headers.map((header: any, index: number) => (
            <th key={index}>
              <p>{header}</p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((value, index) => (
          <tr key={index}>
            {value.map((item: any, index: number) => (
              <td key={index}>
                <p>{item}</p>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
