import * as generated from "./generated/event";

const event = generated.event.enhanceEndpoints({
    endpoints: {
        eventList: {
            providesTags: ["Events"]
        },
        eventInfo: {
            providesTags: ["Events"]
        },
        eventCreate: {
            invalidatesTags: ["Events"]
        },
        eventUpdate: {
            invalidatesTags: ["Events"]
        },
        eventDelete: {
            invalidatesTags: ["Events"]
        },
    }
});

export * from "./generated/event";
export default event;
export const {
    useEventCreateMutation,
    useEventDeleteMutation,
    useEventInfoQuery,
    useEventListQuery,
    useEventUpdateMutation,
    useLazyEventInfoQuery,
    useLazyEventListQuery
} = event;
