import styles from "./styles.module.scss";

interface ValueItemProps {
  children: JSX.Element | JSX.Element[];
}

export default function ValueItem({ children }: ValueItemProps) {
  return (
    <div className={styles.ValueItem}>
      <div className={styles.singleValue}>{children}</div>
    </div>
  );
}
