import { createSlice } from "@reduxjs/toolkit";
import { FlowType } from "@/globals/constants/api";

type initialState = {
  flowType: FlowType;
};

const initialState: initialState = {
  flowType: "WELL_WOMAN",
};

export const flowSlice = createSlice({
  name: "flow",
  initialState,
  reducers: {
    setFlowType: (state, { payload }) => {
      state.flowType = payload;
    },
  }
});

export const { setFlowType } = flowSlice.actions;

export default flowSlice.reducer;
