/* ScheduleEvent Appointment Display */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import { motion, useAnimation } from "framer-motion";
import { useState } from "react";

/* Local Imports */

// components

// constants

// store

// styles
import styles from "../styles.module.scss";

/* ScheduleEvent Typescript Interface */
interface ScheduleEventProps {
  tagLabel: string;
  backgroundColor: string; // hex code
  start: string;
  end: string;
  offset?: number;
}

export default function ScheduleEvent({
  tagLabel,
  backgroundColor,
  start,
  end,
  offset = 0
}: ScheduleEventProps) {
  // get appointment duration in minutes
  const duration = dayjs(end).diff(dayjs(start), "minutes");

  // Animation controls
  const controls = useAnimation();

  // Handlers for hover events
  const handleMouseEnter = () => {
    controls.start({ opacity: 1, x: 0 });
  };

  const handleMouseLeave = () => {
    controls.start({ opacity: 0, x: -4 });
  };

  return (
    <div className={clsx(styles.ScheduleEvent)}>
      <div
        className={clsx(styles.appointmentCell, styles.scheduleEvent)}
        style={{
          height: `calc(${(duration / 15) * 100}% - 4px`,
          backgroundColor: backgroundColor,
          left: offset * 12
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <motion.div
          className={clsx(styles.flag)}
          style={{
            backgroundColor: backgroundColor
          }}
          initial={{ opacity: 0, x: -50 }}
          animate={controls}
        >
          <div className={styles.scheduleInfo}>
            <p>{tagLabel}</p>
            <p>
              {dayjs(start).format("hh:mm a")} - {dayjs(end).format("hh:mm a")}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
