/* Demographics Name */
/* External Imports */
import clsx from "clsx";
import { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

/* Local Imports */

// components
import InsuranceInformation from "@/components/forms/insurance";
import BasicAccordion from "@/components/accordions/basic";
import Button from "@/components/button";
import PatientGeneralInfoForm from "@/components/forms/patientGeneralInformation";
import PatientEmergencyContactForm from "@/components/forms/patientEmergencyContacts";
import Icon from "@/components/icons";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { PatientInfo, UserId } from "@/store/services/patient";
import { UserInfo } from "@/store/services/encounter";
import { ProviderInfo } from "@/store/services/provider";
import { usePatientGetInfoQuery } from "@/store/services/patient";
import { useLazyPatientInfoExportQuery } from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// utils
import { openPdfFromBase64 } from "@/components/labOrdering/utils";

// styles
import styles from "./styles.module.scss";

/* Demographics Typescript Interface */
interface DemographicsProps {
  user?: PatientInfo | ProviderInfo | UserInfo;
  isTaskView?: boolean;
  patientId?: UserId;
}

export default function Demographics({
  user,
  isTaskView = false,
  patientId
}: DemographicsProps) {
  /* Redux */
  const dispatch = useDispatch();
  const [exportPatientInfo] = useLazyPatientInfoExportQuery();

  /* Local State */
  const [isEditingInfo, setIsEditingInfo] = useState<boolean>(isTaskView);
  const [isEditingFirst, setIsEditingFirst] = useState<boolean>(isTaskView);
  const [isEditingSecond, setIsEditingSecond] = useState<boolean>(isTaskView);
  const [isEditingThird, setIsEditingThird] = useState<boolean>(isTaskView);
  const { data: localPatient } = usePatientGetInfoQuery(
    { patientId: patientId as number },
    { skip: !patientId }
  );

  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  /* Event Handlers */

  // for the task version of these forms,
  // I want to refetch the patient each time I update
  // this is the make the RTKQuery tags play well and instantly update the data inside the drawer
  const localUser = useMemo(() => {
    if (isTaskView) {
      return localPatient;
    } else {
      return user;
    }
  }, [user, localPatient, isTaskView]);

  const handleExportList = () => {
    exportPatientInfo({
      patientId: localUser?.user_id as number
    })
      .unwrap()
      .then(res => {
        openPdfFromBase64(res.content);
      })
      .catch(() =>
        dispatch(
          addAlertToToastTrough({
            message: "Failed to export",
            type: STATUS_KEYS.ERROR
          })
        )
      );
  };

  return (
    <div className={clsx(styles.Demographics, { [styles.oneCol]: isTaskView })}>
      <div className={styles.exportButton}>
        <Button style={STYLES.TERTIARY} onClick={handleExportList}>
          <Icon svg="printer" />
          Export Demographics & Insurance Information
        </Button>
      </div>
      <div className={styles.colWrap}>
        <BasicAccordion
          title="General Information"
          style={isMobile ? STYLES.PRIMARY : STYLES.SECONDARY}
          open
          customLeftContent={
            <>
              {!isMobile && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingInfo(!isEditingInfo);
                  }}
                >
                  {isEditingInfo ? "Cancel" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <PatientGeneralInfoForm
            patient={localUser as PatientInfo}
            isReadOnly={!isEditingInfo}
            onUpdate={() => setIsEditingInfo(false)}
          />
        </BasicAccordion>
        <BasicAccordion
          title="Primary Insurance"
          style={isMobile ? STYLES.PRIMARY : STYLES.SECONDARY}
          open
          customLeftContent={
            <>
              {!isMobile && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingFirst(!isEditingFirst);
                  }}
                >
                  {isEditingFirst ? "Cancel" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <InsuranceInformation
            type={"PRIMARY"}
            patientId={localUser?.user_id as number}
            isReadOnly={!isEditingFirst}
            onUpdate={() => setIsEditingFirst(false)}
            isTaskView={isTaskView}
          />
        </BasicAccordion>
      </div>

      <BasicAccordion
        title="Emergency Contacts"
        style={isMobile ? STYLES.PRIMARY : STYLES.SECONDARY}
        open
        customLeftContent={
          <>
            {!isMobile && (
              <Button
                style={STYLES.SECONDARY}
                onClick={e => {
                  e?.stopPropagation();
                  setIsEditingInfo(!isEditingInfo);
                }}
              >
                {isEditingInfo ? "Cancel" : "Edit"}
              </Button>
            )}
          </>
        }
      >
        <div className={styles.colWrap}>
          {(localUser as PatientInfo).emergency_contacts?.map(
            (contact, index: number) => (
              <div key={contact.email}>
                <PatientEmergencyContactForm
                  patient={localUser as PatientInfo}
                  isReadOnly={!isEditingInfo}
                  onUpdate={() => setIsEditingInfo(false)}
                  contactIndex={index}
                />
              </div>
            )
          )}
          {!isEditingInfo &&
            !(localUser as PatientInfo).emergency_contacts?.length && (
              <PatientEmergencyContactForm
                patient={localUser as PatientInfo}
                isReadOnly
                onUpdate={() => setIsEditingInfo(false)}
              />
            )}
          {isEditingInfo && (
            <div>
              <PatientEmergencyContactForm
                patient={localUser as PatientInfo}
                isReadOnly={!isEditingInfo}
                onUpdate={() => setIsEditingInfo(false)}
                contactIndex={
                  (localUser as PatientInfo).emergency_contacts &&
                  (localUser as PatientInfo).emergency_contacts?.length
                    ? ((localUser as PatientInfo).emergency_contacts
                        ?.length as number) + 1
                    : 0
                }
              />
            </div>
          )}
        </div>
      </BasicAccordion>

      <div className={styles.colWrap}>
        {" "}
        <BasicAccordion
          title="Secondary Insurance"
          style={isMobile ? STYLES.PRIMARY : STYLES.SECONDARY}
          customLeftContent={
            <>
              {!isMobile && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingSecond(!isEditingSecond);
                  }}
                >
                  {isEditingSecond ? "Cancel" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <InsuranceInformation
            type={"SECONDARY"}
            patientId={localUser?.user_id as number}
            isReadOnly={!isEditingSecond}
            onUpdate={() => setIsEditingSecond(false)}
          />
        </BasicAccordion>
        <BasicAccordion
          title="Tertiary Insurance"
          style={isMobile ? STYLES.PRIMARY : STYLES.SECONDARY}
          customLeftContent={
            <>
              {!isMobile && (
                <Button
                  style={STYLES.SECONDARY}
                  onClick={e => {
                    e?.stopPropagation();
                    setIsEditingThird(!isEditingThird);
                  }}
                >
                  {isEditingThird ? "Cancel" : "Edit"}
                </Button>
              )}
            </>
          }
        >
          <InsuranceInformation
            type={"TERTIARY"}
            patientId={localUser?.user_id as number}
            isReadOnly={!isEditingThird}
            onUpdate={() => setIsEditingThird(false)}
          />
        </BasicAccordion>
      </div>
    </div>
  );
}
