import { METRICS } from "@/globals/constants";

const STAGES = {
  ADMISSION: "admission",
  TRIAGE: "triage",
  LABOR: "labor",
  BIRTH: "birth",
  HEMORRHAGE: "hemorrhage",
  RESUSCITATION: "resusciation",
  POSTPARTUM: "immediate_postpartum",
  TRANSFER: "transfer",
  SUMMARY: "summary",
  CONCLUSION: "conclusion"
};

const STAGE_LABELS = {
  [STAGES.ADMISSION]: "Admission",
  [STAGES.TRIAGE]: "Triage",
  [STAGES.LABOR]: "Labor",
  [STAGES.BIRTH]: "Birth",
  [STAGES.HEMORRHAGE]: "Hemorrhage",
  [STAGES.RESUSCITATION]: "Resuscitation",
  [STAGES.POSTPARTUM]: "Immediate Postpartum",
  [STAGES.TRANSFER]: "Transfer",
  [STAGES.SUMMARY]: "Summary",
  [STAGES.CONCLUSION]: "Discharge"
};
const EVENTS = {
  ADMISSION: "admission",
  ONSET_LABOR: "onset_of_labor",
  ACTIVE_LABOR: "active_labor",
  PUSHING: "spontaneous_pushing",
  SECOND_STAGE: "second_stage",
  THIRD_STAGE: "third_stage",
  FIRST_STAGE: "first_stage",
  EVENT: "event",
  PROGRESS_UPDATE: "progress_update",
  MEDICATION: "medication",
  HYDROTHERAPY: "hydrotherapy",
  ROM: "ROM",
  CROWN: "crown",
  HEAD: "head",
  BIRTH: "birth",
  LATCH: "first_latch",
  PLACENTA: "placenta",
  CORD_CUT: "cord_cut",
  LACERATION: "laceration",
  EPIS: "epis",
  BLOOD_LOSS: "blood_loss",
  APGAR_1: "apgar_1",
  APGAR_5: "apgar_5",
  APGAR_10: "apgar_10",
  NEWBORN_EXAM: "newborn_exam",
  DISCHARGE: "discharge",
  SOAP: "soap_note",
  TRANSPORT: "transport_decision",
  HOSPITAL_NOT: "hospital_notified",
  DEPARTED: "departed_starting_location",
  ARRIVED: "arrived_hospital",
  PITOCIN: "pitocin",
  MISOPROSTOL: "misoprostol",
  METHERGINE: "methergine",
  PLACE_IV: "place_IV",
  LACTATED_RINGER: "lactated_ringer",
  CATH_BLAD: "catherize_bladder",
  MAN_PLACENTA_REMOV: "manual_placenta_removal",
  PLACENTA_OUT: "placenta_out",
  TRENDELENBURG: "trendelenburg",
  OXYGEN: "oxygen",
  ANTI_SHOCK_GARM: "anti_shock_garment",
  BIMANUAL_COMPRESS: "bimanual_compress",
  EMS_ACTIVATED: "EMS_activated",
  DRY_STIM: "dry_and_stimulate",
  INFLATION_BREATHS: "inflation_breaths",
  PPV_AIR: "PPV_room_air",
  PPV_OXYGEN: "PPV_oxygen",
  PULSE_OXIMETER: "pulse_oximeter",
  CHEST_COMPR_PPV: "chest_compressions_ppv",
  CHEST_COMP_DISC: "chest_compressions_discontinued",
  FIRST_BREATH: "first_breath",
  PPV_DISC: "ppv_discontinued",
  LARYNGEAL_MASK: "laryngeal_mask_airway",
  INTUBATION: "intubation",
  UMBILICAL_LINE: "umbilical_line",
  EPINEPHRINE: "epinephrine",
  DECISION: "decision_to_transfer",
  RECEIVER_NOTIFIED: "recieving_entity_notified",
  AMBULANCE_CALLED: "call_to_ambulance",
  AMBULANCE_ARRIVED: "ambulance_arrived",
  AMBULANCE_LEFT: "ambulance_lefted",
  ARRIVED_AT_HOSPITAL: "arrived_at_hospital",
  OUTCOME_AFTER: "outcome_after_transfer",
  COMPLICATIONS: "complications_and_interventions",
  LENGTH_OF_STAY: "length_of_stay",
  TRANSFER_SENT: "transfer_sent",
  SUMMARY: "labor_summary",
  STOP_TIMERS: "stop_timers",
  SUCTION: "suction",
  PIT_IV: "pit_iv",
  TXA: "txa",
  NEWBORN_DISCHARGE: "newborn_discharge",
  PARENT_DISCHARGE: "parent_discharge",
  CREATE_INFANT: "create_infant",
  LABOR_CONCLUDED: "labor_concluded",
  MATERNAL_VITALS: "maternal_vitals",
  NEWBORN_VITALS: "newborn_vitals",
  CPAP: "cpap",
  MILK_UMBILICAL_CORD: "milk_umbilical_cord",
  POSTURAL_DRAINAGE: "postural_drainage",
  PERCUSSION: "percussion",
  DELEE_SUCTION: "delee_suction",
  BULB_SUCTION: "bulb_suction",
  TRIAGE: "triage",
  TRIAGE_DISCHARGE: "triage_discharge"
};

const EVENT_DETAIL_EVENTS = {
  [STAGES.HEMORRHAGE]: [
    EVENTS.MATERNAL_VITALS,
    EVENTS.PITOCIN,
    EVENTS.MISOPROSTOL,
    EVENTS.METHERGINE,
    EVENTS.PLACE_IV,
    EVENTS.LACTATED_RINGER,
    EVENTS.CATH_BLAD,
    EVENTS.MAN_PLACENTA_REMOV,
    EVENTS.PLACENTA_OUT,
    EVENTS.TRENDELENBURG,
    EVENTS.OXYGEN,
    EVENTS.ANTI_SHOCK_GARM,
    EVENTS.BIMANUAL_COMPRESS,
    EVENTS.EMS_ACTIVATED,
    EVENTS.PIT_IV,
    EVENTS.TXA
  ],
  [STAGES.RESUSCITATION]: [
    EVENTS.MATERNAL_VITALS,
    EVENTS.NEWBORN_VITALS,
    EVENTS.DRY_STIM,
    EVENTS.INFLATION_BREATHS,
    EVENTS.PPV_AIR,
    EVENTS.PPV_OXYGEN,
    EVENTS.PULSE_OXIMETER,
    EVENTS.CHEST_COMPR_PPV,
    EVENTS.CHEST_COMP_DISC,
    EVENTS.FIRST_BREATH,
    EVENTS.PPV_DISC,
    EVENTS.LARYNGEAL_MASK,
    EVENTS.INTUBATION,
    EVENTS.UMBILICAL_LINE,
    EVENTS.EPINEPHRINE,
    EVENTS.EMS_ACTIVATED,
    EVENTS.SUCTION,
    EVENTS.CPAP,
    EVENTS.MILK_UMBILICAL_CORD,
    EVENTS.POSTURAL_DRAINAGE,
    EVENTS.PERCUSSION,
    EVENTS.DELEE_SUCTION,
    EVENTS.BULB_SUCTION
  ],
  [STAGES.ADMISSION]: [EVENTS.ADMISSION],
  [STAGES.TRIAGE]: [EVENTS.TRIAGE, EVENTS.TRIAGE_DISCHARGE],
  [STAGES.LABOR]: [
    EVENTS.ONSET_LABOR,
    EVENTS.ACTIVE_LABOR,
    EVENTS.SECOND_STAGE,
    EVENTS.ROM
  ],
  [STAGES.BIRTH]: [
    EVENTS.CROWN,
    EVENTS.HEAD,
    EVENTS.BIRTH,
    EVENTS.PLACENTA,
    EVENTS.LACERATION,
    EVENTS.BLOOD_LOSS,
    EVENTS.FIRST_BREATH
  ],
  [STAGES.POSTPARTUM]: [
    EVENTS.LATCH,
    EVENTS.CORD_CUT,
    EVENTS.APGAR_1,
    EVENTS.APGAR_5,
    EVENTS.APGAR_10
  ],
  [STAGES.CONCLUSION]: [
    EVENTS.SUMMARY,
    EVENTS.PARENT_DISCHARGE,
    EVENTS.NEWBORN_DISCHARGE
  ],
  [STAGES.TRANSFER]: [
    EVENTS.DECISION,
    EVENTS.RECEIVER_NOTIFIED,
    EVENTS.AMBULANCE_CALLED,
    EVENTS.AMBULANCE_ARRIVED,
    EVENTS.AMBULANCE_LEFT,
    EVENTS.ARRIVED_AT_HOSPITAL,
    EVENTS.OUTCOME_AFTER,
    EVENTS.COMPLICATIONS,
    EVENTS.LENGTH_OF_STAY,
    EVENTS.STOP_TIMERS
  ]
};

const EVENT_DETAIL_EVENTS_V2 = {
  [STAGES.HEMORRHAGE]: [
    EVENTS.MATERNAL_VITALS,
    EVENTS.PITOCIN,
    EVENTS.MISOPROSTOL,
    EVENTS.METHERGINE,
    EVENTS.PLACE_IV,
    EVENTS.LACTATED_RINGER,
    EVENTS.CATH_BLAD,
    EVENTS.MAN_PLACENTA_REMOV,
    EVENTS.PLACENTA_OUT,
    EVENTS.TRENDELENBURG,
    EVENTS.OXYGEN,
    EVENTS.ANTI_SHOCK_GARM,
    EVENTS.BIMANUAL_COMPRESS,
    EVENTS.EMS_ACTIVATED,
    EVENTS.PIT_IV,
    EVENTS.TXA
  ],
  [STAGES.RESUSCITATION]: [
    EVENTS.MATERNAL_VITALS,
    EVENTS.NEWBORN_VITALS,
    EVENTS.DRY_STIM,
    EVENTS.INFLATION_BREATHS,
    EVENTS.PPV_AIR,
    EVENTS.PPV_OXYGEN,
    EVENTS.PULSE_OXIMETER,
    EVENTS.CHEST_COMPR_PPV,
    EVENTS.CHEST_COMP_DISC,
    EVENTS.FIRST_BREATH,
    EVENTS.PPV_DISC,
    EVENTS.LARYNGEAL_MASK,
    EVENTS.INTUBATION,
    EVENTS.UMBILICAL_LINE,
    EVENTS.EPINEPHRINE,
    EVENTS.EMS_ACTIVATED,
    EVENTS.SUCTION,
    EVENTS.CPAP,
    EVENTS.MILK_UMBILICAL_CORD,
    EVENTS.POSTURAL_DRAINAGE,
    EVENTS.PERCUSSION,
    EVENTS.DELEE_SUCTION,
    EVENTS.BULB_SUCTION
  ],
  [STAGES.ADMISSION]: [EVENTS.ADMISSION],
  [STAGES.TRIAGE]: [EVENTS.TRIAGE, EVENTS.TRIAGE_DISCHARGE],
  [STAGES.LABOR]: [
    EVENTS.ONSET_LABOR,
    EVENTS.ACTIVE_LABOR,
    EVENTS.SECOND_STAGE,
    EVENTS.ROM,
    EVENTS.CROWN,
    EVENTS.HEAD,
    EVENTS.BIRTH,
    EVENTS.PLACENTA,
    EVENTS.EPIS,
    EVENTS.LACERATION,
    EVENTS.BLOOD_LOSS,
    EVENTS.FIRST_BREATH
  ],
  [STAGES.POSTPARTUM]: [
    EVENTS.LATCH,
    EVENTS.CORD_CUT,
    EVENTS.BLOOD_LOSS,
    EVENTS.APGAR_1,
    EVENTS.APGAR_5,
    EVENTS.APGAR_10
  ],
  [STAGES.CONCLUSION]: [
    EVENTS.SUMMARY,
    EVENTS.PARENT_DISCHARGE,
    EVENTS.NEWBORN_DISCHARGE
  ],
  [STAGES.TRANSFER]: [
    EVENTS.DECISION,
    EVENTS.RECEIVER_NOTIFIED,
    EVENTS.AMBULANCE_CALLED,
    EVENTS.AMBULANCE_ARRIVED,
    EVENTS.AMBULANCE_LEFT,
    EVENTS.ARRIVED_AT_HOSPITAL,
    EVENTS.OUTCOME_AFTER,
    EVENTS.COMPLICATIONS,
    EVENTS.LENGTH_OF_STAY,
    EVENTS.STOP_TIMERS
  ]
};

const EVENT_LABELS = {
  [EVENTS.ADMISSION]: "Admission",
  [EVENTS.ONSET_LABOR]: "Onset of Labor",
  [EVENTS.ACTIVE_LABOR]: "Active Labor",
  [EVENTS.PUSHING]: "Spontaneous Pushing",
  [EVENTS.SECOND_STAGE]: "Second Stage",
  [EVENTS.THIRD_STAGE]: "Third Stage",
  [EVENTS.EVENT]: "Event",
  [EVENTS.PROGRESS_UPDATE]: "Progress Update",
  [EVENTS.MEDICATION]: "Medication",
  [EVENTS.HYDROTHERAPY]: "Hydrotherapy",
  [EVENTS.ROM]: "ROM",
  [EVENTS.CROWN]: "Crown",
  [EVENTS.HEAD]: "Head",
  [EVENTS.BIRTH]: "Birth",
  [EVENTS.LATCH]: "First Latch",
  [EVENTS.PLACENTA]: "Placenta",
  [EVENTS.CORD_CUT]: "Cord Cut",
  [EVENTS.LACERATION]: "Laceration",
  [EVENTS.EPIS]: "EPIS",
  [EVENTS.BLOOD_LOSS]: "Blood Loss",
  [EVENTS.APGAR_1]: "Apgar 1",
  [EVENTS.APGAR_5]: "Apgar 5",
  [EVENTS.APGAR_10]: "Apgar 10",
  [EVENTS.NEWBORN_EXAM]: "Newborn Exam",
  [EVENTS.DISCHARGE]: "Discharge",
  [EVENTS.SOAP]: "Soap Note",
  [EVENTS.TRANSPORT]: "Transport Decision",
  [EVENTS.HOSPITAL_NOT]: "Hospital Notified",
  [EVENTS.DEPARTED]: "Departed Starting Location",
  [EVENTS.ARRIVED]: "Arrived at Hospital",
  [EVENTS.PITOCIN]: "Pitocin IM",
  [EVENTS.MISOPROSTOL]: "Misoprostol",
  [EVENTS.METHERGINE]: "Methergine",
  [EVENTS.PLACE_IV]: "Place IV",
  [EVENTS.LACTATED_RINGER]: "Lactated Ringer",
  [EVENTS.CATH_BLAD]: "Catheterize Bladder",
  [EVENTS.MAN_PLACENTA_REMOV]: "Manual Removal of Placenta",
  [EVENTS.PLACENTA_OUT]: "Placenta Out",
  [EVENTS.TRENDELENBURG]: "Trendelenburg",
  [EVENTS.OXYGEN]: "Oxygen",
  [EVENTS.ANTI_SHOCK_GARM]: "Anti-Shock Garment",
  [EVENTS.BIMANUAL_COMPRESS]: "Bi-manual Compress",
  [EVENTS.EMS_ACTIVATED]: "EMS Activated",
  [EVENTS.DRY_STIM]: "Dry and Stimulate",
  [EVENTS.INFLATION_BREATHS]: "Inflation Breaths",
  [EVENTS.PPV_AIR]: "PPV Room Air",
  [EVENTS.PPV_OXYGEN]: "PPV 100% Oxygen",
  [EVENTS.PULSE_OXIMETER]: "Pulse Oximeter placed on right wrist",
  [EVENTS.CHEST_COMPR_PPV]: "Chest Compressions Coordinate with PPV",
  [EVENTS.CHEST_COMP_DISC]: "Chest Compressions Discontinued",
  [EVENTS.FIRST_BREATH]: "First Breath",
  [EVENTS.PPV_DISC]: "PPV Discontinued",
  [EVENTS.LARYNGEAL_MASK]: "Laryngeal Mask Airway",
  [EVENTS.INTUBATION]: "Intubation",
  [EVENTS.UMBILICAL_LINE]: "Umbilical Line",
  [EVENTS.EPINEPHRINE]: "Epinephrine",
  [EVENTS.DECISION]: "Decision to transfer",
  [EVENTS.RECEIVER_NOTIFIED]: "Receiving entity notified",
  [EVENTS.AMBULANCE_CALLED]: "Call to ambulance",
  [EVENTS.AMBULANCE_ARRIVED]: "Ambulance arrival",
  [EVENTS.AMBULANCE_LEFT]: "Ambulance left",
  [EVENTS.ARRIVED_AT_HOSPITAL]: "Arrival at hospital",
  [EVENTS.OUTCOME_AFTER]: "Outcome after transfer",
  [EVENTS.COMPLICATIONS]: "Complications and Interventions",
  [EVENTS.LENGTH_OF_STAY]: "Length of stay",
  [EVENTS.STOP_TIMERS]: "Stop timers",
  [EVENTS.SUMMARY]: "Conclude labor",
  [EVENTS.SUCTION]: "Suction",
  [EVENTS.PIT_IV]: "Pitocin IV",
  [EVENTS.TXA]: "TXA",
  [EVENTS.NEWBORN_DISCHARGE]: "Newborn discharge",
  [EVENTS.PARENT_DISCHARGE]: "Parent discharge",
  [EVENTS.MATERNAL_VITALS]: "Maternal vitals",
  [EVENTS.NEWBORN_VITALS]: "Newborn vitals",
  [EVENTS.CPAP]: "CPAP",
  [EVENTS.MILK_UMBILICAL_CORD]: "Milk ubmilical cord",
  [EVENTS.POSTURAL_DRAINAGE]: "Postural drainage",
  [EVENTS.PERCUSSION]: "Percussion",
  [EVENTS.DELEE_SUCTION]: "Delee suction",
  [EVENTS.BULB_SUCTION]: "Bulb suction",
  [EVENTS.TRIAGE]: "Triage",
  [EVENTS.TRIAGE_DISCHARGE]: "Discharge"
};

const EVENT_MEDICATIONS = [
  EVENTS.PITOCIN,
  EVENTS.MISOPROSTOL,
  EVENTS.METHERGINE,
  EVENTS.LACTATED_RINGER,
  EVENTS.OXYGEN,
  EVENTS.EPINEPHRINE,
  EVENTS.TXA
];

enum LABOR_EVENT_TYPES {
  EVENT = "EVENT",
  PROGRESS_UPDATE = "PROGRESS_UPDATE",
  MEDICATION = "MEDICATION",
  NOTES = "NOTES",
  RECURRING_EVENT = "RECURRING_EVENT",
  INFANT_PROGRESS_UPDATE = "INFANT_PROGRESS_UPDATE",
  INFANT_MEDICATION = "INFANT_MEDICATION"
}

const SORT_ORDER = {
  [EVENTS.PROGRESS_UPDATE]: [
    "FHT",
    "baseline_shift",
    "new_baseline",
    "BP",
    "temp",
    "heart_rate",
    "freq",
    "dur",
    "strength",
    "dil",
    "eff",
    "station",
    "voids",
    "listening_device",
    "relative_to_contractions",
    "accelerations",
    "decelerations"
  ]
};

const NEWBORN_EXAM_HEADERS = [
  "first_name",
  "baby_sex",
  "birth_weight",
  "baby_heart_rate"
];

const PROGRESS_UPDATE_ITEMS = {
  FHT: "FHT",
  BP: "BP",
  TEMP: "temp",
  HEART_RATE: "heart_rate",
  FREQ: "freq",
  DUR: "dur",
  STRENGTH: "strength",
  DIL: "dil",
  EFF: "eff",
  STATION: "station",
  VOIDS: "void",
  LISTENING_DEVICE: "listening_device",
  RELATIVE_TO_CONTRACTIONS: "relative_to_contractions",
  ACCELERATIONS: "accelerations",
  DECELERATIONS: "decelerations",
  START_TIME: "start_time",
  LOT_NUM: "lot_num",
  MED_ROUTE: "med_route"
};

const PROGRESS_UPDATE_DEFAULTS = {
  FHT: "",
  baseline_shift: "",
  relative_to_contractions: "",
  accelerations: "",
  decelerations: "",
  listening_device: "doppler",
  start_time: "",
  BP: "",
  hr: "",
  temp: "",
  inputs: "",
  outputs: "",
  maternal_position: 0,
  freq: "",
  dur: "",
  strength: "",
  dil: "",
  eff: "",
  station: "",
  HR: "",
  RR: "",
  color: "",
  respirations: "",
  heart_rate: "",
  fundus: "",
  est_bleeding: "",
  comments: ""
};

const NEWBORN_PROGRESS_UPDATE_DEFAULTS = {
  respirations: "",
  temp: "",
  heart_rate: ""
};

const MEDICATION_FORM_DEFAULTS = {
  name: "",
  dose: "",
  med_route: "",
  expiration: "",
  lot_num: "",
  units: "",
  site: "",
  comments: ""
};

const PROGRESS_UPDATE_LABELS = {
  [PROGRESS_UPDATE_ITEMS.FHT]: "FHT",
  [PROGRESS_UPDATE_ITEMS.BP]: "BP",
  [PROGRESS_UPDATE_ITEMS.TEMP]: "Temp",
  [PROGRESS_UPDATE_ITEMS.HEART_RATE]: "Heart Rate",
  [PROGRESS_UPDATE_ITEMS.FREQ]: "Contractions Frequency",
  [PROGRESS_UPDATE_ITEMS.DUR]: "Contractions Duration",
  [PROGRESS_UPDATE_ITEMS.STRENGTH]: "Contractions Strength",
  [PROGRESS_UPDATE_ITEMS.DIL]: "Dil",
  [PROGRESS_UPDATE_ITEMS.EFF]: "Eff",
  [PROGRESS_UPDATE_ITEMS.STATION]: "Station",
  [PROGRESS_UPDATE_ITEMS.VOIDS]: "Voids",
  [PROGRESS_UPDATE_ITEMS.LISTENING_DEVICE]: "Listening Device",
  [PROGRESS_UPDATE_ITEMS.RELATIVE_TO_CONTRACTIONS]: "Relative to Contractions",
  [PROGRESS_UPDATE_ITEMS.ACCELERATIONS]: "Accelerations",
  [PROGRESS_UPDATE_ITEMS.DECELERATIONS]: "Decelerations",
  [PROGRESS_UPDATE_ITEMS.START_TIME]: "Time",
  [PROGRESS_UPDATE_ITEMS.LOT_NUM]: "Lot #",
  [PROGRESS_UPDATE_ITEMS.MED_ROUTE]: "Route"
};

const STAGE_TABS = {
  [STAGES.ADMISSION]: [{ id: "admission", label: "Admission Note" }],
  [STAGES.TRIAGE]: [
    {
      id: "triage",
      label: "Triage"
    },
    {
      id: "triage_discharge",
      label: "Discharge"
    }
  ],
  [STAGES.LABOR]: [
    { id: "progress", label: "Progress" },
    { id: "milestones", label: "Milestones" },
    { id: "hydrotherapy", label: "Hydrotherapy" },
    { id: "medication", label: "Medication" },
    { id: "soap", label: "SOAP Note" }
  ],
  [STAGES.BIRTH]: [
    { id: "progress", label: "Progress" },
    { id: "milestones", label: "Milestones" },
    { id: "medication", label: "Medication" },
    { id: "soap", label: "SOAP Note" }
  ],
  [STAGES.POSTPARTUM]: [
    { id: "progress", label: "Maternal Progress" },
    { id: "newborn_progress", label: "Newborn Progress" },
    { id: "milestones", label: "Milestones" },
    { id: "medication", label: "Maternal Medication" },
    { id: "newborn_medication", label: "Newborn Medication" },
    { id: "newborn_exam", label: "Newborn Physical Exam" },
    { id: "soap", label: "SOAP Note" }
  ],
  [STAGES.CONCLUSION]: [
    { id: "conclude", label: "Conclude labor" },
    { id: "parent_discharge", label: "Parent discharge" },
    { id: "newborn_discharge", label: "Newborn discharge" }
  ],
  [STAGES.TRANSFER]: [
    { id: "transfer", label: "Transfer Form" },
    { id: "progress", label: "Progress" },
    { id: "milestones", label: "Milestone" },
    { id: "medication", label: "Medication" },
    { id: "soap", label: "SOAP Note" }
  ],
  [STAGES.HEMORRHAGE]: [{ id: "milestones", label: "Events" }],
  [STAGES.RESUSCITATION]: [{ id: "milestones", label: "Events" }]
};

const STAGE_TABS_V2 = {
  [STAGES.ADMISSION]: [{ id: "admission", label: "Admission Note" }],
  [STAGES.TRIAGE]: [
    {
      id: "triage",
      label: "Triage"
    },
    {
      id: "triage_discharge",
      label: "Discharge"
    }
  ],
  [STAGES.LABOR]: [
    { id: "progress", label: "Progress" },
    { id: "milestones", label: "Milestones" },
    { id: "hydrotherapy", label: "Hydrotherapy" },
    { id: "medication", label: "Medication" },
    { id: "soap", label: "SOAP Note" }
  ],
  [STAGES.POSTPARTUM]: [
    { id: "progress", label: "Maternal Progress" },
    { id: "newborn_progress", label: "Newborn Progress" },
    { id: "milestones", label: "Milestones" },
    { id: "medication", label: "Maternal Medication" },
    { id: "newborn_medication", label: "Newborn Medication" },
    { id: "newborn_exam", label: "Newborn Physical Exam" },
    { id: "soap", label: "SOAP Note" }
  ],
  [STAGES.CONCLUSION]: [
    { id: "conclude", label: "Conclude labor" },
    { id: "parent_discharge", label: "Parent discharge" },
    { id: "newborn_discharge", label: "Newborn discharge" }
  ],
  [STAGES.TRANSFER]: [
    { id: "transfer", label: "Transfer Form" },
    { id: "progress", label: "Progress" },
    { id: "milestones", label: "Milestone" },
    { id: "medication", label: "Medication" },
    { id: "soap", label: "SOAP Note" }
  ],
  [STAGES.HEMORRHAGE]: [{ id: "milestones", label: "Events" }],
  [STAGES.RESUSCITATION]: [{ id: "milestones", label: "Events" }]
};

/**
 * These are the constants for the events that start and stop
 * the different stages of labor. Different than our stages tab
 * these are used for the timers and the length of the different
 * stages. These can be used to key into the labor object like:
 * labor.stages[FIRST_STAGE_EVENTS.START_STAGE].events[FIRST_STAGE_EVENTS.START_EVENT]
 * TODO(Landon): refactor even more so this is more ubiquitous
 */
const FIRST_STAGE_EVENTS = {
  START_STAGE: STAGES.LABOR,
  START_EVENT: EVENTS.ACTIVE_LABOR,
  END_STAGE: STAGES.LABOR,
  END_EVENT: EVENTS.SECOND_STAGE
};

const SECOND_STAGE_EVENTS = {
  START_STAGE: STAGES.LABOR,
  START_EVENT: EVENTS.SECOND_STAGE,
  END_STAGE: STAGES.LABOR,
  END_EVENT: EVENTS.BIRTH
};

const THIRD_STAGE_EVENTS = {
  START_STAGE: STAGES.LABOR,
  START_EVENT: EVENTS.BIRTH,
  END_STAGE: STAGES.LABOR,
  END_EVENT: EVENTS.PLACENTA
};

const SINCE_BIRTH_EVENTS = {
  START_STAGE: STAGES.LABOR,
  START_EVENT: EVENTS.BIRTH,
  END_STAGE: STAGES.POSTPARTUM,
  END_EVENT: EVENTS.DISCHARGE
};

const SINCE_RUPTURE_EVENTS = {
  START_STAGE: STAGES.LABOR,
  START_EVENT: EVENTS.ROM,
  END_STAGE: STAGES.LABOR,
  END_EVENT: EVENTS.BIRTH
};

export {
  STAGES,
  EVENTS,
  EVENT_LABELS,
  EVENT_DETAIL_EVENTS,
  STAGE_LABELS,
  STAGE_TABS,
  EVENT_MEDICATIONS,
  SORT_ORDER,
  PROGRESS_UPDATE_LABELS,
  PROGRESS_UPDATE_DEFAULTS,
  NEWBORN_PROGRESS_UPDATE_DEFAULTS,
  MEDICATION_FORM_DEFAULTS,
  LABOR_EVENT_TYPES,
  FIRST_STAGE_EVENTS,
  SECOND_STAGE_EVENTS,
  THIRD_STAGE_EVENTS,
  SINCE_BIRTH_EVENTS,
  SINCE_RUPTURE_EVENTS,
  NEWBORN_EXAM_HEADERS,
  STAGE_TABS_V2,
  EVENT_DETAIL_EVENTS_V2
};
