// External
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useForm } from "react-hook-form";

// Store
import { STATUS_KEYS, STATUS_TYPES, STYLES } from "@/globals/constants";
import { setModalContent } from "../modalSlice";
import { MODAL_TYPES } from "../dispatcher";
import {
  useCreateEddMutation,
  useUpdateEddMutation
} from "@/store/services/pregnancy";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { EstimatedDueDate } from "@/store/services/patient";

// Components
import Input from "@/components/input";
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import Alert from "@/components/alert";

// Styles
import styles from "../styles.module.scss";


interface NewUnknownEstimateProps {
  pregnancyId: number;
  defaultEdd: EstimatedDueDate;
}

export default function NewUnknownEstimate({
  pregnancyId,
  defaultEdd
}: NewUnknownEstimateProps) {
  const dispatch = useDispatch();

  const form = useForm();
  const { handleSubmit, register, setValue } = form;

  const [createEdd, { isSuccess, isError }] = useCreateEddMutation();
  const [updateEdd, { isSuccess: isUpdateSuccess, isError: isUpdateError }] =
    useUpdateEddMutation();

  const [isEstimateWarning, setIsEstimateWarning] = useState<boolean>(false);

  useEffect(() => {
    if (defaultEdd) {
      setValue("edd", defaultEdd.estimated_due_date);
      setValue("date_of_method", defaultEdd.date_of_method);
      setValue("comments", defaultEdd.comment);
    }
  }, [defaultEdd]);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.DATING,
          props: {
            title: "Estimated Due Dates",
            pregnancyId: pregnancyId
          }
        })
      );
      let successMsg = isSuccess
        ? "Successfully created EDD"
        : "Successfully updated EDD";
      dispatch(
        addAlertToToastTrough({
          message: successMsg,
          type: STATUS_KEYS.SUCCESS
        })
      );
    }
  }, [isSuccess, isUpdateSuccess]);

  useEffect(() => {
    if (isError || isUpdateError) {
      let errorMsg = isError
        ? "Something went wrong creating EDD"
        : "Something went wrong updating EDD";

      dispatch(
        addAlertToToastTrough({
          message: errorMsg,
          type: STATUS_KEYS.ERROR
        })
      );
    }
  }, [isError, isUpdateError]);

  const handleBackClick = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.DATING,
        props: {
          title: "Estimated Due Dates",
          pregnancyId: pregnancyId
        }
      })
    );
  };

  const onSubmit = (data: any) => {
    if (defaultEdd) {
      // There's a defaultEdd (meaning we're editing one) so update
      updateEdd({
        pregnancyId: pregnancyId,
        eddId: defaultEdd.estimate_id as string,
        estimatedDueDateUpdate: {
          estimated_due_date: dayjs(data.edd).format("YYYY-MM-DD"),
          method: "UNKNOWN",
          date_of_method: data.service_date,
          comment: data.comments
        }
      });
    } else {
      createEdd({
        pregnancyId: pregnancyId,
        estimatedDueDate: {
          estimated_due_date: dayjs(data.edd).format("YYYY-MM-DD"),
          method: "UNKNOWN",
          date_of_method: data.service_date,
          comment: data.comments
        }
      });
    }
  };

  return (
    <div className={styles.LmpEstimate} data-cy="uk-estimate-modal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.inputContainer}>
          <div className={styles.lmp}>
            <Input
              type="date"
              label="EDD"
              name="edd"
              id="edd"
              register={register}
              rules={{
                required: true,
                onBlur: () => {
                  setIsEstimateWarning(
                    dayjs(form.getValues("lmp")).isSameOrBefore(
                      dayjs().subtract(1, "year").format("YYYY-MM-DD")
                    )
                  );
                }
              }}
            />
          </div>
        </div>
        <div className={styles.commentsContainer}>
          <Input
            type="date"
            label="Date of service"
            name="service_date"
            id="service_date"
            value={dayjs().format("YYYY-MM-DD")}
            register={register}
          />
          <ControlledTextArea
            label="Comments"
            id="comments"
            name="comments"
            form={form}
          />
        </div>
        <Alert
          message="Estimate is more than a year in the past, is this correct?"
          type={STATUS_TYPES[STATUS_KEYS.WARNING]}
          isHidden={!isEstimateWarning}
        />
        <div className={styles.buttonContainer}>
          <Button
            style={STYLES.SECONDARY}
            onClick={handleBackClick}
            type="button"
          >
            Back
          </Button>
          <Button type="submit">Submit</Button>
        </div>
      </form>
    </div>
  );
}
