import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnnotationType } from "@/globals/constants/types";
import { ANNOTATION_TYPES } from "@/globals/constants";

// TEMP: this whole slice is probably temporary
// this state will be tracked in the labs slice
// but waiting for Josh labs PR to integrate that
// annotations data will come from backend
// const annotations = [
//   {
//     id: 1,
//     type: ANNOTATION_TYPES.ANNOTATE,
//     userName: "Dr. Eden Fromberg",
//     userId: "1",
//     createTime: "5/30/23",
//     content:
//       "Refer to this during our chat with Jacob today and make sure she gets some antibiotics.",
//   },
//   {
//     id: 2,
//     type: ANNOTATION_TYPES.TASK,
//     userName: "Dr. Yasmin Rosshandler",
//     userId: "1",
//     createTime: "5/30/23",
//     content:
//       "@MaggieDiehl Contact Kate immediately for follow up appt. Move things around if you have to.",
//   },
// ];
type initialState = {
  annotations: AnnotationType[];
};

const initialState: initialState = {
  // @ts-ignore
  annotations: [],
};

export const annotationSlice = createSlice({
  name: "annotation",
  initialState,
  reducers: {
    addAnnotation: (state, { payload }: PayloadAction<AnnotationType>) => {
      state.annotations.push(payload);
    },
  },
});

export const { addAnnotation } = annotationSlice.actions;

export default annotationSlice.reducer;
