import * as generated from "./generated/payor";

const payor = generated.payor.enhanceEndpoints({
    addTagTypes: ["Payor"],
    endpoints: {
        payorList: {
            providesTags: ["Payor"],
        },
        payorGetInfo: {
            providesTags: ["Payor"],
        },
        payorCreate: {
            invalidatesTags: ["Payor"],
        },
    }
});

export * from "./generated/payor";
export default payor;
export const {
  usePayorListQuery,
  useLazyPayorListQuery,
  usePayorGetInfoQuery,
  useLazyPayorGetInfoQuery
} = payor;
