/* Toggle Name */
/* External Imports */
import { useEffect, useState } from "react";
import clsx from "clsx";

/* Local Imports */

// Toggles

// constants

// store

// styles
import styles from "./styles.module.scss";

/* Toggle Typescript Interface */
interface ToggleProps {
  label?: string;
  initialChecked?: boolean;
  onChange?: (isChecked: boolean) => void;
  labelIsRight?: boolean;
  isDisabled?: boolean;
}

export default function Toggle({
  label = "",
  initialChecked = false,
  onChange = () => {},
  labelIsRight,
  isDisabled
}: ToggleProps) {
  /* Redux */

  /* Local State */
  const [isChecked, setIsChecked] = useState(initialChecked);

  /* Effects */
  useEffect(() => {
    setIsChecked(initialChecked);
   }, [initialChecked]);

  /* Event Handlers */
  const handleToggle = () => {
    if (isDisabled) return;
    setIsChecked(!isChecked);
    onChange(!isChecked); // bubble up to parent
  };

  return (
    <div
      className={clsx(styles.Toggle, {
        [styles.checked]: isChecked,
        [styles.disabled]: isDisabled
      })}
      onClick={handleToggle}
    >
      <label
        htmlFor="toggle_switch"
        className={clsx({ [styles.labelRight]: labelIsRight })}
      >
        {label}
      </label>
      <input
        type="checkbox"
        id="toggle_switch"
        name="toggle_switch"
        className={styles.toggleCheckbox}
        checked={isChecked}
        disabled={isDisabled}
      />
      <span aria-hidden className={styles.switch} />
    </div>
  );
}
