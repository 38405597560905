// store
import {
  Pregnancy,
} from "@/store/services/patient";

// constants
import { METRICS } from "@/globals/constants";

// components
import ReadOnlySection from "../birthSummary/readOnlySection";
import ObjectTableItem from "../birthSummary/objectTableItem";

export interface PrenatalFlowSheetProps {
  pregnancy: Pregnancy;
  print?: boolean;
}

export default function ReadOnlyPrenatalFlowSheet({
  pregnancy,
  print = false,
}: PrenatalFlowSheetProps) {
  const PRENATAL_KEYS = [
    METRICS.WEEKS,
    METRICS.DATE,
    METRICS.WEIGHT,
    METRICS.BP,
    METRICS.PULSE,
    METRICS.FUNDAL_HT,
    METRICS.FHT,
    METRICS.FETAL_POS,
    METRICS.EDEMA,
    METRICS.FETAL_ACT,
  ];

  return (
    <div>
      {pregnancy && pregnancy.flow_sheet && (
        <ReadOnlySection header="Prenatal Flow Sheet" print={print}>
          <ObjectTableItem
            orderedHeaders={PRENATAL_KEYS}
            objectGrid={pregnancy.flow_sheet}
          />
        </ReadOnlySection>
      )}
    </div>
  );
}
