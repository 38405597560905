import styles from "./styles.module.scss";
import clsx from "clsx";

interface NotificationIconProps {
  notificationNumber?: number | null;
  small?: boolean;
}

export default function NotificationIcon({
  notificationNumber,
  small = false,
}: NotificationIconProps) {
  const sanitizedNumber =
    notificationNumber && notificationNumber > 99 ? "99+" : notificationNumber;
  return (
    <div className={clsx(styles.NotificationLink, { [styles.small]: small })}>
      {notificationNumber && <span>{sanitizedNumber}</span>}
    </div>
  );
}
