/* Create Patient Modal Template */

// External
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z, ZodType } from "zod";

// styles
import styles from "./styles.module.scss";

// components
import Button from "@/components/button";
import GeneratedInput from "@/components/input/generated";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Icon from "../icons";
// constants
import { STYLES, STATUS_KEYS, METRIC_LABELS } from "@/globals/constants";
import {
  FIELD_TYPES,
  FORM_SCHEMA,
  buildZodObject
} from "@/globals/constants/formSchema";

// store
import { RootState } from "@/store/store";
import {
  usePatientUpsertMutation,
  PatientUpsertApiArg,
  PatientInfo
} from "@/store/services/patient";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
import KeyValueGrid from "../birthSummary/keyValueGrid";
import KeyValueItem from "../birthSummary/keyValueItem";
import { convertUtcIntToLocalDate } from "../scheduling/calendars/utils";
import { FormFieldOptions } from "@/store/services/form";
import { normalizePhoneNumber } from "@/globals/helpers";
import { UserEmergencyContact } from "@/store/services/user";

export interface PatientFormProps {
  patient?: Partial<PatientInfo>;
  isReadOnly?: boolean;
  onUpdate?: () => void;
  contactIndex?: number;
}

export default function PatientEmergencyContactForm({
  patient,
  isReadOnly,
  onUpdate,
  contactIndex = 0
}: PatientFormProps) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  // create or update a patient
  const [upsertPatient] = usePatientUpsertMutation();

  // validation schema definition
  const schema: ZodType = z.object(
    buildZodObject(FORM_SCHEMA.EmergencyContactFields)
  );
  type FormSchemaType = z.infer<typeof schema>;
  const {
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FormSchemaType>({
    resolver: zodResolver(schema),
    defaultValues: patient && getInitialValues(patient, contactIndex)
  });

  /* Local State */

  /* Event handlers */

  // on "Update" button click
  const onSubmit = (data: any) => {
    const phone = normalizePhoneNumber(data.phone)?.normalizedPhoneNumber;
    // UPSERT PATIENT

    const req: PatientUpsertApiArg = {
      patientUpsertRequest: {
        ...patient,
        emergency_contacts: [
          ...(patient?.emergency_contacts || []),
          { ...data, phone }
        ]
      }
    };

    upsertPatient(req)
      .unwrap()
      .then(() => {
        // on successful upsert, display success toast

        let message = "Patient emergency contact successfully updated";

        dispatch(
          addAlertToToastTrough({
            message,
            type: STATUS_KEYS.SUCCESS
          })
        );

        // handle update in parent
        if (onUpdate) {
          onUpdate();
        }
      })
      .catch(() => {
        let message = "Oops! Patient emergency contact could not be updated";

        dispatch(
          addAlertToToastTrough({
            message,
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleRemoveEmergencyContact = () => {
    const req: PatientUpsertApiArg = {
      patientUpsertRequest: {
        ...patient,
        emergency_contacts: patient?.emergency_contacts?.filter(
          ({ email }) =>
            email !== patient?.emergency_contacts?.[contactIndex]?.email
        )
      }
    };

    upsertPatient(req)
      .unwrap()
      .then(() => {
        // on successful upsert, display success toast

        let message = "Patient emergency contact successfully deleted";

        dispatch(
          addAlertToToastTrough({
            message,
            type: STATUS_KEYS.SUCCESS
          })
        );

        // handle update in parent
        if (onUpdate) {
          onUpdate();
        }
      })
      .catch(() => {
        let message = "Oops! Patient emergency contact could not be deleted";

        dispatch(
          addAlertToToastTrough({
            message,
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  if (!isReadOnly) {
    return (
      <form
        className={clsx(styles.PatientGeneralInfo)}
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Patient information section */}
        {/* set open to default if patient is for new patient */}
        {FORM_SCHEMA.EmergencyContactFields.map(
          ({ fieldType, fieldID: field, options }) =>
            fieldType !== FIELD_TYPES.SINGLE_DROPDOWN ? (
              <GeneratedInput
                key={field}
                type={
                  [FIELD_TYPES.DATE, FIELD_TYPES.DATE_REQ].includes(fieldType)
                    ? "date"
                    : "text"
                }
                label={field.replaceAll("_", " ").toLocaleLowerCase()}
                name={field}
                id={field}
                register={register}
                errors={errors}
              />
            ) : (
              <ControlledCombobox
                key={field}
                label={field.replaceAll("_", " ").toLocaleLowerCase()}
                errors={errors}
                name={field}
                control={control}
                options={options as FormFieldOptions}
              />
            )
        )}
        <div className={styles.buttonGrid}>
          <Button
            style={STYLES.TERTIARY}
            onClick={handleRemoveEmergencyContact}
          >
            <Icon svg="trash_grey" />
          </Button>
          <Button style={STYLES.FULL_WIDTH} type="submit">
            Update
          </Button>
        </div>
      </form>
    );
  } else {
    return (
      <KeyValueGrid>
        {FORM_SCHEMA.EmergencyContactFields.map(
          ({ fieldType, fieldID: field }) => {
            if (
              fieldType == FIELD_TYPES.DATE ||
              fieldType == FIELD_TYPES.DATE_REQ
            ) {
              if (!patient) {
                return <p key={field}></p>;
              }
              const val = getInitialValues(patient, contactIndex)[
                field as keyof {}
              ];
              const formattedVal =
                convertUtcIntToLocalDate(val).format("MM/DD/YYYY");

              return (
                <KeyValueItem
                  keyLabel={field.replaceAll("_", " ").toLocaleLowerCase()}
                  key={field}
                >
                  {patient ? <p>{formattedVal}</p> : <p></p>}
                </KeyValueItem>
              );
            } else {
              return (
                <KeyValueItem
                  keyLabel={field.replaceAll("_", " ").toLocaleLowerCase()}
                  key={field}
                >
                  {patient ? (
                    <p>
                      {field === FIELD_TYPES.SSN
                        ? "*****" +
                          getInitialValues(patient, contactIndex)[
                            field as keyof {}
                          ]?.substring(5)
                        : METRIC_LABELS[
                            getInitialValues(patient, contactIndex)[
                              field as keyof {}
                            ]
                          ] ||
                          getInitialValues(patient, contactIndex)[
                            field as keyof {}
                          ]}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </KeyValueItem>
              );
            }
          }
        )}
      </KeyValueGrid>
    );
  }
}

// TODO: in future, support multiple emergency contacts
function getInitialValues(patient: Partial<PatientInfo>, contactIndex: number) {
  const defaults: Record<string, any> = {
    name: patient?.emergency_contacts?.[contactIndex]?.name || "",
    email: patient?.emergency_contacts?.[contactIndex]?.email || "",
    phone: patient?.emergency_contacts?.[contactIndex]?.phone || "",
    phone_type: patient?.emergency_contacts?.[contactIndex]?.phone_type || "",
    relationship:
      patient?.emergency_contacts?.[contactIndex]?.relationship || ""
  };

  return defaults;
}
