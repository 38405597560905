import { ChangeEvent, useEffect, useState } from "react";
import clsx from "clsx";

import styles from "./styles.module.scss";

interface WeightInputProps {
  value: Weight;
  onChange: (value: Weight) => void;
  hiddenLabel?: boolean;
  showGramsConversion?: boolean;
  horizontalLayout?: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function WeightInput({
  value,
  onChange,
  hiddenLabel = false,
  showGramsConversion = false,
  horizontalLayout = false,
  onBlur
}: WeightInputProps) {
  const [pounds, setPounds] = useState(0);
  const [ounces, setOunces] = useState(0);

  useEffect(() => {
    if (!value) {
      return;
    }
    setPounds(value.pounds);
    setOunces(value.ounces);
  }, [value]);

  return (
    <div className={styles.WeightInput} data-cy="weight_input">
      <div className={clsx({ [styles.horizontalLayout]: horizontalLayout })}>
        <label htmlFor="pounds" style={hiddenLabel ? { display: "none" } : {}}>
          Weight
        </label>
        <div className={styles.weightInputContainer}>
          <div className={styles.inputWithUnit}>
            <input
              type="numeric"
              id="pounds"
              name="pounds"
              placeholder="lbs"
              required
              onChange={e => {
                setPounds(parseInt(e.target.value) || 0);
                onChange(
                  getWeightObjectFromPoundsOunces(
                    parseInt(e.target.value) || 0,
                    ounces
                  )
                );
              }}
              onBlur={onBlur}
              value={pounds}
              onFocus={e => e.target.select()}
            />
            <span>lbs</span>
          </div>
          <div className={styles.inputWithUnit}>
            <input
              type="numeric"
              id="ounces"
              name="ounces"
              placeholder="oz"
              min="0"
              max="15"
              required
              onChange={e => {
                setOunces(parseInt(e.target.value) || 0);
                onChange(
                  getWeightObjectFromPoundsOunces(
                    pounds,
                    parseInt(e.target.value) || 0
                  )
                );
              }}
              onBlur={onBlur}
              value={ounces}
              onFocus={e => e.target.select()}
            />
            <span>oz</span>
          </div>
        </div>
      </div>
      <p style={!showGramsConversion ? { display: "none" } : {}}>
        Grams: {value && (Math.round(value.grams) || 0)}
      </p>
    </div>
  );
}

/**
 * Helpers
 */
export type Weight = {
  pounds: number;
  ounces: number;
  grams: number;
};

export function getWeightObjectFromGrams(grams: number): Weight {
  let ounces = Math.round(grams / 28.35);
  const pounds = Math.floor(ounces / 16);
  ounces -= pounds * 16;
  return {
    pounds: pounds,
    ounces: ounces,
    grams: grams
  };
}
export function getWeightObjectFromPoundsOunces(
  pounds: number,
  ounces: number
): Weight {
  const totalOunces = pounds * 16 + ounces;
  return {
    pounds: pounds,
    ounces: ounces,
    grams: Math.round(totalOunces * 28.35)
  };
}
