/* ProblemList */
/* External Imports */
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import Fuse from "fuse.js";

/* Local Imports */

// components
import Button from "@/components/button";
import ComboboxSelect from "@/components/input/combobox";
import CreateImmunization from "./createImmunizations";

// constants
import {
  STYLES,
  STATUS_KEYS,
  METRIC_LABELS,
  METRICS,
} from "@/globals/constants";
// store

import { useUserUpdateMutation } from "@/store/services/user";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";
// styles
import styles from "./styles.module.scss";
import Immunization from "./immunizations";

/* ProblemList Typescript Interface */
interface ProblemListProps {
  isExpanded?: boolean;
  // TODO: type with generated types when reference supports it
  immunizations?: { diagnoses: any[]; notes: any[] };
  patientId: number;
}

export default function ImmunizationList({
  isExpanded = false,
  immunizations = { diagnoses: [], notes: [] },
  patientId,
}: ProblemListProps) {
  /* Redux */

  const dispatch = useDispatch();
  const [updateUser] = useUserUpdateMutation();
  /* Local State */

  const [isAddProblemMode, setIsAddProblemMode] = useState<boolean>(false);

  // immunizations to display when user filters
  const [filteredImmunization, setFilteredAllergies] = useState(immunizations);
  /* Form Validation & Management using react-hook-form */

  // react-hook-form hook configuration
  const {
    register,
    control,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    reset,
  } = useForm();

  /* Effects */

  // useEffect(() => {
  //   setFilteredAllergies(immunizations);
  // }, [immunizations]);

  /* Event Handlers */

  // Update immunization note
  const handleUpdateImmunizationNote = async (id: number, type: any) => {
    const formInfo = getValues();
    const note = formInfo[`immunization-${id}-note`];
    if (note) {
      try {
        // TODO: upsert immunization when backend can support it
        // await upsertProblem({
        //   patientId,
        //   problemUpsertRequest: { id, type, note },
        // }).unwrap();
        // user alert
        launchToast(
          "Immunization note updated successfully.",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
        // clear edit mode
        // setEditProblemInstance(null);
      } catch (error) {
        launchToast(
          "An error occurred while updating immunization note.",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        );
      }
    }
  };

  // handle add immunization button
  const onSubmit = (data: any) => {
    // const handleProblemUpsert = async () => {
    //   try {
    //     let immunization: Partial<ProblemUpsertRequest> = {
    //       note: data.problem_new_note,
    //       type: "NOTE" as ProblemType,
    //       title: data.new_problem_title,
    //     };
    //     if (data.select_icd10) {
    //       immunization.icd_id = data.select_icd10.icd_id;
    //       immunization.type = METRICS.DIAGNOSIS as ProblemType;
    //       immunization.title = data.select_icd10.description;
    //     }
    //     await upsertProblem({
    //       patientId,
    //       // @ts-ignore
    //       problemUpsertRequest: immunization,
    //     });
    //     setIsAddProblemMode(false);
    //     // user alert
    //     launchToast(
    //       `Immunization added successfully.`,
    //       STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
    //     );
    //   } catch (error) {
    //     launchToast(
    //       `An error occurred while adding immunization, please try again later.`,
    //       STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
    //     );
    //   }
    // };
    // handleProblemUpsert();
    reset();
  };

  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type,
      })
    );
  };

  const handleFilterAllergies = (term: string) => {
    // filter immunizations using fuzzy search if user inputs more than 3 characters g
    if (immunizations && term?.length > 2) {
      // search through note type immunizations
      const filteredNotesResults =
        immunizations.notes && immunizations.notes.length > 0
          ? notesFuse.search(term)
          : [];
      const currentFilteredNotes = filteredNotesResults.map(({ item }) => item);
      // search through diagnoses
      const filteredDiagnosesResults =
        immunizations.diagnoses && immunizations.diagnoses.length > 0
          ? diagnosesFuse.search(term)
          : [];
      const currentFilteredDiagnoses = filteredDiagnosesResults.map(
        ({ item }) => item
      );
      const currentFilteredProblems = {
        notes: currentFilteredNotes,
        diagnoses: currentFilteredDiagnoses,
      };
      setFilteredAllergies(currentFilteredProblems);
    } else {
      // reset to all immunizations when user clears input
      setFilteredAllergies(immunizations);
    }
  };
  /* Fuse Search Configuration */
  const notesFuse = new Fuse(
    // @ts-ignore
    immunizations && immunizations?.notes?.length > 0 && immunizations.notes,
    {
      keys: ["note.content", "title"],
    }
  );

  const diagnosesFuse = new Fuse(immunizations && immunizations?.diagnoses, {
    keys: [
      "title",
      "name",
      "instances.diagnosis.icd_id",
      "instances.diagnosis.description",
    ],
  });

  return (
    <div
      className={clsx(styles.ImmunizationList, {
        [styles.isExpanded]: isExpanded,
      })}
    >
      {isAddProblemMode ? (
        <CreateImmunization
          onCancel={() => setIsAddProblemMode(false)}
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={errors}
          getValues={getValues}
          control={control}
          setValue={setValue}
        />
      ) : (
        <div className={styles.content}>
          {isExpanded && (
            <div className={styles.filterWrapper}>
              <Controller
                name="search_immunizations"
                control={control}
                render={({ field: { onChange } }) => (
                  <ComboboxSelect
                    label="Search immunizations"
                    placeholder="Search immunizations"
                    hiddenLabel
                    options={[
                      ...filteredImmunization.notes,
                      ...filteredImmunization.diagnoses,
                    ]}
                    labelAcc={(problem) => `${problem?.note?.content}`}
                    onChange={(v) => onChange(v)}
                    onSearch={handleFilterAllergies}
                    noMenu
                    fullWidth
                  />
                )}
                rules={{ required: false }}
              />
              <Button
                style={STYLES.PRIMARY}
                onClick={() => setIsAddProblemMode(true)}
              >
                New
              </Button>
            </div>
          )}
          {[
            ...filteredImmunization.diagnoses,
            ...filteredImmunization.notes,
          ]?.map((immunization) => (
            // accordion for each immunization
            <Immunization
              key={immunization.id}
              patientId={patientId}
              id={immunization.id}
              title={immunization.title}
              register={register}
              isExpanded={isExpanded}
              tagLabel="Note"
              onUpdateNote={(immunizationId) =>
                handleUpdateImmunizationNote(immunizationId, "NOTE")
              }
              mostRecentTimestamp={immunization.created}
              instances={[
                {
                  timestamp: immunization?.note?.at,
                  immunizationId: immunization?.id,
                  noteContent: immunization?.note?.content,
                },
              ]}
            />
          ))}
          {[...filteredImmunization.diagnoses, ...filteredImmunization.notes]
            .length === 0 && "No immunizations added"}
        </div>
      )}
    </div>
  );
}
