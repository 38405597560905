/* Tag Name */
/* External Imports */
import clsx from "clsx";
import { Inter } from "next/font/google";

/* Local Imports */

// components
import Icon from "@/components/icons";

// constants
import { STATUS_KEYS, STATUS_TYPES } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";

/* Tag Typescript Interface */
export interface TagProps {
  type: keyof typeof STATUS_TYPES;
  label?: string;
  isStatus?: boolean; // determines whether status tag (status tags have dot)
  customIconSvgPath?: string; // svg
  isFullWidth?: boolean;
  onRemove?: () => void;
  dataCyRemove?: string;
  isFalseyStatus?: boolean;
  interactivityIsDisabled?: boolean;
}

// Helper function to take a name and just get the first and last initial
const getInitialsFromName = (name: string) => {
  const initials: Array<string> = [];
  const names: Array<string> = name.split(" ");
  names.forEach(name => {
    initials.push(name.charAt(0));
  });
  return initials.slice(0, 2);
};

export default function Tag({
  type,
  label,
  isStatus = false,
  customIconSvgPath = "",
  isFullWidth = false,
  onRemove,
  isFalseyStatus,
  interactivityIsDisabled = false,
  dataCyRemove
}: TagProps) {
  const hasCustomIcon = !!customIconSvgPath;
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div
      className={clsx(styles.Tag, styles[type], {
        [styles.fullWidth]: isFullWidth
      })}
    >
      {isStatus && <span className={styles.dot} />}
      {isFalseyStatus && <span className={styles.bar} />}
      {type === STATUS_KEYS.RECIPIENT && (
        <span className={styles.initialsIcon}>
          {label && getInitialsFromName(label)}
        </span>
      )}

      {hasCustomIcon && (
        <span className={styles.icon}>
          <Icon svg={customIconSvgPath} width={12} />
        </span>
      )}

      {label && <span>{label}</span>}

      {onRemove && (
        <button
          onClick={onRemove}
          type="button"
          aria-label="remove"
          disabled={interactivityIsDisabled}
          data-cy={dataCyRemove}
        >
          <Icon svg="close_grey" height={8} width={6} />
        </button>
      )}
    </div>
  );
}
