/* External Imports */
import { useSelector, useDispatch } from "react-redux";
import { useMemo, useEffect, useState, MouseEvent } from "react";
import clsx from "clsx";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

/* Local */

// components
import CalendarHeader from "@/components/scheduling/calendars/calendarHeader";
import MonthContent from "../appointments/monthContent";
import DateBadge from "../appointments/dateBadge";
import Tag from "@/components/tag";

// constants
import { APP_KEYS, METRICS, STATUS_KEYS } from "@/globals/constants";
import { MODAL_TYPES } from "@/components/modal/dispatcher";

// helpers
import {
  convertLocalDatetimeToUtcInt,
  convertUtcIntToLocalDatetime,
  getDay
} from "../utils";

// store
import { RootState } from "@/store/store";
import {
  AppointmentInfo,
  useLazyAppointmentsGetListQuery,
  AppointmentList,
  AppointmentStatus
} from "@/store/services/scheduling";
import { setDetailDays } from "../calendarSlice";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { useGetFeatureFlagsQuery } from "@/store/services/system";
import {
  getApptsFilteredByProviderAndLocation,
  apptSlots as slots
} from "../utils";
import { setTabId } from "@/components/tabbed/tabSlice";
import {
  EventListApiArg,
  EventType,
  useLazyEventListQuery
} from "@/store/services/event";

// styles
import styles from "../styles.module.scss";
import {
  setRightPaneContent,
  setRightPaneOpen
} from "@/components/drawer/drawerSlice";

interface MonthViewProps {
  overview?: boolean;
}

const MonthView = ({ overview = false }: MonthViewProps) => {
  /* Redux */
  const dispatch = useDispatch();
  const { selectedDate, allDays, detailDays, detailView, locationFilter } =
    useSelector((state: RootState) => state.calendar);

  const currentDays = overview ? allDays : (detailDays as string[]); // month view will never be multi-user view

  const { providerFilter } = useSelector((state: RootState) => state.calendar);

  // get user session  info
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  // get features
  const { data: features } = useGetFeatureFlagsQuery();

  const [
    getAppointments,
    { data: appointments, isLoading: appointmentsLoading }
  ] = useLazyAppointmentsGetListQuery();

  const [getEvents, { data: scheduleEvents, isLoading: eventsIsLoading }] =
    useLazyEventListQuery();

  /* Local State */
  const [selected, setSelected] = useState("");
  const [dateAvailability, setDateAvailability] = useState<null | {
    [timestamp: string]: number;
  }>(null);
  /* Memoized Constants */
  const filteredAppointments = useMemo(
    () =>
      appointments
        ? getApptsFilteredByProviderAndLocation(
            providerFilter,
            locationFilter,
            appointments
          )
        : [],
    [providerFilter, appointments, locationFilter]
  );
  /* Shared Styles */
  // Define the common class names that should be applied to both the button and non-button elements
  const commonClassNames = (timestamp: string) => ({
    [styles.gridCell]: true,
    [styles.highlighted]: timestamp === dayjs().startOf("day").toISOString(),
    [styles.selected]: timestamp === selected,
    [styles.disabled]:
      dayjs(timestamp).month() !== dayjs(selectedDate.timestamp).month()
  });

  /* Effects */
  // once a location is set, fetch appointments
  // update appointments fetched each time the filter changes
  useEffect(() => {
    getAppointments({
      id: sessionInfo?.practice_id as number,
      scope: "practice",
      statusNotIn: [METRICS.CANCELED as AppointmentStatus]
    });
    const eventRequest: EventListApiArg = {
      rangeStart: dayjs(detailDays[0] as string)
        .startOf("day")
        .toISOString(),
      rangeEnd: dayjs(detailDays[detailDays.length - 1] as string)
        .startOf("day")
        .toISOString()
    };
    getEvents(eventRequest);
  }, [locationFilter, providerFilter]);

  useEffect(() => {
    let filteredEvents = scheduleEvents;
    let filteredAppointments = appointments;
    if (providerFilter && providerFilter.length > 0) {
      const providerFilteredscheduleEvents = filteredEvents?.filter(event =>
        event.participants.some(participant =>
          providerFilter.includes(participant.user_id)
        )
      );
      filteredEvents = providerFilteredscheduleEvents;
      filteredAppointments = appointments?.filter(appt =>
        providerFilter.includes(appt.provider.user_id)
      );
    }
    if (filteredEvents && filteredEvents.length > 0 && filteredAppointments) {
      const scheduleEvents = filteredEvents.filter(
        event => event.event_type === ("SCHEDULE" as EventType)
      );
      scheduleEvents.forEach(currentEvent => {
        const totalAvailabilityTime = dayjs(currentEvent.end_at).diff(
          dayjs(currentEvent.start_at),
          "minutes"
        );
        const calculateTotalAppointmentTime = (
          appointments: AppointmentList
        ) => {
          let totalMinutes = 0;
          const utcInteger = convertLocalDatetimeToUtcInt(
            dayjs(currentEvent.start_at)
          );

          const todaysAppointments = appointments.filter(({ starts }) => {
            return getDay(utcInteger as number) === getDay(starts);
          });
          todaysAppointments.forEach(appointment => {
            // TODO: do not count appointments that are before or after availability
            // TODO: figure out how to include appts that start before, but go into schedule

            const startTime = convertUtcIntToLocalDatetime(appointment.starts);
            const endTime = convertUtcIntToLocalDatetime(appointment.ends);
            const appointmentMinutes = endTime.diff(startTime, "minutes");

            totalMinutes += appointmentMinutes;
          });

          const todaysEvents = filteredEvents?.filter(
            event =>
              event.event_type !== ("SCHEDULE" as EventType) &&
              getDay(utcInteger as number) ===
                getDay(
                  convertLocalDatetimeToUtcInt(dayjs(event.start_at)) as number
                )
          );
          todaysEvents?.forEach(event => {
            // TODO: do not count appointments that are before or after availability
            // TODO: figure out how to include appts that start before, but go into schedule

            const startTime = dayjs(event.start_at);
            const endTime = dayjs(event.end_at);
            const eventMinutes = endTime.diff(startTime, "minutes");

            totalMinutes += eventMinutes;
          });

          return totalMinutes;
        };

        const totalAppointmentTimeInMinutes = calculateTotalAppointmentTime(
          filteredAppointments as AppointmentList
        );

        setDateAvailability(prevAvailability => ({
          ...prevAvailability,
          [dayjs(currentEvent.start_at).startOf("day").toISOString()]:
            totalAvailabilityTime - totalAppointmentTimeInMinutes > 0
              ? totalAvailabilityTime - totalAppointmentTimeInMinutes
              : 0
        }));
      });
    }
  }, [scheduleEvents, appointments, providerFilter]);

  /* Event Handlers */

  // open appointment modal when clicking directly on an appointment
  const handleAppointmentClick = (
    appointment: AppointmentInfo,
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    dispatch(
      setRightPaneContent({
        type: MODAL_TYPES.APPOINTMENT,
        props: {
          appointment,
          // if appointment is at least a day in the past, it is not editable
          isReadOnly:
            getDay(appointment.starts) <
            getDay(convertLocalDatetimeToUtcInt(dayjs()) as number)
        }
      })
    );
    dispatch(setRightPaneOpen(true));
  };

  // zoom into day view when clicking on "see more"
  const handleSeeMore = (timestamp: string) => {
    const view = features?.multi_provider_schedule_enabled
      ? APP_KEYS.MULTI
      : APP_KEYS.DAY;
    dispatch(setDetailDays({ view, timestamp }));
    dispatch(setTabId(view));
  };

  return (
    <div
      className={clsx(styles.CalendarGrid, {
        [styles.ScheduleView]: !overview
      })}
    >
      {currentDays && <CalendarHeader days={currentDays} />}
      {currentDays &&
        currentDays.map((timestamp, i) => {
          const utcInteger = convertLocalDatetimeToUtcInt(dayjs(timestamp));

          const todaysAppointments = filteredAppointments?.filter(
            ({ starts }) => {
              return getDay(utcInteger as number) === getDay(starts);
            }
          );

          const numberOfAppts = todaysAppointments?.length || 0;
          let additionalAppts;
          let displayAppts = todaysAppointments;
          if (numberOfAppts > 2) {
            additionalAppts = numberOfAppts - 1;

            displayAppts = todaysAppointments?.slice(0, 1);
          }
          return overview ? (
            <button
              key={timestamp}
              className={clsx(commonClassNames(timestamp))}
              onClick={() => {
                dispatch(setDetailDays({ timestamp, view: detailView }));
                setSelected(timestamp);
              }}
            >
              <DateBadge
                timestamp={timestamp}
                isSelected={selectedDate.timestamp === timestamp}
              />
            </button>
          ) : (
            <div key={timestamp} className={clsx(commonClassNames(timestamp))}>
              <div
                className={styles.availability}
                role="button"
                onClick={() => {
                  handleSeeMore(timestamp);
                }}
                data-cy={`${dayjs(timestamp).format("YYYYMMDD")}-see-more`}
              >
                <div className={styles.status}>
                  <Tag
                    type={
                      !dateAvailability?.[timestamp]
                        ? STATUS_KEYS.ERROR
                        : STATUS_KEYS.INFO
                    }
                    isStatus={!!dateAvailability?.[timestamp]}
                    isFalseyStatus={!dateAvailability?.[timestamp]}
                  />
                </div>

                <DateBadge
                  timestamp={timestamp}
                  isSelected={timestamp === selected}
                />
              </div>
              {
                <MonthContent
                  appointments={displayAppts}
                  handleAppointmentClick={handleAppointmentClick}
                  handleSeeMoreClick={handleSeeMore}
                  timestamp={timestamp}
                  additionalApptsCount={additionalAppts || 0}
                  isLoading={appointmentsLoading}
                />
              }
            </div>
          );
        })}
    </div>
  );
};

export default MonthView;
