/* Appointmentation Modal Template */

// External
import clsx from "clsx";
import { useDispatch } from "react-redux";

// styles
import styles from "../styles.module.scss";

// components
import Button from "@/components/button";

// store
import { setModalIsOpen } from "../modalSlice";

export interface MessageConfirmationProps {
  isSuccess: boolean;
}

export default function InternalMessageModal({
  isSuccess,
}: MessageConfirmationProps) {
  const dispatch = useDispatch();

  const onConfirm = () => {
    dispatch(setModalIsOpen(false));
  };

  return (
    <div className={clsx(styles.InternalMessageConfirmation)}>
      {isSuccess ? (
        <div className={styles.confirmationMessage}>
          <h3 className={styles.newMessageTitle}>Your message was sent!</h3>
          <Button onClick={onConfirm}>Okay</Button>
        </div>
      ) : (
        <div className={styles.confirmationMessage}>
          <h3 className={styles.newMessageTitle}>Something went wrong</h3>
          <p>Please try again later.</p>
          <Button onClick={onConfirm}>Okay</Button>
        </div>
      )}
    </div>
  );
}
