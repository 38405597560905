/* CreateImmunization */
/* External Imports */
import clsx from "clsx";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormGetValues,
  UseFormSetValue,
} from "react-hook-form";
import { motion } from "framer-motion";

/* Local Imports */

// components
import TextArea from "@/components/textArea";
import ComboboxSelect from "@/components/input/combobox";
import Button from "@/components/button";
import Input from "@/components/input";

// constants
import { STYLES } from "@/globals/constants";

// store
import { useLazyCodingSearchIcd10Query } from "@/store/services/coding";

// styles
import styles from "./styles.module.scss";

/* CreateImmunization Typescript Interface */
interface CreateImmunizationProps {
  onSubmit: () => void;
  onCancel: () => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  control: Control<FieldValues, any>;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}

export default function CreateImmunization({
  onSubmit,
  onCancel,
  register,
  errors,
  control,
  getValues,
  setValue,
}: CreateImmunizationProps) {
  /* Redux */
  const [searchICD10, { data: icd10codes }] = useLazyCodingSearchIcd10Query();
  /* Local State */

  /* Effects */

  /* Event Handlers */
  return (
    <motion.div
      className={clsx(styles.CreateImmunization)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <p className="t3 tMd">Add New Immunization</p>
      <form onSubmit={onSubmit}>
        <div className={styles.filterWrapper}>
          <Controller
            name="select_icd10"
            control={control}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <ComboboxSelect
                label="Select diagnosis"
                placeholder="Search for ICD10 code"
                options={icd10codes || []}
                labelAcc={(item) => `${item?.code} - ${item?.description}`}
                onChange={(v) => onChange(v)}
                onSearch={(term) => {
                  // get ICD10 codes from API
                  if (term.length >= 3) searchICD10({ term });
                }}
                error={
                  errors?.select_icd10 &&
                  (errors?.select_icd10?.message as string)
                }
                fullWidth
              />
            )}
            rules={{ required: false }}
          />
          <span className="tMd t5">or</span>
          <Input
            label="Create immunization title"
            id="new_immunization_title"
            name="new_immunization_title"
            type="text"
            required={!getValues("select_icd10")}
            register={register}
          />
        </div>
        <Input
          label="Add reaction"
          id="immunization_reaction"
          name="immunization_reaction"
          type="text"
          register={register}
          fullWidth
        />
        <TextArea
          label="Comments"
          id={`immunization_new_note`}
          name={`immunization_new_note`}
          placeholder="add a note about this immunization"
          setValue={setValue}
        />
        <div className={styles.buttons}>
          <Button style={STYLES.SECONDARY} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save Immunization</Button>
        </div>
      </form>
    </motion.div>
  );
}
