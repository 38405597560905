// external
import { useState } from "react";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import { v4 } from "uuid";

// constants
import { STATUS_KEYS } from "@/globals/constants";

// store
import { UserId } from "@/store/services/user";
import { TaskId } from "@/store/services/task";
import {
  addAlertToToastTrough,
  removeAlertFromToastTrough
} from "@/components/toastTrough/toastSlice";
import {
  useTaskCompleteMutation,
  useTaskInfoQuery
} from "@/store/services/task";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";
import { FileUploadApiArg, useFileUploadMutation } from "@/store/services/file";

// components
import ContentRenderer from "@/components/textArea/contentRenderer";
import FileUploader from "@/components/fileExplorer/upload";
import Button from "@/components/button";

// styles
import styles from "../styles.module.scss";
import { setModalIsOpen } from "../modalSlice";

export interface UploadModalProps {
  patientId: UserId;
  taskId: TaskId;
}

export default function UploadTaskModal({
  patientId,
  taskId
}: UploadModalProps) {
  const dispatch = useDispatch();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);

  const [uploadFiles] = useFileUploadMutation();
  const [completeTask] = useTaskCompleteMutation();

  const { data: task, isSuccess: isTaskSuccess } = useTaskInfoQuery({
    taskId: taskId
  });

  const handleFilesAdded = (files: FileList) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      const f = files[i];
      formData.append(f.name, f, f.name);
    }

    const req: FileUploadApiArg = {
      body: formData,
      patientId: patientId
    };

    const toastId = v4();
    dispatch(
      addAlertToToastTrough({
        message: "Uploading files...",
        type: STATUS_KEYS.LOADING,
        id: toastId
      })
    );

    uploadFiles(req)
      .then(() => {
        dispatch(removeAlertFromToastTrough(toastId));
        setSubmitDisabled(false);
      })
      .catch(() => {
        dispatch(removeAlertFromToastTrough(toastId));
      });
  };

  const onSubmit = () => {
    completeTask({
      taskId: taskId
    });
    dispatch(setRightPaneOpen(false));
    dispatch(setModalIsOpen(false));
    dispatch(addAlertToToastTrough({
      type: STATUS_KEYS.SUCCESS,
      message: "Successfully completed task",
    }));
  };

  return (
    <div className={styles.TaskCompletionModal}>
      {isTaskSuccess && task.upload ? (
        <div>
          <h3>{task.title}</h3>
          <ContentRenderer content={task.upload.prompt || ""} />
          <FileUploader onFilesAdded={handleFilesAdded} />
          <div className={styles.buttonContainer}>
            <Button
              type="button"
              nativeButtonProps={{ disabled: submitDisabled }}
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </div>
      ) : (
        <>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </>
      )}
    </div>
  );
}
