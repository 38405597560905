/* External Imports */
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Skeleton from "react-loading-skeleton";

dayjs.extend(utc);
dayjs.extend(timezone);
/* Local */

// components
import CalendarHeader from "@/components/scheduling/calendars/calendarHeader";

// store
import { useLazyAppointmentsGetListQuery } from "@/store/services/scheduling";
// constants
import Day from "./day";
import { APP_KEYS, METRICS } from "@/globals/constants";
import { RootState } from "@/store/store";

// helpers
import {
  getPixelsElapsed,
  convertUtcIntToLocalDatetime,
  getApptsFilteredByProviderAndLocation,
  apptSlots as slots,
  convertLocalDatetimeToUtcInt,
  getDay
} from "../utils";

// styles
import styles from "../styles.module.scss";
import { AppointmentStatus } from "@/store/services/encounter";

export interface WeekView {
  days: string[];
}

const WeekView = ({ days }: WeekView) => {
  /* Redux */
  const { locationFilter } = useSelector((state: RootState) => state.calendar);
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [
    getAppointments,
    { data: appointments, isLoading: appointmentsLoading }
  ] = useLazyAppointmentsGetListQuery();

  const { providerFilter, detailView } = useSelector(
    (state: RootState) => state.calendar
  );
  /* Local State */
  const [pixelsElapsed, setPixelsElapsed] = useState(getPixelsElapsed());

  const todaySlots = slots(dayjs().toISOString(), 30);

  const filteredAppointments = useMemo(
    () =>
      appointments
        ? getApptsFilteredByProviderAndLocation(
            providerFilter,
            locationFilter,
            appointments
          )
        : [],
    [providerFilter, locationFilter, appointments]
  );
  /* Effects */

  useEffect(() => {
    const interval = setInterval(() => {
      const pixelsElapsed = getPixelsElapsed();
      setPixelsElapsed(pixelsElapsed); // Update the state with the new pixel value
    }, 6000); // Run the interval every minute (every 60,000 milliseconds)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  // scroll to noon time in calendar
  useEffect(() => {
    const renderer = document.getElementById(
      "calendar-week-container"
    ) as HTMLElement;

    if (renderer?.scrollHeight <= renderer?.clientHeight) {
      return;
    }

    // get time at 8 am
    const localEightAM = dayjs().hour(8).minute(0).second(0).millisecond(0);
    const eightAM = convertLocalDatetimeToUtcInt(localEightAM);
    const topPos = document.getElementById(`slot-${eightAM}`)?.offsetTop;
    if (topPos) {
      renderer.scrollTop = topPos as number;
    }
  }, []);

  // once a practice is set, fetch appointments
  // update appointments fetched each time the filter changes
  useEffect(() => {
    if (locationFilter) {
      getAppointments({
        id: sessionInfo?.practice_id as number,
        scope: "practice",
        statusNotIn: [METRICS.CANCELED as AppointmentStatus]
      });
    }
  }, [locationFilter]);

  return (
    <>
      <div
        className={clsx(
          styles.CalendarGrid,
          styles.ScheduleView,
          styles.WeekView
        )}
        style={{ gridTemplateColumns: `70px repeat(${days.length}, 1fr)` }}
      >
        {days && (
          <CalendarHeader
            isWeekView
            isMulti={detailView === APP_KEYS.MULTI}
            days={days}
          />
        )}
      </div>
      <div
        className={clsx(
          styles.CalendarGrid,
          styles.ScheduleView,
          styles.WeekView
        )}
        id="calendar-week-container"
        style={{ gridTemplateColumns: `70px repeat(${days.length}, 1fr)` }}
      >
        <div className={clsx(styles.Day, styles.timeColumn)}>
          {todaySlots.map((slot: number) => (
            <div
              key={slot}
              className={clsx(styles.slot, styles.slotTimeIndicator)}
              id={`slot-${slot}`}
            >
              <p className="xLight">
                {dayjs(convertUtcIntToLocalDatetime(slot)).format("h:mm a")}
              </p>
            </div>
          ))}
        </div>
        {days &&
          days.map((day: string) => {
            let daysAppointments;
            const utcInteger = convertLocalDatetimeToUtcInt(dayjs(day));
            daysAppointments = filteredAppointments?.filter(
              ({ starts }) => getDay(utcInteger as number) === getDay(starts)
            );

            // filter by provider too
            if (providerFilter.length > 0) {
              daysAppointments = filteredAppointments.filter(({ provider }) =>
                providerFilter.includes(provider?.user_id)
              );
            }
            const apptSlots = slots(day);

            if (appointmentsLoading) {
              return <Skeleton key={day} height="100%" width={"98%"} />;
            }

            return (
              daysAppointments && (
                <Day
                  key={day}
                  appointments={daysAppointments}
                  disabled={false}
                  slots={apptSlots}
                />
              )
            );
          })}
      </div>
    </>
  );
};

export default WeekView;
