import clsx from "clsx";

import styles from "./styles.module.scss";

export enum TOOLTIP_POSITIONS {
  STANDARD_TOP = "standardTop",
  RIGHT_ALIGNED_TOP = "rightAlignedTop",
}

interface TooltipProps {
  tooltipText: string;
  tooltipPosition?: TOOLTIP_POSITIONS;
  children: JSX.Element | JSX.Element[];
}

export default function Tooltip({
  tooltipText,
  children,
  tooltipPosition = TOOLTIP_POSITIONS.STANDARD_TOP
}: TooltipProps) {
  return (
    <div className={clsx(styles.Tooltip, styles[tooltipPosition])}>
      {children}
      <span className={styles.tooltipText}>{tooltipText}</span>
    </div>
  );
}
