import { PatientInfo } from "@/store/services/patient";
import ObjectTableItem from "./objectTableItem";
import ReadOnlySection from "./readOnlySection";

interface ReadOnlyAllergyListProps {
  patient: PatientInfo;
  print?: boolean;
}

export default function ReadOnlyAllergyList({
  patient,
  print = false,
}: ReadOnlyAllergyListProps) {
  const ALLERGY_HEADERS = ["description", "icd10", "reaction", "severity"];

  return (
    <ReadOnlySection header="Allergies" print={print}>
      {patient.medical_history.allergies && (
        <ObjectTableItem
          orderedHeaders={ALLERGY_HEADERS}
          objectGrid={patient.medical_history.allergies}
        />
      )}
    </ReadOnlySection>
  );
}
