import { backendSlice as api } from "../../../components/api/apiSlice";
const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    payorList: build.query<PayorListApiResponse, PayorListApiArg>({
      query: () => ({ url: `/payors/list` })
    }),
    payorCreate: build.mutation<PayorCreateApiResponse, PayorCreateApiArg>({
      query: queryArg => ({
        url: `/payors/create`,
        method: "POST",
        body: queryArg.payorCreateRequest
      })
    }),
    payorGetInfo: build.query<PayorGetInfoApiResponse, PayorGetInfoApiArg>({
      query: queryArg => ({ url: `/payors/${queryArg.payorId}/info` })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as payor };
export type PayorListApiResponse =
  /** status 200 Sent in response to a request list payors.
   */ PayorList;
export type PayorListApiArg = void;
export type PayorCreateApiResponse =
  /** status 200 Sent in response to a successfully handled request to create a new payor.
   */ HgOrgId;
export type PayorCreateApiArg = {
  /** Payload for creating a new payor and adding it to Health Gorilla.
   */
  payorCreateRequest: PayorCreateRequest;
};
export type PayorGetInfoApiResponse =
  /** status 200 Sent in response to a request for all the information about a particular payor.
   */ PayorInfo;
export type PayorGetInfoApiArg = {
  payorId: HgOrgId;
};
export type PayorListItem = {
  name: string;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  hg_id: string;
  address?: string;
};
export type PayorList = PayorListItem[];
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type HgOrgId = string;
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type Address = {
  /** Address line 1. Mandatory. This can include the street number.
   */
  line1?: string;
  /** Optional line 2 of 3 for an address.
   */
  line2?: string;
  /** Optional line 3 of 3 for an address.
   */
  line3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** This is the optional, human-readable representation of the address. This is used
    when communicating addresses that come from sources that don't parse their addresses
    down into its constituent parts, like Health Gorilla.
     */
  display?: string;
};
export type PayorCreateRequest = {
  name: string;
  address: Address;
};
export type PayorInfo = {
  name: string;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  hg_id: string;
  address?: Address;
};
export const {
  usePayorListQuery,
  useLazyPayorListQuery,
  usePayorCreateMutation,
  usePayorGetInfoQuery,
  useLazyPayorGetInfoQuery
} = injectedRtkApi;
