/* NoKnownToggle Name */
/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";
/* Local Imports */

// components
import Toggle from "@/components/input/toggle";

// constants
import { STATUS_KEYS } from "@/globals/constants";
// store
import {
  UserId,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { addAlertToToastTrough } from "../toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

/* NoKnownToggle Typescript Interface */
interface NoKnownToggleProps {
  patientId: UserId;
  toggleField:
    | "no_known_drug_allergies"
    | "no_known_problems"
    | "no_known_medications";
}

// human readable toggle field names
const TOGGLE_FIELD_NAMES: Record<string, string> = {
  no_known_drug_allergies: "No Known Drug Allergies",
  no_known_problems: "No Known Problems",
  no_known_medications: "No Known Medications"
};

export default function NoKnownToggle({
  patientId,
  toggleField
}: NoKnownToggleProps) {
  /* Redux */
  const dispatch = useDispatch();
  // get patient
  const { data: patient } = usePatientGetInfoQuery({ patientId });
  // update patient
  const [updatePatient] = usePatientUpsertMutation();

  /* Local State */

  /* Effects */

  /* Event Handlers */

  const handleToggle = (toggleVal: boolean) => {
    updatePatient({
      patientUpsertRequest: {
        user_id: patient?.user_id,
        medical_history: {
          ...patient?.medical_history,
          [toggleField]: toggleVal
        }
      }
    })
      .unwrap()
      .then(() => {
        launchToast(
          "Saved successfully.",
          STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        );
      })
      .catch(() =>
        launchToast(
          "An error occurred while saving.",
          STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        )
      );
  };

  const launchToast = (message: string, type: keyof typeof STATUS_KEYS) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  return (
    <div className={clsx(styles.NoKnownToggle)}>
      <div className={styles.noneRecorded}>
        <Toggle
          label={TOGGLE_FIELD_NAMES[toggleField]}
          labelIsRight
          onChange={handleToggle}
          //   @ts-ignore
          initialChecked={patient?.medical_history[toggleField]}
        />
        {/* @ts-ignore */}
        {patient?.medical_history[toggleField] ? (
          <div className="note warning">
            This patient has{" "}
            {TOGGLE_FIELD_NAMES[toggleField]?.toLocaleLowerCase()}. If the
            patient has any, please add them and switch this toggle off.
          </div>
        ) : (
          <div className="note">
            If the patient has{" "}
            {TOGGLE_FIELD_NAMES[toggleField]?.toLocaleLowerCase()}, please
            indicate via the toggle above.
          </div>
        )}
      </div>
    </div>
  );
}
