import Button from "@/components/button";
import MedicationList from "@/components/medications";
import { STYLES } from "@/globals/constants";
import { PatientInfo } from "@/store/services/patient";
import { useDispatch } from "react-redux";
import { setModalContent, setModalIsOpen } from "../modalSlice";
import { MODAL_TYPES } from "../dispatcher";

interface MedicationListProps {
  patient: PatientInfo;
}

export default function MedicationListModal({ patient }: MedicationListProps) {
  const dispatch = useDispatch();

  const handleOpenAddMedicationModal = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.ADD_MEDICATION,
        props: { userId: patient.user_id, title: "Add Medication" }
      })
    );
  };

  return (
    <div>
      <MedicationList patient={patient} />
      <Button type="button" style={STYLES.FULL_WIDTH} onClick={handleOpenAddMedicationModal}>
        Add new medication
      </Button>
    </div>
  );
}
