/* Timestamp form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";

// components
import Button from "@/components/button";
import Input from "@/components/input";

// constants
import { STYLES } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* Hydrotherapyform Typescript Interface */
interface HydrotherapyformProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  startTime?: number;
  defaultValues?: any;
  disabled?: boolean;
}

export default function Hydrotherapyform({
  onSubmit,
  startTime,
  defaultValues,
  disabled = false
}: HydrotherapyformProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset
  } = useForm({ defaultValues });

  return (
    <form
      className={clsx(styles.LaborForm, styles.Hydrotherapyform)}
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data, reset);
      })}
    >
      <div>
        <Controller
          name="start_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                label="Time in"
                onChange={onChange}
                initialValue={defaultValues && defaultValues.start_time}
                value={value}
              />
            );
          }}
        />
        <Controller
          name="end_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                label="Time out"
                onChange={onChange}
                initialValue={defaultValues && defaultValues.end_time}
                value={value}
              />
            );
          }}
        />
        <Input
          type="number"
          label="Water temp"
          name="water_temp"
          id="water_temp"
          fullWidth
          register={register}
          disabled={disabled}
        />
      </div>
      <div></div>
      <Input
        type="text"
        label="Comments"
        name="comments"
        id="comments"
        fullWidth
        register={register}
        disabled={disabled}
      />
      <div></div>
      <Button
        style={STYLES.FULL_WIDTH}
        type="submit"
        nativeButtonProps={{ disabled }}
      >
        Save to flow sheet
      </Button>
      <div></div>
    </form>
  );
}
