import * as generated from "./generated/user";

const user = generated.user.enhanceEndpoints({});

export * from "./generated/user";
export default user;
export const {
  useUserCreateMutation,
  useUserUpdateMutation,
  useUserGetInfoQuery,
  useLazyUserGetInfoQuery,
  useUserGetNotificationsQuery,
  useLazyUserGetNotificationsQuery,
  useUserSelectAuthQuery,
  useLazyUserSelectAuthQuery,
  useDummyQuery,
  useLazyDummyQuery,
} = user;
