// External
import { useMemo } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

// Globals, helpers, store
import { setModalContent } from "../modalSlice";
import { MODAL_TYPES } from "../dispatcher";
import {
  useDeleteEddMutation,
  useListEddHistoryQuery,
  usePregnancyInfoQuery,
  useUpdateEddMutation
} from "@/store/services/pregnancy";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS } from "@/globals/constants";

// Components
import Button from "@/components/button";
import { GridDataType, metrics } from "@/components/datagrid";
import EddFlowSheet from "@/components/flowSheet/EddFlowSheet";

// Styles
import styles from "../styles.module.scss";

interface PregnancyDatingProps {
  pregnancyId: number;
  patientId: number;
}

export default function PregnancyDating({
  pregnancyId,
  patientId
}: PregnancyDatingProps) {
  const dispatch = useDispatch();

  const { data: pregnancyData } = usePregnancyInfoQuery(
    {
      pregnancyId: pregnancyId
    },
    {
      skip: !pregnancyId
    }
  );

  const { data: estimateData } = useListEddHistoryQuery(
    {
      pregnancyId: pregnancyId
    },
    {
      skip: !pregnancyId
    }
  );

  const [updateEdd] = useUpdateEddMutation();
  const [deleteEdd] = useDeleteEddMutation();

  const eddFlowsheetData: GridDataType | null = useMemo(() => {
    if (!estimateData || !pregnancyData) {
      return null;
    }
    return estimateData?.map(edd => {
      return {
        id: edd.estimate_id as string,
        metrics: {
          estimated_due_date: edd.estimated_due_date,
          method: edd.method,
          date_of_method: edd.date_of_method,
          active: pregnancyData.edd?.estimate_id == edd.estimate_id,
          comment: edd.comment
        } as metrics
      };
    });
  }, [estimateData, pregnancyData]);

  const flowsheetFormattingHandler = (
    metrics: metrics,
    metric: string,
    id: number | string
  ) => {
    switch (metric) {
      case "estimated_due_date":
        return <p>{dayjs(metrics[metric] as string).format("MM/DD/YYYY")}</p>;
      case "method":
        switch (metrics[metric]) {
          case "LMP":
            return <p>LMP</p>;
          case "ULTRASOUND":
            return <p>Ultrasound</p>;
          case "DATE_OF_CONCEPTION":
            return <p>Date of conception</p>;
          case "UNKNOWN":
            return <p>Unknown</p>;
          default:
            return <p>{metrics[metric] as string}</p>;
        }
      case "date_of_method":
        return <p>{dayjs(metrics[metric] as string).format("MM/DD/YYYY")}</p>;
      case "active":
        return (
          <input
            type="checkbox"
            name={`active-${id}`}
            id={`active-${id}`}
            checked={!!metrics[metric]}
            onClick={e => {
              handleToggleActive(id as string, !!metrics[metric]);
            }}
          />
        );
      default:
        return "";
    }
  };

  const handleToggleActive = (id: string, checked: boolean) => {
    const estimate = estimateData?.find(est => est.estimate_id == id);
    if (!estimate || !estimate.estimate_id) {
      console.warn("Estimate not found");
      return;
    }
    if (!checked) {
      updateEdd({
        pregnancyId: pregnancyId,
        eddId: estimate.estimate_id,
        estimatedDueDateUpdate: {
          set_active: true
        }
      })
        .unwrap()
        .then(() => {
          dispatch(
            addAlertToToastTrough({
              message: "Successfully updated active EDD",
              type: STATUS_KEYS.SUCCESS
            })
          );
        })
        .catch(err => {
          console.error(err);
          dispatch(
            addAlertToToastTrough({
              message: "Something went wrong updating active EDD",
              type: STATUS_KEYS.ERROR
            })
          );
        });
    } else {
      dispatch(
        addAlertToToastTrough({
          message: "Cannot unset active EDD",
          type: STATUS_KEYS.WARNING
        })
      );
    }
  };

  const handleNewEstimate = (
    type: "LMP" | "Ultrasound" | "DateOfConception" | "Unknown"
  ) => {
    if (type === "LMP") {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_LMP_ESTIMATE,
          props: {
            title: "New LMP estimate",
            pregnancyId: pregnancyId
          }
        })
      );
    } else if (type == "Ultrasound") {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_ULTRASOUND_ESTIMATE,
          props: {
            title: "New Ultrasound estimate",
            pregnancyId: pregnancyId,
            patientId: patientId
          }
        })
      );
    } else if (type == "DateOfConception") {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_DOC_ESTIMATE,
          props: {
            title: "New Date of conception estimate",
            pregnancyId: pregnancyId,
            patientId: patientId
          }
        })
      );
    } else {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_UNKNOWN_ESTIMATE,
          props: {
            title: "New unknown estimate",
            pregnancyId: pregnancyId
          }
        })
      );
    }
  };

  const handleEditEstimate = (estimateId: string, type: string) => {
    if (!estimateData) {
      console.warn(
        "Shouldn't be editing estimate when there's no estimate data"
      );
      return;
    }
    const estimate = estimateData.find(est => est.estimate_id == estimateId);

    if (type === "LMP") {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_LMP_ESTIMATE,
          props: {
            title: "Edit LMP estimate",
            pregnancyId: pregnancyId,
            defaultEdd: estimate
          }
        })
      );
    } else if (type == "ULTRASOUND") {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_ULTRASOUND_ESTIMATE,
          props: {
            title: "Edit Ultrasound estimate",
            pregnancyId: pregnancyId,
            patientId: patientId,
            defaultEdd: estimate
          }
        })
      );
    } else if (type == "DATE_OF_CONCEPTION") {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_DOC_ESTIMATE,
          props: {
            title: "Edit Date of Conception estimate",
            pregnancyId: pregnancyId,
            defaultEdd: estimate
          }
        })
      );
    } else {
      dispatch(
        setModalContent({
          type: MODAL_TYPES.NEW_UNKNOWN_ESTIMATE,
          props: {
            title: "Edit unknown estimate",
            pregnancyId: pregnancyId,
            defaultEdd: estimate
          }
        })
      );
    }
  };

  const handleDeleteEstimate = (estimateId: string) => {
    if (!estimateData) {
      console.warn(
        "Shouldn't be deleting estimate when there's no estimate data"
      );
      return;
    }

    deleteEdd({
      pregnancyId: pregnancyId,
      eddId: estimateId
    })
      .unwrap()
      .then(res => {
        console.log(res);
      });
  };

  return (
    <div className={styles.PregnancyDating} data-cy="dating-modal">
      {eddFlowsheetData ? (
        <EddFlowSheet
          data={eddFlowsheetData}
          headers={[
            "active",
            "estimated_due_date",
            "method",
            "date_of_method",
            "edit",
            "note"
          ]}
          customFormattingFunction={flowsheetFormattingHandler}
          onEdit={handleEditEstimate}
          onDelete={handleDeleteEstimate}
        />
      ) : (
        <p>No estimated due dates created yet.</p>
      )}
      <div className={styles.buttonContainer}>
        <Button onClick={() => handleNewEstimate("LMP")} type="button">
          New LMP estimate
        </Button>
        <Button onClick={() => handleNewEstimate("Ultrasound")} type="button">
          New ultrasound estimate
        </Button>
        <Button
          onClick={() => handleNewEstimate("DateOfConception")}
          type="button"
        >
          New date of conception estimate
        </Button>
        <Button onClick={() => handleNewEstimate("Unknown")} type="button">
          New unknown estimate
        </Button>
      </div>
    </div>
  );
}
