import * as generated from "./generated/lab";

const lab = generated.lab.enhanceEndpoints({
  addTagTypes: ["Encounter"],
  endpoints: {
    labSendOrder: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter"];
      }
    },
    labToggleOrderReviewed: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labOrderUpdate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter"];
      }
    },
    labOrderCreate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter"];
      }
    }
  }
});

export * from "./generated/lab";
export default lab;
export const {
  useLabListQuery,
  useLazyLabListQuery,
  useLabGetInfoQuery,
  useLazyLabGetInfoQuery,
  useLabOrderCreateMutation,
  useLabOrderUpdateMutation,
  useLabListTestsQuery,
  useLazyLabListTestsQuery,
  useLabGetAoeQuestionsQuery,
  useLazyLabGetAoeQuestionsQuery,
  useLabSearchLocationsMutation,
  useLabBookmarkLocationMutation,
  useLabListBookmarkedLocationsQuery,
  useLazyLabListBookmarkedLocationsQuery,
  useLabGetLocationInfoQuery,
  useLazyLabGetLocationInfoQuery,
  useLabDeleteBookmarkedLocationMutation,
  useLabListTestBundlesQuery,
  useLazyLabListTestBundlesQuery,
  useLabCreateTestBundleMutation,
  useLabUpdateTestBundleMutation,
  useLabDeleteTestBundleMutation,
  useLabSendOrderMutation,
  useLabGetOrderResultsPdfQuery,
  useLazyLabGetOrderResultsPdfQuery,
  useLabGetOrderRequisitionPdfQuery,
  useLazyLabGetOrderRequisitionPdfQuery,
  useLabGetOrderObservationsQuery,
  useLazyLabGetOrderObservationsQuery,
  useLabToggleOrderReviewedMutation,
  useLabGetOrderAbnQuery,
  useLazyLabGetOrderAbnQuery
} = lab;
