import "@/styles/globals.scss";
import type { ReactElement, ReactNode } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { wrapper } from "../store/store";
import Head from "next/head";
import { Inter } from "next/font/google";
import ProviderWrapper from "./_provider";
import ErrorBoundary from "@/components/errorBoundary";

const font = Inter({
  weight: ["400", "500", "700"],
  subsets: ["latin"]
});

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

export function reportWebVitals(metric: any) {
  console.log(metric);
}

export default function App({ Component, ...rest }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? (page => page);
  const { props } = wrapper.useWrappedStore(rest);
  const { pageProps } = props;

  return (
    <ProviderWrapper>
      <div className={font.className}>
        <Head>
          <title>Pario</title>
          <meta
            name="description"
            content="The platform for women's health practitioners"
          />
          <meta name="author" content="Pario Health Inc ©" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/svgs/logo_primary.svg" />
          {/* Twitter, OpenGraph */}
          <meta
            property="og:image"
            content="./public/Pario_OG.png"
            key="ogimage"
          />
          <meta
            property="og:site_name"
            content="Pario Health"
            key="ogsitename"
          />
          <meta
            property="og:url"
            content="https://pariohealth.com/"
            key="ogurl"
          />
          <meta
            name="twitter:image"
            content={`https://pariohealth.com/Pario_OG.png`}
          />
        </Head>
        <ErrorBoundary>{getLayout(<Component {...pageProps} />)}</ErrorBoundary>
      </div>
    </ProviderWrapper>
  );
}
