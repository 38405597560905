import * as generated from "./generated/scheduling"

const scheduling = generated.scheduling.enhanceEndpoints({
  endpoints: {
    appointmentsGetList: {
      providesTags: ["Appointments"]
    },
    appointmentUpsert: {
      invalidatesTags: ["Appointments", "Tasks", "Encounter"]
    },
    appointmentStart: {
      invalidatesTags: ["Appointments"]
    }
  }
});

export * from "./generated/scheduling";
export default scheduling;
export const {
  useAppointmentsGetListQuery,
  useLazyAppointmentsGetListQuery,
  useAppointmentUpsertMutation,
  useAppointmentStartMutation,
} = scheduling;
