// third-party
import dayjs from "dayjs";

// components
import KeyValueGrid from "../../birthSummary/keyValueGrid";

// store
import {
  UserInsurance,
  usePatientGetInfoQuery
} from "@/store/services/patient";
import { Insurance } from "@/store/services/user";

// constants
import KeyValueItem from "../../birthSummary/keyValueItem";
import { FORMAT } from "@/globals/helpers/formatters";
import { useMemo } from "react";
import { usePayorListQuery } from "@/store/services/payor";

interface InsuranceReadOnlyProps {
  patientId: number;
  type: "PRIMARY" | "SECONDARY" | "TERTIARY";
}

export default function InsuranceReadOnly({
  patientId,
  type
}: InsuranceReadOnlyProps) {
  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId
    },
    {
      skip: !patientId
    }
  );

  const { data: payors } = usePayorListQuery();

  const formatKey = (keyLabel: string) => {
    const keystring = keyLabel.replaceAll("_", " ");
    const capital = keystring.slice(0, 1).toUpperCase();
    const restOfString = keystring.slice(1);
    return capital + restOfString;
  };

  const formattedPayorName = useMemo(() => {
    const insurance = patient?.insurance?.[
      type.toLowerCase() as keyof UserInsurance
    ] as Insurance;
    if (!insurance) {
      return "";
    }
    const payor = payors?.find(payor => payor.hg_id === insurance.hg_org_id);
    if (payor) {
      return payor.name + " - " + payor.address;
    }

    return insurance.payor;
  }, [patient]);

  return (
    <KeyValueGrid>
      <KeyValueItem keyLabel="Patient is Self Pay">
        <p>{patient?.insurance?.self_pay ? "Yes" : "No"}</p>
      </KeyValueItem>
      {(patient?.insurance?.[
        type.toLowerCase() as keyof UserInsurance
      ] as Insurance) &&
        Object.entries(
          patient?.insurance?.[
            type.toLowerCase() as keyof UserInsurance
          ] as Insurance
        ).map(([key, value]) => {
          if (key.indexOf("address") == -1) {
            if (key.indexOf("dob") != -1) {
              return (
                <KeyValueItem keyLabel={formatKey(key)} key={key}>
                  <p>
                    {value != "" ? dayjs(value + "").format("MM/DD/YYYY") : ""}
                  </p>
                </KeyValueItem>
              );
            } else if (key == "payor") {
              return (
                <KeyValueItem keyLabel={formatKey(key)} key={key}>
                  <p>{formattedPayorName}</p>
                </KeyValueItem>
              );
            } else {
              return (
                <KeyValueItem keyLabel={formatKey(key)} key={key}>
                  <p>{value as string}</p>
                </KeyValueItem>
              );
            }
          } else {
            return (
              <KeyValueItem keyLabel={formatKey(key)} key={key}>
                {/* I can't figure out how to get TS to know forsure this is an
              Address type so I'm just going to ts-ignore it */}
                {/* @ts-ignore */}
                <p>{FORMAT.address(value)}</p>
              </KeyValueItem>
            );
          }
        })}
    </KeyValueGrid>
  );
}
