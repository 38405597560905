import {
  PatientProblems,
  usePatientGetInfoQuery,
} from "@/store/services/patient";
import styles from "./styles.module.scss";
import SectionRow from "./sectionRow";
import { convertUtcIntToLocalDatetime } from "../scheduling/calendars/utils";
import ReadOnlySection from "./readOnlySection";
interface ReadOnlyProblemListProps {
  patientId: number;
  print?: boolean;
}

export default function ReadOnlyProblemList({
  patientId,
  print = false,
}: ReadOnlyProblemListProps) {
  const { data } = usePatientGetInfoQuery({ patientId }, { skip: !patientId });
  return (
    <ReadOnlySection header='Problem list' print={print}>
      <SectionRow>
        <table className={styles.TableItem}>
          <tr>
            <th>Status</th>
            <th>Problem</th>
            <th>Date</th>
            <th>Note/Code</th>
          </tr>
          {data &&
            data.problems &&
            data.problems.diagnoses.map((problem, index) => (
              <tr key={index}>
                <td>{problem.status}</td>
                <td>{problem.title}</td>
                <td>
                  {convertUtcIntToLocalDatetime(problem.lastUpdated).format(
                    "MM/DD/YYYY"
                  )}
                </td>
                <td>{problem.name}</td>
              </tr>
            ))}
          {data &&
            data.problems &&
            data.problems.notes.map((problem, index) => (
              <tr key={index}>
                <td>{problem.type}</td>
                <td>{problem.title}</td>
                <td>
                  {convertUtcIntToLocalDatetime(problem.created).format(
                    "MM/DD/YYYY"
                  )}
                </td>
                <td>
                  <p
                    dangerouslySetInnerHTML={{ __html: problem.note.content }}
                  ></p>
                </td>
              </tr>
            ))}
        </table>
      </SectionRow>
    </ReadOnlySection>
  );
}
