import clsx from "clsx";
import { Inter } from "next/font/google";
import { InputHTMLAttributes } from "react";
import { Control, Controller, FieldValue, FieldValues, Path } from "react-hook-form";

import OptionsMenu from "@/components/optionsMenu";

import styles from "@/components/input/styles.module.scss";

const font = Inter({
  subsets: ["latin"],
});

interface GeneratedBoolFieldProps<T extends FieldValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: Path<T>;
  defaultValue?: FieldValue<T>;
  control: Control<T>;
}

export default function GeneratedBoolField<T extends FieldValues>({
  label,
  name,
  defaultValue,
  control,
}: GeneratedBoolFieldProps<T>) {
  return (
    <div
      className={clsx(
        styles.Input,
        styles.Generated,
        font.className,
        styles.horizontal
      )}
    >
      <label htmlFor={name}>{label}</label>
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field }) => (
            <OptionsMenu
              options={[
                { id: "y", label: "Yes" },
                { id: "n", label: "No" },
              ]}
              selectedOption={
                field.value === null || field.value === undefined ? null : field.value ? "y" : "n"
              }
              onClick={(id) => {
                if (id === "y") {
                  field.onChange(field.value ? null : true);
                } else if (id === "n") {
                  field.onChange(field.value === false ? null : false);
                }
              }}
            />
          )}
        />
      </div>
    </div>
  );
}
