import GeneratedInput from "@/components/input/generated";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { NEWBORN_FORM } from "../constants";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import {
  ForwardedRef,
  Key,
  forwardRef,
  useEffect,
  useImperativeHandle
} from "react";
import {
  PatientUpsertRequest,
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";
import { NewbornFormPartsProps } from "./newborn";
import dayjs from "dayjs";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import ComboboxSelect from "@/components/input/combobox";

interface NewbornDemographicsProps extends NewbornFormPartsProps {
  newbornId: number;
  parentId: number;
  disabled?: boolean;
}

const NewbornDemographics = forwardRef(function (
  { disabled = false, newbornId, parentId, form }: NewbornDemographicsProps,
  ref: ForwardedRef<any>
) {
  const { data: newbornInfo } = usePatientGetInfoQuery(
    { patientId: newbornId },
    { skip: !newbornId }
  );

  const { data: parentInfo } = usePatientGetInfoQuery(
    { patientId: parentId },
    { skip: !parentId }
  );

  const [updateNewborn] = usePatientUpsertMutation();

  const {
    control,
    register,
    formState: { errors },
    setValue
  } = form;

  // We're going to forward our own custom submit function
  // so the newborn exam can call all of the submit functions
  // forwarded by children, so each exam part can deal with
  // different data.
  useImperativeHandle(ref, () => {
    return {
      examPartSubmit: (data: any) => {
        const newbornUpsertReq: PatientUpsertRequest = {
          user_id: newbornId,
          first_name: data.first_name,
          last_name: data.last_name,
          sex: data.baby_sex
        };
        updateNewborn({
          patientUpsertRequest: newbornUpsertReq
        });
      }
    };
  });

  useEffect(() => {
    if (!newbornInfo) {
      return;
    }

    if (newbornInfo.dob) {
      setValue(
        "birth_date",
        dayjs(`${newbornInfo.dob}`, "YYYYMMDDHHmmss").format("YYYY-MM-DD")
      );
    }

    if (newbornInfo.first_name) {
      setValue("first_name", newbornInfo.first_name);
    }

    if (newbornInfo.last_name || parentInfo?.last_name) {
      setValue("last_name", newbornInfo.last_name || parentInfo?.last_name);
    }

    if (newbornInfo.sex) {
      setValue("baby_sex", newbornInfo.sex);
    }
  }, [newbornInfo]);

  return (
    <>
      {newbornInfo &&
        NEWBORN_FORM.demographics.map(
          ({
            id,
            label,
            type = "text"
          }: {
            id: string;
            label: string;
            type?: string;
          }) => {
            if (id == "baby_sex") {
              return (
                <Controller
                  name={id}
                  control={control}
                  key={id}
                  render={({ field: { onChange } }) => (
                    <ComboboxSelect
                      label={label}
                      options={["MALE", "FEMALE"]}
                      onChange={v => onChange(v)}
                      value={newbornInfo.sex}
                      isHorizontalLayout
                    />
                  )}
                  rules={{ required: false }}
                />
              );
            } else if (id == "birth_time") {
              return (
                <GeneratedInput
                  type="time"
                  label={label}
                  name={id}
                  id={id}
                  key={id}
                  register={register}
                  errors={errors}
                />
              );
            } else {
              return (
                <GeneratedInput
                  key={id as Key}
                  name={id}
                  id={id}
                  label={label}
                  type={type}
                  register={register}
                  errors={errors}
                  placeholder={label}
                  disabled={disabled}
                />
              );
            }
          }
        )}
    </>
  );
});

NewbornDemographics.displayName = "NewbornDemographics";

export default NewbornDemographics;
