import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
import ReadOnlySection from "../readOnlySection";
import SectionRow from "../sectionRow";
import TableItem from "../tableItem";
import { LaborModel } from "@/store/services/patient";
import SectionCol from "../sectionCol";
import KeyValueGrid from "../keyValueGrid";
import KeyValueItem from "../keyValueItem";
import { EVENT_LABELS } from "@/components/flows/labor/constants";

interface SummarySignificantEventsInfoProps {
  labor: LaborModel;
}

export default function SummarySignificantEventsInfo({
  labor,
}: SummarySignificantEventsInfoProps) {
  const serializeResusciationInfo = (laborObject: LaborModel) => {
    if (!laborObject.stages.resusciation) {
      return [];
    }

    let rObjects: Array<any> = [];

    if (laborObject.stages.resusciation.recurring_events) {
      rObjects = laborObject.stages.resusciation.recurring_events?.filter(
        (item) => {
          const form = item.forms as Record<string, any>;
          return (
            form.name == "PPV_oxygen" || form.name == "chest_compressions_ppv"
          );
        }
      );
    }
    return rObjects;
  };

  if (!labor || serializeResusciationInfo(labor).length <= 0) {
    return <></>;
  }

  return (
    <ReadOnlySection header="Significant Events">
      <SectionRow>
        {labor.stages.resusciation && (
          <SectionCol>
            <h4>Resuscitation</h4>
            <KeyValueGrid>
              {serializeResusciationInfo(labor).map((item, index) => (
                <KeyValueItem
                  keyLabel={EVENT_LABELS[item.forms.name]}
                  key={index}
                >
                  <p>
                    {convertUtcIntToLocalDatetime(item.start as number).format(
                      "MM/DD/YYYY HH:mm"
                    )}
                  </p>
                </KeyValueItem>
              ))}
            </KeyValueGrid>
          </SectionCol>
        )}
      </SectionRow>
    </ReadOnlySection>
  );
}
