/* Wizard Name */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
import { useSelector } from "react-redux";

/* Local Imports */

// components
import Button from "../button";

// constants
import { STYLES } from "@/globals/constants";

// store
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

/* Wizard Typescript Interface */
interface WizardProps {
  steps: string[];
  labels: { [key: string]: string };
  children: (tab: string) => JSX.Element;
  onNextClick: (step: string) => void;
  customButtons?: {
    [step: string]: { label: string; actionId: string; disabled: boolean }[];
  };
  onCustomButtonClick?: (step: string) => void;
  isSubmitDisabled?: boolean;
  onSubmit?: () => any;
}

export default function Wizard({
  steps,
  labels,
  children,
  onNextClick,
  customButtons,
  onCustomButtonClick,
  isSubmitDisabled = true,
  onSubmit
}: WizardProps) {
  /* Redux */
  const { electronicOrderingEnabled, isSubmittingOrder } = useSelector(
    (state: RootState) => state.wizard
  );

  /* Local State */
  const [selectedStep, setSelectedStep] = useState(steps[0]);

  /* Effects */

  /* Event Handlers */

  const handleNext = () => {
    const selectedStepIndex = steps.indexOf(selectedStep);
    if (selectedStepIndex === steps.length - 1 && onSubmit) {
      onSubmit();
    } else {
      const nextStep = steps[selectedStepIndex + 1];
      setSelectedStep(nextStep);
      onNextClick(selectedStep);
    }
  };

  const handlePrevious = () => {
    const selectedStepIndex = steps.indexOf(selectedStep);
    const prevStep = steps[selectedStepIndex - 1];
    setSelectedStep(prevStep);
  };

  return (
    <div className={clsx(styles.Wizard)}>
      <div className={styles.stepMenu}>
        {steps.map((step, index) => (
          <>
            <button
              className={clsx(styles.stepLabel, {
                [styles.selected]: selectedStep === step
              })}
              key={step}
              type="button"
              onClick={() => setSelectedStep(step)}
            >
              <div className={styles.badge}>{index + 1}</div>
              <p className="t4 tMd">{labels[step]}</p>
            </button>
          </>
        ))}
      </div>
      <div className={styles.step}>
        {/* <div className={styles.stepTitle}>{labels[selectedStep]}</div> */}
        <div className={styles.stepContent}>{children(selectedStep)}</div>
      </div>
      <div className={styles.actions}>
        <Button
          style={STYLES.SECONDARY}
          onClick={handlePrevious}
          nativeButtonProps={{ disabled: steps.indexOf(selectedStep) === 0 }}
        >
          {"<-"} Previous
        </Button>
        <div className="flex">
          {customButtons &&
            customButtons[selectedStep] &&
            customButtons[selectedStep].map(button => (
              <Button
                style={STYLES.SECONDARY}
                key={button.label}
                onClick={() =>
                  onCustomButtonClick && onCustomButtonClick(button.actionId)
                }
                nativeButtonProps={{ disabled: button.disabled }}
              >
                {button.label}
              </Button>
            ))}
          <Button
            loading={isSubmittingOrder}
            onClick={handleNext}
            nativeButtonProps={{
              disabled:
                steps.indexOf(selectedStep) === steps.length - 1 &&
                isSubmitDisabled
            }}
          >
            {steps.indexOf(selectedStep) === steps.length - 1
              ? "Send Order"
              : "Next ->"}
          </Button>
        </div>
      </div>
    </div>
  );
}
