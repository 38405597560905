// External
import { useEffect, useState } from "react";

// Globals, constants, helpers
import { STYLES } from "@/globals/constants";

// API
import { FormSection } from "@/store/services/form";
import { FormSubmission } from "@/store/services/patient";

// Components
import BasicAccordion from "@/components/accordions/basic";
import Button from "@/components/button";
import ReadOnlyForm from "./readOnlyForm";
import DynamicFormSection from "@/components/forms/generated/dynamicFormSection";
import ReadOnlySection from "../birthSummary/readOnlySection";

interface HealthHistoryFormSectionProps {
  section: FormSection;
  submission: FormSubmission;
  onSubmit?: (data: any) => void;
  allOpen?: boolean;
  print?: boolean;
}

export default function HealthHistoryFormSection({
  section,
  submission,
  onSubmit,
  allOpen = true,
  print = false
}: HealthHistoryFormSectionProps) {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [localOpen, setLocalOpen] = useState<boolean | undefined>(true);

  useEffect(() => {
    setLocalOpen(allOpen);
  }, [allOpen]);

  if (print) {
    return (
      <ReadOnlySection header={section.label} print>
        <ReadOnlyForm section={section} submission={submission} />
      </ReadOnlySection>
    );
  }

  return (
    <BasicAccordion
      title={section.label}
      open={localOpen}
      customLeftContent={
        submission.data && (
          <Button
            style={STYLES.SECONDARY}
            onClick={e => {
              e?.stopPropagation();
              setIsEditing(!isEditing);
            }}
          >
            {isEditing ? "Cancel" : "Edit"}
          </Button>
        )
      }
      style={STYLES.SECONDARY}
    >
      {!isEditing && submission.data ? (
        <ReadOnlyForm section={section} submission={submission} />
      ) : (
        <DynamicFormSection
          section={section}
          submission={submission}
          onSubmit={(data: any) => {
            if (onSubmit) {
              onSubmit(data);
              setIsEditing(false);
            }
          }}
          onCancel={() => setIsEditing(false)}
        />
      )}
    </BasicAccordion>
  );
}
