import ReadOnlySection from "../readOnlySection";
import SectionRow from "../sectionRow";
import { LaborModel } from "@/store/services/patient";
import SectionCol from "../sectionCol";
import KeyValueGrid from "../keyValueGrid";
import KeyValueItem from "../keyValueItem";
import { useEffect, useState } from "react";

interface SummarySignificantEventsInfoProps {
  labor: LaborModel;
}

export default function SummaryPlacentaPerineumInfo({
  labor
}: SummarySignificantEventsInfoProps) {
  const [placentaForm, setPlacentaForm] = useState<Record<string, any>>({});
  const [lacerationForm, setLacerationForm] = useState<Record<string, any>>({});

  useEffect(() => {
    // we used to store the placenta and laceration forms under the birth stage
    // now we store them under the labor stage
    // this code is to handle the backwards compatibility of the data
    if (labor?.stages?.birth?.events?.placenta) {
      setPlacentaForm(
        labor.stages.birth.events.placenta.forms as Record<string, any>
      );
    }
    if (labor?.stages?.birth?.events?.laceration) {
      setLacerationForm(
        labor.stages.birth.events.laceration.forms as Record<string, any>
      );
    }
    if (labor?.stages?.labor?.events?.placenta) {
      setPlacentaForm(
        labor.stages.labor.events.placenta.forms as Record<string, any>
      );
    }
    if (labor?.stages?.labor?.events?.laceration) {
      setLacerationForm(
        labor.stages.labor.events.laceration.forms as Record<string, any>
      );
    }
  }, [labor]);

  const placentaFormKeys = [
    "appearance",
    "delivery",
    "intact",
    "presentation_at_delivery",
    "vessels"
  ];

  const lacerationFormKeys = ["laceration_degree", "repair", "comments"];

  const formatKey = (key: string) => {
    const capitalized = key.charAt(0).toUpperCase() + key.slice(1);
    return capitalized.replaceAll("_", " ");
  };

  if (!labor) {
    return <></>;
  }

  return (
    <ReadOnlySection header="Placenta and Perineum">
      {/* Event under labor, match the form */}
      <SectionRow>
        <SectionCol>
          <KeyValueGrid>
            {placentaFormKeys.map(key => (
              <KeyValueItem keyLabel={`${formatKey(key)}:`} key={key}>
                <p>{placentaForm && placentaForm[key]}</p>
              </KeyValueItem>
            ))}
          </KeyValueGrid>
        </SectionCol>
        <SectionCol>
          <KeyValueGrid>
            {lacerationFormKeys.map(key => (
              <KeyValueItem keyLabel={`${formatKey(key)}:`} key={key}>
                <p>{lacerationForm && lacerationForm[key]}</p>
              </KeyValueItem>
            ))}
          </KeyValueGrid>
        </SectionCol>
      </SectionRow>
    </ReadOnlySection>
  );
}
