import { createSlice } from "@reduxjs/toolkit";
import {v4} from "uuid"
import { STATUS_KEYS } from "@/globals/constants";

type ToastAlert = {
    message?: string;
    type?: keyof typeof STATUS_KEYS;
    isVisible: boolean;
    id: number;
}

interface ToastTroughState {
    alerts: (any | ToastAlert)[]
}

const initialState: ToastTroughState = {
    alerts: []
};
export const toastSlice = createSlice({
  name: "toastTrough",
  initialState: initialState,
  reducers: {
    addAlertToToastTrough: (state, { payload }) => {
      const id = payload.id || v4();
      const newAlert = { ...payload, id }
      if (state.alerts.length > 3) {
        const newAlerts = state.alerts.shift()
        state.alerts.push(newAlert)
      } else {
        state.alerts.push(newAlert);
      }
      },
    removeAlertFromToastTrough: (state, { payload: id }) => {
        state.alerts = state.alerts.filter((alert) => alert.id !== id)
  },
  },
});

export const { addAlertToToastTrough, removeAlertFromToastTrough } = toastSlice.actions;

export default toastSlice.reducer;
