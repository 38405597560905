import * as generated from "./generated/problem";

const problem = generated.problem.enhanceEndpoints({
  endpoints: {
    problemUpsert: {
      invalidatesTags: ["Problems", "Patients"]
    },
    problemInfo: {
      providesTags: ["Problems"]
    }
  }
});

export * from "./generated/problem";
export default problem;
export const {
  useProblemUpsertMutation,
  useProblemInfoQuery,
  useLazyProblemInfoQuery,
} = problem;
